<template>
  <TopNavigatorBar ref="topNavBarDOMTag" :highlightIndex="0"/>
<!--  锚点定位-->
  <div class="v-space1"></div>
<!--  图片生成块-->
  <TxtToimgMain />
<!--  锚点定位2-->
  <div class="v-space2"></div>
  <!-- 风格展示块 -->
  <AiGirlShowcase/>
  <IntroduceItem style="background: #0D0D0D;" @click="introduceAction"/>
  <HowToUseSection style="background: #171717;"/>
  <ReviewSection style="background: #0D0D0D;"/>
  <FAQSection style="background: #171717;"/>
  <BottomBar/>
  <div class="v-space-extra"></div>
</template>

<script setup>
import IntroduceItem from '@/components/home/IntroduceHomeIndex.vue';
import TopNavigatorBar from '@/components/TopNavigatorBar.vue';
import HowToUseSection from '@/components/txtToImgIndex/HowToUseSection.vue';
import ReviewSection from '@/components/txtToImgIndex/ReviewSection.vue';
import FAQSection from '@/components/txtToImgIndex/FAQSection.vue';
import BottomBar from '@/components/BottomBar.vue';
import { ref, onMounted, getCurrentInstance, provide } from 'vue';

import TxtToimgMain from '@/components/txtToimg/TxtToimgMain.vue';
import { useTxtToImg } from '@/stores/useTxtToImg';
import AiGirlShowcase from '@/components/txtToimg/AiGirlShowcase.vue';


const { $api, $utility, $analytic, i18n } = getCurrentInstance().appContext.config.globalProperties;
const topNavBarDOMTag = ref(null);
useTxtToImg().init();

const sArg = $utility.getQueryVariable('s');
if (sArg) {
  window.localStorage.setItem('affiliateChildKey', sArg);
  $api.affiliateVisit(sArg);
}

onMounted(() => {
  document
    .querySelector('title')
    .innerText = i18n.global.t('meta.title');
  document
    .querySelector('meta[name="description"]')
    .setAttribute('content', i18n.global.t('meta.description'));
  document
    .querySelector('meta[name="keywords"]')
    .setAttribute('content', i18n.global.t('meta.keywords'));

  $analytic.page = 'creation';
  $analytic.log('creation_screen_express');
});

const introduceAction = () => {
    $analytic.log('desc_freetrial_tick');
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
};

provide('toSignUpHandle', () => {
  topNavBarDOMTag.value.toSignUp();
});

</script>

<style lang="scss">
@import "@/assets/overallScss/appMainSc.scss";

body {
  background-color: #070A14;
  background-position: top;
  background-size: 100%;
  background-repeat: no-repeat;
  margin: 0px;
  overscroll-behavior-y: none;
  color: #FFFFFF;
}
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background: transparent; /* make scrollbar transparent */
}
.v-space1 {
  height: 89px;
}
.v-space2 {
  //height: 51px;
}
.v-space-extra {
    height: 0px;
}
@media (max-width: 1070px) {
  .v-space1 {
    height: 66px;
  }
  .v-space2 {
    height: 21px;
  }
  .v-space-extra {
    height: 50px;
  }
}
</style>
