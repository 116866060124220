import basic from '../basic/fr'
export default {
    ...basic,
    ...{
        ok : "D'ACCORD",
        tutorialEntrance : "Comment réaliser une création de qualité",
        examplePopup : {
            title : "Comment réaliser une création de haute qualité ?",
            photoTip : "Conseils : Pour obtenir la meilleure photo d'échange de visage IA, veuillez vous assurer de la qualité de la photo que vous souhaitez refaire en ligne.",
            videoTip : "Conseils : Pour obtenir la meilleure vidéo d'échange de visage par IA, veuillez vous assurer de la qualité de la vidéo que vous souhaitez refaire en ligne.",
            goodExample : "De bons exemples",
            badExample : "Mauvais exemples",
            highQuality : "Haute qualité",
            lookingAtViewer : "Regarder la visionneuse",
            sideFaces : "·Faces latérales",
            tooFar : "·Trop loin",
            blurry : "Flou",
            lessCover : "Moins de couverture",
            lowResolution : "Basse résolution",
        },
        youtubeSectionTitle : "Comment utiliser l'échange de visage ?",
        tutorial : "Didacticiel",        
    },
}