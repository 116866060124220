<template>
  <div class="faceswap-edit-secondary-container" :style="{maxHeight: state.maxHeight}">
    <div :class="['wrapper', { none: !state.wsWorkList.length }]">
      <div class="tip">
        <div class="for-you">
          {{
            $t("textToImgToHistory.tab1or2header")
          }}
          &nbsp;
        </div>
        <div class="to-history" @click="toHistory">
          {{ $t("textToImgToHistory.footer") }}↗
        </div>
      </div>
      <div class="none-work" v-if="!state.wsWorkList.length">
        <img :src="$utility.assetImage('textToImgDefBackimg')" alt=""/>
      </div>
      <MultWorkFaceSwap
          v-if="state.wsWorkList.length === 1"
          class="one-work"
          :work="state.wsWorkList[0]"
          :bigImgType="'txtToImg'"
      >
      </MultWorkFaceSwap>
      <div
          v-if="
          (state.wsWorkList.length > 1 && !state.isMobile) ||
          (state.isMobile &&
            state.wsWorkList.length < 7 &&
            state.wsWorkList.length > 1)
        "
          class="mult-work-list" ref="workBoxRef"
      >
        <MultWorkFaceSwap
            class="mult-works"
            v-for="item in state.wsWorkList"
            :key="item.taskId"
            :work="item"
            :bigImgType="'txtToImg'"
        ></MultWorkFaceSwap>
      </div>
      <div
          class="swiper-work"
          v-if="state.isMobile && state.splitWsWorkList.length > 1"
      >
        <Swiper
            @slideChange="onSwiper"
            :slides-per-view="1"
            :space-between="50"
        >
          <SwiperSlide
              v-for="(item, index) in state.splitWsWorkList"
              :key="index"
          >
            <div class="swiper-mult-work-list">
              <MultWorkFaceSwap
                  class="mult-works"
                  v-for="childitem in item"
                  :key="childitem.taskId"
                  :work="childitem"
                  :bigImgType="'txtToImg'"
              ></MultWorkFaceSwap>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <div
          class="swiper-nums"
          v-if="state.isMobile && state.splitWsWorkList.length > 1"
      >
        <div
            :class="['swiper-num', `${index === state.activeIndex ? 'act' : ''}`]"
            v-for="(item, index) in state.splitWsWorkList.length"
            :key="item"
        ></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  getCurrentInstance,
  reactive,
  ref,
  computed,
  watch,
  onUpdated,
  inject,
} from 'vue';
import MultWorkFaceSwap from '@/components/MultWorkFaceSwap.vue';
import {Task} from '@/stores/task';
import _ from 'lodash';
import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/css';
import {Pagination} from 'swiper/modules';
import 'swiper/css/pagination';
import {User} from '@/script/user';
import {useFaceSwap} from '@/stores/useFaceSwap';
import { signUpDialog } from '@/components/Dialog';
import {useTxtToImgTaskManager} from "@/stores/useTxtToImgTaskManager";

const modules = [Pagination];

let {$utility, $showErrorToast} =
    getCurrentInstance().appContext.config.globalProperties;
const signImgSwapFaceUrl = $utility.assetImage('sign_img_swap_face');
const multImgSwapFaceUrl = $utility.assetImage('mult_img_swap_face');
const signVideoSwapFaceUrl = $utility.assetImage('sign_video_swap_face');
const multVideoSwapFaceUrl = $utility.assetImage('mult_video_swap_face');
const images = [
  signImgSwapFaceUrl,
  multImgSwapFaceUrl,
  signVideoSwapFaceUrl,
  multVideoSwapFaceUrl,
];
const workBoxRef = ref(null)
const activeIndexImage = inject('tabCategory');
const state = reactive({
  wsWorkList: [],
  isMobile: window.mobileAndTabletCheck(),
  splitWsWorkList: [],
  activeIndex: 0,
  upOnePage: '',
  isLoginShow: false,
  isSignUp: false,
  isResetPassWord: false,
  maxHeight: '',
});
watch(()=> state.wsWorkList.length, () => {
  if (workBoxRef.value) {
    workBoxRef.value.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
})
// watch(() => useFaceSwap().faceswapEditPrimaryContainerHeight, () => {
//   state.maxHeight = `${useFaceSwap().faceswapEditPrimaryContainerHeight}px`;
//   if ($utility.isNarrow.value) {
//     state.maxHeight = 'none';
//   }
// });
const toSignUp = (type) => {
  state.upOnePage = type;
  (state.isLoginShow = false), (state.isSignUp = true);
};
const toPassWord = (type) => {
  (state.isLoginShow = false), (state.isResetPassWord = true);
  state.upOnePage = type;
};
const backlogin = () => {
  state.upOnePage = null;
  state.isSignUp = false;
  state.isResetPassWord = false;
  state.isLoginShow = true;
};
watch(useTxtToImgTaskManager().tasks, () => {
  const taskList = useTxtToImgTaskManager().tasks;
  state.wsWorkList = taskList;
  taskList.forEach((element) => {
    if (
        element.taskStatus === Task.STATUS_GENERATE_ERROR &&
        element.failedMessage
    ) {
      $showErrorToast(element.failedMessage);
      useTxtToImgTaskManager().clearItemFailedMessage(element.taskId);
    }
  });
  // 移动端 六页后分页
  if (state.isMobile && taskList.length > 5) {
    state.splitWsWorkList = _.chunk(taskList, 6);
  }
});
const onSwiper = (swiper) => {
  state.activeIndex = swiper.activeIndex;
};
const toHistory = () => {
  if (!User.getInstance.isLogin.value) {
    signUpDialog();
    return;
  }
  window.open(`${window.location.protocol}//${window.location.host}/history`);
};
</script>

<style scoped lang="scss">
.faceswap-edit-secondary-container .wrapper {
  padding: 20px 0px 0px;
  display: flex;
  flex-direction: column;

  .tip {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    margin-bottom: 33px;
    font-size: 14px;
    padding: 0 20px;
    flex-wrap: wrap;
    /* height: 50px; */
    font-weight: 500;
    color: #999999;
    line-height: 16px;
    text-align: right;
    font-style: normal;

    .for-you {
      text-wrap: nowrap;
      color: #999999;
    }

    .to-history {
      color: #ff9441;
      cursor: pointer;
      text-decoration: underline;
      text-decoration-color: #ff9441;
      text-wrap: nowrap;

      &:hover {
        color: #E05435;
        text-decoration-color: #E05435;

      }
    }
  }

  .none-work {
    padding: 0 20px;
    flex: 1;
    background: #20222B;
    height: 100%;
    border-radius: 8px;

    img {
      width: 100%;
      height: 100%;
      max-width: 613px;
      object-fit: contain;
    }
  }

  .mult-work-list {
    width: calc(100% - 28px);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: start;
    margin-bottom: 14px;
    overflow-y: scroll;
    padding: 14px;
    height: 100%;
    max-height: 766px;
    //background: #20222B 50%;
    //border-radius: 8px;

    &::-webkit-scrollbar {
      color: rgba(255, 255, 255, 0.14);
      border-radius: 3px;
      width: 6px;
      display: none;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.14);
      border-radius: 3px;
      cursor: pointer;
    }
  }

  .swiper-work {
    flex: 1;
    width: 100%;
    margin-bottom: 30px;

    .swiper {
      height: 100%;
    }

    .swiper-mult-work-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-content: start;
      height: 100%;
    }
  }

  .swiper-nums {
    position: absolute;
    bottom: 14px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;

    .swiper-num {
      width: 4px;
      height: 4px;
      background: rgba(255, 255, 255, 0.5);
      margin: 0 4px;
      border-radius: 2px;

      &.act {
        width: 14px;
        height: 4px;
        background: #ffffff;
      }
    }
  }
}

@media (max-width: 1300px) {
  .faceswap-edit-secondary-container .wrapper {
    padding: 16px 0px 0px;
    .mult-work-list {
      //max-height: max-content;
      //box-sizing: border-box;
      //overflow-y: scroll;
      //padding: 14px;
      //height: 100%;
    }
    .tip {
      //font-size: 12px;
      display: block;
      //line-height: 14px;
      text-align: center;
      margin-bottom: 15px;

      .for-you {
        color: #999999;
        margin-bottom: 10px;
      }

      .to-history {
        margin: 0 auto;
        width: max-content;
        color: #ff9441;
      }
    }
  }
}

@media (min-width: 1071px) {
  .faceswap-edit-secondary-container {
    max-height: 816px;
  }

  .faceswap-edit-secondary-container .wrapper {
    height: 100%;
    max-height: 100%;
    position: relative;
    padding-bottom: 22px;
    box-sizing: border-box;
  }
}

@media (max-width: 1070px) {
  .faceswap-edit-secondary-container .wrapper {
    margin-top: 20px;
    background-color: #20222b;
    border-radius: 16px;
    height: 625px;
    position: relative;
    .mult-work-list {
      //max-height: max-content;
      box-sizing: border-box;
      width: 100%;
      overflow-y: scroll;
    }
  }
  .faceswap-edit-secondary-container .wrapper.none {
    height: 425px;

    .none-work {
      width: 280px;
      height: 208px;
      position: absolute;
      top: 45%;
      left: 50%;
      margin-top: 20px;
      transform: translate(-50%, -50%);
    }
  }
  .faceswap-edit-secondary-container .wrapper .tip{
    display: block;
    //line-height: 14px;
    text-align: center;
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    font-style: normal;
  }
}
</style>
