<template>
  <div :class="[$attrs.class, 'mult-work']">
    <!-- 环形状态页 -->
    <div
        class="main-status"
        v-if="'mainStatus' == isShowModel(props.work.taskStatus)"
    >
      <div ref="chartRef" class="charts-ref"></div>
      <div class="status-text">{{ statueText }}</div>
      <div class="status-about">
        {{
          computstatusAbout()
        }}
      </div>
      <div class="status-batch">{{ props.bigImgType == 'txtToImg' ? $t('choose.batchsupportAIGirl') : $t("choose.batchsupport") }}</div>
    </div>
    <!-- 重试页 -->
    <div
        class="main-retry"
        v-if="'mainRetry' == isShowModel(props.work.taskStatus)"
    >
      <img class="img" :src="faceSwapRetry" alt=""/>
      <div class="retry-text">
        {{ $t("choose.retryText") }}
      </div>
      <div
          @click="retryTask"
          class="retry-btn"
      >
        {{ $t("choose.retry") }}
      </div>
    </div>
    <!-- 成功页 -->
    <div
        v-if="'mainSuccess' == isShowModel(props.work.taskStatus)"
        class="main-success"
        @click="showBigView(props.work.taskId)"
    >
      <img class="back-img" :src="props.work.imageBlobUrl" alt=""/>
      <div class="success-img">
        <div class="video-img" v-if="props.work.fileData.fileType !== 'image'">
          <img :src="video_icon" alt=""/>
        </div>
        <img class="voc_img" :src="props.work.imageBlobUrl" alt=""/>
      </div>

      <div @click.stop="downLoad" class="down">
        <img class="down-img" :src="faceSwapDown" alt=""/>
      </div>
    </div>
  </div>
  <MediaSwiperAiImg v-if="state.isShow" :wsWork="work" v-model:visible="state.isShow" :dataList="dataList"
                    :index="defIndex" :showDelete="false" :bigImgType="bigImgType"
                    @on-slide-change="onSlideChange"></MediaSwiperAiImg>

  <!--  <ImageVideoDialog v-if="state.isShow" :wsWork="work" v-model:isShow="state.isShow" />-->
</template>
<script setup>
import * as echarts from 'echarts';
import { useAttrs,
  defineProps,
  onMounted,
  getCurrentInstance,
  reactive,
  ref,
  computed,
  onUpdated,
  nextTick, } from 'vue';
import { useFaceSwapTaskManager } from '@/stores/useFaceSwapTaskManager';
import { useTxtToImgTaskManager } from '@/stores/useTxtToImgTaskManager';
import { useFaceSwap } from '@/stores/useFaceSwap';
import eventBus from '@/eventBus/index';
import MediaSwiperAiImg from '@/components/MediaSwiperAiImg.vue';

const $attrs = useAttrs();
let { $utility, i18n, $analytic } = getCurrentInstance().appContext.config.globalProperties;

// 重试按钮
const faceSwapRetry = $utility.assetImage('face_swap_retry');
// 下载
const faceSwapDown = $utility.assetImage('face_swap_down');
const video_icon = $utility.assetImage('video_icon');

const defIndex = ref(0);
const props = defineProps({
  work: {
    type: Object,
    required: true,
  },
  bigImgType: {
    type: String,
    default: '',
  },
});
let dataList = reactive([]);
const state = reactive({ isShow: false });
const chartRef = ref(null);

function formatTime (seconds) {
  const hrs = Math.floor(seconds / 3600);
  const mins = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;

  const formattedHrs = String(hrs).padStart(2, '0');
  const formattedMins = String(mins).padStart(2, '0');
  const formattedSecs = String(secs).padStart(2, '0');

  return `${formattedHrs}:${formattedMins}:${formattedSecs}`;
}

/** 根据status判断显示模块 */
function isShowModel (taskStatus) {
  if (taskStatus === -1 || taskStatus === -1001) {
    if (myChart) { // 释放图表资源
      myChart.dispose();
      myChart = null;
    }
    return 'mainRetry';
  }
  if (taskStatus !== -1 && taskStatus !== 4) {
    return 'mainStatus';
  }
  if (taskStatus === 4) {
    if (myChart) { // 释放图表资源
      myChart.dispose();
      myChart = null;
    }
    return 'mainSuccess';
  }
  return 'error';
}

const showBigView = (taskId) => {
  $analytic.log('creation_image_max_screen_tick', {
    style: props.work.submitParams.styleKey,
    taskKey: props.work.taskId,
  });
  let temdatalist = [];
  if (props.bigImgType == 'txtToImg') {
    temdatalist = useTxtToImgTaskManager().tasks;
  } else {
    useFaceSwap().pausePreviewVideoFlag += 1;
    temdatalist = useFaceSwapTaskManager().tasks;
  }
  dataList = temdatalist.filter((item) => {
    return item.taskStatus == 4;
  });
  for (let i = 0; i < dataList.length; i++) {
    if (dataList[i].taskId == taskId) {
      defIndex.value = i;
    }
  }
  // dataList = [{...props.work}]
  state.isShow = true;
};
const onSlideChange = (arges) => {
  // console.log("当前选中项")
  // console.log(arges[0])
};

const statueText = computed(() => {
  const key = props.work.taskStatus;
  switch (key) {
    case 0:
      return i18n.global.t('choose.submitting');
    case 1:
      return i18n.global.t('choose.queuing');
    case 2:
      return i18n.global.t('choose.creating');
    case 3:
      return i18n.global.t('choose.downloading');
    case 4 || 'success':
      return i18n.global.t('choose.downloading');
  }
});

let myChart;
const init = async () => {
  if (props.work.taskStatus === -1 || props.work.taskStatus === 4) return;
  await nextTick();
  myChart = echarts.init(chartRef.value);
  let option = {
    series: [
      {
        type: 'gauge',
        startAngle: 90,
        endAngle: -270,
        pointer: { show: false },
        progress: {
          show: true,
          overlap: false,
          roundCap: true,
          clip: false,
          itemStyle: { borderWidth: 0 },
        },
        axisLine: {
          lineStyle: {
            width: 4,
            color: [
              [1, '#3c3e42'], // 未被数据填充部分颜色
            ],
          },
        },
        splitLine: {
          show: false,
          distance: 0,
          length: 10,
        },
        axisTick: { show: false },
        axisLabel: {
          show: false,
          distance: 50,
        },
        data: [
          {
            value: props.work.progress * 100,
            itemStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: '#FF9441', // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: '#D33A31', // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
              borderWidth: 0,
              borderCap: 'round',
            },
          },
        ],
        detail: { show: false },
      },
    ],
  };

  myChart.setOption(option);
};
const downLoad = () => {
  if (props.bigImgType == 'txtToImg') {
    $analytic.log('creation_image_downing_tick', {
      imageurl: props.work.fileData.fileUrl,
      face: props.work.submitParams.faceId ? '1' : '0',
    });
    useTxtToImgTaskManager().downloadToLocal(props.work);
  } else {
    useFaceSwapTaskManager().downloadToLocal(props.work);
  }
};
const retryTask = () => {
  if (props.bigImgType == 'txtToImg') {
    useTxtToImgTaskManager().retry(props.work);
    setTimeout(() => {
      init();
    }, 200);
  } else {
    useFaceSwapTaskManager().retry(props.work);
    setTimeout(() => {
      init();
    }, 200);
  }
};
onMounted(() => {
  init();
  eventBus.on('retrySameParamsTask', (e) => {
    if (e == props.work.submitParamsIdentify) {
      setTimeout(() => {
        init();
      }, 200);
    }
  });
});
onUpdated(() => {
  myChart &&
  myChart.setOption({
    series: [
      {
        data: [
          {
            value: props.work.progress * 100,
            itemStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: '#FF9441', // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: '#D33A31', // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
              borderWidth: 0,
              borderCap: 'round',
            },
          },
        ],
        pointer: { show: false },
      },
    ],
  });
});
const computstatusAbout = () => {
  if (props.work.currentLeftTime == 0) {
    const key = props.work.taskStatus;
    switch (key) {
      case 0:
        return i18n.global.t('choose.keepPatient');
      case 1:
        return i18n.global.t('choose.needMoretime');
      case 2:
        return i18n.global.t('choose.aimostDoneKeep');
    }
  }
  return i18n.global.t('choose.aboutS', { leftTime: formatTime(props.work.currentLeftTime) });
};
</script>
<style lang="scss" scoped>
.mult-work {
  width: calc(50% - 6px);
  height: 314px;
  overflow: hidden;
  margin-bottom: 14px;
  background: #282a33;
  border-radius: 20px;
  position: relative;

  .main-status {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .charts-ref {
      width: 70px;
      height: 70px;
    }

    .status-text {
      margin-top: 10px;
      font-family: 'PingFang SC';
      font-weight: 500;
      font-size: 16px;
      color: #ffffff;
      line-height: 19px;
      text-align: center;
      font-style: normal;
    }

    .status-about {
      margin-top: 4px;
      font-weight: 500;
      font-size: 14px;
      color: #888a98;
      line-height: 16px;
      text-align: center;
      font-style: normal;
    }

    .status-batch {
      padding: 0 20px;
      margin-top: 10px;
      font-weight: 500;
      font-size: 12px;
      color: #ffffff;
      line-height: 14px;
      text-align: center;
    }
  }

  .main-retry {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;

    .img {
      width: 72px;
      height: 60px;
    }

    .retry-text {
      margin-top: 20px;
      font-family: 'PingFang SC';
      font-weight: 500;
      font-size: 14px;
      color: #888a98;
      line-height: 20px;
      text-align: center;
      font-style: normal;
    }

    .retry-btn {
      cursor: pointer;
      margin-top: 20px;
      width: 100px;
      height: 30px;
      background: #888a98;
      border-radius: 15px;
      line-height: 30px;
      text-align: center;
      font-size: 14px;
      color: #20222b;
    }
  }

  .main-success {
    height: 100%;
    overflow: hidden;
    position: relative;

    .back-img {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 0;
      filter: blur(15px);
    }

    .success-img {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;

      .video-img {
        width: 50px;
        height: 50px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        img{
          width: 100%;
          height: 100%;
        }
      }

      .voc_img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .down {
      cursor: pointer;
      position: absolute;
      top: 10px;
      right: 10px;
      width: 36px;
      height: 36px;
      background: rgba(0, 0, 0, 0.45);
      border-radius: 9px;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        background: #000000;
      }
      .down-img {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.mult-works.mult-work {
  .main-retry {
    .retry-text {
      margin-top: 10px;
      font-family: 'PingFang SC';
      font-weight: 500;
      font-size: 12px;
      color: #888a98;
      line-height: 20px;
      text-align: center;
      font-style: normal;
    }

    .retry-btn {
      cursor: pointer;
      margin-top: 10px;
      width: 100px;
      height: 30px;
      background: #888a98;
      border-radius: 15px;
      line-height: 30px;
      text-align: center;
      font-size: 14px;
      color: #20222b;
    }
  }
}

.one-work {
  width: calc(100% - 28px);
  margin: 0 14px;
  flex: 1;
  margin-bottom: 14px;
  overflow: hidden;
  background: #282a33;
  border-radius: 20px;
  position: relative;
}

@media (max-width: 1300px) {
  .one-work {
    margin-bottom: 10px;
  }

  .mult-works {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 450px) {
  .mult-works.mult-work {
    width: calc(50% - 6px);
    height: calc(calc(100% - 24px) / 3);
  }
  .one-work.mult-work {
    width: calc(100% - 28px);
  }
  .mult-works.mult-work .main-retry {
    .img {
      width: 46px;
      height: 38px;
    }

    .retry-text {
      margin-top: 10px;
      font-weight: 500;
      font-size: 12px;
      color: #888a98;
      line-height: 14px;
      text-align: center;
    }

    .retry-btn {
      width: 80px;
      height: 24px;
      background: #888a98;
      border-radius: 15px;
      line-height: 24px;
    }
  }

  .mult-works.mult-work .main-status {
    .charts-ref {
      height: 40px;
      width: 40px;
    }

    .status-text {
      font-family: 'PingFang SC';
      font-size: 14px;
      line-height: 16px;
      margin-top: 10px;
    }

    .status-about {
      font-size: 12px;
      color: #888a98;
      line-height: 14px;
      margin-top: 4px;
    }

    .status-batch {
      margin-top: 4px !important;
      line-height: 12px !important;
      font-size: 10px !important;
      padding: 0 5px !important;
    }
  }
}

@media screen and (max-width: 1070px) {
  //.mult-work .main-success .back-img {
  //  display: none;
  //}
  //.mult-work .main-success .success-img .voc_img {
  //  object-fit: cover;
  //}
}
</style>
