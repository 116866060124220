<template>
    <div v-if="visible" class="container">
        <img v-if="slideIndex > 0" class="last-icon" @click="showLast" :src="assetImage('swiper_left')" />
        <img v-if="slideIndex < (files.length - 1)" class="next-icon" @click="showNext"
            :src="assetImage('swiper_right')" />
        <img class="close" @click="closeSwiper" :src="assetImage('dialog_close')" />

        <div v-if="!isDemoFile && showDelete" class="delete">
            <img @click="deleteFile" :src="assetImage('upload_file_delete')" />
        </div>

        <swiper-container :initialSlide="index" :cssMode="isMobile()" @swiperprogress="onProgress"
            @swiperslidechange="onSlideChange">
            <swiper-slide v-for="(item, itemIndex) in files" :key="item.file.fileKey">

                <div v-if="!itemVisibleList[itemIndex]" class="bg-image">
                    <VOCImageView :requestInfo="imageRequestInfo(item)"
                        style="width: 100%;height: 100%;filter: blur(40px);" :key="item.file.fileKey + '1'">
                    </VOCImageView>
                </div>

                <div v-if="!itemVisibleList[itemIndex]" class="foreground-image">
                    <VOCImageView :requestInfo="imageRequestInfo(item)" :key="item.file.fileKey + '2'"
                        objectFit="contain" style="width: 100%; height: 100%; ">
                    </VOCImageView>
                </div>

                <template v-if="item.file.fileType === 'video' && !itemVisibleList[itemIndex]">
                    <img :src="assetImage('icon_play')" @click="showVideo(itemIndex)" class="play-icon" />
                </template>


                <template v-if="itemVisibleList[itemIndex]">
                    <HlsVideoView :videoSrc="item.file.fileUrl" class="video"></HlsVideoView>
                </template>

            </swiper-slide>


        </swiper-container>

    </div>


</template>

<script setup>
import VOCImageView from '@/components/VOCImageView.vue';
import { CommonUtility } from '@/script/utility';
import { VOCImageRequestInfo } from '@/script/voc_img_tool';
import { register } from 'swiper/element/bundle';
import { uploadFilesVM } from '@/vm/faceswap_vm'
import { computed, getCurrentInstance, onMounted, reactive, ref } from 'vue';
import { InfoDialogType } from '../dialog/infoDialog';
import HlsVideoView from '@/components/HlsVideoView.vue';
import { useFaceSwap } from '@/stores/useFaceSwap';


let { $showInfoDialog, i18n } = getCurrentInstance().appContext.config.globalProperties


register()

let prop = defineProps({
    visible: {
        type: Boolean,
        default: false,
    },
    files: {
        type: Array,
        default: [],
    },
    index: {
        type: Number,
    },
    showDelete: {
        type: Boolean,
        default: true,
    },
    emptyCallback: {
        type: Function,
    }
})
const emit = defineEmits(['update:visible']);


const isDemoFile = ref(false)
const slideIndex = ref(0)
const itemVisibleList = reactive([])
onMounted(() => {
    setSlideIndex(prop.index)
    setDemoFlagValue()
    itemVisibleList.length = 0
    itemVisibleList.concat(prop.files.map(() => false))
})
const onProgress = (e) => {
    // const [swiper, progress] = e.detail;
    // console.log(progress)
};

const onSlideChange = (e) => {

    let swiper = getSwiper()
    if (swiper) {
        itemVisibleList[slideIndex.value] = false
        setSlideIndex(swiper.activeIndex)
        setDemoFlagValue()
    }
}

const closeSwiper = () => {
    emit('update:visible', false);
}



const deleteFile = () => {
    $showInfoDialog(InfoDialogType.Normal, { title: i18n.global.t('infoDialog.deleteFile'), imageSrc: "dialog_delete", leftBtnText: i18n.global.t('infoDialog.confirm'), rightBtnText: i18n.global.t('infoDialog.cancel'), }).then((result) => {
        if (!result) {
            let index = getSwiper().activeIndex
            const reduce = (index != 0) && (index == uploadFilesVM.files.length - 1)
            uploadFilesVM.deleteFile(index).then((result) => {
                if(result && uploadFilesVM.files.length > 0){
                    itemVisibleList.splice(index,1)
                    getSwiper().update()
                    setSlideIndex(getSwiper().activeIndex)
                }

            })
            if (uploadFilesVM.files.length == 0) {
                useFaceSwap().setSelectedFiledIndex(-1)
                prop.emptyCallback()
            }else {
                useFaceSwap().setSelectedFiledIndex(reduce ? useFaceSwap().selectedFiledIndex - 1 : useFaceSwap().selectedFiledIndex)
            }
        }
    })
}

const showNext = () => {
    getSwiper().slideNext();
}

const showLast = () => {
    getSwiper().slidePrev();
}

const showVideo = (itemIndex) => {
    itemVisibleList[itemIndex]  = true
}

function isMobile() {
    return window.mobileAndTabletCheck()
}

function imageRequestInfo(item) {
    let url
    if (item.file.fileType === "video") {
        url = item.file.fileThumbUrl
    } else {
        url = item.file.fileUrl
    }
    return new VOCImageRequestInfo({
        url: url
    })
}

function getSwiper() {
    const swiperEl = document.querySelector('swiper-container');
    if (swiperEl) {
        return swiperEl.swiper
    }
    return null
}

function setDemoFlagValue() {
    let item = prop.files[slideIndex.value]
    if (item.file.demoFlag) {
        isDemoFile.value = true
    } else {
        isDemoFile.value = false
    }
}

function setSlideIndex(index) {
    slideIndex.value = index
}

function assetImage(name) {
    return CommonUtility.assetImage(name)
}

</script>

<style scoped lang="scss">
.container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgb(3, 17, 18);
    z-index: 1001;

    display: flex;
    justify-content: center;
    align-items: center;

}

swiper-container {
    width: 100%;
    height: 100%;
    cursor: grab;

}

swiper-slide {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.bg-image {
    width: 53%;
    height: 75%;
    position: absolute;
    overflow: hidden;
}

.foreground-image {
    width: 53%;
    height: 75%;
    position: absolute;
    object-fit: contain;

    img {
        display: block;
        object-fit: contain;
    }
}

.video {
    width: 53%;
    height: 75%;
    position: absolute;
    object-fit: contain;
}

.last-icon {
    width: 31px;
    height: 56px;
    position: absolute;
    left: 14%;
    z-index: 1002;
    cursor: pointer;
}

.next-icon {
    width: 31px;
    height: 56px;
    position: absolute;
    right: 14%;
    z-index: 1002;
    cursor: pointer;
}

.close {
    width: 40px;
    height: 40px;
    position: absolute;
    right: 14%;
    top: 20px;
    z-index: 1002;
    cursor: pointer;
}

.delete {
    width: 56px;
    height: 56px;
    background: #FFFFFF1A;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 78%;
    top: 12%;
    z-index: 1002;
    cursor: pointer;

    img {
        width: 26px;
        height: 28px;
    }
}

.play-icon {
    width: 100px;
    height: 100px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &:hover {
        background-image: url('@/assets/images/png/icon_play_hover.png');
        background-size: 100px 100px;
    }
}

@media (max-width: 600px) {

    .delete {
        width: 30px;
        height: 30px;
        border-radius: 8px;
        left: unset;
        right: 15px;
        top: 20px;

        img {
            width: 14px;
            height: 14px;
        }
    }

    .close {
        width: 30px;
        height: 30px;
        border-radius: 8px;
        left: 15px;
        top: 20px;
    }

    .last-icon {
        width: 0;
        height: 0;

    }

    .next-icon {
        width: 0;
        height: 0;
    }


    swiper-slide {
        align-items: end;
    }

    .bg-image {
        width: 100%;
        height: 87.5%;
    }

    .foreground-image {
        width: 100%;
        height: 87.5%;

    }

    .video {
        width: 100%;
        height: 87.5%;
    }

}
</style>