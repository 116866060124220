

<template>
<!--  大模块-->
<div class="error_box" v-if="modul == 'big'" >
  <img class="big_img" :src="$utility.assetImage('retry_big')" alt="" srcset="" @click="retryClick">
  <div class="re_btn">
    {{$t("choose.retry")}}
  </div>
</div>
<!--小模块，error_box2_no_back用于区分有无背景 -->
  <div v-else :class="['error_box2', {error_box2_no_back: modul == 'smNoBack'}] " >
    <img class="sm_img" :src="$utility.assetImage('retry_big')"  alt="" srcset="" @click="retryClick">
  </div>
</template>

<script setup>

import {getCurrentInstance, defineProps, defineEmits } from "vue";

let { $utility } = getCurrentInstance().appContext.config.globalProperties

// props
const props = defineProps({
  //  大模块： big；  小模块有背景：smBack；  小模块无背景： smNoBack
  modul:{
    default: "big",
    type: String
  }
})

// 向父组件暴露fun
const emit = defineEmits(['retryFun'])

/**
 * 从新加载图片
 */
const retryClick = () => {
  emit("retryFun")
}


</script>

<style scoped lang="scss">
.error_box{
  width: 100%;
  height: 100%;
  border-radius: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .big_img{
    width:72px;
    height: 60px;
  }
  .re_btn{
    margin-top: 20px;
    width: max-content;
    font-weight: bold;
    font-size: 16px;
    color: #888A98;
    line-height: 19px;
    text-align: center;
    font-style: normal;
  }
}

.error_box2{
  width: 100%;
  height: 100%;
  border-radius: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #3F4149;
  .sm_img{
    width:24px;
    height: 20px;
  }
}
.error_box2_no_back{
  background: #20222B;
}
@media screen and (max-width: 1070px) {
  .error_box{

    .big_img{
      width:40px;
      height: 33px;
    }
    .re_btn{
      margin-top: 14px;
      width: max-content;
      font-weight: 500;
      font-size: 12px;
      color: #888A98;
      line-height: 16px;
      text-align: center;
      font-style: normal;
    }
  }
  .error_box2{
    .sm_img{
      width:20px;
      height: 17px;
    }
  }
}



</style>
