<template>
    <div class="guide-card">
        <div>
            <img class="card-img" :src="src" alt="">
        </div>
        <h3 class="card-title">{{ title }}</h3>
        <p class="card-subtitle">{{ subtitle }}</p>
    </div>
</template>

<script>
export default {
    data () {
        return {};
    },
    props: {
        src: String,
        title: String,
        subtitle: String,
    },
};
</script>

<style scoped lang="scss"> 
  

@media (min-width: 1071px) {
    .guide-card {
        background-color: #20222B;
        border-radius: 20px;
    }
    .card-img {
        width: 100%;
    }
    .card-title {
        font-weight: 700;
        font-family: 'PingFang SC';
        font-size: 18px;
        color: white;
        padding-left: 20px;
        padding-right: 20px;
        text-align: start;
    }
    .card-subtitle {
        font-weight: 400;
        font-family: 'PingFang SC';
        font-size: 12px;
        color: white;
        padding-left: 20px;
        padding-right: 20px;
        text-align: start;
    }
}
@media (max-width: 1070px) {
    .guide-card {
        background-color: #20222B;
        border-radius: 20px;
    }
    .card-img {
        width: 100%;
    }
    .card-title {
        font-weight: 700;
        font-family: 'PingFang SC';
        font-size: 14px;
        color: white;
        padding-left: 20px;
        padding-right: 20px;
        text-align: start;
    }
    .card-subtitle {
        font-size: 12px;
        font-weight: 400;
        font-family: 'PingFang SC';
        color: white;
        padding-left: 20px;
        padding-right: 20px;
        text-align: start;
        padding-bottom: 14px;
    }
}
</style>