export default {
  meta: {
    faceswapTitle:
      'Échange de visage vidéo gratuit, meilleures vidéos et photos IA Échange de visage en ligne',
    faceswapKeywords:
      'Échange de visage, générateur d\'images AI, échange de visage vidéo,',
    faceswapDescription:
      '3 crédits gratuits pour les nouveaux utilisateurs enregistrés, échange de visage vidéo AI de la plus haute qualité en ligne, tout le monde peut facilement échanger un visage contre des photos et des vidéos en quelques clics',
    faceswapTab1title:
      'Échange de photos et d\'images de haute qualité et échange de corps en ligne',
    faceswapTab1keywords:
      'Échange de visage, générateur d\'images AI, échange de visage vidéo',
    faceswapTab1description:
      'Essai gratuit, il s\'agit d\'un site Web d\'échange de visage avec générateur d\'art IA pour photos et images, vous pouvez utiliser ce logiciel d\'édition d\'échange de corps pour échanger deux photos ou un créateur de photos.',
    faceswapTab2title:
      'Éditeur d\'échange de visage et d\'échange de corps d\'image AI de haute qualité en ligne',
    faceswapTab2keywords:
      'Échange de visage, générateur d\'images AI, échange de visage vidéo',
    faceswapTab2description:
      'Essai gratuit, il s\'agit d\'un site Web d\'échange de visage de générateur d\'art ai pour images et photos, vous pouvez utiliser ce logiciel d\'échange de visage de corps pour l\'échange de visage de célébrité nsfw ou le créateur de photos meme',
    faceswapTab3title:
      'Créateur de vidéo Échange de visage et échange de visage vidéo de haute qualité en ligne',
    faceswapTab3keywords:
      'Échange de visage, générateur d\'images AI, échange de visage vidéo',
    faceswapTab3description:
      'Essai gratuit, il s\'agit d\'un générateur ai échange de visage pour le site Web d\'échange de visage vidéo, vous pouvez utiliser ce logiciel d\'édition vidéo d\'échange de visage sur PC pour un film d\'échange de visage, un échange de visage de célébrité nsfw ou un créateur de vidéo échange de visage',
    faceswapTab4title:
      'Vidéos d\'échange de visage et d\'échange de visage de haute qualité en ligne',
    faceswapTab4keywords:
      'Échange de visage, générateur d\'images AI, échange de visage vidéo',
    faceswapTab4description:
      'Essai gratuit, il s\'agit d\'une vidéo d\'échange de visage ai en ligne Gratuit, vous pouvez utiliser ce logiciel d\'édition vidéo d\'échange de visage sur PC pour l\'échange de visage du générateur d\'art ai, l\'échange de visage de célébrité nsfw',
  },
  siteName: 'Beauty AI',
  creation: 'Fille IA',
  faceSwap: 'Morphing',
  product: 'Produit',
  magicBrush: 'Brosse Magique',
  pricing: 'Tarifs',
  freeCredits: 'Crédits gratuits',
  credits: 'Crédits',
  getMoreCredits: 'Plus de crédits',
  left: 'Gauche',
  upgradeToPremium: 'Passer à la version premium',
  go: 'Aller!',
  accountInformation: 'Information sur le compte',
  signOut: 'Se déconnecter',
  getMore: 'Avoir plus',
  contactSupport: 'Contactez le support',
  history: 'Historique',
  login: 'Connexion',
  signup: 'Inscription',
  aiFuture: 'Fonctionnalité AI',
  photoFaceSwap: 'Échange de visage de photo',
  photoMutipleFaceSwap: 'Échange de visages avec plusieurs images',
  videoFaceSwap: 'Créateur de vidéos Échange de visage',
  videoMutipleFaceSwap: 'Échange de visages vidéo multiples',
  multipleFaceSwap: 'Échange de Visages Multiples',
  aIGirlGenerator: 'Générateur d\'Images AI',
  aIImageGenerator: 'Générateur d\'Images AI',
  aiGirlGenerator: 'Générateur d\'Images AI',
  support: 'Support',
  language: 'langue',
  userPolicy: 'Politique d\'Utilisation',
  privacyPolicy: 'Politique de Confidentialité',
  helpAndFAQS: 'Aide & FAQ',
  siteMap: 'Plan du Site',
  bussiness: 'Affaires',
  affiliateProgram: 'Programme d\'Affiliation',
  api: 'API',
  contactUs: 'Nous Contacter',
  aiFaceFunTitle: 'Échange de visage vidéo IA en ligne',
  aiFaceFunSubTitle:
    'Échangez instantanément des visages avec l\'échange de visage vidéo Beauty AI. Remplacez les visages des photos et des vidéos par ceux de votre choix. Créez des mèmes amusants avec notre produit alimenté par l\'IA.',
  startFaceButtonTitle: 'Commencer l\'Échange de Visage',
  details: 'Détails  >',
  chooseFace: 'Choisir visage',
  faceSwaps: 'échanges de visages',
  faceswapUploadArea: {
    tab1Desc1:
      'Téléchargez la photo originale dans laquelle échanger le visage.',
    tab1Desc2: 'Faites glisser et déposez une photo ou choisissez une photo',
    tab2Desc1:
      'Téléchargez l’image originale dans laquelle échanger le visage.',
    tab2Desc2: 'Glissez et déposez une image ou choisissez une image',
    tab3Desc1:
      'Téléchargez la vidéo originale dans un échange de faux visages profonds.',
    tab3Desc2: 'Glissez et déposez la vidéo ou choisissez la vidéo',
    tab4Desc1:
      'Téléchargez la vidéo originale dans laquelle faire l\'échange de visage.',
    tab4Desc2: 'Glissez et déposez la vidéo ou choisissez la vidéo',
    tab1title:
      '3 étapes pour échanger un visage dans des photos ou des images en ligne',
    tab1step1: '1. Téléchargez votre photo de visage originale.',
    tab1step2:
      '2. Téléchargez une photo du visage cible de la personne qui remplacera le visage de la photo originale. Vous pouvez également choisir parmi les démos de photos fournies.',
    tab1step3:
      '3. Démarrez Photo échange de visages, puis prévisualisez et téléchargez les résultats HD.',
    tab2title:
      '3 étapes pour échanger plusieurs visages dans des images en ligne',
    tab2step1: '1. Téléchargez votre photo de visage originale.',
    tab2step2:
      '2. Téléchargez une photo du visage cible de la personne qui remplacera le visage dans la photo originale. Vous pouvez également choisir parmi les démos d’images fournies.',
    tab2step3:
      '3. Démarrez Face Swap Picture, puis prévisualisez et téléchargez les résultats HD.',
    tab3title: '3 étapes pour échanger le visage dans les vidéos en ligne',
    tab3step1: '1. Téléchargez votre vidéo de visage originale.',
    tab3step2:
      '2. Téléchargez le visage cible de la personne qui remplacera le visage de la vidéo originale. Vous pouvez également choisir parmi les démos fournies.',
    tab3step3:
      '3. Démarrez Video Face Swap, puis prévisualisez et téléchargez les résultats HD.',
    tab4title: '3 étapes pour échanger le visage dans les vidéos en ligne',
    tab4step1: '1. Téléchargez votre vidéo de visage originale.',
    tab4step2:
      '2. Téléchargez le visage cible de la personne qui remplacera le visage de la vidéo originale. Vous pouvez également choisir parmi les démos fournies.',
    tab4step3:
      '3. Démarrez Video Face Swap, puis prévisualisez et téléchargez les résultats HD.',
    chooseFiles: 'Choisir des fichiers',
    photoLimit: '10 Mo maximum',
    videoLimit: 'Max 500 M, 10 minutes',
    uploading: 'Téléchargement <xx1>',
    faceAnalyzing: 'Analyse du visage',
    queuingAbout: 'File d\'attente... À propos de <xx1>s',
    analyzingAbout: 'Analyse... À propos de <xx1>s',
    somethingError: 'Quelque chose c\'est mal passé. Merci d\'essayer plus tard.',
  },
  faceswapUploadFilePopup: {
    title: 'Tous les fichiers analysés',
    subtitle: 'Fichiers stockés uniquement pendant 7 jours',
    try: 'Essayer',
  },
  faceswapFaceRecognized: 'Visage reconnu',
  swapFaces: 'Échanger les visages',
  useCredit: 'utiliser <xx1> crédit',
  useCredits: 'utiliser <xx1> crédits',
  faceSwapDetail: {
    firstSectionItem1Title: 'Introduction à l\'échange de visage vidéo',
    firstSectionItem1Text:
      'Un changeur de visage AI est une application logicielle qui utilise l\'intelligence artificielle (IA) et des techniques d\'apprentissage automatique pour modifier ou échanger des visages dans des images et des vidéos.',
    firstSectionItem2Title: 'Public cible',
    firstSectionItem2Text:
      'Les principaux utilisateurs de cette technologie devraient être des chercheurs spécialisés dans l\'échange de visage, des développeurs impliqués dans l\'échange de visage et des passionnés de l\'échange de visage.',
    firstSectionItem3Title: 'Introduction à l\'échange de visage vidéo AI',
    firstSectionItem3Text:
      'Video Face Swap est une tendance vidéo populaire dans laquelle vous échangez votre visage avec celui de quelqu\'un d\'autre. Avec Beauty AI, vous pouvez échanger des visages dans des vidéos avec des amis, des célébrités ou même des personnages historiques. Créez des vidéos amusantes, des mèmes et des collages, ou amusez-vous simplement à expérimenter votre apparence.',
    secondSectionTitle:
      'Ce que vous pouvez faire avec l\'échange de visage vidéo',
    secondSectionItem1Title: 'Explorer les possibilités',
    secondSectionItem1Text:
      'Découvrez les possibilités fascinantes et les applications inattendues de cette technique innovante. Découvrez un monde où les transformations faciales allient créativité et technologie, offrant plus que ce à quoi vous vous attendiez initialement.',
    secondSectionItem2Title: 'Échange de visage vidéo',
    secondSectionItem2Text:
      'Plongez dans les possibilités fascinantes et les applications inattendues de cette technique. Découvrez un monde de transformations faciales qui vont bien au-delà des attentes initiales, mêlant créativité et technologie.',
    secondSectionItem3Title: 'Changement de genre',
    secondSectionItem3Text:
      'Rejoignez la tendance de l’échange de genre sur TikTok, Snapchat et Instagram. Partagez vos photos de genre échangé pour attirer des likes et des fans, donnant ainsi une mise à jour dynamique à votre présence sur les réseaux sociaux.',
    secondSectionItem4Title: 'Échange de visage vidéo de célébrité',
    secondSectionItem4Text:
      'Transformez votre apparence en plaçant votre visage sur la vidéo de n\'importe quelle célébrité. Mélangez vos caractéristiques avec celles d\'une célébrité pour créer un contenu intrigant.',
    secondSectionItem5Title: 'Échange de visage de mème',
    secondSectionItem5Text:
      'Créez des mèmes amusants en utilisant la fonction d\'échange de visage. Générez de nouveaux mèmes comiques avec votre propre visage ou tout autre visage et partagez les rires sur les plateformes de médias sociaux.',
    secondSectionItem6Title: 'Échanger avec des rôles dans le film',
    secondSectionItem6Text:
      'Échangez les visages avec vos personnages de films préférés et devenez un super-héros intrépide ou un charmant protagoniste romantique. Partagez vos images pour exciter le fandom du cinéma.',
    secondSectionItem7Title: 'Échange de visage AI sur les peintures',
    secondSectionItem7Text:
      'Remplacez les visages des peintures classiques par les vôtres. Partagez ces créations uniques avec vos amis et sur les réseaux sociaux, en cultivant un style personnel distinctif.',
  },
  faceswapIntroduce: {
    sectionTitle: 'AI Face Swap en ligne',
    item1Title: 'Créateur de photos Échange de visage',
    item1Subtitle:
      'Changeur de visage en ligne gratuit qui vous permet d\'échanger des visages en images.',
    item1ActionText: 'Échange de visage maintenant',
    item2Title: 'Générateur de photos Échange de visage multiples',
    item2Subtitle:
      'Échangez plusieurs visages sur des photos de famille, des photos de mariage, des photos de fête sur PC ou sur iPhone. etc.',
    item2ActionText: 'Échange de visages multiples maintenant',
    item3Title: 'Créateur de vidéos Échange de visage',
    item3Subtitle:
      'Échangez des visages dans des vidéos, améliorez la réalisation de films ou créez du contenu pour les réseaux sociaux.',
    item3ActionText: 'Échange de visage maintenant',
    item4Title: 'Échange de visages vidéo multiples',
    item4Subtitle:
      'Remplacez facilement plusieurs visages dans des vidéos, offrant un moyen amusant et créatif de transformer le contenu visuel.',
    item4ActionText: 'Échange de visages multiples maintenant',
  },
  faceswapGuide: {
    tab1SectionTitle: 'Comment fonctionne l\'échange de visage d\'image AI',
    tab1item1Title: 'Téléchargez votre photo de visage originale',
    tab1item1Subtitle:
      'Commencez par télécharger une photo contenant votre visage pour servir d’entrée pour l’échange de visage.',
    tab1item2Title:
      'Choisissez la photo du visage cible pour l\'échange de visage',
    tab1item2Subtitle:
      'Téléchargez une photo du visage cible de la personne qui remplacera le visage de la photo d\'origine. Vous pouvez également choisir parmi les démos de photos fournies.',
    tab1item3Title: 'Démarrer la photo d\'échange de visage',
    tab1item3Subtitle:
      'Cliquez sur "Face Swap Now" pour laisser l\'échangeur de visage opérer sa magie. L\'échange de visage ne prend que 5 à 10 secondes.',
    tab1item4Title: 'Prévisualiser et télécharger le résultat HD',
    tab1item4Subtitle:
      'Une fois l\'échange de visage terminé, téléchargez l\'image résultante avec les visages échangés pour un usage personnel ou un partage.',
    tab2SectionTitle: 'Comment fonctionne AI Picture Face Swap Maker',
    tab2item1Title: 'Téléchargez votre photo de visage originale',
    tab2item1Subtitle:
      'Commencez par télécharger une image contenant votre visage pour servir d’entrée pour l’échange de visage.',
    tab2item2Title:
      'Choisissez l\'image du visage cible pour l\'échange de visage',
    tab2item2Subtitle:
      'Téléchargez une photo du visage cible de la personne qui remplacera le visage dans la photo d\'origine. Vous pouvez également choisir parmi les démos d’images fournies.',
    tab2item3Title: 'Démarrer l\'échange de visage',
    tab2item3Subtitle:
      'Cliquez sur "Face Swap Now" pour laisser l\'échangeur de visage opérer sa magie. L\'échange de visage ne prend que 5 à 10 secondes.',
    tab2item4Title: 'Prévisualiser et télécharger le résultat HD',
    tab2item4Subtitle:
      'Une fois l\'échange de visage terminé, téléchargez l\'image résultante avec les visages échangés pour un usage personnel ou un partage.',
    tab3SectionTitle: 'Comment fonctionne l\'échange de visage vidéo AI',
    tab3item1Title: 'Téléchargez votre vidéo de visage originale',
    tab3item1Subtitle:
      'Commencez par télécharger une vidéo contenant votre visage pour servir d’entrée pour l’échange de visage.',
    tab3item2Title:
      'Choisissez la photo du visage cible pour l\'échange de visage',
    tab3item2Subtitle:
      'Téléchargez le visage cible de la personne qui remplacera le visage de la vidéo originale. Vous pouvez également choisir parmi les démos fournies.',
    tab3item3Title: 'Démarrer la vidéo d\'échange de visage',
    tab3item3Subtitle:
      'Cliquez sur "Face Swap Now" pour laisser l\'échangeur de visage opérer sa magie. L\'échange de visage ne prend que 5 à 10 secondes.',
    tab3item4Title: 'Prévisualiser et télécharger le résultat HD',
    tab3item4Subtitle:
      'Une fois l\'échange de visage terminé, téléchargez la vidéo résultante avec les visages échangés pour un usage personnel ou un partage.',
    tab4SectionTitle: 'Comment fonctionne l\'échange de visage vidéo AI',
    tab4item1Title: 'Téléchargez votre vidéo de visage originale',
    tab4item1Subtitle:
      'Commencez par télécharger une vidéo contenant votre visage pour servir d’entrée pour l’échange de visage.',
    tab4item2Title:
      'Choisissez la photo du visage cible pour l\'échange de visage',
    tab4item2Subtitle:
      'Téléchargez le visage cible de la personne qui remplacera le visage de la vidéo originale. Vous pouvez également choisir parmi les démos fournies.',
    tab4item3Title: 'Démarrer la vidéo d\'échange de visage',
    tab4item3Subtitle:
      'Cliquez sur "Face Swap Now" pour laisser l\'échangeur de visage opérer sa magie. L\'échange de visage ne prend que 5 à 10 secondes.',
    tab4item4Title: 'Prévisualiser et télécharger le résultat HD',
    tab4item4Subtitle:
      'Une fois l\'échange de visage terminé, téléchargez la vidéo résultante avec les visages échangés pour un usage personnel ou un partage.',
    sectionActionText: 'Commencer l\'échange de visage',
    howToUse: 'Comment utiliser >',
  },
  review: {
    title: 'Ils nous adorent, vous aussi',
    subtitle:
      'Rejoignez l\'équipe de plus de 10 000 partenaires à travers le monde. Les récompenses qu\'ils reçoivent du site sont continues et stables.',
    data1: {
      title: 'J\'adore utiliser Beauty AI !',
      content:
        'J\'adore utiliser Beauty AI ! C\'est mon outil préféré pour ajouter une touche de fun à mes photos. Que ce soit pour échanger des visages avec des amis ou essayer différents looks de célébrités, les résultats sont toujours hilarants et divertissants.',
      name: 'Cliff Quan',
      dateText: '2 mars 2024',
    },
    data2: {
      title: 'En tant qu\'amateur de maquillage',
      content:
        'En tant qu\'amateur de maquillage, j\'utilise Beauty AI pour essayer de nouveaux looks avant de les adopter dans la vraie vie. Échanger mon visage avec différents personnages m\'aide à visualiser comment divers styles m\'iront. C\'est un outil amusant et pratique pour tous ceux qui aiment expérimenter avec le maquillage !',
      name: 'Anli Brevo',
      dateText: '16 mai 2024',
    },
    data3: {
      title: 'Il est facile d\'utiliser l\'échange de visage vidéo',
      content:
        'Il est facile d\'utiliser la fonction d\'échange de visage vidéo pour remplacer votre visage par celui de quelqu\'un d\'autre. Que vous vouliez mettre le visage d\'une star de cinéma sur votre tête ou remplacer votre visage par celui d\'un joueur de basketball, cet outil fait le travail parfaitement. Continuez ainsi !',
      name: 'Ada Wu',
      dateText: '19 avril 2024',
    },
    data4: {
      title: 'Cet outil incroyable détecte intelligemment',
      content:
        'Cet outil incroyable détecte intelligemment les visages dans les photos et les remplace sans couture par n\'importe quel visage de votre choix. C\'est comme avoir votre propre studio créatif personnel à portée de main ! Avec Beauty AI, échanger des visages dans n\'importe quelle photo est un jeu d\'enfant, ce qui me permet de libérer mon imagination sans aucun tracas.',
      name: 'Mario Kuper',
      dateText: '24 janvier 2024',
    },
    data5: {
      title: 'J\'ai toujours trouvé l\'édition de photos difficile',
      content:
        'J\'ai toujours trouvé l\'édition de photos difficile, mais Beauty AI rend cela facile ! J\'adore expérimenter avec les différentes options d\'échange de visage IA et voir les combinaisons hilarantes. C\'est une façon fantastique d\'ajouter de l\'humour à mes photos et de faire rire mes amis.',
      name: 'Chris Jin',
      dateText: '12 décembre 2023',
    },
    data6: {
      title: 'L\'échange de visage IA est idéal pour créer des mèmes drôles',
      content:
        'L\'échange de visage IA est idéal pour créer des mèmes drôles, des portraits et des vidéos d\'échange de visage. Beauty AI est parfait non seulement pour le divertissement mais aussi pour la création de contenu engageant. Il vous aide à attirer plus d\'attention sur les réseaux sociaux.',
      name: 'Kotláriková',
      dateText: '24 mars 2024',
    },
  },
  faq: {
    title: 'Questions Fréquemment Posées',
    details: 'Détails  >',
    data1: {
      question: 'Qu\'est-ce que Beauty AI ?',
      answer:
        'Beauty AI est une plateforme visuelle d\'IA utilisant des technologies avancées pour échanger automatiquement des visages, retoucher, améliorer, coloriser, corriger et cartooniser des images sans avoir besoin d\'outils complexes d\'édition photo comme Photoshop. En utilisant des algorithmes IA de pointe, Beauty AI simplifie le traitement des photos, économisant du temps, de l\'énergie et des coûts pour les utilisateurs individuels et les entreprises nécessitant une édition photo en masse.',
    },
    data2: {
      question: 'Comment fonctionne un outil d\'échange de visage IA ?',
      answer:
        'L\'échange de visage IA repose sur des algorithmes sophistiqués d\'intelligence artificielle pour analyser méticuleusement les images sources, reconnaître les traits du visage et les remplacer sans couture par un autre visage tout en maintenant l\'éclairage, les angles et les expressions naturels. Cela donne des échanges de visage réalistes générés par l\'IA.',
    },
    data3: {
      question: 'Comment utiliser l\'échange de visage vidéo ?',
      answer:
        'Tout d\'abord, sélectionnez une image de face claire comme base. Ensuite, choisissez le visage avec lequel vous souhaitez échanger, que ce soit le vôtre ou celui d\'une célébrité. Troisièmement, lancez le processus de remplacement, et le visage choisi sera placé sans couture sur l\'image de base.',
    },
    data4: {
      question: 'Puis-je remplacer mon visage par n\'importe quelle image ?',
      answer:
        'Oui, absolument. Beauty AI vous permet de remplacer votre visage par n\'importe quelle image sélectionnée gratuitement. Par exemple, vous pouvez placer un visage masculin sur un portrait féminin ou un visage vieilli sur la photo d\'un bébé. Les possibilités sont infinies, vous permettant de devenir n\'importe qui sans limitations.',
    },
    data5: {
      question: 'Comment obtenir le meilleur résultat ?',
      answer:
        'Pour obtenir des résultats optimaux avec l\'échange de visage, suivez ces conseils: sélectionnez une photo claire avec des traits du visage bien définis, évitez les images floues. Assurez-vous qu\'il n\'y a qu\'un seul visage sur la photo et que le visage est directement face à la caméra sans obstruction par des objets comme les mains.',
    },
    data6: {
      question: 'Puis-je échanger des visages en ligne gratuitement ?',
      answer:
        'Absolument ! Vous pouvez échanger des visages en ligne gratuitement en utilisant Beauty AI. Nous offrons des plans gratuits et premium pour répondre à vos besoins. En tant que nouvel utilisateur, vous recevrez trois crédits lors de l\'inscription, vous donnant un accès complet à notre service d\'échange de visage et à d\'autres fonctionnalités telles que la découpe de visage et la création d\'avatar.',
    },
  },
  faceswapToHistory: {
    tab1or2header:
      'Nous avons conservé pour vous l\'historique des photos d\'échange de visage,',
    tab3or4header:
      'Nous avons gardé pour vous l\'Historique des vidéos d\'échange de visage,',
    footer: 'plus de détails pour voir l\'histoire.',
  },
  faceswapOnlineSection: {
    tab1Title: 'Échange de visage photo AI en ligne',
    tab2Title: 'Échange de visage d\'image AI en ligne',
    tab3Title: 'Échange de visage vidéo IA en ligne',
    tab4Title: 'Créateur de vidéo AI Face Swap en ligne',
    tab1Desc:
      'Échangez instantanément votre visage avec Beauty AI Photo Face Swap. Remplacez le visage sur les photos et les vidéos par celui de votre choix. Créez des mèmes amusants avec notre.',
    tab2Desc:
      'Échangez instantanément des visages avec Beauty AI Picture Face Swap. Remplacez les visages dans les images et les vidéos par ceux de votre choix. Créez des mèmes amusants avec notre échange de visage avec générateur d\'art IA.',
    tab3Desc:
      'Échangez instantanément des visages avec Beauty AI Video Face Swap. Remplacez les visages des photos et des vidéos par ceux de votre choix. Créez des mèmes amusants avec notre produit alimenté par l\'IA.',
    tab4Desc:
      'Échangez instantanément des visages avec Beauty AI Video Face Swap. Changez de visage dans les vidéos pour celui de votre choix. Créez des mèmes amusants avec notre générateur d\'échange de visages avec générateur d\'art IA.',
    action: 'Commencer l\'échange de visage',
  },
  choose: {
    delText: 'Etes-vous sûr de supprimer ce visage ?',
    leftBtnText: 'Continuer',
    rightBtnText: 'Annuler',
    overFaces:
      'Les visages téléchargés ont atteint la limite. Veuillez supprimer les autres visages pour continuer.',
    faceAn: 'Analyse du visage...',
    facesuccess: 'Succès de l\'analyse du visage',
    facefailed: 'Échec de l\'analyse du visage',
    feedback: 'Merci pour vos commentaires!',
    copy: 'Copie réussie !',
    batchsupport:
      'Lot pris en charge ! Vous pouvez effectuer un nouvel échange de visage quel que soit le résultat actuel.',
    batchsupportAIGirl:
      'Lot pris en charge ! Vous pouvez créer une nouvelle Image IA quel que soit le résultat actuel.',
    submitting: 'Soumission',
    queuing: 'File d\'attente',
    creating: 'Création',
    downloading: 'Téléchargement',
    retryText: 'Quelque chose c\'est mal passé. Merci d\'essayer plus tard.',
    retry: 'Recommencez',
    emailTip:
      'Veuillez saisir un suffixe de nom de domaine valide (tel que .com, .net, etc.)',
    noaccount: 'Pas encore de compte ?',
    enterPassWord: 'Entrer le mot de passe',
    passWordErr: 'Définir un mot de passe de 8 caractères minimum',
    loginPassWordErr: 'Mot de passe doit être d\'au moins 8 caractères',
    forgotpass: 'Mot de passe oublié?',
    googlesign: 'Vous connecter avec Google Sign-In ?',
    resetpassword: 'réinitialiser le mot de passe',
    readyaccount: 'Vous avez déjà un compte?',
    confirm: 'Confirmer',
    resetpasswordsuccess: 'Réinitialisez le mot de passe avec succès !',
    senderror: 'Envoyer une erreur !',
    sendsuccess: 'Envoyez Succès !',
    signupfree: 'Inscription gratuite',
    signupsuccess: 'Inscription Succès !',
    sendcode: 'envoyer le Code',
    loginsuccess: 'connexion réussie!',
    logingerror: 'erreur de connexion!',
    aboutS: 'À propos {leftTime}s',
    keepPatient: 'Soyez patient!',
    needMoretime: 'Besoin de plus de temps!',
    aimostDoneKeep: 'Presque fini! Soyez patient!',
    usernamePlace: 'user{\'@\'}example.com',
    emailCode: 'Code de vérification',
  },
  feedbackDialog: {
    title: 'Nous avons besoin de vos commentaires pour améliorer Beauty AI.',
    subTitle1: 'Envie de vous améliorer ?',
    option1: 'Analyse de visage inexacte sur les fichiers téléchargés',
    option2: 'On dirait pas',
    option3: 'Problèmes de détails du visage (déformés, yeux, bouche, etc.)',
    option4: 'Temps lent',
    option5: 'Résultat Netteté',
    txtOption1: 'Contenu sensible ou dérangeant',
    txtOption2: 'Violation des conditions d\'utilisation',
    txtOption3: 'Pertinence rapide',
    txtOption4: 'Visage déformé',
    txtOption5: 'Contenu des images',
    txtOption6: 'Netteté de l\'image',
    txtOption7: 'Temps lent',
    subTitle2: 'Vous voulez en dire plus ?',
    placeholder: 'D\'autres suggestions...',
    submit: 'Soumettre',
    reportTitle: 'Signaler la photo',
    reportOptionTitle: 'Catégorie',
    reportOption1: 'Contenu sensible ou dérangeant',
    reportOption2: 'Courrier indésirable',
    reportOption3: 'Violation des conditions d\'utilisation',
  },
  infoDialog: {
    advancedFeatures: 'Fonctionnalités avancées',
    advancedFeaturesText:
      'Veuillez payer pour nos plans visant à débloquer une fonctionnalité d\'échange de visage réaliste en vidéo.',
    choosePlan: 'Choisir un forfait',
    insufficientCredits: 'Crédits insuffisants',
    insufficientCreditsText: 'Votre solde créditeur :',
    getMoreCredits: 'Obtenez plus de crédits',
    videoTooBig: 'La vidéo est trop grande',
    videoTooLong: 'La vidéo est trop longue',
    videoCannotExceed: 'La vidéo ne peut pas dépasser',
    inFileSize: 'en taille de fichier',
    selectAnotherFile: 'Sélectionnez un autre fichier',
    photoTooBig: 'La photo est trop grande',
    photoCannotExceed: 'La photo ne peut pas dépasser',
    tenMin: '10 minutes',
    deleteFile: 'Êtes-vous sûr de supprimer ce fichier ?',
    cancel: 'Annuler',
    confirm: 'Confirmer',
  },
  fileUpload: {
    demo: 'Demo',
    historicalFiles: 'Tous les fichiers',
    uploading: 'Envoi',
    queuing: 'File d\'attente',
    analyzing: 'en cours d\'analyse',
    photoFileFormatError:
      'Le format de fichier n\'est pas pris en charge ! Veuillez télécharger une photo.',
    videoFileFormatError:
      'Le format de fichier n\'est pas pris en charge ! Veuillez télécharger la vidéo.',
    resolutionExceed1920: 'La résolution ne peut pas dépasser 1920*1920',
    videoIsProcessed:
      'La vidéo est en cours de traitement, cela peut prendre quelques instants en raison de la consommation de ressources, mais tout va bien. S\'il vous plaît, soyez patient !',
    filesLoading: 'Chargement, réessayez plus tard',
  },
  submitSamePhotoTaskHint:
    'Avertissement! Vous avez soumis les mêmes visages avec la même photo, veuillez changer vos visages pour soumettre. (Veuillez noter que cette soumission ne coûte pas votre crédit)',
  submitSameVideoTaskHint:
    'Avertissement! Vous avez soumis les mêmes visages avec la même vidéo, veuillez changer vos visages pour soumettre. (Veuillez noter que cette soumission ne coûte pas votre crédit)',
  allRightsReserved: 'Tous droits réservés.',
  aiGirlHowToUse: 'Comment utiliser l\'image AI',
  aiGirlFAQDetails: 'FAQ sur les images AI Détails',
  lookslikeUploadOpps: {
    title: 'Oops!',
    subtitle:
      'Oops! Dépassez les limites de crédits gratuits !\nVeuillez souscrire à un forfait pour utiliser toutes les fonctionnalités de manière illimitée.',
    unlockLimit: 'Limite de déverrouillage',
  },
  selectStyle: 'Sélectionnez le style',
};
