<template>
    <div :class="{'photo-example-popup':true, 'offstage': offstage}">
        <div :class="{body: true, popin: popin, popout: !popin}">
            <img class="close" ref="closeView" :src="$utility.assetImage('close_icon')" alt="" @click="dismiss" @mouseenter="closeHover(true)" @mouseleave="closeHover(false)">
            <p class="title">{{ $t('examplePopup.title') }}</p>
            <p class="tip">{{ $t('examplePopup.photoTip') }}</p>
            <div class="good">
                <div class="txt-row">
                    <p class="title">{{ $t('examplePopup.goodExample') }}</p>
                    <img class="icon" :src="$utility.assetImage('faceswap_example_good_icon')" alt="">
                </div>
                <div class="img-row">
                    <img class="img" v-for="index of [1,2,3,4]" :src="$utility.assetImage(`faceswap_example_good_item${index}`)" alt="">
                </div>
            </div>
            <div class="bad">
                <div class="txt-row">
                    <p class="title">{{ $t('examplePopup.badExample') }}</p>
                    <img class="icon" :src="$utility.assetImage('faceswap_example_bad_icon')" alt="">
                </div>
                <div class="img-row">
                    <img class="img" v-for="index of [1,2,3,4]" :src="$utility.assetImage(`faceswap_example_bad_item${index}`)" alt="">
                </div>
            </div>
            <div class="ok-btn" @click="dismiss">
                <p>{{ $t('ok') }}</p>
            </div>
        </div>
    </div>
</template>

<script setup>
import { getCurrentInstance, ref, watch, defineExpose } from 'vue'

const { $utility } = getCurrentInstance().appContext.config.globalProperties
const closeView = ref(null)
const offstage = ref(true);
const popin = ref(false);
let animating = false;

watch(offstage, () => {
    document.body.style.overflow = !offstage.value ? 'hidden' : 'auto';    
});

function closeHover(value) {
    if (value) {
        closeView.value.src = $utility.assetImage('close_icon')
    }else {
        closeView.value.src = $utility.assetImage('close_icon_hover')
    }
}

function show () {
    if (animating) return;
    animating = true;
    offstage.value = false;
    popin.value = true;
    setTimeout(() => animating = false, 500);
}
function dismiss () {
    if (animating) return;
    animating = true;
    popin.value = false;
    setTimeout(() => {
        animating = false;
        offstage.value = true;
    }, 500);
}

defineExpose({
    show,
    dismiss,
});
</script>

<style scoped lang="scss">
@keyframes popin {
    0% {
        transform: translate(0%, 150%);
    }
    100% {
        transform: none;
    }
}
@keyframes popout {
    0% {
        transform: none;
    }
    100% {
        transform: translate(0%, 150%);
    }
}
.popin {
    animation: popin .5s;
    animation-fill-mode: forwards;
}
.popout {
    animation: popout .5s;
    animation-fill-mode: forwards;
}
.photo-example-popup.offstage {
    display: none;
}
@media (min-width: 1070px) {
    .photo-example-popup {
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        background-color: rgba(0, 0, 0, 0.6);
        z-index: 1000;
        display: flex;
        align-items: center;
        justify-content: center;
        .body {
            position: relative;
            width: 519px;
            border-radius: 8px;
            background: #27282D;
            .close {
                position: absolute;
                top: 20px;
                right: 20px;
                width: 32px;
                height: 32px;
                cursor: pointer;
            }
            .title {
                max-width: 396px;
                text-align: center;
                font-size: 20px;
                font-family: 'PingFang SC';
                font-weight: 700;
                color: white;
                margin-left: auto;
                margin-right: auto;
                margin-top: 30px;
                margin-bottom: 0px;
            }
            .tip {
                max-width: 460px;
                text-align: center;
                font-size: 14px;
                font-family: 'PingFang SC';
                font-weight: 400;
                color: #F3F9FF;
                margin-left: auto;
                margin-right: auto;
                margin-top: 20px;
                margin-bottom: 0px;
            }
            .good {
                display: flex;
                flex-direction: column;
                margin-top: 30px;
            }
            .bad {
                display: flex;
                flex-direction: column;
                margin-top: 20px;
                .txt-row {
                    .title {
                        color: #FF0016;
                    }
                }
            }
            .txt-row {
                display: flex;
                justify-content: space-between;
                margin-left: 30px;
                margin-right: 30px;
                margin-bottom: 16px;
                .title {
                    font-size: 16px;
                    font-weight: 500px;
                    font-family: 'PingFang SC';
                    color: #43FF1F;
                    margin: 0px;
                }
                .icon {
                    width: 22px;
                    height: 22px;
                    object-fit: fill;
                }
            }
            .img-row {
                display: flex;
                justify-content: center;
            }
            .img {
                width: 96px;
                height: 96px;
                margin-right: 25px;
            }
            .img:last-child {
                margin-right: 0px;
            }
            .ok-btn {
                margin-top: 30px;
                margin-bottom: 30px;
                margin-left: auto;
                margin-right: auto;
                width: 330px;
                height: 45px;
                border-radius: 8px;
                background: #FF7839;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                &:hover {
                    background: #CF4D23;
                }
                p {
                    margin: 0;
                    font-size: 16px;
                    font-weight: 500;
                    color: white;
                    font-family: 'PingFang SC';
                }
            }
        }   
    }
}
@media (max-width: 1069px) {
    .photo-example-popup {
        position: fixed;
        top: 0;
        // bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.6);
        z-index: 1000;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        .body {
            position: relative;
            width: 335px;
            border-radius: 8px;
            background: #27282D;
            .close {
                position: absolute;
                top: 10px;
                right: 10px;
                width: 24px;
                height: 24px;
                cursor: pointer;
            }
            .title {
                max-width: 255px;
                text-align: center;
                font-size: 18px;
                font-family: 'PingFang SC';
                font-weight: 600;
                color: white;
                margin-left: auto;
                margin-right: auto;
                margin-top: 20px;
                margin-bottom: 0px;
            }
            .tip {
                max-width: 295px;
                text-align: center;
                font-size: 12px;
                font-family: 'PingFang SC';
                font-weight: 400;
                color: #F3F9FF;
                margin-left: auto;
                margin-right: auto;
                margin-top: 10px;
                margin-bottom: 0px;
            }
            .good {
                display: flex;
                flex-direction: column;
                margin-top: 20px;
            }
            .bad {
                display: flex;
                flex-direction: column;
                margin-top: 16px;
                .txt-row {
                    .title {
                        color: #FF0016;
                    }
                }
            }
            .txt-row {
                display: flex;
                justify-content: space-between;
                margin-left: 20px;
                margin-right: 20px;
                margin-bottom: 16px;
                .title {
                    font-size: 12px;
                    font-weight: 500px;
                    font-family: 'PingFang SC';
                    color: #43FF1F;
                    margin: 0px;
                }
                .icon {
                    width: 16px;
                    height: 16px;
                    object-fit: fill;
                }
            }
            .img-row {
                display: flex;
                justify-content: center;
            }
            .img {
                width: 65px;
                height: 65px;
                margin-right: 11.5px;
            }
            .img:last-child {
                margin-right: 0px;
            }
            .ok-btn {
                margin-top: 20px;
                margin-bottom: 20px;
                margin-left: auto;
                margin-right: auto;
                width: 200px;
                height: 36px;
                border-radius: 8px;
                background: #FF7839;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                &:hover {
                    background: #CF4D23;
                }
                p {
                    margin: 0;
                    font-size: 14px;
                    font-weight: 500;
                    color: white;
                    font-family: 'PingFang SC';
                }
            }
        }   
    }
}
</style>