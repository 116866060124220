<template>
  <div class="--img_box">
    <div class="--img_def" v-if="imgSta == 0">
    </div>
    <ShimmerCard v-else-if="imgSta == 1"/>
    <div class="--img_error" v-else-if="imgSta == 3">
      <slot name="custom_err_slot" :reload="retryLoad" v-if="customErr"></slot>
      <ImageErrView v-else-if="!customErr" @retryFun="retryClick" :modul="errorModul"></ImageErrView>
    </div>
    <slot v-else-if="imgSta == 3 && !customErr" name="imgErr"></slot>
    <img class="--img"  :style="{objectFit: objectFit}" ref="imgRef" v-lazy="{src: imgSrc}" alt="" srcset="" v-show="imgSta == 2" @load="handleLoad"
         @error="handleError">
  </div>

</template>


<script setup>
import {ref, reactive, defineProps, onMounted, watch, watchEffect, defineExpose} from "vue"

import ShimmerCard from "@/components/common/ShimmerCard.vue";
import * as imgTool from "@/script/voc_img_tool";
import ImageErrView from "@/components/ImageErrView.vue";
import {VOCImageRequestInfo} from "@/script/voc_img_tool";

const props = defineProps({
  //  大模块： big；  小模块有背景：smBack；  小模块无背景： smNoBack
  errorModul: {
    default: "big",
    type: String
  },
  customErr: {
    default: false,
    type: Boolean
  },
  imgUrl: {
    default: "",
    type: String
  },
  requestInfo: Object,
  objectFit:{
    default: "cover",
    type: String
  }
})

// 测试地址
const ssss = ref("https://img-home.csdnimg.cn/images/20240218021837.png")
// 图片加载状态   0：开始默认； 1：正在加载； 2：加载完成； 3：加载失败
const imgSta = ref(1)
//  图片标签对象
const imgRef = ref(null)
// 最终的图片地址
const imgSrc = ref("")
// 初始图片地址解析对象
const imageObj = reactive({
  getImg: reactive(imgTool.VOCImageProvider.getInstance.loadImage({request: new VOCImageRequestInfo({url: props.imgUrl})}))
})

/**
 * 图片重新加载点击事件
 */
const retryClick = () => {
  retryLoad()
}

/**
 * 图片load事件（弃用）
 */
const handleLoad = () => {
  // imgSta.value = 1;
  // console.log("图片wanzheng【【【【【【【【【【【【【【")
  // console.log(imgSta.value )

};
/**
 * 图片error事件
 */
const handleError = () => {
  imgSta.value = 3;
};

/**
 * 图片重新加载处理
 */
const retryLoad = () => {
  console.log("重新加载图片")
  imgSta.value = 1
  // imgRef.value.src = ''; // 重置图片源强制重新加载
  // setTimeout(() => {
  //   imgRef.value.src = imgSrc.value;
  // }, 5);
  imageObj.getImg = reactive(imgTool.VOCImageProvider.getInstance.loadImage({request: new VOCImageRequestInfo({url: props.imgUrl})}))
};


watch(imageObj.getImg, () => {
  if (imageObj.getImg.imageStatus == imgTool.VOCImageRef.IMAGE_STATUS_DONE) {
    imgSrc.value = imageObj.getImg.image.url;
    // imgSrc.value = ssss.value;
    imgSta.value = 2
    // console.log(imgSrc.value)
  } else if (imageObj.getImg.imageStatus == imgTool.VOCImageRef.IMAGE_STATUS_FAILED){
    // console.log("imageObj.image.url;")
    // console.log(imageObj.image.url)
    imgSta.value = 3
  }
});

onMounted(() => {
  // const imgElement = imgRef.value;

  // imgElement.addEventListener('load', () => {
  //   imgSta.value = 2;
  //   console.log("图片家中中【【【【【【【【【【【【【【")
  //   console.log(imgSta.value )
  // });
  //
  // imgElement.addEventListener('error', () => {
  //   imgSta.value = 3;
  //   console.log("图片失败【【【【【【【【【【【【【【")
  // });
});

defineExpose({
  retryLoad
})
</script>

<style lang="scss">
.--img_def {
  width: 100%;
  height: 100%;
  border-radius: inherit;
}
.--img_box{
  width: 100%;
  height: 100%;
  border-radius: inherit;
  pointer-events:none;
}

.--img_error {
  width: 100%;
  height: 100%;
  border-radius: inherit;
  overflow: hidden;
}

.--img {
  width: 100%;
  height: 100%;
  border-radius: inherit;
  pointer-events:none;
}
</style>
