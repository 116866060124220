export default {
  meta: {
    faceswapTitle:
      '無料ビデオフェイススワップ、最高のAIビデオと写真ディープフェイクオンライン',
    faceswapKeywords:
      'フェイススワップ、AI画像ジェネレーター、ビデオフェイススワップ、',
    faceswapDescription:
      '新規登録ユーザーには3つの無料クレジット、最高品質のAIビデオフェイススワップオンライン、数回クリックするだけで誰でも簡単に写真やビデオの顔を交換できます',
    faceswapTab1title:
      '高品質の写真と画像のフェイススワップとボディスワップオンライン',
    faceswapTab1keywords:
      'フェイススワップ、AI画像ジェネレーター、ビデオフェイススワップ',
    faceswapTab1description:
      '無料トライアル、これは写真と画像のウェブサイト用のAIアートジェネレーターフェイススワップです。このボディスワップエディターソフトウェアを使用して、2枚の写真または画像メーカーのフェイススワップを行うことができます。',
    faceswapTab2title:
      '高品質の AI 画像フェイススワップとボディスワップエディターオンライン',
    faceswapTab2keywords:
      'フェイススワップ、AI画像ジェネレーター、ビデオフェイススワップ',
    faceswapTab2description:
      '無料トライアル、これは写真や写真のウェブサイト用のAIアートジェネレーターフェイススワップです。このボディフェイススワッパーソフトウェアは、NSFWセレブのフェイススワップやミームの写真メーカーに使用できます。',
    faceswapTab3title:
      '高品質のディープフェイクビデオメーカーとビデオフェイススワップオンライン',
    faceswapTab3keywords:
      'フェイススワップ、AI画像ジェネレーター、ビデオフェイススワップ',
    faceswapTab3description:
      '無料トライアル、これはビデオフェイススワップウェブサイト用のAIディープフェイクジェネレーターです。このフェイススワップビデオエディターソフトウェアをPCで使用して、フェイススワップムービー、NSFWセレブフェイススワップ、またはディープフェイクビデオメーカーを作成できます。',
    faceswapTab4title:
      '高品質のビデオフェイススワップ＆フェイススワップビデオオンライン',
    faceswapTab4keywords:
      'フェイススワップ、AI画像ジェネレーター、ビデオフェイススワップ',
    faceswapTab4description:
      '無料トライアル、これはオンラインのAIフェイススワップビデオです。無料で、このフェイススワップビデオエディターソフトウェアをPCで使用して、AIアートジェネレーターフェイススワップ、NSFWセレブフェイススワップを行うことができます。',
  },
  siteName: 'Beauty AI',
  creation: 'AIガール',
  faceSwap: 'フェイススワップ',
  product: '製品',
  magicBrush: 'マジックブラシ',
  pricing: '価格',
  freeCredits: '無料クレジット',
  credits: '積分',
  getMoreCredits: 'クレジットを追加',
  left: '左',
  upgradeToPremium: 'プレミアムにアップグレード',
  go: '行く！',
  accountInformation: '口座情報',
  signOut: 'サインアウト',
  getMore: '多くを得る',
  contactSupport: 'サポート問い合わせ先',
  history: '履歴',
  login: 'ログイン',
  signup: 'サインアップ',
  aiFuture: 'AI機能',
  photoFaceSwap: '写真の顔の交換',
  photoMutipleFaceSwap: '複数の写真の顔の交換',
  videoFaceSwap: 'ディープフェイクビデオメーカー',
  videoMutipleFaceSwap: '複数のビデオの顔の交換',
  multipleFaceSwap: '複数フェイススワップ',
  aIGirlGenerator: 'AI画像生成',
  aIImageGenerator: 'AI画像生成',
  support: 'サポート',
  language: '言語',
  userPolicy: '利用規約',
  privacyPolicy: 'プライバシーポリシー',
  helpAndFAQS: 'ヘルプ & FAQ',
  siteMap: 'サイトマップ',
  bussiness: 'ビジネス',
  affiliateProgram: 'アフィリエイトプログラム',
  api: 'API',
  contactUs: 'お問い合わせ',
  aiFaceFunTitle: 'AI ビデオ フェイス スワップ オンライン',
  aiFaceFunSubTitle:
    'Beauty AI ビデオ フェイス スワップで顔を瞬時に交換します。 写真やビデオ内の顔を選択した人物に変更します。 当社の AI を活用した製品で面白いミームを作成します。',
  startFaceButtonTitle: 'フェイススワップを開始',
  details: '詳細  >',
  chooseFace: '顔を選ぶ',
  faceSwaps: '顔の交換',
  faceswapUploadArea: {
    tab1Desc1: '顔を入れ替える元の写真をアップロードします。',
    tab1Desc2: '写真をドラッグアンドドロップするか、写真を選択してください',
    tab2Desc1: '顔を入れ替える元の写真をアップロードします。',
    tab2Desc2: '画像をドラッグアンドドロップするか、画像を選択してください',
    tab3Desc1:
      'ディープフェイクの顔を入れ替える元のビデオをアップロードします。',
    tab3Desc2: 'ビデオをドラッグアンドドロップするか、ビデオを選択してください',
    tab4Desc1: '顔を入れ替える元のビデオをアップロードします。',
    tab4Desc2: 'ビデオをドラッグアンドドロップするか、ビデオを選択してください',
    tab1title: 'オンラインで写真や画像の顔を交換する3つのステップ',
    tab1step1: '1. オリジナルの顔写真をアップロードします。',
    tab1step2:
      '2. 元の写真の顔を置き換える人物の顔写真をアップロードします。提供されている写真デモから選択することもできます。',
    tab1step3:
      '3. 顔交換写真 を起動し、HD 結果をプレビューしてダウンロードします。',
    tab2title: 'オンラインで写真内の複数の顔を交換する3つのステップ',
    tab2step1: '1. オリジナルの顔写真をアップロードします。',
    tab2step2:
      '2. 元の写真の顔を置き換える人物の顔写真をアップロードします。提供されている写真デモから選択することもできます。',
    tab2step3:
      '3. Face Swap Picture を起動し、HD 結果をプレビューしてダウンロードします。',
    tab3title: 'オンラインで動画の顔を交換する3つのステップ',
    tab3step1: '1. オリジナルの顔動画をアップロードします。',
    tab3step2:
      '2. 元のビデオの顔を置き換える人物のターゲットの顔をアップロードします。提供されているデモから選択することもできます。',
    tab3step3:
      '3. ビデオ フェイス スワップを開始し、HD 結果をプレビューしてダウンロードします。',
    tab4title: 'オンラインで動画の顔を交換する3つのステップ',
    tab4step1: '1. オリジナルの顔動画をアップロードします。',
    tab4step2:
      '2. 元のビデオの顔を置き換える人物のターゲットの顔をアップロードします。提供されているデモから選択することもできます。',
    tab4step3:
      '3. ビデオ フェイス スワップを開始し、HD 結果をプレビューしてダウンロードします。',
    chooseFiles: 'ファイルを選択',
    photoLimit: '最大10MB',
    videoLimit: '最大500メートル、10分',
    uploading: '<xx1> をアップロードしています',
    faceAnalyzing: '顔分析',
    queuingAbout: 'キューイング中...<xx1> について',
    analyzingAbout: '分析中...<xx1> について',
    somethingError: '何かが間違っています。後でもう一度試してください。',
  },
  faceswapUploadFilePopup: {
    title: '分析されたすべてのファイル',
    subtitle: 'ファイルは7日間のみ保存されます',
    try: '試す',
  },
  faceswapFaceRecognized: '顔認識',
  swapFaces: '顔を交換する',
  useCredit: '<xx1> クレジットを使用する',
  useCredits: '<xx1> クレジットを使用する',
  faceSwapDetail: {
    firstSectionItem1Title: 'ビデオフェイススワップの紹介',
    firstSectionItem1Text:
      'AI フェイス チェンジャーは、人工知能 (AI) と機械学習技術を使用して、画像やビデオ内の顔を変更または交換するソフトウェア アプリケーションです。',
    firstSectionItem2Title: '対象者',
    firstSectionItem2Text:
      'このテクノロジーの主なユーザーは、顔交換の研究者、顔交換に携わる開発者、および顔交換の愛好家であることが予想されます。',
    firstSectionItem3Title: 'AI ビデオ フェイス スワップの紹介',
    firstSectionItem3Text:
      'ビデオ フェイス スワップは、自分の顔を他の人の顔と入れ替える人気のビデオ トレンドです。Beauty AI を使用すると、ビデオで友達、有名人、さらには歴史上の人物と顔を交換できます。面白いビデオ、ミーム、コラージュを作成したり、単に自分の外見を試して楽しんだりできます。',
    secondSectionTitle: 'ビデオ フェイス スワップでできること',
    secondSectionItem1Title: '可能性を探る',
    secondSectionItem1Text:
      'この革新的な技術の魅力的な可能性と予想外の応用例を発見してください。顔の変化が創造性とテクノロジーを融合し、当初の期待以上のものを提供する世界を体験してください。',
    secondSectionItem2Title: 'ビデオフェイススワップ',
    secondSectionItem2Text:
      'この技術の興味深い可能性と予想外の応用について学んでみましょう。創造性とテクノロジーを融合し、当初の期待をはるかに超える顔の変化の世界を体験してください。',
    secondSectionItem3Title: '性別の入れ替え',
    secondSectionItem3Text:
      'TikTok、Snapchat、Instagram のジェンダー スワップ トレンドに参加しましょう。ジェンダー スワップした写真を共有して「いいね」やファンを集め、ソーシャル メディアでの存在感を活気あるものにしましょう。',
    secondSectionItem4Title: 'セレブのビデオ フェイス スワップ',
    secondSectionItem4Text:
      'セレブのビデオに自分の顔を配置して、外見を変えましょう。自分の顔の特徴をセレブの特徴と融合させて、魅力的なコンテンツを作りましょう。',
    secondSectionItem5Title: 'ミームの顔の交換',
    secondSectionItem5Text:
      '顔スワップ機能を使用して面白いミームを作成します。自分の顔や他の顔を使って新鮮で面白いミームを生成し、ソーシャル メディア プラットフォームで笑いを共有します。',
    secondSectionItem6Title: '映画の役柄と入れ替わる',
    secondSectionItem6Text:
      'お気に入りの映画のキャラクターと顔を交換して、恐れ知らずのスーパーヒーローや魅力的なロマンチックな主人公になりきりましょう。画像を共有して映画ファンを興奮させましょう。',
    secondSectionItem7Title: '絵画上の AI 顔交換',
    secondSectionItem7Text:
      '古典的な絵画の顔を自分の顔に置き換えます。これらのユニークな作品を友人やソーシャル メディアで共有し、独特の個人的なスタイルを育みます。',
  },
  faceswapIntroduce: {
    sectionTitle: 'AI ディープフェイク フェイススワップ オンライン',
    item1Title: 'ディープフェイク写真メーカー',
    item1Subtitle:
      '写真内の顔を交換できる無料のオンラインフェイスチェンジャー。',
    item1ActionText: '今すぐフェイススワップ',
    item2Title: '複数のディープフェイク写真ジェネレーター',
    item2Subtitle:
      '家族写真、結婚式の写真、パーティーの写真の複数の顔を PC または iPhone などで入れ替えます。',
    item2ActionText: '複数の顔の交換を今すぐ',
    item3Title: 'ディープフェイクビデオメーカー',
    item3Subtitle:
      'ビデオ内の顔を交換して、映画制作を強化したり、ソーシャル メディア用のコンテンツを作成したりできます。',
    item3ActionText: '今すぐフェイススワップ',
    item4Title: '複数のビデオの顔の交換',
    item4Subtitle:
      'ビデオ内の複数の顔を簡単に置き換えて、視覚コンテンツを変換する楽しくクリエイティブな方法を提供します。',
    item4ActionText: '複数の顔の交換を今すぐ',
  },
  faceswapGuide: {
    tab1SectionTitle: 'AI写真顔交換の仕組み',
    tab1item1Title: 'オリジナルの顔写真をアップロードしてください',
    tab1item1Subtitle:
      'まず、顔交換の入力として、自分の顔が写っている写真をアップロードします。',
    tab1item2Title: '顔交換の対象となる顔写真を選択する',
    tab1item2Subtitle:
      '元の写真の顔を置き換える人物の対象となる顔写真をアップロードします。提供されている写真デモから選択することもできます。',
    tab1item3Title: 'フェイススワップ写真を開始',
    tab1item3Subtitle:
      '「今すぐフェイススワップ」をクリックすると、フェイススワッパーが魔法のように動作します。フェイススワップが完了するまでに 5 ～ 10 秒しかかかりません。',
    tab1item4Title: 'HD 結果をプレビューしてダウンロードする',
    tab1item4Subtitle:
      '顔の交換が完了したら、交換された顔を含む結果の画像をダウンロードして、個人使用または共有します。',
    tab2SectionTitle: 'AI画像顔スワップメーカーの仕組み',
    tab2item1Title: 'オリジナルの顔写真をアップロード',
    tab2item1Subtitle:
      'まず、顔交換の入力として、自分の顔が写っている写真をアップロードします。',
    tab2item2Title: '顔交換の対象となる顔写真を選択する',
    tab2item2Subtitle:
      '元の写真の顔を置き換える人物の顔写真をアップロードします。提供されている写真デモから選択することもできます。',
    tab2item3Title: 'フェイススワップ画像を開始',
    tab2item3Subtitle:
      '「今すぐフェイススワップ」をクリックすると、フェイススワッパーが魔法のように動作します。フェイススワップが完了するまでに 5 ～ 10 秒しかかかりません。',
    tab2item4Title: 'HD 結果をプレビューしてダウンロードする',
    tab2item4Subtitle:
      '顔の交換が完了したら、交換した顔を含む結果の写真をダウンロードして、個人的に使用したり共有したりできます。',
    tab3SectionTitle: 'AIビデオフェイススワップの仕組み',
    tab3item1Title: 'オリジナルの顔動画をアップロード',
    tab3item1Subtitle:
      'まず、フェイススワッピングの入力として、自分の顔が写ったビデオをアップロードします。',
    tab3item2Title: '顔交換の対象となる顔写真を選択する',
    tab3item2Subtitle:
      '元のビデオの顔を置き換える人物のターゲットの顔をアップロードします。提供されているデモから選択することもできます。',
    tab3item3Title: 'フェイススワップビデオを開始',
    tab3item3Subtitle:
      '「今すぐフェイススワップ」をクリックすると、フェイススワッパーが魔法のように動作します。フェイススワップが完了するまでに 5 ～ 10 秒しかかかりません。',
    tab3item4Title: 'HD 結果をプレビューしてダウンロードする',
    tab3item4Subtitle:
      '顔の交換が完了したら、交換された顔を含む結果のビデオをダウンロードして、個人的に使用したり共有したりできます。',
    tab4SectionTitle: 'AIビデオフェイススワップの仕組み',
    tab4item1Title: 'オリジナルの顔動画をアップロード',
    tab4item1Subtitle:
      'まず、フェイススワッピングの入力として、自分の顔が写ったビデオをアップロードします。',
    tab4item2Title: '顔交換の対象となる顔写真を選択する',
    tab4item2Subtitle:
      '元のビデオの顔を置き換える人物のターゲットの顔をアップロードします。提供されているデモから選択することもできます。',
    tab4item3Title: 'フェイススワップビデオを開始',
    tab4item3Subtitle:
      '「今すぐフェイススワップ」をクリックすると、フェイススワッパーが魔法のように動作します。フェイススワップが完了するまでに 5 ～ 10 秒しかかかりません。',
    tab4item4Title: 'HD 結果をプレビューしてダウンロードする',
    tab4item4Subtitle:
      '顔の交換が完了したら、交換された顔を含むビデオを個人使用または共有用にダウンロードします。',
    sectionActionText: 'フェイススワッピングを開始',
    howToUse: '使い方 >',
  },
  review: {
    title: '彼らは私たちを愛しており、あなたもそうするでしょう',
    subtitle:
      '世界中の10,000人以上のパートナーのチームに参加してください。ウェブサイトから受け取る報酬は継続的で安定しています。',
    data1: {
      title: 'Beauty AIが大好きです！',
      content:
        'Beauty AIが大好きです！写真に楽しいひねりを加えるための私のお気に入りのツールです。友達と顔を交換したり、さまざまな有名人のルックを試したりするたびに、結果は常に面白くて楽しいです。',
      name: 'クリフ・クアン',
      dateText: '2024年3月2日',
    },
    data2: {
      title: 'メイクアップ愛好家として',
      content:
        'メイクアップ愛好家として、Beauty AIを使って実際に試す前に新しいルックを試します。さまざまなキャラクターと顔を交換することで、さまざまなスタイルが自分にどのように見えるかを視覚化するのに役立ちます。メイクを試すのが好きな人には楽しくて実用的なツールです！',
      name: 'アンリ・ブレヴォ',
      dateText: '2024年5月16日',
    },
    data3: {
      title: 'ビデオ顔交換は簡単です',
      content:
        'ビデオ顔交換機能を使って、他の誰かと顔を交換するのは簡単です。映画スターの顔を自分の頭に置いたり、バスケットボール選手の顔と交換したりしたい場合、このツールは完璧に仕事をこなします。頑張ってください！',
      name: 'エイダ・ウー',
      dateText: '2024年4月19日',
    },
    data4: {
      title: 'この素晴らしいツールはインテリジェントに',
      content:
        'この素晴らしいツールは写真の顔をインテリジェントに検出し、選択した任意の顔とシームレスに交換します。まるで手元に自分の個人的なクリエイティブスタジオがあるかのようです！Beauty AIを使えば、どんな写真でも顔を簡単に交換できるので、手間なく想像力を解き放つことができます。',
      name: 'マリオ・クーパー',
      dateText: '2024年1月24日',
    },
    data5: {
      title: '写真編集は常に難しいと感じていました',
      content:
        '写真編集は常に難しいと感じていましたが、Beauty AIはそれを簡単にしてくれます！さまざまなAI顔交換オプションを試して、面白い組み合わせを見て楽しむのが大好きです。これは私の写真にユーモアを加え、友達を笑わせるための素晴らしい方法です。',
      name: 'クリス・ジン',
      dateText: '2023年12月12日',
    },
    data6: {
      title: 'AI顔交換は面白いミームの作成に最適です',
      content:
        'AI顔交換は面白いミーム、ヘッドショット、顔交換ビデオの作成に最適です。Beauty AIはエンターテインメントだけでなく、魅力的なコンテンツ作成にも完璧です。ソーシャルメディアでより多くの注目を集めるのに役立ちます。',
      name: 'コトラリコバ',
      dateText: '2024年3月24日',
    },
  },
  faq: {
    title: 'よくある質問',
    details: '詳細 >',
    data1: {
      question: 'Beauty AIとは何ですか？',
      answer:
        'Beauty AIは、複雑な写真編集ツールを必要とせずに、顔の交換、レタッチ、強化、カラー化、補正、カートゥーン化を自動的に行う先進的な技術を活用したビジュアルAIプラットフォームです。最新のAIアルゴリズムを活用して、Beauty AIは写真処理を簡素化し、個人ユーザーと大量の写真編集を必要とするビジネスユーザーの時間、エネルギー、コストを節約します。',
    },
    data2: {
      question: 'AI顔交換ツールはどのように機能しますか？',
      answer:
        'AI顔交換は、高度な人工知能アルゴリズムに依存して、ソース画像を詳細に分析し、顔の特徴を認識し、自然な照明、角度、表情を維持しながら、別の顔にシームレスに置き換えます。これにより、AIが生成したリアルな顔交換が実現します。',
    },
    data3: {
      question: 'ビデオ顔交換の使い方は？',
      answer:
        'まず、基になるクリアな正面画像を選択します。次に、自分の顔または有名人の顔のどちらを交換したいかを選択します。最後に、置換プロセスを開始すると、選択した顔が基の画像にシームレスに配置されます。',
    },
    data4: {
      question: '顔をどんな画像にでも置き換えられますか？',
      answer:
        'はい、もちろんです。Beauty AIを使用すると、選択した任意の画像と顔を無料で交換できます。例えば、男性の顔を女性のポートレートに置いたり、赤ちゃんの写真に老齢の顔を置いたりできます。可能性は無限で、制限なく誰にでもなれます。',
    },
    data5: {
      question: '最高の結果を得るにはどうすればよいですか？',
      answer:
        '顔交換で最適な結果を得るためのヒントを以下に示します：顔の特徴が明確に定義されたクリアな写真を選択し、ぼやけた画像は避けてください。写真に顔が1つだけあることを確認し、顔がカメラに直接向いており、手などの物で遮られていないことを確認してください。',
    },
    data6: {
      question: '無料でオンラインで顔を交換できますか？',
      answer:
        'もちろんです！Beauty AIを使用すると、無料でオンラインで顔を交換できます。ニーズに応じて無料プランとプレミアムプランの両方を提供しています。新しいユーザーとして登録すると、3つのクレジットが付与され、顔交換サービスと顔切り取りやアバター作成などの追加機能に完全にアクセスできます。',
    },
  },
  faceswapToHistory: {
    tab1or2header: 'フェイススワップ写真の歴史をあなたのために保存しました。',
    tab3or4header: 'フェイススワップビデオの歴史をあなたのために保存しました。',
    footer: '詳細については履歴を参照してください。',
  },
  faceswapOnlineSection: {
    tab1Title: 'AI写真フェイススワップオンライン',
    tab2Title: 'AI画像フェイススワップオンライン',
    tab3Title: 'AI ビデオ フェイス スワップ オンライン',
    tab4Title: 'AI フェイススワップビデオメーカーオンライン',
    tab1Desc:
      'Beauty AI Photo Face Swap で顔を瞬時に交換します。写真やビデオの顔を、選択した誰かに置き換えます。面白いミームを作成します。',
    tab2Desc:
      'Beauty AI Picture Face Swap で顔を瞬時に交換します。写真やビデオの顔を、選択した人に変更します。AI アート ジェネレーターの Face Swap を使用して、面白いミームを作成します。',
    tab3Desc:
      'Beauty AI Video Face Swap で顔を瞬時に交換しましょう。写真やビデオの顔を、好きな人に変えましょう。AI 搭載の製品で面白いミームを作成しましょう。',
    tab4Desc:
      'Beauty AI Video Face Swap で顔を瞬時に交換しましょう。動画内の顔を好きな人に変えましょう。AI アート ジェネレーターのフェイス スワップ メーカーで面白いミームを作成しましょう。',
    action: 'フェイススワッピングを開始',
  },
  choose: {
    delText: 'この顔を削除してもよろしいですか?',
    leftBtnText: '続く',
    rightBtnText: 'キャンセル',
    overFaces:
      'アップロードできる顔の数が制限に達しました。続行するには他の顔を削除してください。',
    faceAn: '顔分析中...',
    facesuccess: '顔分析成功',
    facefailed: '顔分析に失敗しました',
    feedback: 'ご意見ありがとうございます！',
    copy: 'コピー成功しました!',
    batchsupport:
      'バッチ処理がサポートされています。現在の結果に関係なく、新しい顔の交換を行うことができます。',
    batchsupportAIGirl:
      'バッチ処理に対応！現在の結果に関係なく、新しい AI ガールを作成できます。',
    submitting: '提出中',
    queuing: 'キューイング',
    creating: '作成',
    downloading: 'ダウンロード中',
    retryText: '何かが間違っています。後でもう一度試してください。',
    retry: 'リトライ',
    emailTip:
      '有効なドメイン名サフィックス（.com、.net など）を入力してください。',
    noaccount: 'まだアカウントがありませんか？',
    enterPassWord: 'パスワードを入力する',
    passWordErr: 'パスワードを8文字以上に設定する',
    loginPassWordErr: 'パスワードは8文字以上でなければなりません',
    forgotpass: 'パスワードをお忘れですか？',
    googlesign: 'Google サインインでログインしますか?',
    resetpassword: 'パスワードを再設定する',
    readyaccount: 'すでにアカウントをお持ちですか？',
    confirm: '確認する',
    resetpasswordsuccess: 'パスワードのリセットに成功しました!',
    senderror: '送信エラー!',
    sendsuccess: '送信成功！',
    signupfree: '無料でお申し込み頂けます',
    signupsuccess: 'サインアップ成功！',
    sendcode: '送信コード',
    loginsuccess: 'ログイン成功！',
    logingerror: 'ログインエラー！',
    aboutS: 'について {leftTime}s',
    keepPatient: '忍耐強く！',
    needMoretime: 'もっと時間がかかります！',
    aimostDoneKeep: 'もう少しで完成するところだった！忍耐強く！',
    usernamePlace: 'user{\'@\'}example.com',
    emailCode: '認証コード',
  },
  feedbackDialog: {
    title: 'Beauty AI を改善するには、皆様のフィードバックが必要です。',
    subTitle1: '改善したいですか?',
    option1: 'アップロードされたファイルに関する不正確な顔分析',
    option2: '似ていない',
    option3: '顔の細部の問題（歪んでいる、目、口など）',
    option4: 'スロータイム',
    option5: '結果の鮮明さ',
    txtOption1: 'センシティブまたは不快な内容',
    txtOption2: '利用規約違反',
    txtOption3: 'プロンプトの関連性',
    txtOption4: '顔が歪む',
    txtOption5: '画像コンテンツ',
    txtOption6: '画像の鮮明度',
    txtOption7: 'スロータイム',
    subTitle2: 'もっと言いたいことはありますか?',
    placeholder: 'その他の提案...',
    submit: '提出する',
    reportTitle: '写真を報告する',
    reportOptionTitle: 'カテゴリ',
    reportOption1: 'センシティブまたは不快な内容',
    reportOption2: 'スパム',
    reportOption3: '利用規約違反',
  },
  infoDialog: {
    advancedFeatures: '高度な機能',
    advancedFeaturesText:
      'ビデオでリアルな顔の交換機能を有効にするには、プランの料金をお支払いください。',
    choosePlan: 'プランを選択',
    insufficientCredits: 'クレジットが不足しています',
    insufficientCreditsText: 'クレジット残高:',
    getMoreCredits: 'クレジットをさらに獲得',
    videoTooBig: 'ビデオが大きすぎます',
    videoTooLong: 'ビデオが長すぎます',
    videoCannotExceed: 'ビデオは',
    inFileSize: 'ファイルサイズ',
    selectAnotherFile: '別のファイルを選択',
    photoTooBig: '写真が大きすぎます',
    photoCannotExceed: '写真は',
    tenMin: '10分',
    deleteFile: 'このファイルを削除してもよろしいですか?',
    cancel: 'キャンセル',
    confirm: '確認する',
  },
  fileUpload: {
    demo: 'デモ',
    historicalFiles: 'すべてのファイル',
    uploading: 'アップロード中',
    queuing: 'キューイング',
    analyzing: '分析',
    photoFileFormatError:
      'ファイル形式がサポートされていません。写真をアップロードしてください。',
    videoFileFormatError:
      'ファイル形式がサポートされていません。ビデオをアップロードしてください。',
    resolutionExceed1920: '解像度は1920×1920を超えることはできません',
    videoIsProcessed:
      'ビデオを処理中です。リソースを消費するため、少し時間がかかる場合がありますが、問題ありません。しばらくお待ちください。',
    filesLoading: '読み込み中、しばらくしてからもう一度お試しください',
  },
  submitSamePhotoTaskHint:
    '警告! 同じ顔と同じ写真が送信されています。送信するには顔を変更してください。(この送信ではクレジットは発生しません)',
  submitSameVideoTaskHint:
    '警告! 同じビデオで同じ顔を投稿しました。顔を変えて投稿してください。(この投稿でクレジットが消費されることはありません)',
  allRightsReserved: '無断転載を禁じます。',
  aiGirlHowToUse: 'AI画像の使い方',
  aiGirlFAQDetails: 'AI画像に関するFAQ詳細',
  lookslikeUploadOpps: {
    title: 'おっとっと！',
    subtitle:
      'おっと！無料クレジットの制限を超えました！\nすべての機能を無制限に使用するには、プランに加入してください。',
    unlockLimit: '制限を解除',
  },
  selectStyle: 'スタイルを選択',
};
