<template>
    <div class="faceswap_edit_recognized_face_listView">
        <p class="title">{{ $t('faceswapFaceRecognized') }}</p>
        <div class="overflow" ref="overflow">
            <div class="list_container" ref="listContainer">
                <FaceSwapEditRecognizedMappingCell 
                    v-for="(mapping, index) in useFaceSwap().mappings" 
                    class="cell"
                    :key="mapping.sourceFace.id"
                    :selected="index == useFaceSwap().currentOperateMaapingIndex" 
                    :mappingData="mapping"
                    @click="mappingCellClick(index)"
                    @removeTargetFace="removeMappingTargetFace(index)"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import FaceSwapEditRecognizedMappingCell from '@/components/faceSwapIndex/edit/FaceSwapEditRecognizedMappingCell.vue';
import { useFaceSwap } from '@/stores/useFaceSwap';
import { getCurrentInstance, onMounted, onUpdated, watch, ref } from 'vue';

let { $utility } = getCurrentInstance().appContext.config.globalProperties;
let listContainer = ref(null);
let overflow = ref(null);
let narrow = $utility.isNarrow;


watch(() => $utility.isNarrow.value, () => {
    layoutSubviews();
});

onMounted(() => {
    layoutSubviews();
});

onUpdated(() => {
    layoutSubviews();
});
function layoutSubviews () {
    let cells = document.querySelectorAll('.list_container .cell');
    if (narrow.value) {
        overflow.value.style.overflowY = 'hidden';
        listContainer.value.style.overflowX = '';
        listContainer.value.style.maxHeight = '164px';
        let maxW = 372;
        if (cells.length != 2) {
            let column = Math.ceil((cells.length / 2));
            maxW = column * 186;
        }
        listContainer.value.style.maxWidth = `${maxW}px`;
        listContainer.value.style.width = `${maxW}px`;
        listContainer.value.style.margin = '0px -10px 0px -10px';
        for (let cell of cells) {
            cell.style.margin = '10px 10px 0px 10px';
            cell.style.width = '166px';
            cell.style.height = '72px';
        }
    }else {
        overflow.value.style.overflowY = '';
        listContainer.value.style.width = 'calc(100% + 40px)';
        listContainer.value.style.maxWidth = null;
        listContainer.value.style.overflowX = 'hidden';
        listContainer.value.style.maxHeight = '283px';
        listContainer.value.style.margin = '4px -20px 0px -20px';
        for (let cell of cells) {
            cell.style.margin = '10px 20px 0px 20px';
            cell.style.width = '203px';
            cell.style.height = '88px';
        }
    }
}

function mappingCellClick (index) {
    if (index == useFaceSwap().currentOperateMaapingIndex) {
        return;
    }
    layoutSubviews();
    useFaceSwap().setCurrentOperateMappingIndex(index);
}
function removeMappingTargetFace (index) {
    useFaceSwap().mappings[index].targetFace = null;
}

</script>

<style scoped lang="scss"> 
.faceswap_edit_recognized_face_listView .list_container {
    display: flex;
    flex-wrap: wrap;
}
.faceswap_edit_recognized_face_listView .title {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    font-family: 'PingFang SC';
    color: white;
}
@media (max-width: 1070px) {
    .faceswap_edit_recognized_face_listView .title {
        font-size: 16px;
        font-weight: 700;
    }
}
</style>