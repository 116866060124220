<template>
  <div class="section-container">
    <h1 class="section-title">{{ $t('AiGirlShowcase.title') }}</h1>
    <div class="caseimg-div">
      <div class="caseimg-div-row" v-for="item in caseList" :key="item.key">
        <div class="list_item" v-for="citem in item.data" :key="citem.promptImageStyleKey" @click="gotoTextImg(citem)">
          <img class="caseimg-img" loading="lazy" :src="citem.promptImageUrl" alt="" />
          <div class="report-ishover" @click.stop="showReport"></div>
          <div class="caseimg-hover ishover">
            <p>
              {{ $t('AiGirlShowcase.hovertitle') }}
              <img :src="buttonDetailImgSrc" alt="">
            </p>
            <span>{{ citem.promptKeyword }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { getCurrentInstance, computed } from 'vue';
import { caseList_es, caseList_fr, caseList_ja, caseList_pt, caseList_en } from '@/script/mock/inspire_mock_data';
import { useTxtToImg } from '@/stores/useTxtToImg';
import { API } from '@/script/api';
const txtToImgStore = useTxtToImg();
const { $analytic, i18n, $showReportFeedbackDialog, $showToast } = getCurrentInstance().appContext.config.globalProperties;
const buttonDetailImgSrc = getCurrentInstance().appContext.config.globalProperties.$utility.assetImage('button_detail');

const caseList = computed(() => {
  if (i18n.global.locate === 'fr') {
    return caseList_fr;
  } else if (i18n.global.locale === 'es') {
    return caseList_es;
  } else if (i18n.global.locale === 'pt') {
    return caseList_pt;
  } else if (i18n.global.locale === 'ja') {
    return caseList_ja;
  }
  return caseList_en;
});


const scrollToTop = () => {
  let sTop = document.documentElement.scrollTop || document.body.scrollTop;
  if (sTop > 0) {
    window.requestAnimationFrame(scrollToTop);
    window.scrollTo(0, sTop - (sTop / 8));
  }
  // 触发图集点击
  txtToImgStore.aiGirlShowcaseTriggerToTopAction();
};

function gotoTextImg (item) {
  scrollToTop();
  $analytic.log('desc_inspirate_tick');
  // window.open('/free-ai-image-generator', '_blank');
  window.localStorage.setItem('txttoimgparams', JSON.stringify(item));
  txtToImgStore.syncRules(item);
}

function showReport () {
  $showReportFeedbackDialog().then((result) => {
    const { confirm, selectedArray, moreMsg } = result;
    if (confirm) {
      $showToast(i18n.global.t('choose.feedback'));
      API.txtToImgFeedBack({
        taskId: '',
        category: selectedArray.join(','),
        content: moreMsg,
      }).then(() => {
      });
    }
  });
}
</script>

<style scoped lang="scss">
@media (hover: hover) {
  .button {
    &:hover {
      background: #CF4D23;
    }
  }
}

@media (any-hover: hover) {
  .report-ishover:hover {
    background: url('@/assets/images/webp/warning_report_hover.webp') !important;
    background-size: contain !important;
  }

  .list_item:hover {
    .report-ishover {
      display: block !important;
    }
  }
}

@media (min-width: 1000px) {
  .section-container {
    text-align: center;
    /* max-width: 1400px; */
    padding: 0 120px;
    background: rgb(23, 23, 23);
    padding: 100px 0;
    width: 100%;

    .section-title {
      margin: 0 auto;
      font-size: 48px;
      font-weight: bold;
      color: #FFF;
      padding: 0;
      line-height: 48px;
    }

    .caseimg-div {
      margin: 40px auto 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      overflow-y: hidden;
      max-width: 1611px;

      .caseimg-div-row:not(:first-child) {
        margin-top: 20px;
      }

      .caseimg-div-row {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;

        >div {
          position: relative;
        }

        >div:not(:last-child) {
          margin-right: 20px;
        }

        .list_item {
          cursor: pointer;
          max-width: 253px;

          &:hover {
            .ishover {
              display: flex;
            }
          }
        }

        .caseimg-img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 8px;
        }

        .report-ishover {
          position: absolute;
          top: 10px;
          right: 10px;
          width: 18px;
          height: 18px;
          background: url('@/assets/images/webp/warning_report.webp');
          background-size: contain;
          display: none;
        }

        .caseimg-hover {
          border-radius: 8px;
          position: absolute;
          left: 0px;
          bottom: 0px;
          text-align: left;
          color: #FFF;
          display: none;
          overflow: hidden;
          height: 50%;
          width: 100%;
          padding-bottom: 18px;

          p {
            font-size: 16px;
            font-weight: bold;
            margin: 0;
            padding: 0 16px;
            margin-bottom: 12px;
          }

          img {
            width: 16px;
            height: 12px;
          }

          span {
            font-size: 10px;
            line-height: 14px;
            padding: 0 16px;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .ishover {
          display: none;
          flex-direction: column;
          justify-content: flex-end;
          background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
        }
      }
    }

    .button-div {
      display: flex;
      justify-content: center;
      margin-top: 40px;

      .button {
        padding: 0 12px;
        min-width: 336px;
        height: 64px;
        background-color: #FF7839;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        align-items: center;
        -webkit-justify-content: center;
        justify-content: center;
        border-radius: 8px;
        cursor: pointer;
        margin-bottom: 10px;
        text-decoration: none;

        &:hover {
          background: #CF4D23;
        }
      }

      .button-detail-icon {
        width: 19px;
        height: 14px;
        margin-left: 9px;
      }

      .button-txt {
        display: inline-block;
        color: white;
        font-family: 'PingFang SC';
        font-size: 22px;
      }
    }
  }
}

@media (max-width: 999px) {
  .section-container {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding: 30px 12px 20px 12px;
    background: rgb(23, 23, 23);

    .section-title {
      font-weight: bold;
      font-size: 18px;
      color: #FFFFFF;
      line-height: 21px;
      text-align: center;
      font-style: normal;
      padding: 0;
    }

    .caseimg-div {
      //margin: 20px auto 0 auto;
      margin: 0 auto;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      overflow-y: hidden;
      padding-top: 20px;

      .caseimg-div-row:not(:first-child) {
        margin-top: 12px;
      }

      .caseimg-div-row {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;

        >div {
          position: relative;
          min-width: 150px;
        }

        >div:not(:last-child) {
          margin-right: 10px;
        }

        .caseimg-img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          width: 100%;
          border-radius: 8px;
        }

        .report-ishover {
          position: absolute;
          top: 6px;
          right: 6px;
          width: 14px;
          height: 14px;
          background: url('@/assets/images/webp/warning_report.webp');
          background-size: contain;
          display: block;
        }

        .caseimg-hover {
          border-radius: 8px;
          position: absolute;
          left: 0px;
          bottom: 0px;
          text-align: left;
          color: #FFF;
          overflow: hidden;
          height: 50%;
          width: 100%;
          padding-bottom: 13px;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));

          p {
            font-size: 13px;
            line-height: 15px;
            margin: 0;
            padding: 0 6px;
            margin-bottom: 3px;
            font-weight: bold;
            font-style: normal;
          }

          img {
            width: 9px;
            height: 8px;
          }

          span {
            font-size: 8px;
            line-height: 10px;
            padding: 0 6px;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: 500;
            color: #F3F9FF;
            font-style: normal;
          }
        }
      }
    }

    .button-div {
      display: flex;
      justify-content: center;
      margin-top: 20px;

      .button {
        padding: 0 12px;
        min-width: 150px;
        min-height: 48px;
        background-color: #FF7839;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        align-items: center;
        -webkit-justify-content: center;
        justify-content: center;
        cursor: pointer;
        margin-bottom: 10px;
        text-decoration: none;

        &:active {
          background: #CF4D23;
        }
      }

      .button-detail-icon {
        width: 13px;
        height: 9px;
        margin-left: 10px;
      }

      .button-txt {
        display: inline-block;
        color: white;
        font-family: 'PingFang SC';
        font-size: 16px;
      }
    }
  }
}
</style>
