<template>
    <div class="container" :style="pageIsNarrow">

        <div class="row">
            <div style="display: flex; align-items: center; cursor: pointer;" @click="showHistoryDialog">
                <div class="title"> {{  $t('fileUpload.historicalFiles') }}</div>
                <img class="arrow-icon" :src="assetImage('arrow_ios_right')" />
            </div>
            <div class="tutorial-narrow-content" @click="showTutorialPopup">
                <p class="tutorial-title">{{ $t('tutorial') }}</p>
                <img class="tutorial-icon" :src="assetImage('faceswap_upload_tutorial')">
            </div>
        </div>
        <div style="height: 12px;"></div>
        <div class="grid-view" ref="scrollContainer" @mousedown="handleMouseDown" @mouseup="handleMouseUp"
            @mouseleave="handleMouseLeave" @mousemove="handleMouseMove">

            <template v-if="page === 'swap' && !fileUploading">
                <div class="add-item">
                    <img class="add_icon" :src="assetImage('add')" />
                    <input type="file" :accept="fileAccept()" @click="onFileClickHook" @change="onFileChange"
                        ref="inputFileDomTag" />
                </div>
            </template>

            <!-- 上传文件时显示 -->
            <template v-if="page === 'swap' && fileUploading">
                <div class="status-item">
                    <div class="status-progress">{{ uploadProgressText || uploadLeftTime }}</div>
                    <div style="height: 4px;"></div>
                    <div class="status-text">{{ uploadStatusText }}</div>
                </div>
            </template>
            <div v-for="(item, index) in uploadFilesVM.files" :key="item.file.fileKey" @click="selectImage(index)"  class="image-item"
                :class="{ 'active': index === selectedFiledIndex }">
                <VOCImageView :requestInfo="imageRequestInfo(item)" ref="vocImage"
                    class="voc-image">

                    <template #failed>
                        <div @click="reloadImage($event, index)" class="retry-item">
                            <img class="retry-img" :src="assetImage('retry_small')" />
                        </div>
                    </template>

                </VOCImageView>
                <div v-if="item.file.demoFlag" class="demo-flag">{{  $t('fileUpload.demo') }}</div>
                <div v-if="!item.file.demoFlag && item.file.fileType === 'video'" class="video-duration">
                    {{ videoDuration(item) }}
                </div>

            </div>
            <!-- 没有获取到文件时过渡动画 -->
            <template v-for="n in 16" :key="n">
                <ShimmerCard v-if="historyFileLoading" class="shimmer-item" style="border-radius: 10px;" />
            </template>
        </div>
        <div class="tutorial-large-content"tyle="width: 100%; display: flex; justify-content: center;">
            <div style="display: flex; align-items: center; margin-right: 10px; cursor: pointer;" @click="showTutorialPopup">
                <p class="tutorial-title">{{ $t('tutorial') }}</p>
                <img class="tutorial-icon" :src="assetImage('faceswap_upload_tutorial')">
            </div>
        </div>
    </div>
    <FileHistoryPopup ref="popupRef" />
    <PhotoExamplePopup ref="photoExamplePopup"/>
    <VideoExamplePopup ref="videoExamplePopup"/>
</template>

<script setup>
import { getCurrentInstance, computed } from 'vue';
import { UploadFileDataStatus, uploadFilesVM } from '@/vm/faceswap_vm';
import ShimmerCard from '@/components/common/ShimmerCard.vue';
import PhotoExamplePopup from '@/components/faceSwapIndex/examplePopup/PhotoExamplePopup'
import VideoExamplePopup from '@/components/faceSwapIndex/examplePopup/VideoExamplePopup'
import { onMounted, ref, inject } from 'vue';
import VOCImageView from '@/components/VOCImageView.vue';
import { VOCImageRequestInfo } from '@/script/voc_img_tool';
import { API } from '@/script/api';
import { useFaceSwap } from '@/stores/useFaceSwap';
import { watch } from 'vue';
import FileHistoryPopup from '@/components/faceSwapIndex/edit/filePopup/FileListPopup.vue';
import { InfoDialogType } from '@/components/common/dialog/infoDialog';
import { User } from '@/script/user';
import { signUpDialog } from '@/components/Dialog';

let props = defineProps({
    inputFileType: {
        type: String,
        default: 'image',
        validator: (value) => {
            return ['image', 'video'].includes(value);
        },
    },
});
const tabCategory = inject('tabCategory');
let { $showToast, $showErrorToast, $showInfoDialog, $analytic, $jumpOtherPage, i18n } = getCurrentInstance().appContext.config.globalProperties;


const photoExamplePopup = ref(null)
const videoExamplePopup = ref(null)
const toSignUpHandle = inject('toSignUpHandle');
const faceSwap = useFaceSwap();
const popupRef = ref(null);
const vocImage = ref(null);
const inputFileDomTag = ref(null);
const uploadStatusText = ref('');
const uploadProgressText = ref('');
const uploadLeftTime = ref('');
const scrollContainer = ref(null);
let isDown = false;
let startX;
let scrollLeft;


watch(uploadFilesVM.uploadFileStatus.currentLeftTime, () => {
    uploadLeftTime.value = `${uploadFilesVM.uploadFileStatus.currentLeftTime.value}s`;
});

watch(uploadFilesVM.uploadFileStatus.status, () => {
    switch (uploadFilesVM.uploadFileStatus.status.value) {
        case UploadFileDataStatus.UPLOADFILE_STATUS_UPLOAD:
            uploadStatusText.value =  i18n.global.t('fileUpload.uploading');
            break;
        case UploadFileDataStatus.UPLOADFILE_STATUS_QUEUE:
            uploadStatusText.value = i18n.global.t('fileUpload.queuing');
            uploadProgressText.value = '';
            break;
        case UploadFileDataStatus.UPLOADFILE_STATUS_ANALYSE:
            uploadStatusText.value = i18n.global.t('fileUpload.analyzing');
            uploadProgressText.value = '';
            break;
        case UploadFileDataStatus.UPLOADFILE_STATUS_FAILED:
            $showErrorToast(uploadFilesVM.uploadFileStatus.failedMessage || i18n.global.t('faceswapUploadArea.somethingError'), 'icon_warning', 'warning');
            uploadStatusText.value = '';
            break;
        case UploadFileDataStatus.UPLOADFILE_STATUS_DONE:
            faceSwap.setSelectedFiledIndex(0);
            uploadStatusText.value = '';
            break;
        default: break;
    }
});

watch(uploadFilesVM.uploadFileStatus.progress, () => {
    uploadProgressText.value = `${(uploadFilesVM.uploadFileStatus.progress.value * 100).toFixed(0)}%`;
});

onMounted(() => {
    assetImage('retry_small');
});


const historyFileLoading = computed(() => {
    return useFaceSwap().dataLoadStatus === API.REQUEST_STATUS_LOADING;
});

const selectedFiledIndex = computed(() => {
    return faceSwap.selectedFiledIndex;
});

const page = computed(() => {
    // index时没有上传按钮，swap有上传按钮
    return faceSwap.selectedFiledIndex < 0 ? 'index' : 'swap';
});

const fileUploading = computed(() => {
    let status = uploadFilesVM.uploadFileStatus.status.value;
    return status !== UploadFileDataStatus.UPLOADFILE_STATUS_NONE &&
        status !== UploadFileDataStatus.UPLOADFILE_STATUS_FAILED &&
        status !== UploadFileDataStatus.UPLOADFILE_STATUS_DONE;
});

const handleMouseDown = (e) => {
    isDown = true;
    startX = e.pageX - scrollContainer.value.offsetLeft;
    scrollLeft = scrollContainer.value.scrollLeft;
};

const handleMouseLeave = () => {
    isDown = false;
};

const handleMouseUp = () => {
    isDown = false;
};

const handleMouseMove = (e) => {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - scrollContainer.value.offsetLeft;
    const walk = (x - startX) * 2; // 滚动速度
    scrollContainer.value.scrollLeft = scrollLeft - walk;
};

function fileAccept () {
    return (props.inputFileType == 'image') ? 'image/*' : 'video/*';
}

const onFileClickHook = (e) => {
    $analytic.log('faceswap_preview_reupload_tick');
    if (!User.getInstance.isLogin.value) {
        e.preventDefault();
        signUpDialog();
        return;
    }
    if (props.inputFileType === 'video' && !(User.getInstance.hasPaid.value && User.instance.allLeftNumber.value > 0)) {
        e.preventDefault();
        $showInfoDialog(InfoDialogType.AdvancedFeatures).then((result) => {
            if (result) {
                $jumpOtherPage('/pricing', true, false);
            }
        });
        return;
    }
};

const onFileChange = async (event) => {
    const file = event.target.files[0];
    event.target.value = null;
    try {
        let analyticCategory;
        if (props.inputFileType == 'image') {
            analyticCategory = 'photo';
            let metadata = await uploadFilesVM.takeImageMetadata(file);
            if (metadata.size > 10 * 1024 * 1024) { // 超出内存大小限制
                $showInfoDialog(InfoDialogType.PhotoTooBig).then((result) => {
                    if (result) {
                        inputFileDomTag.value.click();
                    }
                });
                return;
            } else if (metadata.type == 'image/gif') {
                $showErrorToast(i18n.global.t('fileUpload.photoFileFormatError'));
                return;
            }
            $analytic.log('faceswap_uploading_open', {
                category: 'photo',
                length: 0,
                size: Math.ceil(metadata.size / 1024),
            });
        } else {
            analyticCategory = 'video';
            let metadata = await uploadFilesVM.takeVideoMetadata(file);
            if (metadata.duration > 600) { // 大于10分钟限制
                $showInfoDialog(InfoDialogType.VideoTooLong).then((result) => {
                    if (result) {
                        inputFileDomTag.value.click();
                    }
                });
                return;
            } else if (metadata.width > 1920 || metadata.height > 1920) { // 超出限制长、宽
                $showErrorToast(i18n.global.t('fileUpload.resolutionExceed1920'),);
                return;
            } else if (metadata.size > 500 * 1024 * 1024) { // 超出内存大小限制
                $showInfoDialog(InfoDialogType.VideoTooBig).then((result) => {
                    if (result) {
                        inputFileDomTag.value.click();
                    }
                });
                return;
            }
            $showToast(i18n.global.t('fileUpload.videoIsProcessed'));
            $analytic.log('faceswap_uploading_open', {
                category: analyticCategory,
                length: Math.ceil(metadata.duration),
                size: Math.ceil(metadata.size / 1024),
            });
        }
        uploadFilesVM.uploadFile(file).then(() => {
            $analytic.log('faceswap_upload_finished', { category: analyticCategory });
        }).catch((_) => {
            $analytic.log('faceswap_analyser_unsuccess', { category: analyticCategory });
        });
    } catch (e) {
        if (tabCategory == 0 || tabCategory == 1) {
            $showErrorToast(i18n.global.t('fileUpload.photoFileFormatError'),);    
        }else {
            $showErrorToast(i18n.global.t('fileUpload.videoFileFormatError'),);
        }
    }
};

function assetImage (src) {
    const instance = getCurrentInstance();
    if (instance) {
        const utility = instance.appContext.config.globalProperties.$utility;
        return utility.assetImage(src);
    }
}

function imageRequestInfo (item) {
    let url;
    if (item.file.fileType === 'video') {
        url = item.file.fileThumbUrl;
    } else {
        url = item.file.fileUrl;
    }
    return new VOCImageRequestInfo({ url });
}


function videoDuration (item) {
    let seconds = Math.floor(item.file.fileDuration);
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');

    return `${formattedMinutes}:${formattedSeconds}`;
}

function selectImage (index) {
    if (faceSwap.selectedFiledIndex === index) {
        return;
    }
    faceSwap.setSelectedFiledIndex(index);
}

function showHistoryDialog () {
    if (faceSwap.dataLoadStatus != API.REQUEST_STATUS_SCUCESS) {
        $showToast(i18n.global.t('fileUpload.filesLoading'));
        return;
    }
    popupRef.value.show();
}

function reloadImage (event, index) {
    vocImage.value[index].loadImage();
    event.stopPropagation();  // 阻止事件冒泡
}

function pageIsNarrow () {
    if (window.innerWidth < 1070 && page.value === 'swap') {
        return 'padding:10px';
    }
}

function showTutorialPopup() {
    if (tabCategory <= 1) {
        photoExamplePopup.value.show()
    }else {
        videoExamplePopup.value.show()
    }
}


</script>

<style scoped lang="scss">
.voc-image {
    width: 100%;
    height: 100%;

    img {
        object-fit: cover;
    }
}

.container {
    width: 190px;
    height: 100%;
    background: rgba(255, 255, 255, 0.04);
    box-sizing: border-box;
    padding: 14px 0px 0 14px;
    border-radius: 24px 0px 0px 24px;
    display: flex;
    flex-direction: column;
    align-items: start;
    font-family: 'PingFang SC';
}

.row {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title {
    width: auto;
    height: 18px;
    line-height: 18px;
    margin-right: 5px;
    font-size: 14px;
    color: #FFFFFF;
    text-align: left;
    font-weight: bold;
}

.tutorial-narrow-content {
    display: none;
}
.tutorial-large-content {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 8px;
    margin-bottom: 9px;
}

.tutorial-title {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    font-family: 'PingFang SC';
    color: #FF9441;
    text-decoration: underline;
}

.tutorial-icon {
    margin-left: 3px;
    width: 14px;
    height: 14px;
}

.arrow-icon {
    width: 5px;
    height: 8px;
}

.grid-view {
    flex-grow: 1;
    display: grid;
    grid-template-columns: 76px 76px;
    grid-auto-rows: 76px;
    /* 与滑动条的距离 */
    padding-right: 10px;
    gap: 10px;
    overflow-y: scroll;
}

.grid-view::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

// .grid-view::-webkit-scrollbar-track {
//     background: transparent;
// }

// .grid-view::-webkit-scrollbar-thumb {
//     background: rgba(255, 255, 255, 0.14);
//     border-radius: 3px;
// }

.add-item {
    background: #3F4149;
    border-radius: 10px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;

    input[type="file"] {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        cursor: pointer;
    }
}


.add_icon {
    width: 20px;
    height: 20px;
}

@mixin gradient-border {
    border: 2px solid transparent;
    border-radius: 10px;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(135deg, #3F4149, #3F4149),
        linear-gradient(135deg, rgba(255, 148, 65, 1), rgba(211, 58, 49, 1));
}

.image-item {
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    cursor: pointer;

    &.active {
        @include gradient-border;
    }
}

@media (min-width: 1070px) {
    .image-item:hover {
        @include gradient-border;
    }

    .add-item:hover {
        background-image: url('@/assets/images/png/add_rrect_hover.png');
        background-size: 76px 76px;
    }
}


.status-item {
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #541613 0%, #673C1A 100%);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.status-progress {
    width: auto;
    height: 19px;
    font-weight: bold;
    font-size: 14px;
    color: #FFFFFF80;
    line-height: 19px;
    text-align: center;

}

.status-text {
    width: auto;
    height: 14px;
    font-weight: 400;
    font-size: 12px;
    color: #FFFFFF80;
    line-height: 14px;
    text-align: center;
}

.retry-img {
    width: 24px;
    height: 20px;
}

.retry-item {
    width: 76px;
    height: 76px;
    box-sizing: border-box;
    background: rgba(63, 65, 73, 1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.demo-flag {
    width: 48px;
    height: 16px;
    background: linear-gradient(11deg, #D33A31 0%, #FF9441 100%), #3D3D42;
    border-radius: 16px;
    position: absolute;
    bottom: 2px;
    left: 14px;
    font-weight: 400;
    font-size: 12px;
    color: #FFFFFF;
    line-height: 16px;
    text-align: center;
}

.video-duration {
    width: 42px;
    height: 16px;
    position: absolute;
    bottom: 2px;
    left: 17px;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.95);
    line-height: 16px;
    text-align: center;
}


@media (max-width: 1069px) {
    .container {
        width: 100%;
        height: 108px;
        box-sizing: border-box;
        padding: 10px 15px 10px 20px;
        display: flex;
        flex-direction: column;
        align-items: start;
        font-family: 'PingFang SC';
        background: transparent
    }

    .title {
        height: 14px;
        font-weight: 500;
        font-size: 12px;
    }

    .grid-view {
        width: 100%;
        height: 58px;
        display: flex;
        overflow: auto;
        white-space: nowrap;
        gap: 0 6px;
    }

    .video-duration {
        bottom: 2px;
        left: 8px;
    }

    @mixin narrow-item {
        width: 58px;
        height: 58px;
        flex: 0 0 auto;
    }

    .status-item {
        @include narrow-item()
    }


    .add-item {
        @include narrow-item();

        // &:hover {
        //     background-size: 58px 58px;
        // }
    }

    .image-item {
        @include narrow-item();
        box-sizing: border-box;

    }

    .retry-item {
        @include narrow-item();
        box-sizing: border-box;
    }

    .shimmer-item {
        @include narrow-item();
    }

    .add_icon {
        width: 17px;
        height: 17px;
    }

    .retry-img {
        width: 20px;
        height: 17px;
    }

    .status-progress {
        height: 16px;
        font-size: 12px;
        line-height: 16px;
    }

    .status-text {
        height: 11px;
        font-size: 10px;
        line-height: 11px;
    }

    .demo-flag {
        bottom: 2px;
        left: 5px;
    }

    .tutorial-narrow-content {
        display: flex; 
        align-items: center;
        margin-right: 10px;
        cursor: pointer;
    }
    .tutorial-large-content {
        display: none;
    }
    .tutorial-title {
        margin: 0;
        font-size: 12px;
        font-weight: 500;
        font-family: 'PingFang SC';
        color: #FF9441;
        text-decoration: underline;
    }

    .tutorial-icon {
        margin-left: 2px;
        width: 12px;
        height: 12px;
    }
}
</style>