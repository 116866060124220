<template>
    <div class="faq-section">
        <div class="faq-section-wrapper">
            <div class="faq-section-title-header">
                <view class="faq-section-header-flex-wrapper-8">
                    <h2 class="faq-section-title">{{ $t('txtToImgIndex.faq.sectionTitle') }}</h2>
                </view>
            </div>
            <div class="faq-items-container">
                <div class="item" v-for="item in datas" :key="item.id">
                    <FAQItem :data="item" @faqItemClick="faqItemClick" />
                </div>
            </div>
            <div class="space"></div>
        </div>
    </div>
</template>

<script>
import FAQItem from '@/components/txtToImgIndex/FAQItem.vue';
export default {
    components: { FAQItem },
    data () {
        return {
            datas: [],
            currentUnfoldIndex: -1,
        };
    },
    methods: {
        faqItemClick (data) {
            if (this.datas.indexOf(data) == -1) {
                return;
            }
            let index = this.datas.indexOf(data);
            data.unfold = !data.unfold;
            if (this.currentUnfoldIndex != -1 && index != this.currentUnfoldIndex) {
                this.datas[this.currentUnfoldIndex].unfold = false;
            }
            if (data.unfold) {
                this.currentUnfoldIndex = index;
            } else {
                this.currentUnfoldIndex = -1;
            }
        },
    },
    props: { isDetails: Boolean },
    beforeMount () {
        for (let i = 0; i < 5; i++) {
            this.datas.push({
                id: `${i}`,
                question: this.$t(`txtToImgIndex.faq.item${i + 1}Q`),
                answer: this.$t(`txtToImgIndex.faq.item${i + 1}A`),
                unfold: false,
            });
        }
    },
};
</script>

<style scoped lang="scss">
@media (min-width: 1071px) {
    .faq-section {
        .faq-section-wrapper {
            margin-left: auto;
            margin-right: auto;
            text-align: center;
            max-width: 990px;
            .space {
                height: 100px;
            }
        }

        .faq-section-title-header {
            width: 100%;
            display: -webkit-flex;
            display: flex;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            -webkit-align-items: center;
            align-items: end;
        }

        .faq-section-header-flex-wrapper-8 {
            flex: 8;
        }

        .faq-section-header-flex-wrapper-2 {
            flex: 2;
        }

        .faq-section-title {
            margin: 100px auto 0px auto;
            font-size: 48px;
            font-weight: 700;
            font-family: 'PingFang SC';
            color: white;
        }

        .faq-h-end {
            text-align: end;
            margin-bottom: 8px;
        }

        .faq-section-details {
            right: 0px;
            font-size: 16px;
            font-weight: 400;
            font-family: 'PingFang SC';
            color: white;

            &:hover {
                color: #FF9441;
            }
        }

        .faq-items-container {
            margin-top: 60px;
            .item {
                border-top: 1px solid rgba(255, 255, 2551, 0.12);
                min-height: 53px;
            }
            .item:last-child {
                border-bottom: 1px solid rgba(255, 255, 2551, 0.12);
            }
        }

        .faq-display-none-large {
            display: none;
        }
    }
}
@media (max-width: 1070px) {
    .faq-section {
        .faq-section-wrapper {
            margin-left: auto;
            margin-right: auto;
            text-align: center;
            max-width: 990px;
            padding-left: 20px;
            padding-right: 20px;
            .space {
                height: 30px;
            }
        }
        .faq-section-title-header {
            width: 100%;
            display: -webkit-flex;
            display: flex;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            -webkit-align-items: center;
            align-items: end;
        }
        .faq-section-header-flex-wrapper-8 {
            flex: 8;
        }
        .faq-section-header-flex-wrapper-2 {
            flex: 2;
        }
        .faq-section-title {
            margin-left: auto;
            margin-right: auto;
            font-size: 18px;
            font-weight: 700;
            font-family: 'PingFang SC';
            color: white;
            margin-top: 29px;
            margin-bottom: 40px;
        }
        .faq-h-end {
            text-align: end;
            margin-bottom: 8px;
        }
        .faq-section-details {
            right: 0px;
            font-size: 14px;
            font-weight: 400;
            font-family: 'PingFang SC';
            color: white;
            &:hover {
                color: #FF9441;
            }
        }
        .faq-section-details-top {
            margin-top: 10px;
        }
        .faq-items-container {
            .item {
                border-top: 0.5px solid rgba(255, 255, 2551, 0.12);
            }
            .item:last-child {
                border-bottom: 0.5px solid rgba(255, 255, 2551, 0.12);
            }
        }
        .faq-display-none-narrow {
            display: none;
        }
    }
}
</style>