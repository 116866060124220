import basic from '../basic/ja'
export default {
    ...basic,
    ...{
        ok : "わかりました",
        tutorialEntrance : "質の高い作品を作る方法",
        examplePopup : {
            title : "高品質な作品を作るにはどうすればいいでしょうか？",
            photoTip : "ヒント：最高のAIフェイススワップ写真を取得するには、オンラインでリフェイスしたい写真の品質を確認してください。",
            videoTip : "ヒント：最高のAIフェイススワップビデオを取得するには、オンラインでリフェイスしたいビデオの品質を確認してください。",
            goodExample : "良い例",
            badExample : "悪い例",
            highQuality : "高品質",
            lookingAtViewer : "ビューアーを見る",
            sideFaces : "·側面",
            tooFar : "·遠すぎる",
            blurry : "ぼやけた",
            lessCover : "カバーが少ない",
            lowResolution : "低解像度",
        },
        youtubeSectionTitle : "Face Swapの使い方は？",
        tutorial : "チュートリアル",        
    },
}