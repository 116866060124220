<template>
    <div class="faq-item-container" @click="click">
        <div class="faq-question-bar">
            <h3 :class="faqQuestionTxtClsBind">{{ data.question }}</h3>
            <img :class="faqStatusImgClsBind" :src="arrowSrc">
        </div>
        <transition name="router-slid">
            <div :class="faqAnswerContentClsBind" style="width: 100%; min-width: 100%;">
                <p class="faq-answer-txt">{{ data.answer }}</p>
            </div>    
        </transition>
    </div>
</template>

<script>
export default {
    data () {
        return {
            faqAnswerContentClsBind : ['faq-answer-content', 'faq-display-none'],
            faqStatusImgClsBind : 'faq-status-img',
            arrowSrc: this.$utility.assetImage('txtToIndex_faqItem_expand'),
            faqQuestionTxtClsBind : 'faq-question-txt',
        };
    },
    props: { data: Object },  
    watch: {
        'data.unfold' () {
            this.updateDisplayStatus();
        },
    },
    methods: {
        click () {
            this.$emit('faqItemClick', this.data);
        },
        
        updateDisplayStatus () {
            let isMobile = window.mobileAndTabletCheck();
            if (this.data.unfold) {
                this.faqAnswerContentClsBind = ['faq-answer-content'];
                this.arrowSrc = this.$utility.assetImage('txtToIndex_faqItem_close');
                this.faqStatusImgClsBind = 'faq-status-img-selected';
            }else {
                this.faqStatusImgClsBind = 'faq-status-img';
                this.faqAnswerContentClsBind = ['faq-answer-content', 'faq-display-none'];
                this.arrowSrc = this.$utility.assetImage('txtToIndex_faqItem_expand');
            }
            this.faqQuestionTxtClsBind = 'faq-question-txt';
        },
    },
};
</script>

<style scoped lang="scss">
@media (min-width: 1071px) {
    .faq-item-container {
        width: 100%;
        min-width: 300px;
        cursor: pointer;
        text-align: start;
        color: white;
        font-family: 'PingFang SC';
        margin: 0px;
        min-height: 50px;
    }
    .faq-question-bar {
        margin-top: 25px;
        display: flex;
        display: -webkit-flex;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        -webkit-align-items: center;
        align-items: center;
    }
    .faq-display-none {
        display: none;
    }
    .faq-question-txt {
        margin: 0;
        font-size: 20px;
        line-height: 20px;
        font-weight: 500; 
        font-family: 'PingFang SC';
    }  
    .faq-status-img {
        width: 26px;
        height: 26px;
    }
    .faq-status-img-selected {
        width: 26px;
        height: 26px;
    }
    .faq-answer-txt {
        margin: 20px 0 30px 0;
        font-size: 12px;
        font-weight: 400;
        font-family: 'PingFang SC';
        color: #828589;
    }
    .router-slid-enter-active, .router-slid-leave-active {
        transition: all .4s;
    }
    .router-slid-enter, .router-slid-leave-active {
        transform: translate3d(0, 3rem, 0);
        opacity: 0;
    }
}
@media (max-width: 1070px) {
    .faq-item-container {
        width: 100%;
        cursor: pointer;
        text-align: start;
        color: white;
        font-family: 'PingFang SC';
        margin: 0;
        min-height: 53px;
        display: flex;
        flex-direction: column;
        justify-content: left;
        align-items: center;
    }
    .faq-question-bar {
        margin-top: 19.5px;
        width: 100%;
        display: flex;
        display: -webkit-flex;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        align-items: center;
    }
    .faq-display-none {
        display: none;
    }
    .faq-question-txt {
        margin: 0;
        font-size: 12px;
        font-weight: 500; 
        font-family: 'PingFang SC';
        line-height: 14px;
    }  
    .faq-status-img {
        width: 14px;
        height: 14px;
    }
    .faq-status-img-selected {
        width: 14px;
        height: 14px;
    }
    .faq-answer-txt {
        margin: 12px 0 19.5px 0;
        font-size: 10px;
        font-weight: 400;
        font-family: 'PingFang SC';
        color: #828589;
        width: 100%;
        min-width: 100%;
    }
    .router-slid-enter-active, .router-slid-leave-active {
        transition: all .4s;
    }
    .router-slid-enter, .router-slid-leave-active {
        transform: translate3d(0, 3rem, 0);
        opacity: 0;
    }
}
</style>