import { nanoid } from 'nanoid';

export class Task {
  static STATUS_SUBMIT = 0;
  static STATUS_QUEUE = 1;
  static STATUS_PAINTING = 2;
  static STATUS_DOWNLOADING = 3;
  static STATUS_COMPLETE = 4;
  static STATUS_GENERATE_ERROR = -1;
  static STATUS_DONWLOAD_ERROR = -1001;

  constructor (originData, submitParams = {}, localTaskId) {
    this.localTaskId = localTaskId || nanoid();
    this.originData = originData;
    this.submitParams = submitParams;
    this.submitParamsIdentify = '';
    this.submitParams.localTaskId = this.localTaskId;
    this.taskStatus = null; // 任务处理状态
    this.failedMessage = ''; // 任务失败message, 可能为空
    this.taskId = null; // 任务id
    this.currentLeftTime = 0; // 当前阶段剩余时间
    this.leftTime = 0; // 预计排队时间
    this.paintingTime = 0; // 预计生成时间
    this.downloadingTime = 3; // 预计下载时间，默认3s
    this.fileData = null; // 生成结果
    this.imageBlobUrl = null; // 图片blob格式url
    this.hasFeedback = false; // 是否点击过反馈
    this.hasPublish = false; // 是否点击过发布
    this.publishing = false; // 是否发布中
    this.hasReport = false; // 是否点击过举报
    this.countTimerId = null;
    this.animationTimerId = null;
    this.progress = 0.05;
    this.updateStatus(Task.STATUS_SUBMIT);

    // 以下是埋点所用字段
    this.analyticCategory = '';
    this.analytiBeginTime = 0;
    this.analyticDemo = false;
    this.analyticNewFile = false;
  }

  updateStatus (newV, errorMessage) {
    if (errorMessage) {
      this.failedMessage = errorMessage;
    }
    if (this.taskStatus == newV) return;
    this.taskStatus = newV;
    this.countTimerDispose();
    this.animationTimerDispose();
    switch (newV) {
      case Task.STATUS_SUBMIT:
        {
          this.progress = 0.05;
          this.nextAnimationTimer(0.05, 0.25, 1);
        }
        break;
      case Task.STATUS_QUEUE:
        {
          this.progress = 0.25;
          this.nextTimer(this.leftTime);
          this.nextAnimationTimer(0.25, 0.5, this.leftTime);
        }
        break;
      case Task.STATUS_PAINTING:
        {
          this.progress = 0.5;
          this.nextTimer(this.paintingTime);
          this.nextAnimationTimer(0.5, 0.92, this.paintingTime);
        }
        break;
      case Task.STATUS_DOWNLOADING:
        {
          this.progress = 0.92;
          this.nextTimer(this.downloadingTime);
          this.nextAnimationTimer(0.92, 0.99, this.downloadingTime);
        }
        break;
      case Task.STATUS_COMPLETE:
        {
          this.progress = 0.99;
        }
        break;
      default:
        break;
    }
  }

  get processing () {
    switch (this.taskStatus) {
      case Task.STATUS_SUBMIT:
      case Task.STATUS_QUEUE:
      case Task.STATUS_PAINTING:
      case Task.STATUS_DOWNLOADING: {
        return true;
      }
      default:
        return false;
    }
  }

  get inGenerateProcessing () {
    switch (this.taskStatus) {
      case Task.STATUS_SUBMIT:
      case Task.STATUS_QUEUE:
      case Task.STATUS_PAINTING: {
        return true;
      }
      default: {
        return false;
      }
    }
  }

  get inChannelProcessing () {
    switch (this.taskStatus) {
      case Task.STATUS_QUEUE:
      case Task.STATUS_PAINTING: {
        return true;
      }
      default: {
        return false;
      }
    }
  }

  countTimerDispose () {
    if (this.countTimerId) {
      clearInterval(this.countTimerId);
      this.countTimerId = null;
    }
  }

  nextTimer (seconds) {
    this.currentLeftTime = seconds;
    this.countTimerId = setInterval(() => {
      if (this.currentLeftTime > 0) {
        this.currentLeftTime -= 1;
      }
    }, 1000);
  }

  animationTimerDispose () {
    if (this.animationTimerId) {
      clearInterval(this.animationTimerId);
      this.animationTimerId = null;
    }
  }

  nextAnimationTimer (beginValue, endValue, duration) {
    let beginTime = parseInt(Date.now());
    this.animationTimerId = setInterval(() => {
      let curTime = parseInt(Date.now());
      let value = (curTime - beginTime) / (duration * 1000);
      if (value >= 1) {
        return;
      }
      let percent = (endValue - beginValue) * value;
      this.progress = beginValue + percent;
    }, 200);
  }
}
