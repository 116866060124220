<template>
    <div class="review-section-container">
        <h2 class="review-title">{{ $t('review.title') }}</h2>
        <div class="review-scroll-wrapper">
            <div class="review-section-content">
                <div class="clm clm-t-space">
                    <FaceSwapReviewCard :title="datas[0].title" :content="datas[0].content" :avatar="datas[0].avatar" :userName="datas[0].userName" :dateText="datas[0].dateText"/>
                    <div class="clm-v-space"></div>
                    <FaceSwapReviewCard :title="datas[1].title" :content="datas[1].content" :avatar="datas[1].avatar" :userName="datas[1].userName" :dateText="datas[1].dateText"/>
                </div>
                <div class="clm-h-space"></div>
                <div class="clm">
                    <FaceSwapReviewCard :title="datas[2].title" :content="datas[2].content" :avatar="datas[2].avatar" :userName="datas[2].userName" :dateText="datas[2].dateText"/>
                    <div class="clm-v-space"></div>
                    <FaceSwapReviewCard :title="datas[3].title" :content="datas[3].content" :avatar="datas[3].avatar" :userName="datas[3].userName" :dateText="datas[3].dateText"/>
                </div>
                <div class="clm-h-space"></div>
                <div class="clm clm-t-space">
                    <FaceSwapReviewCard :title="datas[4].title" :content="datas[4].content" :avatar="datas[4].avatar" :userName="datas[4].userName" :dateText="datas[4].dateText"/>
                    <div class="clm-v-space"></div>
                    <FaceSwapReviewCard :title="datas[5].title" :content="datas[5].content" :avatar="datas[5].avatar" :userName="datas[5].userName" :dateText="datas[5].dateText"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FaceSwapReviewCard from '@/components/faceSwapIndex/FaceSwapReviewCard';

export default {
    components: { FaceSwapReviewCard },
    data () {
        return { datas: [] };
    },
    beforeMount () {
        for (let i = 0; i < 6; i++) {
            let a = `review.data${i + 1}.title`;
            this.datas.push({
                title: this.$t(a),
                content: this.$t(`review.data${i + 1}.content`),
                avatar: this.$utility.assetImage(`review_user${i + 1}_avatar`),
                userName: this.$t(`review.data${i + 1}.name`),
                dateText: this.$t(`review.data${i + 1}.dateText`),
            });
        }
    },
};

</script>

<style scoped lang="scss"> 
@media (min-width: 1071px) {
    .review-section-container {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }
    .review-title {
        color: white;
        font-size: 40px;
        font-weight: 700; 
        font-family: 'PingFang SC';
        margin: 0;
    }
    .review-section-content {
        margin-top: 40px;
        max-width: 1400px;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        -webkit-align-items: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        padding: 0% 20px;
    }
    .clm {
        max-width: 420px;
        flex: 1;
    }
    .clm-v-space {
        height: 30px;
    }
    .clm-h-space {
        min-width: 20px;
    }
    .clm-t-space {
        margin-top: 20px;
    }
}
@media (max-width: 1070px) {
    .review-section-container {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }
    .review-title {
        color: white;
        font-size: 18px;
        font-weight: 700; 
        font-family: 'PingFang SC';
        margin-left: 18px;
        margin-right: 18px;
        margin-top: 0px;
        margin-bottom: 0px;
    }
    .review-scroll-wrapper {
        margin-top: 40px;
        overflow-x: scroll;
        white-space: nowrap;
        animation: scroll 10s linear infinite;
        width: 100%;
    }
    .review-section-content {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        -webkit-align-items: center;
        align-items: center;
    }
    .clm {
        white-space: normal;
        min-width: 312px;
    }
    .clm:first-child {
        padding-left: 10px;
    }
    .clm:last-child {
        padding-right: 10px;
    }
    .clm-v-space {
        height: 10px;
    }
    .clm-h-space {
        min-width: 10px;
    }
    .clm-t-space {
        margin-top: 20px;
    }
}
</style>