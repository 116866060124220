import { ENV } from '@/script/proj_env';
export const caseList_es = [
    {
        key: 'list1', data: [
        {
                    promptKey: 'inspiration_general_1',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_1.png`,
                    promptKeyword: 'Cree una ilustración inspirada en el art déco de un pintoresco faro situado en una costa rocosa, con vistas a las aguas turquesas de Cala Ratjada. Incluya colores pastel vibrantes, líneas elegantes y una atmósfera retro de verano. El estilo debe recordar a los carteles de viajes antiguos con un toque moderno.',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Realista',
                },
                {
                    promptKey: 'inspiration_general_2',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_2.png`,
                    promptKeyword: '(obra maestra), (extremadamente complejo: 1.3), (realista), retrato de una niña, la más bella del mundo, (armadura medieval), reflejos metálicos, parte superior del cuerpo, exteriores, luz solar intensa, castillo lejano, fotografía profesional de una mujer deslumbrante detallada, enfoque nítido, dramática, galardonada, iluminación cinematográfica, motor irreal de renderizado de octanaje, dtx volumétrico, (grano de película, fondo borroso, primer plano borroso, bokeh, profundidad de campo, puesta de sol, desenfoque de movimiento: 1.3), cota de malla',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Realista',
                },
                {
                    promptKey: 'inspiration_general_3',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_3.png`,
                    promptKeyword: '(resolución ultra alta: 1.4), (obra maestra), (hermosa iluminación: 1.4), bosque verde y exuberante, montañas, la luz del sol brillante ilumina el agua azul cristalina, (bajo el agua), fotorrealista, mitad sobre el agua y mitad bajo el agua, (Claro bajo el agua), hermoso cielo con nubes y humo, peces tropicales, corales, profundidad, red de comunicación iluminada,',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Realista',
                },
                {
                    promptKey: 'inspiration_general_4',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_4.png`,
                    promptKeyword: 'valle, pueblo de cuento de hadas cubierto, pintura mate, muy detallado, iluminación dinámica, cinematográfico, realismo, realista, foto real, puesta de sol, detallado, alto contraste, sin ruido, centrado, michael whelan',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Realista',
                },
                {
                    promptKey: 'inspiration_general_5',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_5.png`,
                    promptKeyword: 'Un gato gordo y amarillo fue a KFC a robar pollo frito.',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Realista',
                },
        ],
    },
    {
        key: 'list2', data: [
                {
                    promptKey: 'inspiration_general_6',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_6.png`,
                    promptKeyword: 'una piscina estilo dibujos animados, piscina con anillos inflables, flotador de pato, ambiente veraniego, puesto de helados colorido, exterior, sin humanos, representación digital, día soleado, cielo azul, palmeras, hierba, flores, margaritas, colores vibrantes, diseño divertido, al aire libre, obra maestra, la mejor calidad,',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realisticsemi',
                    promptImageStyle: 'Digital realista',
                },
                {
                    promptKey: 'inspiration_general_7',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_7.png`,
                    promptKeyword: 'Imágenes promocionales publicitarias. La imagen muestra una lata de bebida azul con la marca blanca Liblib y el logotipo de un melocotón cortado impreso. Jugo de naranja y duraznos en rodajas esparcidos alrededor de la lata, creando una atmósfera animada y tentadora. El fondo es un cielo azul profundo.',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Realista',
                },
                {
                    promptKey: 'inspiration_general_8',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_8.png`,
                    promptKeyword: 'Flores de guisantes de color púrpura y blanco en un jarrón, al estilo de Vincent van Gogh, fondo verde,',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realisticsemi',
                    promptImageStyle: 'Digital realista',
                },
                {
                    promptKey: 'inspiration_general_9',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_9.png`,
                    promptKeyword: 'una sala de estar bellamente diseñada con una mezcla de influencias eclécticas bohemias y escandinavas. creando un espacio acogedor, acogedor y habitado. La paleta de colores presenta tonos terrosos cálidos, neutros y acentos vibrantes principalmente de exuberantes plantas de interior y obras de arte eclécticas. La apariencia se caracteriza por ambientes amplios y luminosos logrados a través de abundante luz natural que entra por grandes ventanales. Los materiales naturales como la madera, el ratán y el mimbre realzan la atmósfera orgánica y serena.',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Realista',
                },
                {
                    promptKey: 'inspiration_general_10',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_10.png`,
                    promptKeyword: 'Fotografía de producto, cielo azul, botellas de vidrio, plantas,',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Realista',
                },
        ],
    },
    {
        key: 'list3', data: [
                {
                    promptKey: 'inspiration_general_11',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_11.png`,
                    promptKeyword: 'hola kitty fondo guerra de las galaxias',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Realista',
                },
                {
                    promptKey: 'inspiration_general_12',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_12.png`,
                    promptKeyword: 'un pastel de bodas blanco con flores rosadas en el costado del pastel',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Realista',
                },
                {
                    promptKey: 'inspiration_general_13',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_13.png`,
                    promptKeyword: 'cgmech, ojos hermosos, parte superior del cuerpo, retrato, robot, armadura blanca naranja, cabello blanco brillante, luz de neón, 8K, RAW, mejor calidad, obra maestra, resolución ultra alta, colorido, (toma ancha media), (perspectiva dinámica), enfoque nítido (profundidad de campo, bokeh: 1,3), ojos y rostro extremadamente detallados, hermosos ojos detallados (oro negro, equipo recortado: 1,2), (en una fábrica de armas futurista: 1,2), ((obra maestra, mejor calidad) ), Fondo detallado, interior de la nave espacial. ',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Realista',
                },
                {
                    promptKey: 'inspiration_general_14',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_14.png`,
                    promptKeyword: 'Camiseta ilustrada, personaje de gato caprichoso, paisaje de Sunset Beach, paleta de colores tropicales, textura de tela, arte lineal atrevido, perspectiva dinámica,',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Realista',
                },
                {
                    promptKey: 'inspiration_general_15',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_15.png`,
                    promptKeyword: 'mejor calidad, obra maestra, UHD, fondos de pantalla extremadamente detallados, iluminación perfecta, el fondo azul claro del universo, galaxia, (cohete), (nave espacial), universo, luna, planeta, computadora, muñeca LEGO, bloques de construcción LEGO, vidrio, cristal ciudad futura, sentido tecnológico, escenas isométricas,',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realisticsemi',
                    promptImageStyle: 'Digital realista',
                },
        ],
    },
    {
        key: 'list4', data: [
                {
                    promptKey: 'inspiration_general_16',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_16.png`,
                    promptKeyword: 'Fondo abstracto de estilo científico, fondo azul, ondas moradas y rosadas, fondo de pantalla brillante de 8k (obra maestra, mejor calidad: 1.3), partículas tecnológicas y sentido de la tecnología.',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Realista',
                },
                {
                    promptKey: 'inspiration_general_17',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_17.png`,
                    promptKeyword: 'fotografía de viaje, colorido globo aerostático, nubes, temprano en la mañana, amanecer, colores vibrantes, pacífico, sereno, vista panorámica, flotando arriba, aventuras, cielo limpio, luz suave, panorámico, vasto paisaje, tonos suaves, niebla de la mañana, tranquilo, aéreo Ver, pintoresco, gran altitud, cielos abiertos, atmosférico, soñador, libertad, exploración, paseos en globo, horizonte, amanecer, naturaleza, belleza escénica, fotorrealista, brillante, alegre, viaje,',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Realista',
                },
                {
                    promptKey: 'inspiration_general_18',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_18.png`,
                    promptKeyword: 'CPice, nieve, nieve, una botella de cerveza azul, botella de cerveza, tema blanco, botella de cerveza sobre un glaciar, cubierta por hielo y nieve (luz brillante: 1,2), vista superior (tono azul: 1,1), (gota: 1,2) . Buena ventilación, gradación de colores, nadie presente (hielo pequeño: 1,2),',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Realista',
                },
                {
                    promptKey: 'inspiration_general_19',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_19.png`,
                    promptKeyword: 'El desierto soleado, las imponentes montañas cubiertas de nieve en la distancia, el cielo azul, las nubes blancas, el hermoso bosque de álamos dorados, el agua del río que fluye cerca, las olas brillantes, la luz de la mañana, el paisaje natural pacífico y armonioso, y el fondo de pantalla del teléfono móvil. Luz deslumbrante, luz para fotografía, (mejor calidad: 1,5), (obra maestra: 1,5), (ultra realista: 1,5), (alto detalle: 1,5),',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Realista',
                },
                {
                    promptKey: 'inspiration_general_20',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_20.png`,
                    promptKeyword: 'fondo de pantalla de mariposa',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Realista',
                },
        ],
    },
];
export const caseList_ja = [
    {
        key: 'list1', data: [
        {
                    promptKey: 'inspiration_general_1',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_1.png`,
                    promptKeyword: 'カララトハダのターコイズブルーの海を見下ろす岩の多い海岸線に建つ美しい灯台を、アールデコ調のイラストで表現しましょう。鮮やかなパステルカラー、洗練されたライン、レトロな夏の雰囲気を取り入れましょう。スタイルは、現代風にアレンジしたビンテージの旅行ポスターを彷彿とさせるものにしましょう。',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: '現実的な',
                },
                {
                    promptKey: 'inspiration_general_2',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_2.png`,
                    promptKeyword: '(傑作)、(非常に精巧:1.3)、(リアル)、世界で最も美しい少女の肖像画、(中世の鎧)、金属の反射、上半身、屋外、強い日光、遠くの城、見事な女性のプロの写真、詳細、シャープなフォーカス、ドラマチック、受賞歴、映画のような照明、オクタン レンダリング アンリアル エンジン、ボリューメトリック DTX、(フィルム グレイン、ぼやけた背景、ぼやけた前景、ボケ、被写界深度、日没、モーション ブラー:1.3)、チェーンメイル',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: '現実的な',
                },
                {
                    promptKey: 'inspiration_general_3',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_3.png`,
                    promptKeyword: '(超高解像度:1.4)、(傑作)、(美しい照明:1.4)、緑豊かな森、山々、明るい日光が透き通った青い水を照らします、(深い水中)、フォトリアリスティック、半分は水上、半分は水中、(透明な水中)、雲と煙のある美しい空、熱帯魚、サンゴ、深さ、照明された通信ネットワーク、',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: '現実的な',
                },
                {
                    promptKey: 'inspiration_general_4',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_4.png`,
                    promptKeyword: '谷、おとぎ話のツリーハウスの村、覆われた、マットペインティング、非常に詳細、ダイナミックライティング、映画的、リアリズム、現実的、写真のようにリアル、日没、詳細、高コントラスト、ノイズ除去、中央、マイケル・ウェラン',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: '現実的な',
                },
                {
                    promptKey: 'inspiration_general_5',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_5.png`,
                    promptKeyword: '太った黄色い猫がフライドチキンを盗むためにKFCに行きました。',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: '現実的な',
                },
        ],
    },
    {
        key: 'list2', data: [
    
        {
                    promptKey: 'inspiration_general_6',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_6.png`,
                    promptKeyword: '漫画風のプール、膨らませる浮き輪付きのプール、アヒルの浮き輪、夏の雰囲気、カラフルなアイスクリームスタンド、屋外、人間なし、デジタルレンダリング、晴れた日、青空、ヤシの木、草、花、ヒナギク、鮮やかな色、遊び心のあるデザイン、屋外、傑作、最高品質、',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realisticsemi',
                    promptImageStyle: 'デジタルリアル',
                },
                {
                    promptKey: 'inspiration_general_7',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_7.png`,
                    promptKeyword: '広告宣伝画像。写真には、白いブランド名 Liblib とカットされた桃のロゴが印刷された青い缶飲料が写っています。缶の周りにはオレンジジュースとスライスされた桃が飛び散り、活気のある魅力的な雰囲気を醸し出しています。背景は深い青空です。',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: '現実的な',
                },
                {
                    promptKey: 'inspiration_general_8',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_8.png`,
                    promptKeyword: 'ヴィンセント・ヴァン・ゴッホ風の花瓶に入った紫と白のスイートピーの花、緑の背景、',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realisticsemi',
                    promptImageStyle: 'デジタルリアル',
                },
                {
                    promptKey: 'inspiration_general_9',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_9.png`,
                    promptKeyword: 'ボヘミアンとスカンジナビアの折衷的なスタイルが融合した、美しくデザインされたリビングルーム。居心地がよく、居心地のよい、生活感のある空間を演出します。カラーパレットは、温かみのあるアースカラー、ニュートラルカラー、そして主に緑豊かな観葉植物と折衷的なアートワークによる鮮やかなアクセントが特徴です。外観と雰囲気は、大きな窓から差し込む十分な自然光によって実現される明るく風通しの良い環境が特徴です。木材、籐、枝編み細工品などの天然素材が、オーガニックで落ち着いた雰囲気を高めています。',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: '現実的な',
                },
                {
                    promptKey: 'inspiration_general_10',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_10.png`,
                    promptKeyword: '商品写真、青空、ガラス瓶、植物、',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: '現実的な',
                },
        ],
    },
    {
        key: 'list3', data: [
    
        {
                    promptKey: 'inspiration_general_11',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_11.png`,
                    promptKeyword: 'ハローキティの背景スターウォーズ',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: '現実的な',
                },
                {
                    promptKey: 'inspiration_general_12',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_12.png`,
                    promptKeyword: 'ケーキの横にピンクの花が飾られた白いウェディングケーキ',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: '現実的な',
                },
                {
                    promptKey: 'inspiration_general_13',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_13.png`,
                    promptKeyword: 'cgmech、美しい目、上半身、ポートレート、ロボット、白オレンジ色の鎧、白く輝く髪、ネオンライト、8K、RAW、最高品質、傑作、超高解像度、カラフル、(中広角ショット)、(ダイナミックパースペクティブ)、シャープフォーカス、(被写界深度、ボケ:1.3)、非常に詳細な目と顔、美しい詳細な目、(ブラックゴールド、トリムされたギア:1.2)、(未来的な武器工場内:1.2)、((傑作、最高品質))、詳細な背景、宇宙船の内部 ',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: '現実的な',
                },
                {
                    promptKey: 'inspiration_general_14',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_14.png`,
                    promptKeyword: 'イラスト入りTシャツ、気まぐれな猫のキャラクター、夕暮れのビーチの風景、トロピカルカラーパレット、布地の質感、大胆な線画、ダイナミックな遠近法、',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: '現実的な',
                },
                {
                    promptKey: 'inspiration_general_15',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_15.png`,
                    promptKeyword: '最高品質、傑作、UHD、非常に詳細な壁紙、完璧な照明、宇宙の明るい青色の背景、銀河、（ロケット）、（宇宙船）、宇宙、月、惑星、コンピューター、レゴ人形、レゴブロック、ガラス、クリスタル、未来都市、テクノロジー感覚、アイソメトリックシーン、',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realisticsemi',
                    promptImageStyle: 'デジタルリアル',
                },
        ],
    },
    {
        key: 'list4', data: [
    
        {
                    promptKey: 'inspiration_general_16',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_16.png`,
                    promptKeyword: '科学的なスタイルの抽象的な背景、青い背景、紫とピンクの波、輝く、8kの壁紙、（傑作、最高品質：1.3）、技術的な粒子、そして技術感覚、',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: '現実的な',
                },
                {
                    promptKey: 'inspiration_general_17',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_17.png`,
                    promptKeyword: '旅行写真、カラフルな熱気球、雲、早朝、日の出、鮮やかな色、平和、穏やか、美しい景色、上空に浮かぶ、冒険、晴れた空、穏やかな光、パノラマ、広大な風景、柔らかい色合い、朝霧、静寂、空中写真、絵のように美しい、高度、広い空、雰囲気のある、夢のような、自由、探検、熱気球、地平線、夜明け、自然、美しい景色、写真のようにリアル、明るい、陽気な、旅、',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: '現実的な',
                },
                {
                    promptKey: 'inspiration_general_18',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_18.png`,
                    promptKeyword: 'C氷、雪、雪、青いビール瓶、ビール瓶、白のテーマ、氷河の上のビール瓶、氷と雪に覆われている、(明るい光: 1.2)、上から見た図、(青のトーン: 1.1)、(水滴: 1.2)。換気良好、カラーグレーディング、誰もいない (小さな氷: 1.2)、',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: '現実的な',
                },
                {
                    promptKey: 'inspiration_general_19',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_19.png`,
                    promptKeyword: '晴れた砂漠、遠くにそびえ立つ雪をかぶった山々、青い空、うねる白い雲、美しい黄金色のポプラの森、近くを流れる川の水、きらめく波、朝の光、平和で調和のとれた自然の風景、そして携帯電話の壁紙。まばゆいばかりの光、写真撮影の光、（最高品質：1.5）、（傑作：1.5）、（超リアル：1.5）、（高詳細：1.5）、',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: '現実的な',
                },
                {
                    promptKey: 'inspiration_general_20',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_20.png`,
                    promptKeyword: '蝶の壁紙',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: '現実的な',
                },
        ],
    },
];
export const caseList_fr = [
    {
        key: 'list1', data: [
        {
                    promptKey: 'inspiration_general_1',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_1.png`,
                    promptKeyword: 'Créez une illustration d\'inspiration art déco d\'un phare pittoresque perché sur une côte rocheuse, surplombant les eaux turquoise de Cala Ratjada. Incluez des couleurs pastel vibrantes, des lignes épurées et une atmosphère estivale rétro. Le style doit rappeler les affiches de voyage vintage avec une touche moderne,',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Réaliste',
                },
                {
                    promptKey: 'inspiration_general_2',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_2.png`,
                    promptKeyword: '(chef-d\'œuvre), (extrêmement complexe :1.3), (réaliste), portrait d\'une jeune fille, la plus belle du monde, (armure médiévale), reflets métalliques, haut du corps, extérieur, soleil intense, château lointain, photographie professionnelle de une femme époustouflante détaillée, mise au point nette, dramatique, primé, éclairage cinématographique, moteur de rendu irréel d\'octane, dtx volumétrique, (grain de film, arrière-plan flou, premier plan flou, bokeh, profondeur de champ, coucher de soleil, flou de mouvement : 1,3), cotte de mailles',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Réaliste',
                },
                {
                    promptKey: 'inspiration_general_3',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_3.png`,
                    promptKeyword: '(ultra haute résolution : 1,4), (chef-d\'œuvre), (bel éclairage : 1,4), forêt verdoyante, montagnes, la lumière du soleil illumine l\'eau bleue cristalline, (profondément sous l\'eau), photoréaliste, à moitié au-dessus de l\'eau et à moitié sous l\'eau, (clair sous l\'eau), beau ciel avec nuages et fumée, poissons tropicaux, coraux, profondeur, réseau de communication éclairé,',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Réaliste',
                },
                {
                    promptKey: 'inspiration_general_4',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_4.png`,
                    promptKeyword: 'vallée, village de cabane dans les arbres de conte de fées couvert, peinture mate, très détaillé, éclairage dynamique, cinématique, réalisme, réaliste, photo réelle, coucher de soleil, détaillé, contraste élevé, débruité, centré, Michael Whelan',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Réaliste',
                },
                {
                    promptKey: 'inspiration_general_5',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_5.png`,
                    promptKeyword: 'Un gros chat jaune est allé au KFC pour voler du poulet frit.',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Réaliste',
                },
        ],
    },
    {
        key: 'list2', data: [
    
        {
                    promptKey: 'inspiration_general_6',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_6.png`,
                    promptKeyword: 'une piscine de style dessin animé, une piscine avec des anneaux de natation gonflables, un flotteur de canard, une ambiance estivale, un stand de glace coloré, extérieur, pas d\'humains, rendu numérique, journée ensoleillée, ciel bleu, palmiers, herbe, fleurs, marguerites, couleurs vibrantes, design ludique,extérieur,chef-d\'œuvre, meilleure qualité,',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realisticsemi',
                    promptImageStyle: 'Numérique réaliste',
                },
                {
                    promptKey: 'inspiration_general_7',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_7.png`,
                    promptKeyword: 'Images promotionnelles publicitaires. La photo montre une canette bleue de boisson sur laquelle est imprimé le nom de marque Liblib en blanc et le logo d\'une pêche coupée. Du jus d\'orange et des tranches de pêches éclaboussaient la canette, créant une atmosphère animée et attrayante. Le fond est un ciel bleu profond',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Réaliste',
                },
                {
                    promptKey: 'inspiration_general_8',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_8.png`,
                    promptKeyword: 'Fleurs de pois de senteur de couleur violette et blanche dans un vase, dans le style de Vincent van Gogh, fond vert,',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realisticsemi',
                    promptImageStyle: 'Numérique réaliste',
                },
                {
                    promptKey: 'inspiration_general_9',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_9.png`,
                    promptKeyword: 'un salon magnifiquement décoré avec un mélange d\'influences éclectiques bohèmes et scandinaves. créer un espace accueillant, confortable et habité. La palette de couleurs présente des tons terreux chauds, des tons neutres et des accents vibrants provenant principalement de plantes d\'intérieur luxuriantes et d\'œuvres d\'art éclectiques. L\'apparence et la sensation sont caractérisées par des environnements lumineux et aérés obtenus grâce à une lumière naturelle abondante provenant de grandes fenêtres. Les matériaux naturels comme le bois, le rotin et l\'osier rehaussent l\'atmosphère organique et sereine',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Réaliste',
                },
                {
                    promptKey: 'inspiration_general_10',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_10.png`,
                    promptKeyword: 'Photographie de produits, ciel bleu, bouteilles en verre, plantes,',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Réaliste',
                },
        ],
    },
    {
        key: 'list3', data: [
    
        {
                    promptKey: 'inspiration_general_11',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_11.png`,
                    promptKeyword: 'bonjour kitty fondstar wars',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Réaliste',
                },
                {
                    promptKey: 'inspiration_general_12',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_12.png`,
                    promptKeyword: 'un gâteau de mariage blanc avec des fleurs roses sur le côté du gâteau',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Réaliste',
                },
                {
                    promptKey: 'inspiration_general_13',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_13.png`,
                    promptKeyword: 'cgmech, , beaux yeux, haut du corps, portrait, robot, armure blanche orange, cheveux blancs chatoyants, lumière néon, 8K, RAW, meilleure qualité, chef-d\'œuvre, ultra haute résolution, coloré, (plan moyen large), (perspective dynamique), mise au point nette, (profondeur de champ, bokeh : 1,3), yeux et visage extrêmement détaillés, beaux yeux détaillés, (or noir, équipement garni : 1,2), (dans une usine d\'armes futuriste : 1,2), ((chef-d\'œuvre, meilleure qualité) ), fond détaillé, intérieur du vaisseau spatial ',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Réaliste',
                },
                {
                    promptKey: 'inspiration_general_14',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_14.png`,
                    promptKeyword: 'T-shirt illustré, personnage de chat fantaisiste, paysage de plage au coucher du soleil, palette de couleurs tropicales, texture de tissu, linéaire gras, perspective dynamique,',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Réaliste',
                },
                {
                    promptKey: 'inspiration_general_15',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_15.png`,
                    promptKeyword: 'meilleure qualité, chef-d\'œuvre, UHD, fonds d\'écran extrêmement détaillés, éclairage parfait, le fond bleu clair de l\'univers, galaxie, (fusée), (vaisseau spatial), univers, lune, planète, ordinateur, poupée LEGO, blocs de construction LEGO, verre, cristal ville du futur, sens technologique, scènes isométriques,',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realisticsemi',
                    promptImageStyle: 'Numérique réaliste',
                },
        ],
    },
    {
        key: 'list4', data: [
    
        {
                    promptKey: 'inspiration_general_16',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_16.png`,
                    promptKeyword: 'Fond abstrait de style scientifique, fond bleu, vagues violettes et roses, brillant, fond d\'écran 8k (chef-d\'œuvre, meilleure qualité : 1.3), particules technologiques et sens de la technologie,',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Réaliste',
                },
                {
                    promptKey: 'inspiration_general_17',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_17.png`,
                    promptKeyword: 'photo de voyage, montgolfière colorée, nuages, tôt le matin, lever du soleil, couleurs vibrantes, paisible, serein, vue panoramique, flottant au dessus, aventure, ciel clair, lumière douce, panoramique, vaste paysage, teintes douces, brume matinale, tranquille, aérien vue, pittoresque, haute altitude, ciel ouvert, atmosphérique, rêveur, liberté, exploration, montgolfière, horizon, aube, nature, beauté des paysages, photoréaliste, lumineux, gai, voyage,',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Réaliste',
                },
                {
                    promptKey: 'inspiration_general_18',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_18.png`,
                    promptKeyword: 'CPice, Neige, neige, une bouteille de bière bleue, bouteille de bière, thème blanc, bouteille de bière sur glacier, recouverte de glace et de neige, (lumière vive : 1,2), vue de dessus, (ton bleu : 1,1), (gouttelette : 1,2) . Bonne ventilation, étalonnage des couleurs, personne présente (petite glace : 1,2),',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Réaliste',
                },
                {
                    promptKey: 'inspiration_general_19',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_19.png`,
                    promptKeyword: 'Le désert ensoleillé, les imposantes montagnes enneigées au loin, le ciel bleu, les nuages blancs, la magnifique forêt de peupliers dorés, l\'eau de la rivière qui coule à proximité, les vagues scintillantes, la lumière du matin, le paysage naturel paisible et harmonieux, et le fond d\'écran du téléphone portable. Lumière éblouissante, lumière photographique, (meilleure qualité : 1,5), (chef-d\'œuvre : 1,5), (ultra réaliste : 1,5), (détail élevé : 1,5),',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Réaliste',
                },
                {
                    promptKey: 'inspiration_general_20',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_20.png`,
                    promptKeyword: 'papier peint papillon',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Réaliste',
                },
        ],
    },
    ];
export const caseList_pt = [
    {
        key: 'list1', data: [{
                    promptKey: 'inspiration_general_1',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_1.png`,
                    promptKeyword: 'Crie uma ilustração inspirada em art déco de um farol panorâmico situado em uma costa rochosa, com vista para as águas azul-turquesa de Cala Ratjada. Inclua cores pastel vibrantes, linhas elegantes e uma atmosfera retrô de verão. O estilo deve lembrar pôsteres de viagens vintage com um toque moderno,',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Realista',
                },
                {
                    promptKey: 'inspiration_general_2',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_2.png`,
                    promptKeyword: '(obra-prima), (extremamente intricado:1.3), (realista), retrato de uma menina, a mais bela do mundo, (armadura medieval), reflexos metálicos, parte superior do corpo, exterior, luz solar intensa, castelo distante, fotografia profissional de uma mulher deslumbrante detalhada, foco nítido, dramática, premiada, iluminação cinematográfica, mecanismo irreal de renderização de octanas, volumetria dtx, (grão de filme, fundo desfocado, primeiro plano desfocado, bokeh, profundidade de campo, pôr do sol, desfoque de movimento: 1,3), cota de malha',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Realista',
                },
                {
                    promptKey: 'inspiration_general_3',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_3.png`,
                    promptKeyword: '(resolução ultra alta: 1,4), (obra-prima), (bela iluminação: 1,4), floresta verdejante, montanhas, luz solar intensa ilumina a água azul cristalina, (submarino profundo), fotorrealista, metade acima da água e metade abaixo da água, (limpo debaixo d\'água), lindo céu com nuvens e fumaça, peixes tropicais, corais, profundidade, rede de comunicação iluminada,',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Realista',
                },
                {
                    promptKey: 'inspiration_general_4',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_4.png`,
                    promptKeyword: 'vale, vila de casa na árvore de conto de fadas coberta, pintura fosca, altamente detalhada, iluminação dinâmica, cinematográfica, realismo, realista, foto real, pôr do sol, detalhado, alto contraste, sem ruído, centralizado, Michael Whelan',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Realista',
                },
                {
                    promptKey: 'inspiration_general_5',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_5.png`,
                    promptKeyword: 'Um gato amarelo gordo foi ao KFC para roubar frango frito.',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Realista',
                }],
    },
    {
        key: 'list2', data: [
                {
                    promptKey: 'inspiration_general_6',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_6.png`,
                    promptKeyword: 'uma piscina estilo desenho animado, piscina com anéis de natação infláveis, flutuador de pato, clima de verão, barraca de sorvete colorida, ao ar livre, sem humanos, renderização digital, dia ensolarado, céu azul, palmeiras, grama, flores, margaridas, cores vibrantes, design lúdico, ao ar livre, obra-prima, melhor qualidade,',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realisticsemi',
                    promptImageStyle: 'Digital Realista',
                },
                {
                    promptKey: 'inspiration_general_7',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_7.png`,
                    promptKeyword: 'Imagens promocionais publicitárias. A imagem mostra uma lata de bebida azul com a marca branca Liblib e o logotipo de um pêssego cortado impresso. Suco de laranja e pêssegos fatiados espalhados pela lata, criando uma atmosfera animada e atraente. O fundo é um céu azul profundo',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Realista',
                },
                {
                    promptKey: 'inspiration_general_8',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_8.png`,
                    promptKeyword: 'flores de ervilha doce de cor roxa e branca em um vaso, no estilo de Vincent van Gogh, fundo verde,',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realisticsemi',
                    promptImageStyle: 'Digital Realista',
                },
                {
                    promptKey: 'inspiration_general_9',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_9.png`,
                    promptKeyword: 'uma sala de estar lindamente decorada com uma mistura de influências ecléticas boêmias e escandinavas. criando um espaço convidativo, aconchegante e habitado. A paleta de cores apresenta tons terrosos quentes, neutros e detalhes vibrantes, principalmente de plantas de interior exuberantes e obras de arte ecléticas. A aparência é caracterizada por ambientes claros e arejados, obtidos através da ampla entrada de luz natural de grandes janelas. Materiais naturais como madeira, rattan e vime melhoram a atmosfera orgânica e serena',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Realista',
                },
                {
                    promptKey: 'inspiration_general_10',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_10.png`,
                    promptKeyword: 'Fotografia de produtos, céu azul, garrafas de vidro, plantas,',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Realista',
                }],
    },
    {
        key: 'list3', data: [
                {
                    promptKey: 'inspiration_general_11',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_11.png`,
                    promptKeyword: 'olá kitty plano de fundo guerra nas estrelas',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Realista',
                },
                {
                    promptKey: 'inspiration_general_12',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_12.png`,
                    promptKeyword: 'um bolo de casamento branco com flores rosa na lateral do bolo',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Realista',
                },
                {
                    promptKey: 'inspiration_general_13',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_13.png`,
                    promptKeyword: 'cgmech, lindos olhos, parte superior do corpo, retrato, robô, armadura branca laranja, cabelo branco brilhante, luz neon, 8K, RAW, melhor qualidade, obra-prima, resolução ultra alta, colorido, (plano amplo médio), (perspectiva dinâmica), foco nítido, (profundidade de campo, bokeh: 1,3), olhos e rosto extremamente detalhados, belos olhos detalhados, (ouro preto, equipamento aparado: 1,2), (em uma fábrica de armas futurista: 1,2), ((obra-prima, melhor qualidade) ), Plano de fundo detalhado, interior da nave espacial ',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Realista',
                },
                {
                    promptKey: 'inspiration_general_14',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_14.png`,
                    promptKeyword: 'Camiseta ilustrada, personagem de gato caprichoso, paisagem de praia ao pôr do sol, paleta de cores tropicais, textura de tecido, arte linear em negrito, perspectiva dinâmica,',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Realista',
                },
                {
                    promptKey: 'inspiration_general_15',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_15.png`,
                    promptKeyword: 'melhor qualidade, obra-prima, UHD, papéis de parede extremamente detalhados, iluminação perfeita, fundo azul claro do universo, galáxia, (foguete), (nave espacial), universo, lua, planeta, computador, boneca LEGO, blocos de construção LEGO, vidro, cristal , cidade futura, sentido tecnológico, cenas isométricas,',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realisticsemi',
                    promptImageStyle: 'Digital Realista',
                }],
    },
    {
        key: 'list4', data: [
                {
                    promptKey: 'inspiration_general_16',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_16.png`,
                    promptKeyword: 'Fundo abstrato de estilo científico, fundo azul, ondas roxas e rosa, brilhante, papel de parede 8k, (obra-prima, melhor qualidade: 1.3), partículas tecnológicas e um senso de tecnologia,',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Realista',
                },
                {
                    promptKey: 'inspiration_general_17',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_17.png`,
                    promptKeyword: 'fotografia de viagem, balão de ar quente colorido, nuvens, de manhã cedo, nascer do sol, cores vibrantes, pacífico, sereno, vista panorâmica, flutuando acima, aventura, céu limpo, luz suave, panorâmico, vasta paisagem, tons suaves, névoa matinal, tranquilo, aéreo vista, pitoresca, alta altitude, céu aberto, atmosférica, sonhadora, liberdade, exploração, balão de ar quente, horizonte, amanhecer, natureza, beleza cênica, foto-realista, brilhante, alegre, jornada,',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Realista',
                },
                {
                    promptKey: 'inspiration_general_18',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_18.png`,
                    promptKeyword: 'CPice, neve, neve, uma garrafa de cerveja azul, garrafa de cerveja, tema branco, garrafa de cerveja na geleira, coberta por gelo e neve, (luz brilhante: 1,2), vista superior, (tom azul: 1,1), (gota: 1,2) . Boa ventilação, classificação de cores, ninguém presente (gelo pequeno: 1,2),',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Realista',
                },
                {
                    promptKey: 'inspiration_general_19',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_19.png`,
                    promptKeyword: 'O deserto ensolarado, as imponentes montanhas cobertas de neve ao longe, o céu azul, as nuvens brancas ondulantes, a bela floresta de choupos dourados, a água corrente do rio próximo, as ondas cintilantes, a luz da manhã, o cenário natural pacífico e harmonioso, e o papel de parede do telefone móvel. Luz deslumbrante, luz fotográfica, (melhor qualidade: 1,5), (obra-prima: 1,5), (ultra realista: 1,5), (alto detalhe: 1,5),',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Realista',
                },
                {
                    promptKey: 'inspiration_general_20',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_20.png`,
                    promptKeyword: 'papel de parede de borboleta',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Realista',
                }],
    },
];
export const caseList_en = [
    {
     key: 'list1', data: [{
                    promptKey: 'inspiration_general_1',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_1.png`,
                    promptKeyword: 'Create an art deco inspired illustration of A scenic lighthouse perched on a rocky coastline, overlooking the turquoise waters of Cala Ratjada. Include vibrant pastel colors, sleek lines, and a retro summer atmosphere. The style should be reminiscent of vintage travel posters with a modern twist,',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Realistic',
                },
                {
                    promptKey: 'inspiration_general_2',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_2.png`,
                    promptKeyword: '(masterpiece), (extremely intricate:1.3), (realistic), portrait of a girl, the most beautiful in the world, (medieval armor), metal reflections, upper body, outdoors, intense sunlight, far away castle, professional photograph of a stunning woman detailed, sharp focus, dramatic, award winning, cinematic lighting, octane render  unreal engine,  volumetrics dtx, (film grain, blurry background, blurry foreground, bokeh, depth of field, sunset, motion blur:1.3), chainmail',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Realistic',
                },
                {
                    promptKey: 'inspiration_general_3',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_3.png`,
                    promptKeyword: '(ultra high res:1.4), (masterpiece), (beautiful lighting:1.4) , lush green forest, Mountains, Bright sunlight illuminates the crystal-clear blue water, (deep underwater), photorealistic, half above water and half below water, (clear underwater), beautiful sky with clouds and smoke, tropical fish, coral, depth, Illuminated communication network,',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Realistic',
                },
                {
                    promptKey: 'inspiration_general_4',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_4.png`,
                    promptKeyword: 'valley, fairytale treehouse village covered, , matte painting, highly detailed, dynamic lighting, cinematic, realism, realistic, photo real, sunset, detailed, high contrast, denoised, centered, michael whelan',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Realistic',
                },
                {
                    promptKey: 'inspiration_general_5',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_5.png`,
                    promptKeyword: 'A fat yellow cat went to KFC to steal fried chicken.',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Realistic',
                }],
    },
    {
     key: 'list2', data: [
                {
                    promptKey: 'inspiration_general_6',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_6.png`,
                    promptKeyword: 'a cartoon-style swimming pool,pool with inflatable swim rings,duck float,summer vibe,colorful ice cream stand,outdoor,no humans,digital rendering,sunny day,blue sky,palm trees,grass,flowers,daisies,vibrant colors,playful design,outdoors,masterpiece, best quality,',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realisticsemi',
                    promptImageStyle: 'Digital Realistic',
                },
                {
                    promptKey: 'inspiration_general_7',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_7.png`,
                    promptKeyword: 'Advertising promotional images. The picture shows a blue can of beverage with the white brand name Liblib and the logo of a cut peach printed on it. Orange juice and sliced peaches splattered around the can, creating a lively and enticing atmosphere. The background is a deep blue sky',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Realistic',
                },
                {
                    promptKey: 'inspiration_general_8',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_8.png`,
                    promptKeyword: 'purple and white colour sweet pea flowers in a vase, in the style of Vincent van Gogh, green background,',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realisticsemi',
                    promptImageStyle: 'Digital Realistic',
                },
                {
                    promptKey: 'inspiration_general_9',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_9.png`,
                    promptKeyword: 'a beautifully styled living room with a blend of eclectic bohemian and Scandinavian influences. creating space that is inviting, cozy, and lived-in. The color palette features warm earthy tones, neutrals, and vibrant accents primarily from lush indoor plants and eclectic artwork. The look and feel are characterized by bright, airy environments achieved through ample natural light streaming in from large windows. Natural materials like wood, rattan, and wicker enhance the organic, serene atmosphe',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Realistic',
                },
                {
                    promptKey: 'inspiration_general_10',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_10.png`,
                    promptKeyword: 'Product Photography,Blue Sky,Glass Bottles,Plants,',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Realistic',
                }],
    },
    {
     key: 'list3', data: [ 
                {
                    promptKey: 'inspiration_general_11',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_11.png`,
                    promptKeyword: 'hello kitty backgroundstar wars',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Realistic',
                },
                {
                    promptKey: 'inspiration_general_12',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_12.png`,
                    promptKeyword: 'a white wedding cake with pink flowers on the side of the cake',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Realistic',
                },
                {
                    promptKey: 'inspiration_general_13',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_13.png`,
                    promptKeyword: 'cgmech, , beautiful eyes, upper body, portrait, robot,white orange armor, white shimmering hair, neon light, 8K, RAW, best quality, masterpiece, ultra high res, colorful, (medium wide shot), (dynamic perspective), sharp focus , (depth of field, bokeh:1.3), extremely detailed eyes and face, beautiful detailed eyes,(black gold, trimmed gear:1.2),(In a futuristic weapons factory:1.2), ((masterpiece, best quality)), Detailed background, spaceship interior ',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Realistic',
                },
                {
                    promptKey: 'inspiration_general_14',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_14.png`,
                    promptKeyword: 'Illustrated T-shirt, Whimsical Cat Character, Sunset Beach Landscape, Tropical Color Palette, Fabric Texture, Bold Lineart, Dynamic Perspective,',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Realistic',
                },
                {
                    promptKey: 'inspiration_general_15',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_15.png`,
                    promptKeyword: 'best quality, masterpiece, UHD, extremely detailed wallpapers, perfect lighting,The light blue background of the universe, galaxy, (rocket), (spacecraft), universe, moon, planet, computer, LEGO doll, LEGO building blocks, Glass, crystal, future city, technological sense, isometric scenes,',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realisticsemi',
                    promptImageStyle: 'Digital Realistic',
                }],
    },
    {
     key: 'list4', data: [
                {
                    promptKey: 'inspiration_general_16',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_16.png`,
                    promptKeyword: 'Scientific style abstract background, blue background, purple and pink waves, glowing, 8k wallpaper, (masterpiece, best quality: 1.3), technological particles, and a sense of technology,',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Realistic',
                },
                {
                    promptKey: 'inspiration_general_17',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_17.png`,
                    promptKeyword: 'travel photograph, colorful hot air balloon, clouds, early morning, sunrise, vibrant colors, peaceful, serene, scenic view, floating above, adventure, clear sky, gentle light, panoramic, vast landscape, soft hues, morning mist, tranquil, aerial view, picturesque, high altitude, open skies, atmospheric, dreamy, freedom, exploration, hot air ballooning, horizon, dawn, nature, scenic beauty, photo-realistic, bright, cheerful, journey,',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Realistic',
                },
                {
                    promptKey: 'inspiration_general_18',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_18.png`,
                    promptKeyword: 'CPice, Snow, snow, a blue beer bottle, beer bottle, white theme, beer bottle on glacier, covered by ice and snow, (bright light: 1.2), top view, (blue tone: 1.1), (droplet: 1.2). Good ventilation, color grading, no one present (small ice: 1.2),',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Realistic',
                },
                {
                    promptKey: 'inspiration_general_19',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_19.png`,
                    promptKeyword: 'The sunny desert, the towering snow capped mountains in the distance, the blue sky, the surging white clouds, the beautiful golden poplar forest, the flowing river water nearby, the sparkling waves, the morning light, the peaceful and harmonious natural scenery, and the mobile phone wallpaper. Dazzling light, photography light, (best quality: 1.5), (masterpiece: 1.5), (ultra realistic: 1.5), (high detail: 1.5),',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Realistic',
                },
                {
                    promptKey: 'inspiration_general_20',
                    promptImageUrl: `${ENV.currentConfig.mediaCDN}/ai/inspiration/inspiration_general_20.png`,
                    promptKeyword: 'butterfly wallpaper',
                    promptImageSize: '23',
                    promptImageStyleKey: 'realistic',
                    promptImageStyle: 'Realistic',
                }],
    },	
];