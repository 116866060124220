<template>
    <div class="top-nav-prod-more-container">
        <div v-for="(item, index) of items" :key="item.id" @click="click(index,item)" class="top-nav-prod-more-item-container">
            <a :href="item.href" target="_blank"  class="top-nav-prod-more-item">{{ item.name }}</a>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            items: [
                {
                    id: 1,
                    name: this.$t('photoFaceSwap'),
                },
                {
                    id: 2,
                    name: this.$t('photoMutipleFaceSwap'),
                },
                {
                    id: 3,
                    name: this.$t('videoFaceSwap'),
                },
                {
                    id: 4,
                    name: this.$t('videoMutipleFaceSwap'),
                },
                {
                    id: 5,
                    name: this.$t('aIImageGenerator'),
                },
                {
                    id: 6,
                    name: this.$t('magicBrush'),
                },
            ],
        };
    },
    methods: {
        click (index, item) {
            switch (index) {
                case 0:
                case 1: 
                case 2:
                case 3: {
                    this.$analytic.log('navigation_faceswap_tick');
                }break;
                case 4: {
                    this.$analytic.log('navigation_creation_tick');
                }break;
                case 5: {
                    this.$analytic.log('navigation_brush_tick');
                }break;
            }
            window.open(item.href, '_blank');
        },
    },
    created () {
        this.items[0].href = this.$otherPageUrl('/photo-face-swap', true);
        this.items[1].href = this.$otherPageUrl('/multiple-picture-face-swap', true);
        this.items[2].href = this.$otherPageUrl('/video-face-swap', true);
        this.items[3].href = this.$otherPageUrl('/multiple-video-face-swap', true);
        this.items[4].href = this.$otherPageUrl('/free-ai-image-generator', true, true);
        this.items[5].href = this.$otherPageUrl('/brush', false);
    },
};
</script>

<style scoped lang="scss">
/*去除a标签下划线*/
a {
	text-decoration: none;
    color: white;
}
/*去除未被访问的a标签的下划线*/
a:link {
	text-decoration: none;
}
/*去除已经被访问过的a标签的下划线*/
a:visited {
	text-decoration: none;
}
/*去除鼠标悬停时的a标签的下划线*/
a:hover {
	text-decoration: none;
}
/*去除正在点击的a标签的下划线（鼠标按下，尚未松开）*/
a:active {
	text-decoration: none;
}
/*去除获得焦点的a标签的下划线（被鼠标点击过）*/
a:focus {
	text-decoration: none;
}
.top-nav-prod-more-container {
    background: #27282D;
    border-radius: 20px;
    padding: 10px 12px;
    position: fixed;
    top: 69px; 
    transform: translateX(-34%);
    padding-top: 10px;
}
.top-nav-prod-more-item-container {
    border-radius: 10px;
    min-width: 166px;
    padding: 8px;
    text-align: center;
    &:not(:last-child) {
        margin-bottom: 10px;
    }
    &:hover {
        background-color: rgba(255, 255, 255, 0.10);
    }
}
.top-nav-prod-more-item {
    font-size: 14px;
    font-weight: 400;
    font-family: 'PingFang SC';
    color: white;
    pointer-events: none;
}
@media (max-width: 1070px) {
    .top-nav-prod-more-container {
        top: 56px;
    }
    .top-nav-prod-more-item-container {
        min-width: 134px;
    }   
    .top-nav-prod-more-item {
        font-size: 12px;
    }
}
</style>