<template>
    <div class="section-container">
        <h2 class="section-title">{{ title }}</h2>
        <div class="card-container">
            <FaceSwapGuideCard v-for="item in items" :key="item.id" :class="'card-'+item.id" :src="item.src" :title="item.title" :subtitle="item.subtitle"/>
        </div>
        <a class="section-actionBtn" @click.prevent="jump"  :href="$otherPageUrl('/photo-face-swap', true)">
            <p>{{ $t('startFaceButtonTitle') }}</p>
            <img class="section-actionBtn-arrow" :src="buttonDetail" alt="">
        </a>
        <a class="section-link" target="_blank" :href="tutorialUrl">{{ $t('faceswapGuide.howToUse') }}</a>
    </div>
</template>

<script>
import FaceSwapGuideCard from '@/components/faceSwapIndex/FaceSwapGuideCard.vue';
export default {
    components: { FaceSwapGuideCard },
    inject: ['tabCategory'],
    data () {
        return {
            buttonDetail: '',
            tutorialUrl: `${window.location.protocol}//${window.location.host}/how-video-face-swap-work`,
            title: '',
            items: [
                {
                    id: '1',
                    src: '',
                    title: '',
                    subtitle: '',
                },
                {
                    id: '2',
                    src: '',
                    title: '',
                    subtitle: '',
                },
                {
                    id: '3',
                    src: '',
                    title: '',
                    subtitle: '',
                },
                {
                    id: '4',
                    src: '',
                    title: '',
                    subtitle: '',
                },
            ],
        };
    },
    created () {
        this.title = this.$t(`faceswapGuide.tab${this.tabCategory + 1}SectionTitle`);
        for (let i = 0; i < 4; i++) {
            this.items[i].title = `${i + 1}. ${this.$t(`faceswapGuide.tab${this.tabCategory + 1}item${i + 1}Title`)}`;
            this.items[i].subtitle = this.$t(`faceswapGuide.tab${this.tabCategory + 1}item${i + 1}Subtitle`);
        }
    },
    mounted () {
        this.buttonDetail = this.$utility.assetImage('button_detail');
        this.items[0].src = this.$utility.assetImage(`faceswap_guide_${this.$i18n.locale}_item1`);
        this.items[1].src = this.$utility.assetImage('faceswap_guide_item2');
        this.items[2].src = this.$utility.assetImage(`faceswap_guide_${this.$i18n.locale}_item3`);
        this.items[3].src = this.$utility.assetImage('faceswap_guide_item4');
        let lang = this.$utility.geti18nComponentPath();
        if (lang) {
            this.tutorialUrl = `${window.location.protocol}//${window.location.host}/${lang}/how-video-face-swap-work`;
        }else {
            this.tutorialUrl = `${window.location.protocol}//${window.location.host}/how-video-face-swap-work`;
        }
    },
    methods: {
        jump () {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        },
    },
};
</script>

<style scoped lang="scss"> 
.section-container {
    /*去除a标签下划线*/
    a {
        text-decoration: none;
        color: white;
    }
    /*去除未被访问的a标签的下划线*/
    a:link {
        text-decoration: none;
    }
    /*去除已经被访问过的a标签的下划线*/
    a:visited {
        text-decoration: none;
    }
    /*去除鼠标悬停时的a标签的下划线*/
    a:hover {
        text-decoration: none;
    }
    /*去除正在点击的a标签的下划线（鼠标按下，尚未松开）*/
    a:active {
        text-decoration: none;
    }
    /*去除获得焦点的a标签的下划线（被鼠标点击过）*/
    a:focus {
        text-decoration: none;
    }
}
@media (min-width: 1071px) {
    .section-container {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }
    .section-title {
        font-size: 40px;
        font-weight: 700;
        font-family: 'PingFang SC';
        color: white;
        margin: 0;
        padding: 0;
    }
    .card-container {
        margin-left: auto;
        margin-right: auto;
        margin-top: 38px;
        max-width: 1360px;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        padding: 0% 40px;
    }
    .card-1 {
        margin-left: 10px;
        margin-right: 10px; 
        max-width: 320px;
        flex: 1;
    }
    .card-2 {
        margin-left: 10px;
        margin-right: 10px; 
        max-width: 320px;
        flex: 1;
    }
    .card-3 {
        margin-left: 10px;
        margin-right: 10px;  
        max-width: 320px;
        flex: 1;
    }
    .card-4 {
        margin-left: 10px;
        margin-right: 10px; 
        max-width: 320px;
        flex: 1;
    }
    .section-actionBtn {
        width: 388px;
        height: 63px;
        border-radius: 31.5px;
        margin-top: 40px;
        background: linear-gradient(to right, #D33A31, #FF9441);
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        align-items: center;
        -webkit-justify-content: center;
        justify-content: center;
        border-radius: 31.5px;
        cursor: pointer;
        margin-bottom: 10px;
        margin-left: auto;
        margin-right: auto;
        border: 0;
        color: white;
        font-size: 20px;
        font-weight: 700;
        font-family: 'PingFang SC';
        text-align: center;
    }
    .section-actionBtn:hover {
        background: linear-gradient(to right, #A82D27, #CC7633);
    }
    .section-link {
        color: white;
        font-size: 16px;
        font-weight: 400;
        font-family: 'PingFang SC';
        margin-top: 14px;
        border: 0;
        &:hover {
            color: #E05435;
        }
    }
    .section-actionBtn-arrow {
        width: 19px;
        height: 14px;
        margin-left: 10px;
    }
}
@media (max-width: 1070px) {
    .section-container {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        max-width: 600px;
    }
    .section-title {
        font-size: 18px;
        font-weight: 700;
        font-family: 'PingFang SC';
        color: white;
        margin: 0;
        padding: 0;
    }
    .card-container {
        margin-left: auto;
        margin-right: auto;
        margin-top: 38px;
        max-width: 335px;
        padding: 0% 20px;
    }
    .card-1 {
        margin-top: 20px;
        width: 100%;
    }
    .card-2 {
        margin-top: 20px;
        width: 100%;
    }
    .card-3 {
        margin-top: 20px;
        width: 100%;
    }
    .card-4 {
        margin-top: 20px;
        width: 100%;
    }
    .section-actionBtn {
        width: 241px;
        height: 48px;
        margin-top: 20px;
        background: linear-gradient(to right, #D33A31, #FF9441);
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        align-items: center;
        -webkit-justify-content: center;
        justify-content: center;
        border-radius: 24px;
        cursor: pointer;
        margin-bottom: 10px;
        margin-left: auto;
        margin-right: auto;
        border: 0;
        color: white;
        font-size: 16px;
        font-weight: 700;
        font-family: 'PingFang SC';
        text-align: center;
    }
    .section-actionBtn:hover {
        background: linear-gradient(to right, #A82D27, #CC7633);
    }
    .section-link {
        color: white;
        font-size: 14px;
        font-weight: 400;
        font-family: 'PingFang SC';
        margin-top: 10px;
        border: 0;
        &:hover {
            color: #E05435;
        }
    }
    .section-actionBtn-arrow {
        width: 13px;
        height: 9px;
        margin-left: 10px;
    }
}
</style>