import { defineStore } from 'pinia';
import { uploadFilesVM, replaceFacesVm } from '@/vm/faceswap_vm';
import { API } from '@/script/api';
import { watch } from 'vue';
import { User } from '@/script/user';


// 组件中使用useCount()获取和修改值
export const useFaceSwap = defineStore('faceSwap', {
    state: () => {
        return {
            selectedFiledIndex: -1,
            mappings: [],
            currentOperateMaapingIndex: 0,
            dataLoadStatus: API.REQUEST_STATUS_NONE,
            faceswapEditPrimaryContainerHeight: 0,
            pausePreviewVideoFlag: 0,
        };
    },
    getters: {
        currentSelectedMapping: (state) => {
            return state.mappings[state.currentOperateMaapingIndex] || null;
        },
        currentSelectedFile: (state) => {
            return uploadFilesVM.files[state.selectedFiledIndex] || null;
        },
    },
    actions: {
        setSelectedFiledIndex (index) {
            this.selectedFiledIndex = index;
            this.currentOperateMaapingIndex = 0;
            this.mappings = [];
            if (this.selectedFiledIndex != -1) {
                let file = uploadFilesVM.files[this.selectedFiledIndex];
                for (let face of file.faces) {
                    this.mappings.push({
                        sourceFace: face,
                        targetFace: null,
                    });
                } 
            }
        },
        setCurrentOperateMappingIndex (index) {
            this.currentOperateMaapingIndex = index;
        },
        setTargetFace (face) {
            if (this.selectedFiledIndex === -1) return;
            this.mappings[this.currentOperateMaapingIndex].targetFace = face;
            if (this.mappings.length - 1 > this.currentOperateMaapingIndex) {
                this.currentOperateMaapingIndex += 1;
            }
        },
        clearMappingsTargetFace () {
            for (const mapping of this.mappings) {
                mapping.targetFace = null;
            }
        },
        async init () {
            watch(uploadFilesVM.files, () => {
                if (uploadFilesVM.files.length == 0) {
                    this.selectedFiledIndex = -1;
                }
            });
            watch(() => User.getInstance.isLogin.value, () => {
                uploadFilesVM.files.splice(0, uploadFilesVM.files.length);
                replaceFacesVm.faces.splice(0, replaceFacesVm.faces.length);
                uploadFilesVM.loadStatus.value = API.REQUEST_STATUS_NONE;
                replaceFacesVm.loadStatus.value = API.REQUEST_STATUS_NONE;
                this.selectedFiledIndex = -1;
                this.currentOperateMaapingIndex = 0;
                this.mappings.splice(0, this.mappings.length);
                this.loadData();
            });
            this.loadData().then(() => {
                if (this.dataLoadStatus == API.REQUEST_STATUS_SCUCESS) {
                    if (localStorage.getItem('autoSwapFileSelected') == '1') {
                        this.setSelectedFiledIndex(0);
                        localStorage.removeItem('autoSwapFileSelected');
                    }
                }
            });
        },
        async loadData () {
            this.dataLoadStatus = API.REQUEST_STATUS_LOADING;
            let processList = [];
            if (uploadFilesVM.loadStatus.value != API.REQUEST_STATUS_SCUCESS && uploadFilesVM.loadStatus.value != API.REQUEST_STATUS_LOADING) {
                processList.push(uploadFilesVM.loadFiles());
            }   
            if (replaceFacesVm.loadStatus.value != API.REQUEST_STATUS_SCUCESS && replaceFacesVm.loadStatus.value != API.REQUEST_STATUS_LOADING) {
                processList.push(replaceFacesVm.loadFaces());
            }
            try {
                await Promise.all(processList);
                this.dataLoadStatus = API.REQUEST_STATUS_SCUCESS;
            }catch (e) {
                this.dataLoadStatus = API.REQUEST_STATUS_FAILED;
            }
        },
    },
});

