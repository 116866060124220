<template>
    <div class="normal" :class="[status, { highlightHover: supportHover }]" @click="$emit('onClick')">
        <slot></slot>
    </div>
</template>


<script>
export default {

    name: 'FyButton',
    props: ['status', 'supportHover'],
    emits: ['onClick'],
};
</script>


<style scoped lang="scss"> 
.normal {
    width: auto;
    display: inline-block;
    border-radius: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: white;
    background: rgba(255, 255, 255, 0.10);
    font-size: 20px;
    font-weight: 500;
    font-family: 'PingFang SC';
    text-align: center;
    border: 0;
}

.highlight {
    background: linear-gradient(to right, #D33A31, #FF9441);
}

.unable {
    background: linear-gradient(to right, #541613, #673C1A);
    color: rgba(255, 255, 255, 0.50);
}

.highlightHover:hover {
    background: linear-gradient(to right, #A82D27, #CC7633);
}
</style>