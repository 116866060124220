<template>
  <teleport to="body">
    <div v-if="visible" class="dialog-mask">
      <div class="dialog_box">
        <!--      关闭按钮-->
        <div class="close-btn" @click="close">
          <img :src="$utility.assetImage('close_icon')" alt="" />
        </div>
      </div>
      <!--      icon-->
      <div class="dl_and_emo">
        <div class="down" @click="downLoad">
          <img class="down-img" :src="$utility.assetImage('face_swap_down')" alt="" />
        </div>
        <div v-if="showReport" class="report" @click="reportClick">
          <img class="report-img" :src="$utility.assetImage('txtToImg_report')" alt="" />
        </div>
        <div v-if="showPublish" class="publish" @click="publishClick">
          <div v-if="publishing" class="loading"></div>
          <img v-else class="publish-img" :src="$utility.assetImage('txtToImg_publish')" alt="" />
        </div>
        <div v-if="!props.dataList[thisIndex].hasFeedback" class="first-comment">
          <div class="item" @click="likeClick">
            <div class="animat" ref="likeRef"></div>
          </div>
          <div class="gedang"></div>
          <div class="item" @click="feedBack">
            <div class="animat" ref="dislikeRef"></div>
          </div>
        </div>
      </div>

      <!--      <div class="down_mp" @click="downLoad" v-show="state.isMobile">-->
      <!--        <img class="down-img" :src="$utility.assetImage('face_swap_down')" alt=""/>-->
      <!--      </div>-->
      <!--      内容左中右结构-->
      <div class="dialog-main">
        <!--      左边-->
        <div class="left_btn fangxiang" @click.stop="showLast" v-show="thisIndex != 0">
          <img class="lef_def_img" :src="$utility.assetImage('sw_left_def')" srcset="" />
          <img class="lef_sel_img" :src="$utility.assetImage('sw_left_select')" srcset="" />
        </div>
        <div class="content_box">
          <div class="contenr">
            <swiper :slides-per-view="1" :space-between="30" :css-mode="getisMobile()" :initial-slide="index"
              @swiper="onSwiper" @slideChange="onSlideChange">
              <swiper-slide v-for="(item, itemIndex) in props.dataList" :key="itemIndex">
                <!--                轮播图整体内容-->
                <div class="cont_box">
                  <!--                  左边内容-->
                  <div :class="[
                    'cont_lef_box',
                    {
                      maxwid23:
                        !state.isMobile && item.submitParams.ratio == '23',
                      maxwid11:
                        !state.isMobile && item.submitParams.ratio == '11',
                    },
                  ]">
                    <!--                    左边内容上部的 图片、提示、icon框-->
                    <div class="cont_lef_top">
                      <!--                     左边内容上部的 图片、icon-->
                      <div :class="[
                        'cont_img_icon',
                        {
                          txtToimgMarTop:
                            getisMobile() &&
                            bigImgType == 'txtToImg' &&
                            item.submitParams.styleKey == 'realistic',
                        },
                      ]">
                        <!--              呢容-->
                        <div class="cont">
                          <!--                <img class="img_big" :src="$utility.assetImage('textToImgDefBackimg')" alt="" srcset="">-->
                          <ImageView class="imgview item-child-img" style="width: 100%; height: 100%"
                            v-if="item.fileData.fileType == 'image'" :error-modul="'big'" :img-url="item.imageBlobUrl"
                            :object-fit="'contain'"></ImageView>
                          <!--                    <VOCImageView :requestInfo="imageRequestInfo(item)" v-if="item.fileData.fileType == 'image'"-->
                          <!--                                  style="width: 100%;height: 100%;" >-->
                          <!--                    </VOCImageView>-->
                          <HlsVideoView :videoSrc="item.fileData.fileUrl" v-if="item.fileData.fileType !== 'image'">
                          </HlsVideoView>
                        </div>
                      </div>
                      <!--                     左边内容上部的 提示-->
                      <div :class="[
                        'con_tip',
                        {
                          txtToimgMarTop:
                            getisMobile() &&
                            item.submitParams.styleKey == 'realistic' &&
                            bigImgType == 'txtToImg',
                        },
                      ]" v-if="bigImgType == 'txtToImg'">
                        <div class="tip_txt">
                          {{ item.submitParams.prompt }}
                        </div>
                        <div class="tip_icon" @click="copyToClipboard">
                          <img :src="$utility.assetImage('sw_add')" alt="" srcset="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
        <!--      右边-->
        <div class="right_btn fangxiang" @click.stop="showNext"
          v-show="dataList.length > 0 && thisIndex != dataList.length - 1">
          <img class="rig_def_img" :src="$utility.assetImage('sw_right_def')" srcset="" />
          <img class="rig_sel_img" :src="$utility.assetImage('sw_right_select')" srcset="" />
        </div>
      </div>
    </div>
    <RealisticToFaceSwapAnalyticPopup ref="toFaceSwapRef"></RealisticToFaceSwapAnalyticPopup>
  </teleport>
</template>
<script setup>
import useClipboard from 'vue-clipboard3';
import { onMounted,
  getCurrentInstance,
  reactive,
  ref,
  watchEffect,
  defineProps,
  onBeforeUnmount,
  computed, } from 'vue';
import like from '@/assets/animationJson/like.json';
import dislike from '@/assets/animationJson/dislike.json';
import lottie from 'lottie-web';
import HlsVideoView from './HlsVideoView.vue';
import { useFaceSwapTaskManager } from '@/stores/useFaceSwapTaskManager';

import { useTxtToImgTaskManager } from '@/stores/useTxtToImgTaskManager';

import { API } from '@/script/api';
import { Swiper, SwiperSlide } from 'swiper/vue';
import RealisticToFaceSwapAnalyticPopup from '@/components/txtToimg/RealisticToFaceSwapAnalyticPopup.vue';
import { register } from 'swiper/element/bundle';
import ImageView from '@/components/ImageView.vue';

const {
  $utility,
  $showFeedbackDialog,
  $showTxtToImgFeedbackDialog,
  $showReportFeedbackDialog,
  $showToast,
  $analytic,
  i18n,
} = getCurrentInstance().appContext.config.globalProperties;
const { toClipboard } = useClipboard();

register();

const emit = defineEmits(['update:visible', 'onSlideChange']);
const props = defineProps({
  isShow: {
    type: Boolean,
    required: true,
    default: false,
  },
  wsWork: {
    type: Object,
    default: {},
  },
  visible: {
    type: Boolean,
    default: false,
  },
  dataList: {
    type: Array,
    default: [],
  },
  //  初始化显示的位子
  index: { type: Number },
  showDelete: {
    type: Boolean,
    default: true,
  },
  useTaskManager: {
    type: Object,
    default: {},
  },
  bigImgType: {
    type: String,
    default: '',
  },
});
//  当前索引
const thisIndex = ref(props.index);

const swiperRef = ref(null);

const toFaceSwapRef = ref(null);
const state = reactive({
  toFaceTask: {},
  // 是否手机端
  isMobile: window.mobileAndTabletCheck(),
});

function getisMobile () {
  return window.mobileAndTabletCheck();
}
const onSwiper = (swiper) => {
  swiperRef.value = swiper;
};

/**
 * 轮播图切换
 * @param swiper
 */
const onSlideChange = (swiper) => {
  thisIndex.value = swiper.activeIndex;
  emit('onSlideChange', [thisIndex.value]);
};

/**
 * 下一张
 */
const showNext = () => {
  // getSwiper().slideNext();
  if (swiperRef.value) {
    swiperRef.value.slideNext();
  }
};

/**
 * 上一张
 */
const showLast = () => {
  // getSwiper().slidePrev();
  if (swiperRef.value) {
    swiperRef.value.slidePrev();
  }
};
/**
 * 关闭轮播图弹框
 */
const close = () => {
  emit('update:visible', false);
};

// 下载
const downLoad = () => {
  if (props.bigImgType == 'txtToImg') {
    $analytic.log('creation_image_downing_tick', {
      imageurl: props.dataList[thisIndex.value].fileData.fileUrl,
      face: props.dataList[thisIndex.value].submitParams.faceId ? '1' : '0',
    });
    useTxtToImgTaskManager().downloadToLocal(props.dataList[thisIndex.value]);
  } else {
    useFaceSwapTaskManager().downloadToLocal(props.dataList[thisIndex.value]);
  }
};
/**
 * 更新点击动图状态； 用于下一次查看大图时，不显示动图
 */
const setHasFeedback = () => {
  if (props.bigImgType === 'txtToImg') {
    useTxtToImgTaskManager().setItemHasFeedback(props.dataList[thisIndex.value].taskId);
  } else {
    useFaceSwapTaskManager().setItemHasFeedback(props.dataList[thisIndex.value].taskId);
  }
};

/**
 * 点赞
 */
const likeClick = () => {
  $showToast(i18n.global.t('choose.feedback'));
  if (props.bigImgType === 'txtToImg') {
    $analytic.log('creation_image_like_tick', {
      style: props.dataList[thisIndex.value].submitParams.styleKey,
      taskKey: props.dataList[thisIndex.value].taskId,
      face: props.dataList[thisIndex.value].submitParams.faceId ? '1' : '0',
    });
  } else {
    $analytic.log('faceswap_created_like_tick', {
      category:
        props.dataList[thisIndex.value].fileData.fileType === 'image'
          ? 'photo'
          : 'video',
      taskKey: props.dataList[thisIndex.value].taskId,
    });
  }
  setHasFeedback();
};
/**
 * 点踩
 */
const feedBack = () => {
  if (props.bigImgType == 'txtToImg') {
    $analytic.log('creation_image_unlike_tick', {
      style: props.dataList[thisIndex.value].submitParams.styleKey,
      taskKey: props.dataList[thisIndex.value].taskId,
      face: props.dataList[thisIndex.value].submitParams.faceId ? '1' : '0',
    });
  } else {
    $analytic.log('faceswap_created_unlike_tick', {
      category:
        props.dataList[thisIndex.value].fileData.fileType === 'image'
          ? 'photo'
          : 'video',
      taskKey: props.dataList[thisIndex.value].taskId,
    });
  }

  if (props.bigImgType === 'txtToImg') {
    $showTxtToImgFeedbackDialog().then((result) => {
      const { confirm, selectedArray, moreMsg } = result;
      if (confirm) {
        setHasFeedback();
        $showToast(i18n.global.t('choose.feedback'));
        API.txtToImgFeedBack({
          taskId: props.dataList[thisIndex.value].taskId,
          category: selectedArray.join(','),
          content: moreMsg,
        }).then(() => {
          $analytic.log('creation_image_unlike_success', {
            style: props.dataList[thisIndex.value].submitParams.styleKey,
            taskKey: props.dataList[thisIndex.value].taskId,
          });
        });
      }
    });
  } else {
    $showFeedbackDialog().then((result) => {
      const { confirm, selectedArray, moreMsg } = result;
      if (confirm) {
        setHasFeedback();
        $showToast(i18n.global.t('choose.feedback'));
        API.swapFeedBack({
          taskId: props.dataList[thisIndex.value].taskId,
          category: selectedArray.join(','),
          content: moreMsg,
        }).then(() => {
          $analytic.log('faceswap_created_unlike_success', {
            category:
              props.dataList[thisIndex.value].fileData.fileType == 'image'
                ? 'photo'
                : 'video',
            taskKey: props.dataList[thisIndex.value].taskId,
          });
        });
      }
    });
  }
};

/// 是否显示report按钮
const showReport = computed(() => {
  return props.bigImgType === 'txtToImg' && !props.dataList[thisIndex.value].hasReport;
});
/**
 * 举报按钮点击
 */
const reportClick = () => {
  setHasReport();
  $showReportFeedbackDialog().then((result) => {
    const { confirm, selectedArray, moreMsg } = result;
    if (confirm) {
      $showToast(i18n.global.t('choose.feedback'));
      API.txtToImgFeedBack({
        taskId: props.dataList[thisIndex.value].taskId,
        category: selectedArray.join(','),
        content: moreMsg,
      }).then(() => {
      });
    }
  });
};
/**
 * 更新点击举报按钮状态； 用于下一次查看大图时，不显示举报按钮
 */
const setHasReport = () => {
  if (props.bigImgType === 'txtToImg') {
    useTxtToImgTaskManager().setItemHasReport(props.dataList[thisIndex.value].taskId);
  } else {
    useFaceSwapTaskManager().setItemHasReport(props.dataList[thisIndex.value].taskId);
  }
};


const showPublish = computed(() => {
  return props.bigImgType === 'txtToImg' && (!props.dataList[thisIndex.value].hasPublish || publishing.value);
});
const publishing = ref(false);
/**
 * 发布按钮点击
 */
const publishClick = () => {
  setHasPublish();
  publishing.value = true;
  API.txtToImgFeedBack({
    taskId: props.dataList[thisIndex.value].taskId,
    category: '',
    content: '',
  }).then(() => {
    $showToast(i18n.global.t('publishToast'));
    publishing.value = false;
  }).catch(() => {
    $showToast(i18n.global.t('publishToast'));
    publishing.value = false;
  });
};
/**
 * 更新点击举报按钮状态； 用于下一次查看大图时，不显示举报按钮
 * @param status: 0 已点击发布, 1 发布中, 2 发布完成
 */
const setHasPublish = () => {
  if (props.bigImgType === 'txtToImg') {
    useTxtToImgTaskManager().setItemHasPublish(props.dataList[thisIndex.value].taskId);
  } else {
    useFaceSwapTaskManager().setItemHasPublish(props.dataList[thisIndex.value].taskId);
  }
};

/**
 * 复制描述文本
 * @returns {Promise<void>}
 */
const copyToClipboard = async () => {
  try {
    const textToCopy = ref(props.dataList[thisIndex.value].submitParams.prompt);
    // await navigator.clipboard.writeText(textToCopy.value);
    await toClipboard(textToCopy.value);
    $showToast(i18n.global.t('choose.copy'));
    // $analytic.log('faceswap_created_like_tick', {
    //   category: props.dataList[thisIndex.value].fileData.fileType == 'image' ? 'photo' : 'video',
    //   taskKey: props.dataList[thisIndex.value].taskId,
    // });
  } catch (err) {
    console.error('复制到剪贴板失败', err);
  }
};
// 动图
const likeRef = ref(null);
const dislikeRef = ref(null);
let animatlike = null;
let animatdislike = null;
watchEffect(() => {
  animatlike = lottie.loadAnimation({
    container: likeRef.value,
    renderer: 'svg',
    loop: true,
    autoplay: true,
    animationData: like,
  });
  animatdislike = lottie.loadAnimation({
    container: dislikeRef.value,
    renderer: 'svg',
    loop: true,
    autoplay: true,
    animationData: dislike,
  });
});
onMounted(() => {
  document.body.style.overflow = 'hidden';
});
/** 释放动画 */
onBeforeUnmount(() => {
  animatlike.destroy();
  animatdislike.destroy();
  document.body.style.overflow = 'auto';
});
</script>
<style scoped lang="scss">
.dialog-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 500;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(20px);

  .down_mp {
    display: none;
  }

  .dialog_box {
    width: 40px;
    position: absolute;
    height: 40px;
    top: 40px;
    right: 33px;
    z-index: 644;
    cursor: pointer;

    .close-btn {
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .dl_and_emo {
    position: absolute;
    top: 100px;
    right: 28px;
    width: 50px;
    height: auto;
    z-index: 620;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .down {
      width: 40px;
      height: 40px;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .down-img {
        width: 20px;
        height: 20px;
      }
    }

    .report {
      width: 40px;
      height: 40px;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-top: 10px;

      .report-img {
        width: 20px;
        height: 20px;
        object-fit: contain;
      }
    }

    .publish {
      position: relative;
      width: 40px;
      height: 40px;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-top: 10px;

      .publish-img {
        position: absolute;
        top: 10px;
        left: 10px;
        width: 20px;
        height: 20px;
      }

      .loading {
        position: absolute;
        top: 20px;
        left: 20px;
        --d: 8px;
        width: 1.8px;
        height: 1.8px;
        border-radius: 50%;
        color: white;
        box-shadow:
          calc(1*var(--d)) calc(0*var(--d)) 0 0,
          calc(0.707*var(--d)) calc(0.707*var(--d)) 0 0.2px,
          calc(0*var(--d)) calc(1*var(--d)) 0 0.4px,
          calc(-0.707*var(--d)) calc(0.707*var(--d)) 0 0.8px,
          calc(-1*var(--d)) calc(0*var(--d)) 0 1.0px,
          calc(-1*var(--d)) calc(0*var(--d)) 0 1.2px;
        animation: l27 1s infinite steps(6);
      }

      @keyframes l27 {
        100% {
          transform: rotate(1turn)
        }
      }
    }

    .first-comment {
      margin-top: 10px;
      width: 40px;
      height: auto;
      background: #ffffff;
      border-radius: 10px;
      /* opacity: 0.1; */
      background: rgba(255, 255, 255, 0.1);

      .item {
        //padding: 8px;
        cursor: pointer;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        .animat {
          width: 30px;
          height: 30px;
        }
      }

      .gedang {
        margin: 0 auto;
        width: 26px;
        height: 1px;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 1px;
      }
    }
  }

  .dialog-main {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 65px;
    max-width: 1200px;
    box-sizing: border-box;
    //margin: 0 40px;
  }

  .fangxiang {
    width: 31px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;

    img {
      width: 31px;
      height: 56px;
    }
  }

  .left_btn {
    .lef_def_img {
      display: block;
    }

    .lef_sel_img {
      display: none;
    }
  }

  .left_btn:hover {
    .lef_def_img {
      display: none;
    }

    .lef_sel_img {
      display: block;
    }
  }

  .right_btn {
    .rig_def_img {
      display: block;
    }

    .rig_sel_img {
      display: none;
    }
  }

  .right_btn:hover {
    .rig_def_img {
      display: none;
    }

    .rig_sel_img {
      display: block;
    }
  }

  .content_box {
    width: 100%;
    height: 100%;
    padding: 0 40px;
    max-width: 1200px;
    box-sizing: border-box;

    .contenr {
      width: 100%;
      height: 100%;
    }
  }

  .cont_box {
    width: 100%;
    height: 100%;
    max-width: 1334px;
    display: flex;
    /* padding: 0 40px; */
    box-sizing: border-box;
    justify-content: center;
  }

  .cont_lef_box {
    flex-grow: 1;
    /* width: 100%; */
    height: 100%;
    /* max-height: 750px; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    overflow-y: scroll;

    .cont_lef_top {
      flex-grow: 1;
      width: 100%;
      /* height: 97%; */
      /* max-height: 750px; */
      /* padding: 0 50px; */
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
    }

    .cont_img_icon {
      width: 100%;
      height: auto;
      max-height: 60vh;
      min-height: 330px;
      position: relative;
      box-sizing: border-box;
      height: auto;
      max-height: 60vh;

      .cont {
        width: 100%;
        height: 100%;
        /* max-height: 683px; */
        max-width: 1050px;

        .imgview {
          width: 100%;
          height: 100%;
        }

        .--img {
          object-fit: contain !important;
        }

        img {
          object-fit: contain !important;
        }
      }
    }

    .con_tip {
      width: 100%;
      display: flex;
      box-sizing: border-box;
      margin-top: 23px;
      padding-left: 13px;
      max-width: 504px;

      .tip_txt {
        flex-grow: 1;
        height: auto;
        font-weight: 400;
        font-size: 14px;
        color: #f3f9ff;
        line-height: 16px;
        text-align: left;
        font-style: normal;
        margin-right: 20px;
      }

      .tip_icon {
        width: 48px;
        height: 48px;
        cursor: pointer;

        img {
          width: 38px;
          height: 38px;
          margin: auto;
        }
      }
    }

    .btn_box {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .btn_img {
        width: 30px;
        height: 30px;
        animation: spin 1s linear infinite;
      }

      .btn {
        width: 100%;
        max-width: 480px;
        height: 47px;
        background: #f96130;
        border-radius: 8px;
        font-weight: bold;
        font-size: 18px;
        color: #ffffff;
        line-height: 21px;
        text-align: center;
        font-style: normal;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .maxwid23 {
    max-width: 404px;
  }

  .maxwid11 {
    max-width: 600px;
  }

  .cont_right_box {
    width: 106px;
    height: 100%;
  }
}

.swiper {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 500px) {
  .dialog-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 500;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(20px);

    .dl_and_emo {
      position: absolute;
      top: 20px;
      right: 15px;
      width: auto;
      height: 30px;
      z-index: 620;
      display: flex;
      flex-direction: row-reverse;
      /* justify-content: center; */
      /* align-items: center; */
      padding: 0;

      .down {
        width: 30px;
        height: 30px;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 7px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-left: 10px;

        .down-img {
          width: 15px;
          height: 15px;
          margin-bottom: 2px;
        }
      }

      .report {
        width: 30px;
        height: 30px;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 7px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-left: 10px;
        margin-top: 0px;

        .report-img {
          width: 15px;
          height: 15px;
          object-fit: contain;
        }
      }

      .publish {
        width: 30px;
        height: 30px;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 7px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-left: 10px;
        margin-top: 0px;

        .publish-img {
          position: absolute;
          top: 7.5px;
          left: 7px;
          width: 15px;
          height: 15px;
        }

        .loading {
          position: absolute;
          top: 15px;
          left: 15px;
          --d: 6px;
          width: 1.5px;
          height: 1.5px;
          border-radius: 50%;
          color: white;
          box-shadow:
            calc(1*var(--d)) calc(0*var(--d)) 0 0,
            calc(0.707*var(--d)) calc(0.707*var(--d)) 0 0.2px,
            calc(0*var(--d)) calc(1*var(--d)) 0 0.4px,
            calc(-0.707*var(--d)) calc(0.707*var(--d)) 0 0.8px,
            calc(-1*var(--d)) calc(0*var(--d)) 0 1.0px,
            calc(-1*var(--d)) calc(0*var(--d)) 0 1.2px;
          animation: l27 1s infinite steps(6);
        }

        @keyframes l27 {
          100% {
            transform: rotate(1turn)
          }
        }
      }


      .first-comment {
        width: 68px;
        height: 30px;
        background: #ffffff;
        border-radius: 6px;

        background: rgba(255, 255, 255, 0.1);
        display: flex;
        align-items: center;
        margin: 0;

        .item {
          padding: 8px;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;

          .animat {
            width: 18px;
            height: 18px;
          }
        }

        .gedang {
          margin: 0 auto;
          width: 1px;
          min-width: 1px;
          height: 13px;
          background: rgba(255, 255, 255, 0.5);
          border-radius: 1px;
        }
      }
    }

    .down_mp {
      display: block;
      position: absolute;
      top: 20px;
      right: 15px;
      width: 15px;
      height: 15px;

      .down-img {
        width: 100%;
        height: 100%;
      }
    }

    .dialog_box {
      width: 35px;
      position: absolute;
      height: 35px;
      top: 20px;
      right: 0;
      z-index: 644;
      cursor: pointer;
      left: 15px;
      border-radius: 8px;
      margin: 0;
      padding: 0;

      .close-btn {
        width: 30px;
        height: 30px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .dialog-main {
      width: 100%;
      height: 100%;
      //max-height: 748px;
      display: flex;
      justify-content: center;
      align-items: center;
      /* padding: 50px 40px; */
      /* max-width: 1200px; */
      box-sizing: border-box;
      margin: 0;
      padding: 0;
    }

    .fangxiang {
      display: none;

      img {
        width: 31px;
        height: 56px;
      }
    }

    .content_box {
      width: 100%;
      height: 100%;
      /* padding: 0 40px; */
      /* max-width: 1200px; */
      box-sizing: border-box;
      margin: 0;
      padding: 0;

      .contenr {
        width: 100%;
        height: 100%;
      }
    }

    .cont_box {
      width: 100%;
      height: 100%;
      /* max-width: 1334px; */
      display: flex;
      /* padding: 0 40px; */
      box-sizing: border-box;
      margin: 0;
      padding: 0;
    }

    .cont_lef_box {
      flex-grow: 1;
      /* width: 100%; */
      height: 100%;
      /* max-height: 750px; */
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      box-sizing: border-box;
      margin: 0;
      padding: 20px 0;

      .cont_lef_top {
        flex-grow: 1;
        width: 100%;
        /* height: 97%; */
        /* max-height: 750px; */
        /* padding: 0 50px; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
      }

      .cont_img_icon {
        width: 100%;
        /* height: 71%; */
        max-height: 608px;
        /* position: relative; */
        box-sizing: border-box;

        .cont {
          width: 100%;
          height: 100%;
          max-height: 440px;
          max-width: 1050px;

          .imgview {
            width: 100%;
            height: 100%;

            img {
              object-fit: cover !important;
            }
          }
        }

        .dl_and_emo {
          display: none;
        }

        .con_tip {
          width: 80%;
          display: flex;
          box-sizing: border-box;
          margin-top: auto;

          .tip_txt {
            flex-grow: 1;
            height: auto;
            font-weight: 400;
            font-size: 12px;
            color: #ffffff;
            line-height: 16px;
            text-align: left;
            font-style: normal;
            margin-right: 20px;
          }
        }

        .tip_icon {
          width: 48px;
          height: 48px;
          cursor: pointer;

          img {
            width: 38px;
            height: 38px;
            margin: auto;
          }
        }

        .btn_box {
          width: 80%;
          height: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          .btn_img {
            width: 30px;
            height: 30px;
            animation: spin 1s linear infinite;
          }

          .btn {
            width: 100%;
            max-width: 480px;
            height: 47px;
            background: #f96130;
            border-radius: 8px;
            font-weight: 500;
            font-size: 14px;
            color: #ffffff;
            line-height: 16px;
            text-align: right;
            font-style: normal;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }

      .con_tip {
        width: 100%;
        display: flex;
        box-sizing: border-box;
        //margin-top: auto;
        padding-left: 13px;

        .tip_txt {
          flex-grow: 1;
          height: auto;
          font-weight: 400;
          font-size: 14px;
          color: #f3f9ff;
          line-height: 16px;
          text-align: left;
          font-style: normal;
          margin-right: 20px;
        }

        .tip_icon {
          width: 48px;
          height: 48px;
          cursor: pointer;

          img {
            width: 38px;
            height: 38px;
            margin: auto;
          }
        }
      }

      .txtToimgMarTop {
        margin-top: auto;
      }

      .btn_box {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-top: 20px;

        .btn_img {
          width: 30px;
          height: 30px;
          animation: spin 1s linear infinite;
        }

        .btn {
          width: 80%;
          max-width: 407px;
          height: 47px;
          background: #f96130;
          border-radius: 8px;
          font-weight: bold;
          font-size: 18px;
          color: #ffffff;
          line-height: 21px;
          text-align: center;
          font-style: normal;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .cont_right_box {
      display: none;
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
