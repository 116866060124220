<template>
    <div class="review-card">
        <div class="review-content">
            <h3 class="review-title">{{ title }}</h3>
            <p class="review-content-text">{{ content }}</p>
            <div class="review-usr-container">
                <img class="review-avatar" :src="avatar" alt="">
                <div>
                    <p class="review-name">{{ userName }}</p>
                    <p class="review-date">{{ dateText }}</p>
                </div>
                <img class="review-rate-bar" :src="reviewRateBarImgSrc"/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return { reviewRateBarImgSrc: '' };
    },
    mounted () {
        this.reviewRateBarImgSrc = this.$utility.assetImage('review_rata_bar');
    },
    props: {
        title: String,
        content: String,
        avatar: String,
        userName: String,
        dateText: String,
    },
};
</script>

<style scoped lang="scss"> 
@media (min-width: 1071px) {
    .review-card {
        background: #17181C;
        width: 100%;
        border-radius: 4px;
    }
    .review-content {
        padding: 20px;
        text-align: start;
    }
    .review-title {
        font-size: 18px;
        
        font-family: 'PingFang SC';
        color: white;
        padding: 0;
        margin: 0;
    }
    .review-content-text {
        font-size: 12px;
        font-weight: 400;
        font-family: 'PingFang SC';
        color: #B0B6BD;
        padding-top: 14px;
        margin: 0;
    }
    .review-usr-container {
        padding-top: 14px;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        align-items: center;
    }
    .review-avatar {
        width: 47px;
        height: 47px;
        margin-right: 8px;
        margin-right: 8px;
    }
    .review-name {
        color: white;
        font-size: 14px;
        font-weight: 500; 
        font-family: 'PingFang SC';
        margin: 0;
    }
    .review-date {
        color: #B0B6BD;
        font-size: 12px;
        font-weight: 400;
        font-family: 'PingFang SC';
        margin: 0;
        padding-top: 6px;
    }
    .review-rate-bar {
        margin-left: auto;
        width: 142px;
    }
}
@media (max-width: 1070px) {
    .review-card {
        background-color: #17181C;
        width: 100%;
        border-radius: 4px;
        margin: 0;
    }
    .review-content {
        padding: 20px;
        text-align: start;
    }
    .review-title {
        font-size: 14px;
        font-weight: 500; 
        font-family: 'PingFang SC';
        color: white;
        padding: 0;
        margin: 0;
    }
    .review-content-text {
        font-size: 12px;
        font-weight: 400;
        font-family: 'PingFang SC';
        color: #B0B6BD;
        padding-top: 14px;
        margin: 0;
    }
    .review-usr-container {
        padding-top: 10px;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        align-items: center;
    }
    .review-avatar {
        width: 30px;
        height: 30px;
        margin-right: 6px;
    }
    .review-name {
        color: white;
        font-size: 12px;
        font-weight: 500; 
        font-family: 'PingFang SC';
        margin: 0;
    }
    .review-date {
        color: #B0B6BD;
        font-size: 10px;
        font-weight: 400;
        font-family: 'PingFang SC';
        margin: 0;
        padding-top: 4px;
    }
    .review-rate-bar {
        margin-left: auto;
        width: 130px;
    }
}
</style>