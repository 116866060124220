import basic from '../basic/en';
export default {
    ...basic,
    ...{
        meta: {
            title: 'Free AI Image Generator, Online Text to AI Image',
            description: 'Produce AI-generated images and art with a text prompt using Beautyai.fun AI photo generator apps: Real time text to image AI tool',
            keywords: 'Face Swap,AI Image Generator,Video Face Swap,',
        },
        recent: 'Recent',
        txtToImg: {
            history: 'History',
            clear: 'Clear',
            describeCreation: 'Describe Creation',
            promptPlace: 'Enter text prompt, create your creation!',
            looksLike: 'Looks Like',
            batchSize: 'Batch Size',
            hintTags: 'Hint Tags',
            unselect: 'Unselect',
            create: 'Create',
            aspectRatio: 'Aspect Ratio',
            optional: '(Optional)',
            pro: 'PRO',
        },
        use1Credit: 'Use 1 credit.',
        useXCredits: 'Use <xx1> credits.',
        guide:{
            title: 'How to make a better creation',
            headTip: 'Please upload photo with better quality, poor quality photo will lead to bad creation.',
            goodTitle: 'Good examples',
            badTitle:'Bad examples',
        },
        examplePopup: {
            title: 'How to make a better creation',
            photoTip: 'Please upload photo with better quality, poor quality photo will lead to bad creation.',
            goodExample: 'Good examples',
            badExample: 'Bad examples',
            btnTip: 'Got It',
        },
        AiGirlShowcase: {
            title: 'AI Generated Images',
            hovertitle: 'Try this prompt',
            buttontext: 'Btn btn  btn',
        },
        textToImgToHistory: {
            tab1or2header: 'We have kept the History of creation photos for you,',
            footer: 'go to the history to check',
        },
        txtToImgIndex: {
            introduce: {
                item1Title: 'AI Image Generator inspired by your idea',
                item1Subtitle: 'Simply share your idea, and watch as advanced AI technology crafts a personalized AI character just for you.',
                item1ActionText: 'Start creating images',
                item2Title: 'Customize Your Anime AI Image',
                item2Subtitle: 'Beauty AI is an AI Art Image Generator that allows you create animation creations in all styles. Decide to your inspiration, describe it with prompt and turn it into anime images. Remember your creations belong to you as you make you soulmate with the unique anime character!',
                item3Title: 'Generate Real Hot Soulmate with Inspiration',
                item3Subtitle: 'As an easy-to-use AI Image Generator, we aim to fullfill your dream with great effort. Describe your dream image with simply words and we will generate your art in seconds!',
            },
            howToUse: {
                sectionTitle: 'How to create AI images in Beauty AI',
                item1Title: 'Write a text prompt',
                item1Subtitle: 'Type a description of what you want to see in the prompt field.',
                item2Title: 'Create your image',
                item2Subtitle: 'When you are happy with the prompts, click Generate. The results will appear in a few seconds. If you like the image generated by Beauty AI, click the Download button in the upper right corner to download this image.',
                item3Title: 'Refine, revise, and recreate',
                item3Subtitle: 'Play around with the settings to explore different variations. In the left panel, you can adjust everything from aspect ratio to content type. If you like, you can add more detail to the text prompt to generate a completely new image. Just make sure to save any images you like before creating a new one.',
                actionText: 'Start creating images',
            },
            review: {
                sectionTitle: 'They Love Us, You Will Too',
                card1Title: 'After using the Beauty AI',
                card1Subtitle: 'After using the Beauty AI Image Generator function, I deeply felt its powerful function. Not only is it easy to operate, but the generated images are of extremely high quality, which greatly improved my work efficiency and I am very satisfied!',
                card1UserName: 'Michael Johnson',
                card1Date: 'June 6, 2023',
                card2Title: 'AI Image Generator is really a magical tool!',
                card2Subtitle: 'AI Image Generator is really a magical tool! I am amazed by its intelligent algorithm. It can generate accurate AI pictures according to my needs every time. It is an indispensable good helper in my work.',
                card2UserName: 'Sarah Thompson',
                card2Date: 'May 6, 2024',
                card3Title: 'Since I started using AI Image Generator',
                card3Subtitle: 'Since I started using AI Image Generator, my design skills have improved significantly. Its powerful image generation capabilities allow my creativity to be perfectly presented. It’s really easy to use!',
                card3UserName: 'Emily Davis',
                card3Date: 'December 4, 2023',
                card4Title: 'The interface design of AI Image Generator',
                card4Subtitle: 'The interface design of AI Image Generator is very user-friendly and very convenient to use. Moreover, the pics it generates are of high quality and fast, allowing me to get twice the result with half the effort at work. I highly recommend it!',
                card4UserName: 'Mario Kuper',
                card4Date: 'January 1, 2024',
                card5Title: 'This amazing tool intelligently detects faces',
                card5Subtitle: 'This amazing tool intelligently detects faces in photos and seamlessly replaces them with any face you choose. It\'s like having your own personal creative studio at your fingertips! With Beauty AI, swapping faces in any photo is effortless, allowing me to unleash my imagination without any hassle.',
                card5UserName: 'William Smith',
                card5Date: 'February 12, 2023',
                card6Title: 'A big thank you to AI Image Generator',
                card6Subtitle: 'A big thank you to AI Image Generator, the software they developed has saved me a lot of time at work. The generated pictures are not only beautiful, but also fully meet my needs. Five-star praise!',
                card6UserName: 'Christopher Anderson',
                card6Date: 'July 19, 2023',
            },
            faq: {
                sectionTitle: 'Frequently Asked Questions',
                item1Q: 'What is AI Generator?',
                item1A: 'AI Image Generator is a type of generative AI technology that lets you create images with nothing more than a few words. The AI engine receives each word in a text description as an instruction, and it builds the image based on the combination of words and their relationships to one another.',
                item2Q: 'How does AI Image Generator work?',
                item2A: 'AI Image Generator work like other types of artificial intelligence, using machine learning models and huge datasets to produce their results. In other words, the AI technology learns from thousands and thousands of pictures. It creates brand-new images by applying what it has learned to the everyday language you enter in the prompt field.',
                item3Q: 'What makes an amazing AI Image Generator?',
                item3A: 'When choosing your AI image generator, look for one that produces high-quality images, allows you creative control to make those images fit your needs, and makes it possible to use those images commercially. Beauty AI is a great choice because it does all these things.',
                item4Q: 'Are  Beauty AI images available for commercial use?',
                item4A: 'Yes, you can use images generated by the Beauty AI feature for commercial purposes.',
                item5Q: 'Can I create images online for free?',
                item5A: 'Of course you can! You can create images for free with Beauty AI. We offer free and premium plans to suit your needs. As a new user, you will receive three credits upon registration, which will give you full access to our create image feature as well as other features like Face Swap and Magic Brush.',
            },
        },
        realisticToFaceSwapPopup: {
            status1Text: 'Queuing...About <xx1>s',
            status2Text: 'Face Analyzing...About <xx1>s',
        },
        skeywordsHint: 'Inappropriate keywords detected against our policy: <xx1>',
        introducehomeindex: {
            item1title: 'Customize Your AI Art Images',
            item1subtitle: 'Beauty AI is an AI Art Image Generator that allows you create AI creations in all styles. Decide to your inspiration, describe it with prompt and turn it into AI images. Remember your creations belong to you as you make your art creation.',
            item1buttontext: 'Free trial now',
            item2buttontext: 'Free trial now',
        },
        showcasehomeindex: {
            title: 'AI Generated Images',
            buttontext: 'Free trial now',
            hovertitle: 'Try this prompt',
        },
        publishToast: 'The image will be reviewed by our team, if approved, the image will display in gallery.',
    },
};
