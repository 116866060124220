<template>
    <div class="faceswap_edit_uploadFile_input_view">
        <div class="body" :highlight="highlight" ref="bodyDOMTag" @click="chooseFile">
            <img class="icon" :src="iconSrc">
            <p class="hint">{{ hint }}</p>
            <p class="desc">{{ desc }}</p>
        </div>
        <div class="input_button" :processing="processing" @click="chooseFile">
            <span class="title">{{ $t('faceswapUploadArea.chooseFiles') }}</span>
                <img class="arrow" :src="$utility.assetImage('button_detail')" alt="">
            <input 
                type="file" 
                style="display: none;" 
                ref="inputFileDomTag">
            </input>
        </div>
        <div class="processing_button" :processing="processing">
            <span>{{ processingButtonTitle }}</span>
            <span class="loading" ref="loadingDOMTag" :uploading="uploading"></span>
        </div>
        <p class="processing_left" :processing="processing">{{ processingText }}</p>
        <div class="txt_area">
            <p class="title" :processing="processing">{{ titleSteps[0] }}</p>
            <p class="row">{{ titleSteps[1] }}</p>
            <p class="row">{{ titleSteps[2] }}</p>
            <p class="row">{{ titleSteps[3] }}</p>
        </div>
    </div>
</template>

<script setup>
import { uploadFilesVM, UploadFileDataStatus } from '@/vm/faceswap_vm';
import { defineProps, computed, getCurrentInstance, ref, onMounted, onBeforeUnmount, watch, inject } from 'vue';
import lottieLoadingAnimate from '@/assets/animationJson/white_circle_loading';
import lottie from 'lottie-web';
import { useFaceSwap } from '@/stores/useFaceSwap';
import { InfoDialogType } from '@/components/common/dialog/infoDialog';
import { User } from '@/script/user';

import { signUpDialog } from '@/components/Dialog';

const props = defineProps({
    inputFileType: {
        type: String,
        default: 'image',
        validator: (value) => {
            return ['image', 'video'].includes(value);
        },
    },
});
const faceSwap = useFaceSwap();
const tabCategory = inject('tabCategory');
const toSignUpHandle = inject('toSignUpHandle');
const bodyDOMTag = ref(null);
const inputFileDomTag = ref(null);
const loadingDOMTag = ref(null);
const { $utility, $showToast, $showErrorToast, $showInfoDialog, $analytic, $jumpOtherPage, i18n } = getCurrentInstance().appContext.config.globalProperties;
const iconSrc = computed(() => {
    if (processing.value) {
        return $utility.assetImage((props.inputFileType == 'image') ? 'input_type_photo_disable_icon' : 'input_type_video_disable_icon');
    }
        return $utility.assetImage((props.inputFileType == 'image') ? 'input_type_photo_icon' : 'input_type_video_icon');
});
const hint = computed(() => {
    return props.inputFileType == 'image' ? i18n.global.t('faceswapUploadArea.photoLimit') : i18n.global.t('faceswapUploadArea.videoLimit');
});
const desc = computed(() => {
    return `${i18n.global.t(`faceswapUploadArea.tab${tabCategory + 1}Desc1`)} ${i18n.global.t(`faceswapUploadArea.tab${tabCategory + 1}Desc2`)}`;
});
const highlight = ref(false);
const processing = computed(() => {
    return uploadFilesVM.uploadFileStatus.status.value != UploadFileDataStatus.UPLOADFILE_STATUS_NONE &&
           uploadFilesVM.uploadFileStatus.status.value != UploadFileDataStatus.UPLOADFILE_STATUS_DONE && 
           uploadFilesVM.uploadFileStatus.status.value != UploadFileDataStatus.UPLOADFILE_STATUS_FAILED;
});
const uploading = computed(() => uploadFilesVM.uploadFileStatus.status.value == UploadFileDataStatus.UPLOADFILE_STATUS_UPLOAD);

const titleSteps = computed(() => {
    switch (tabCategory) {
        case 0:
            return [
                i18n.global.t('faceswapUploadArea.tab1title'),
                i18n.global.t('faceswapUploadArea.tab1step1'),
                i18n.global.t('faceswapUploadArea.tab1step2'),
                i18n.global.t('faceswapUploadArea.tab1step3'),
            ];
        case 1: 
            return [
                i18n.global.t('faceswapUploadArea.tab2title'),
                i18n.global.t('faceswapUploadArea.tab2step1'),
                i18n.global.t('faceswapUploadArea.tab2step2'),
                i18n.global.t('faceswapUploadArea.tab2step3'),
            ];
        case 2: 
            return [
                i18n.global.t('faceswapUploadArea.tab3title'),
                i18n.global.t('faceswapUploadArea.tab3step1'),
                i18n.global.t('faceswapUploadArea.tab3step2'),
                i18n.global.t('faceswapUploadArea.tab3step3'),
            ];
        default:
            return [
                i18n.global.t('faceswapUploadArea.tab4title'),
                i18n.global.t('faceswapUploadArea.tab4step1'),
                i18n.global.t('faceswapUploadArea.tab4step2'),
                i18n.global.t('faceswapUploadArea.tab4step3'),
            ];
    }
});
const processingButtonTitle = ref('');
const processingText = ref('');
watch(uploadFilesVM.uploadFileStatus.status, () => {
    processingButtonTitle.value = '';
    processingText.value = '';
    switch (uploadFilesVM.uploadFileStatus.status.value) {
        case UploadFileDataStatus.UPLOADFILE_STATUS_UPLOAD: {
            processingButtonTitle.value = i18n.global.t('faceswapUploadArea.uploading').replace('<xx1>', `${(uploadFilesVM.uploadFileStatus.progress.value * 100).toFixed(0)}%`);
            processingText.value = '';
        }break;
        case UploadFileDataStatus.UPLOADFILE_STATUS_QUEUE: {
            processingButtonTitle.value = i18n.global.t('faceswapUploadArea.faceAnalyzing');
            processingText.value = i18n.global.t('faceswapUploadArea.queuingAbout').replace('<xx1>', `${uploadFilesVM.uploadFileStatus.currentLeftTime.value}`);
        }break;
        case UploadFileDataStatus.UPLOADFILE_STATUS_ANALYSE: {
            processingButtonTitle.value = i18n.global.t('faceswapUploadArea.faceAnalyzing');
            processingText.value =  i18n.global.t('faceswapUploadArea.analyzingAbout').replace('<xx1>', `${uploadFilesVM.uploadFileStatus.currentLeftTime.value}`);
        }break;
        case UploadFileDataStatus.UPLOADFILE_STATUS_FAILED: {
            $showErrorToast(uploadFilesVM.uploadFileStatus.failedMessage || i18n.global.t('faceswapUploadArea.somethingError'));
        }break;
        case UploadFileDataStatus.UPLOADFILE_STATUS_DONE: {
            faceSwap.setSelectedFiledIndex(0);
        }break;
        default:break;
    }
});
watch(uploadFilesVM.uploadFileStatus.progress, () => {
    processingButtonTitle.value = i18n.global.t('faceswapUploadArea.uploading').replace('<xx1>', `${(uploadFilesVM.uploadFileStatus.progress.value * 100).toFixed(0)}%`);
});
watch(uploadFilesVM.uploadFileStatus.currentLeftTime, () => {
    if (uploadFilesVM.uploadFileStatus.status.value == UploadFileDataStatus.UPLOADFILE_STATUS_QUEUE) {
        processingText.value = i18n.global.t('faceswapUploadArea.queuingAbout').replace('<xx1>', `${uploadFilesVM.uploadFileStatus.currentLeftTime.value}`);
    }else if (uploadFilesVM.uploadFileStatus.status.value == UploadFileDataStatus.UPLOADFILE_STATUS_ANALYSE) {
        processingText.value = i18n.global.t('faceswapUploadArea.analyzingAbout').replace('<xx1>', `${uploadFilesVM.uploadFileStatus.currentLeftTime.value}`);
    }
});
let lottieAnim = null;
watch(() => processing.value && !uploading.value, () => {
    if (processing.value && !uploading.value) {
        lottieAnim = lottie.loadAnimation({
            container: loadingDOMTag.value,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: lottieLoadingAnimate,
        });
    }else if (lottieAnim) {
        lottieAnim.destroy();
        lottieAnim = null;
    }
});

function dragenter (e) {
	e.preventDefault();
}
function dragover (e) {
	e.preventDefault();
}
function dragleave (e) {
	e.preventDefault();
}
function drop (e) {   
    e.preventDefault();    
    $analytic.log('faceswap_upload_tick');
    if (!User.getInstance.isLogin.value) {
        signUpDialog();
        return;
    }
    if (props.inputFileType === 'video' && !(User.getInstance.hasPaid.value && User.instance.allLeftNumber.value > 0)) {
        $showInfoDialog(InfoDialogType.AdvancedFeatures).then((result) => {
            if (result) {
                $jumpOtherPage('/pricing', true, false);
            }
        });
        return;
    }
    if (processing.value) return; 
    const files = e.dataTransfer.files;
    if (files.length > 0) {
        const file = files[0];
        checkAndSubmit(file);
    } 
}

onMounted(() => {
    inputFileDomTag.value.accept = (props.inputFileType == 'image') ? 'image/*' : 'video/*';
    bodyDOMTag.value.addEventListener('dragenter', dragenter, false);
    bodyDOMTag.value.addEventListener('dragleave', dragleave, false);
    bodyDOMTag.value.addEventListener('dragover', dragover, false);
    bodyDOMTag.value.addEventListener('drop', drop, false);
});

onBeforeUnmount(() => {
    bodyDOMTag.value.removeEventListener('dragenter', dragenter);
    bodyDOMTag.value.removeEventListener('dragleave', dragleave);
    bodyDOMTag.value.removeEventListener('dragover', dragover);
    bodyDOMTag.value.removeEventListener('drop', drop);
});

function chooseFile (e) {
    if (processing.value) return;
    if (e.target.tagName == 'INPUT') return;
    $analytic.log('faceswap_upload_tick');
    if (!User.getInstance.isLogin.value) {
        signUpDialog();
        return;
    }
    if (props.inputFileType === 'video' && !(User.getInstance.hasPaid.value && User.instance.allLeftNumber.value > 0)) {
        $showInfoDialog(InfoDialogType.AdvancedFeatures).then((result) => {
            if (result) {
                $jumpOtherPage('/pricing', true, false);
            }
        });
        return;
    }

    inputFileDomTag.value.click();
    document.addEventListener;
    inputFileDomTag.value.addEventListener('change', async () => {
        if (inputFileDomTag.value.files.length == 0) return;
        const file = inputFileDomTag.value.files[0];
        inputFileDomTag.value.value = '';
        await checkAndSubmit(file);
    });
}

async function checkAndSubmit (file) {
    try {
        let analyticCategory;
        if (props.inputFileType == 'image') {
            analyticCategory = 'photo';
            const metadata = await uploadFilesVM.takeImageMetadata(file);
            if (metadata.size > 10 * 1024 * 1024) { // 超出内存大小限制
                $showInfoDialog(InfoDialogType.PhotoTooBig).then((result) => {
                    if (result) {
                        inputFileDomTag.value.click();
                    }
                });
                return; 
            }else if (metadata.type == 'image/gif') {
                $showErrorToast(i18n.global.t('fileUpload.photoFileFormatError'),);    
                return;
            }
            $analytic.log('faceswap_uploading_open', {
                category: analyticCategory,
                length: 0,
                size: Math.ceil(metadata.size / 1024),
            });
        }else {
            analyticCategory = 'video';
            const metadata = await uploadFilesVM.takeVideoMetadata(file);
            if (metadata.duration > 600) { // 大于10分钟限制
                $showInfoDialog(InfoDialogType.VideoTooLong).then((result) => {
                    if (result) {
                        inputFileDomTag.value.value = '';
                        inputFileDomTag.value.click();
                    }
                });
                return;
            }else if (metadata.width > 1920 || metadata.height > 1920) { // 超出限制长、宽
                $showErrorToast('The resolution cannot exceed 1920*1920');
                return;
            }else if (metadata.size > 500 * 1024 * 1024) { // 超出内存大小限制
                $showInfoDialog(InfoDialogType.VideoTooBig).then((result) => {
                    if (result) {
                        inputFileDomTag.value.value = '';
                        inputFileDomTag.value.click();
                    }
                });
                return;
            }
            $showToast(i18n.global.t('fileUpload.videoIsProcessed'));
            $analytic.log('faceswap_uploading_open', {
                category: analyticCategory,
                length: Math.ceil(metadata.duration),
                size: Math.ceil(metadata.size / 1024),
            });
        }
        uploadFilesVM.uploadFile(file).then(() => {
            $analytic.log('faceswap_upload_finished', { category: analyticCategory });
        }).catch((_) => {
            $analytic.log('faceswap_analyser_unsuccess', { category: analyticCategory });
        });
    }catch (e) {
        inputFileDomTag.value.value = '';
                        
        if (tabCategory == 0 || tabCategory == 1) {
            $showErrorToast(i18n.global.t('fileUpload.photoFileFormatError'),);    
        }else {
            $showErrorToast(i18n.global.t('fileUpload.videoFileFormatError'),);
        }
    }
}
</script>

<style scoped lang="scss"> 
.faceswap_edit_uploadFile_input_view .body {
    border-radius: 12px;
    border: 1px solid transparent;
    background: linear-gradient(#20222B,#20222B) padding-box,
    repeating-linear-gradient(-45deg,#20222B 0, #20222B 0.20em,white 0,white 0.52em);
    width: 270px;
    height: 200px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.faceswap_edit_uploadFile_input_view .body[highlight=true] {
    border-radius: 12px;
    border: 1px solid transparent;
    background: linear-gradient(#362C2D,#362C2D) padding-box,
    repeating-linear-gradient(-45deg,#D33A31 0, #FF9441 0.25em,#20222B 0,#20222B 0.52em);
    background-origin: border-box;
    background-clip: content-box, border-box;
}
.faceswap_edit_uploadFile_input_view .body .icon {
    vertical-align: middle;
    width: 47px;
    height: 39px;
}
.faceswap_edit_uploadFile_input_view .body .hint {
    text-align: center;
    color: white;
    font-size: 14px;
    font-family: 'PingFang SC';
    font-weight: 400;
    margin: 9px 0px 10px 0px;
}
.faceswap_edit_uploadFile_input_view .body .desc {
    text-align: center;
    color: #999999;
    font-size: 12px;
    font-family: 'PingFang SC';
    font-weight: 400;
    margin: 0px 31px 0px 31px;
}
.faceswap_edit_uploadFile_input_view .txt_area {
    margin: 0 auto;
    width:fit-content;
    max-width: 270px;
}
.txt_area .title {
    margin: 30px 0px 10px 0px;
    color: #999999;
    font-size: 16px;
    font-weight: bold;
    font-family: 'PingFang SC';
}
.txt_area .title[progress=true] {
    margin-top: 8px;
}
.txt_area .row {
    justify-content: start;
    margin: 0px;
    color: #999999;
    font-size: 14px;
    font-weight: 400;
    font-family: 'PingFang SC';
}
.faceswap_edit_uploadFile_input_view .input_button {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    font-size: 20px;
    font-weight: 700;
    color: rgba(255, 255, 255, 1);
    width: 270px;
    height: 48px;
    background: linear-gradient( 11deg, #D33A31 0%, #FF9441 100%);
    border-radius: 32px;
    cursor: pointer;
}
.faceswap_edit_uploadFile_input_view .input_button[processing=true] {
    display: none;
}
.faceswap_edit_uploadFile_input_view .input_button:hover {
    background: linear-gradient( 11deg, #A82D27 0%, #CC7633 100%);
}
.input_button .arrow {
    width: 19px;
    height: 14px;
    margin-left: 9px;
}

.faceswap_edit_uploadFile_input_view .processing_button[processing=true] {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.5);
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    width: 270px;
    height: 48px;
    border-radius: 24px;
    background: linear-gradient( 90deg, #541613 0%, #673C1A 100%);
}
.faceswap_edit_uploadFile_input_view .processing_button[processing=false] {
    display: none;
}
.faceswap_edit_uploadFile_input_view .processing_button .loading[uploading=false] {
    width: 18px;
    height: 18px;
    margin-left: 6px;
}
.faceswap_edit_uploadFile_input_view .processing_button .loading[uploading=true] {
    display: none;
}

.faceswap_edit_uploadFile_input_view .processing_left {
    margin-top: 8px;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    font-family: 'PingFang SC';
    color: #999999;
}
.faceswap_edit_uploadFile_input_view .processing_left[processing=false] {
    display: none;
}
@media (max-width: 1070px) {
    .faceswap_edit_uploadFile_input_view .body {
        border-radius: 12px;
        width: calc(100% - 40px);
        height: 118px;
        margin-top: 20px;
    }
    .faceswap_edit_uploadFile_input_view .body .icon {
        width: 35px;
        height: 29px;
    }
    .faceswap_edit_uploadFile_input_view .body .hint {
        text-align: center;
        font-size: 12px;
        margin: 9px 0px 4px 0px;
    }
    .faceswap_edit_uploadFile_input_view .body .desc {
        font-size: 10px;
        margin: 0px 16px 0px 16px;
    }
    .faceswap_edit_uploadFile_input_view .txt_area {
        display: none;
    }
    .faceswap_edit_uploadFile_input_view .input_button {
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 700;
        width: calc(100% - 40px);
        height: 35px;
        border-radius: 17.5px;
    }
    .input_button .arrow {
        width: 13px;
        height: 9px;
        margin-left: 4px;
    }
    .faceswap_edit_uploadFile_input_view .processing_button[processing=true] {
        font-size: 14px;
        font-weight: 700;
        margin-top: 10px;
        width: calc(100% - 40px);
        height: 35px;
        border-radius: 17.5px;
    }
    .faceswap_edit_uploadFile_input_view .processing_button .loading[uploading=false] {
        width: 18px;
        height: 18px;
        margin-left: 4px;
    }
    .faceswap_edit_uploadFile_input_view .processing_left {
        font-size: 12px;
    }
}
</style>