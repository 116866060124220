<template>
    <div class="section-container">
        <h1 class="section-title"><span class="title">{{ $t('textimghomeindex.titlebefore') }}</span>{{ $t('textimghomeindex.titleafter') }}</h1>
        <p class="sub-title">{{ $t('textimghomeindex.subtitle') }}</p>
        <div class="button-div">
            <a class="button" href="/free-ai-image-generator" target="_blank">
                <p class="button-txt">{{ $t('textimghomeindex.buttontext') }}</p>
                <img class="button-detail-icon" :src="buttonDetailImgSrc" alt="">
            </a>
        </div>
        <!-- 黄色渐变背景图 -->
        <!-- <div class="home-txttoimg-bg">

        </div> -->
        <div class="video-div">
            <video id="texttoimgvideo" ref="texttoimgvideo" class="video" loop muted autoplay playsinline webkit-playsinline x5-playsinline x-webkit-airplay :src="require('@/assets/videos/home_introduce_demo.mp4')"></video>
        </div>
    </div>
</template>

<script setup>
import { getCurrentInstance, ref, onMounted } from 'vue';

const buttonDetailImgSrc = getCurrentInstance().appContext.config.globalProperties.$utility.assetImage('button_detail');
const texttoimgvideo = ref(null);
const loading = ref(true);
onMounted(() => {
    if (texttoimgvideo.value) {
        texttoimgvideo.value.play();
    }
});
</script>

<style scoped lang="less">
@media (hover: hover) {
    .button {
        &:hover {
            background: #CF4D23;
        }
    }
}
@media (min-width: 1000px) {
    .section-container {
        background-color: #0D0D0D;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        padding: 50px 120px 87px 120px;
        font-family: 'Roboto';
        background-image: url('@/assets/images/png/home_top_bg.png');
        background-repeat: no-repeat;
        background-size: cover;
        .section-title {
            font-family: 'PingFang SC';
            margin: 0 auto;
            font-size: 66px;
            font-weight: 700;
            color: #FFF;
            padding: 0;
            max-width: 918px;
            line-height: 70px;
                span {
                    color: #FF7839;
                    margin-right: 20px;
                }
        }

        .sub-title {
            font-family: 'PingFang SC';
            font-size: 18px;
            color: #707177;
            padding: 0;
            margin-top: 65px;
            line-height: 24px;
        }
        .home-txttoimg-bg {
            background: #FFBC33;
            opacity: 0.1;
        }
        .button-div {
            display: flex;
            justify-content: center;
            margin-top: 52px;
            .button {
                padding: 0 12px;
                min-width: 336px;
                min-height: 64px;
                background-color: #FF7839;
                display: -webkit-flex;
                display: flex;
                -webkit-align-items: center;
                align-items: center;
                -webkit-justify-content: center;
                justify-content: center;
                border-radius: 8px;
                cursor: pointer;
                margin-bottom: 10px;
                text-decoration: none;
                &:hover {
                    background: #CF4D23;
                }
            }
            .button-detail-icon {
                width: 19px;
                height: 14px;
                margin-left: 9px;
            }
            .button-txt {
                font-family: 'PingFang SC';
                display: inline-block;
                color: white;
                font-size: 22px;
                line-height: 25px;
            }
        }
        .video-div {
            max-width: 1400px;
            height: 100%;
            background-image: url('@/assets/images/png/home_circle.png');
            background-repeat: no-repeat;
            margin: 70px auto 0 auto;
            background-size: 100%;
            background-position: center;
            .video {
                max-width: 1024px;
                // height: 490px;
                object-fit: fill;
                border-radius: 8px;
                pointer-events: none;
                width: 100%;
                height: 100%;
            }
        }
    }
}

@media (max-width: 999px) {
    .section-container {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        padding: 30px 12px 45px 12px;
        font-family: 'Roboto';
        background-color: #0D0D0D;
        background-image: url('@/assets/images/png/home_top_bg.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        .section-title {
            font-family: 'PingFang SC';
            margin: 0 auto;
            font-size: 26px;
            font-weight: 700;
            color: #FFF;
            padding: 0;
            line-height: 34px;
                span {
                    color: #FF7839;
                    margin-right: 10px;
                }
        }

        .sub-title {
            font-family: 'PingFang SC';
            font-size: 16px;
            color: #707177;
            padding: 0;
            margin-top: 20px;
            line-height: 24px;
        }
        .button-div {
            display: flex;
            justify-content: center;
            margin-top: 20px;
            .button {
                padding: 0 12px;
                min-width: 216px;
                min-height: 48px;
                background-color: #FF7839;
                display: -webkit-flex;
                display: flex;
                -webkit-align-items: center;
                align-items: center;
                -webkit-justify-content: center;
                justify-content: center;
                border-radius: 8px;
                cursor: pointer;
                margin-bottom: 10px;
                text-decoration: none;
                &:active {
                    background: #CF4D23;
                }
            }
            .button-detail-icon {
                width: 13px;
                height: 9px;
                margin-left: 10px;
            }
            .button-txt {
                font-family: 'PingFang SC';
                display: inline-block;
                color: white;
                font-size: 16px;
                line-height: 19px;
            }
        }
        .video-div {
            margin-top: 46px;
            width: 100%;
            height: 100%;
            background-image: url('@/assets/images/png/home_circle.png');
            background-repeat: no-repeat;
            margin: 46px auto 0 auto;
            background-size: 100%;
            background-position: center;
            .video {
                width: 73.5%;
                min-width: 335px;
                border-radius: 8px;
                // background-image: url('@/assets/images/png/home_circle.png');
                pointer-events: none;
            }
        }
    }

}
</style>