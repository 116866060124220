import { defineStore } from 'pinia';
import { Task } from '@/stores/task';
import { API } from '@/script/api';
import { watch } from 'vue';
import { WSChannel } from '@/script/ws_channel';
import { ENV } from '@/script/proj_env';
import { VOCImageProvider,
  VOCImageRequestInfo,
  VOCImageRef, } from '@/script/voc_img_tool';
import { downImage, downVideo } from '@/utils/downLoadFile';
import { AnalyticHub } from '@/script/analytic';
import { User } from '@/script/user';

const channel = new WSChannel(
  ENV.currentConfig.swapAPIHost,
  '/ai/swap_channel'
);
export const useFaceSwapTaskManager = defineStore('faceSwapTaskManager', {
  state: () => ({
    tasks: [],
    watchChannelEventStopHandler: null,

    backupInterval: 5,
    backupTime: 0,
    backupTimerId: null,
  }),
  getters: {
    /// 是否有生成中的任务(包含提交、通道)
    hasGenerateProcessingTask: (state) => {
      let processing = false;
      for (let task of state.tasks) {
        if (task.inGenerateProcessing) {
          processing = true;
          break;
        }
      }
      return processing;
    },
    /// 获取所有生成的数据
    allGenerateProcessingTasks: (state) => {
      let tasks = [];
      for (let task of state.tasks) {
        if (task.inGenerateProcessing) {
          tasks.push(task);
        }
      }
      return tasks;
    },
    /// 获取所有在通道中生成的数据
    allInChannelProcessingTasks: (state) => {
      let tasks = [];
      for (let task of state.tasks) {
        if (task.inChannelProcessing) {
          tasks.push(task);
        }
      }
      return tasks;
    },
  },
  actions: {
    checkSameSubmitPramsTask (file, faceMappings) {
      const params = {
        fileKey: file.file.fileKey,
        faceMappings,
      };
      const submitParamsIdentify = JSON.stringify(params);
      const findTask = this.getTaskBySubmitParamsIdentify(submitParamsIdentify);
      return findTask;
    },
    /// 提交换脸任务
    async submit (file, faceMappings, t) {
      let task;
      if (!t) {
        const params = {
          fileKey: file.file.fileKey,
          faceMappings,
        };
        const submitParamsIdentify = JSON.stringify(params);
        this.tasks.unshift(new Task(file, params));
        task = this.tasks[0];
        task.analyticNewFile = file.file.newFile ?? false;
        task.submitParamsIdentify = submitParamsIdentify;
      } else {
        task = t;
      }
      task.updateStatus(Task.STATUS_SUBMIT);
      task.analyticCategory = file.file.fileType == 'image' ? 'photo' : 'video';
      task.analytiBeginTime = parseInt(Date.now());
      task.analyticDemo = file.demoFlag ? '1' : '0';
      try {
        let resp = await API.swapSubmit(task.submitParams);
        if (resp.code == 200) {
          task.submitParams.taskId = resp.data.taskId;
          task.taskId = resp.data.taskId;
          task.leftTime = resp.data.leftTime;
          task.paintingTime = resp.data.paintingTime;

          let success = await this.channelBindTask();
          if (success) {
            task.updateStatus(Task.STATUS_QUEUE);
          } else {
            task.updateStatus(Task.STATUS_GENERATE_ERROR);
          }
        } else {
          task.updateStatus(Task.STATUS_GENERATE_ERROR, resp.message || null);
        }
      } catch (_) {
        AnalyticHub.getInstance.log('faceswap_unsuccess', {
          category: task.analyticCategory,
          from: 'server',
        });
        task.updateStatus(Task.STATUS_GENERATE_ERROR);
      }
    },
    /// 绑定任务和通道，确保能正常推送数据到当前通道
    async channelBindTask () {
      if (!channel.active) {
        let success = await channel.open();
        if (!success) {
          return false;
        }
        let event = channel.listener();
        this.watchChannelEventStopHandler = watch(event.statusCode, () => {
          switch (event.statusCode.value.code) {
            case -1:
              {
                // 后端任务生成错误
                let task = this.getTaskByIds(event.data.taskId);
                AnalyticHub.getInstance.log('faceswap_unsuccess', {
                  category: task.analyticCategory,
                  from: 'server',
                });
                if (typeof event.data === 'object') {
                  task?.updateStatus(
                    Task.STATUS_GENERATE_ERROR,
                    event.data.data.message || null
                  );
                } else {
                  task?.updateStatus(
                    Task.STATUS_GENERATE_ERROR,
                    event.data || null
                  );
                }
              }
              break;
            case WSChannel.ConnectionErrorCode:
              {
                // 通道错误
                // AnalyticHub.getInstance.log('faceswap_unsuccess', {
                //     category: task.analyticCategory,
                //     from: 'socket',
                // })
                this.closeChannel();
                this.backupHandler();
              }
              break;
            case 2:
              {
                // 生成中
                let task = this.getTaskByIds(event.data.data.taskId);
                task?.updateStatus(Task.STATUS_PAINTING);
              }
              break;
            case 3:
              {
                // 完成
                let task = this.getTaskByIds(event.data.data.taskId);
                if (task) {
                  AnalyticHub.getInstance.log('faceswap_success', {
                    category: task.analyticCategory,
                    time:
                      event.data.data.file.fileType == 'video'
                        ? Math.ceil((parseInt(Date.now()) - task.analytiBeginTime) /
                              1000)
                        : 0,
                    size: Math.ceil(event.data.data.file.fileSize / 1024),
                    file: task.analyticNewFile ? 'new' : 'old',
                    demo: task.analyticDemo,
                  });
                  if (event.data.data.user) {
                    User.getInstance.syncUserInfo(event.data.data.user);
                  } else {
                    User.getInstance.loadUserInfo();
                  }
                  task.fileData = event.data.data.file;
                  this.downloadTaskImage(task);
                }
                this.closeChannelIfNeed();
              }
              break;
            default:
              break;
          }
        });
      }
      for (const task of this.allGenerateProcessingTasks) {
        channel.sendVerifyMessage(task.taskId);
      }
      return true;
    },
    /// 通道异常断开后，需要通过http轮询查任务状态
    async backupHandler () {
      let continueFlag = 1;
      const beginTimeStamp = parseInt(Date.now());
      let immediately = true;
      while (continueFlag == 1) {
        let processingTasks = this.allInChannelProcessingTasks;
        // 执行一次轮询，获取所有还在执行中任务的最新状态
        const tasks = await this.executeBackup(immediately, processingTasks);
        immediately = false;
        if (tasks && tasks.length > 0) {
          // 正常获取到期望任务的最新数据
          for (const respTask of tasks) {
            const task = this.getTaskByIds(respTask.taskId);
            if (respTask.taskStatus == 3) {
              AnalyticHub.getInstance.log('faceswap_success', {
                category: task.analyticCategory,
                time:
                  respTask.file.fileType == 'video'
                    ? Math.ceil((parseInt(Date.now()) - task.analytiBeginTime) / 1000)
                    : 0,
                size: Math.ceil(respTask.file.fileSize / 1024),
                file: task.analyticNewFile ? 'new' : 'old',
                demo: task.analyticDemo,
              });
              User.getInstance.loadUserInfo();
              task.fileData = respTask.file;
              this.downloadTaskImage(task);
            } else if (respTask.taskStatus == 2) {
              task.updateStatus(Task.STATUS_PAINTING);
            } else if (respTask.taskStatus == -1) {
              AnalyticHub.getInstance.log('faceswap_unsuccess', {
                category: task.analyticCategory,
                from: 'server',
              });
              task?.updateStatus(Task.STATUS_GENERATE_ERROR);
            }
          }
        }
        processingTasks = this.allInChannelProcessingTasks;
        if (processingTasks.length > 0) {
          // 还有剩余生成中的任务
          if (parseInt(Date.now()) - beginTimeStamp > 3600000) {
            // 虽然有生成中的任务，但轮询时间超过最大值
            const processingTasks = this.allInChannelProcessingTasks;
            for (const task of processingTasks) {
              AnalyticHub.getInstance.log('faceswap_unsuccess', {
                category: task.analyticCategory,
                from: 'server',
              });
              task.updateStatus(Task.STATUS_GENERATE_ERROR);
            }
            continueFlag = -1;
          }
        } else {
          // 已经没有需要轮询查找的任务，停止循环
          continueFlag = 0;
        }
      }
    },
    /// 获取下一次轮询间隔时间
    nextBackupTimeInterval () {
      const value = this.backupInterval;
      this.backupTime++;
      if (this.backupTime % 3 == 1) {
        this.backupInterval *= 2;
      }
      return value * 1000;
    },
    /// 执行一次轮询
    async executeBackup (immediately, tasks) {
      const res = await new Promise(async (resolve, _) => {
        this.backupTimerId = setTimeout(
          async () => {
            const taskIds = tasks.map((task) => task.taskId);
            let resp = null;
            try {
              resp = await API.swapTaskFetch(taskIds);
            } catch (_) {}
            if (resp?.code == 200) {
              resolve(resp.data);
            } else {
              resolve(null);
            }
          },
          immediately ? 0 : this.nextBackupTimeInterval()
        );
      });
      return res;
    },
    /// 重试任务，自动根据错误类型进行任务重试或者下载重试
    async retry (retryTask) {
      for (const task of this.tasks) {
        if (task.taskId == retryTask.taskId) {
          if (task.taskStatus == Task.STATUS_GENERATE_ERROR) {
            await this.submit(
              task.originData,
              task.submitParams.faceMappings,
              task
            );
          } else if (task.taskStatus == Task.STATUS_DONWLOAD_ERROR) {
            this.downloadTaskImage(task);
          }
          break;
        }
      }
    },
    /// 下载任务中图片媒体数据
    async downloadTaskImage (task) {
      task.updateStatus(Task.STATUS_DOWNLOADING);
      let url =
        task.fileData.fileType == 'image'
          ? task.fileData.fileUrl
          : task.fileData.fileThumbUrl;
      if (!url) {
        task.updateStatus(Task.STATUS_DONWLOAD_ERROR);
        return;
      }
      let request = new VOCImageRequestInfo({ url });
      let imgRef = VOCImageProvider.getInstance.loadImage({
        request,
        higherPriority: true,
      });
      let downloadStatusWatchStopHandler;
      downloadStatusWatchStopHandler = watch(imgRef.imageStatus, () => {
        if (imgRef.imageStatus.value == VOCImageRef.IMAGE_STATUS_DONE) {
          downloadStatusWatchStopHandler();
          task.imageBlobUrl = imgRef.image.url;
          task.updateStatus(Task.STATUS_COMPLETE);
        } else if (
          imgRef.imageStatus.value == VOCImageRef.IMAGE_STATUS_FAILED
        ) {
          AnalyticHub.getInstance.log('faceswap_unsuccess', {
            category: task.analyticCategory,
            from: 'download',
          });
          task.updateStatus(Task.STATUS_DONWLOAD_ERROR);
          downloadStatusWatchStopHandler();
        }
      });
    },
    /// 符合条件则关闭ws通道
    closeChannelIfNeed () {
      if (this.hasGenerateProcessingTask) return;
      this.closeChannel();
    },
    /// 关闭ws通道
    closeChannel () {
      channel.dispose();
      if (this.watchChannelEventStopHandler) {
        this.watchChannelEventStopHandler();
      }
    },
    /// 根据taskId、localTaskId获取任务
    getTaskByIds (taskId) {
      for (let task of this.tasks) {
        if (task.taskId == taskId) {
          return task;
        }
      }
      return null;
    },
    /// 根据submitParamsIdentify获取任务
    getTaskBySubmitParamsIdentify (submitParamsIdentify) {
      for (let task of this.tasks) {
        if (task.submitParamsIdentify == submitParamsIdentify) {
          return task;
        }
      }
      return null;
    },
    /// 下载资源到本地
    downloadToLocal (task) {
      if (!task.fileData) return;
      if (task.fileData.fileType == 'image') {
        downImage(task.imageBlobUrl);
      } else {
        downVideo(task.fileData.fileUrl, task.fileData.fileSize);
      }
    },
    /** 设置是否点击反馈 */
    setItemHasFeedback (taskId) {
      const task = this.tasks.find((item) => item.taskId === taskId);
      task && (task.hasFeedback = true);
    },
    /** 设置是否点击举报 */
    setItemHasReport (taskId) {
      const task = this.tasks.find((item) => item.taskId === taskId);
      task && (task.hasReport = true);
    },
    /** 设置是发布的状态 */
    setItemHasPublish (taskId) {
        const task = this.tasks.find((item) => item.taskId === taskId);
        if (!task) return;
        task.hasPublish = true;
      },
    /** 清空错误信息 */
    clearItemFailedMessage (taskId) {
      const task = this.tasks.find((item) => item.taskId === taskId);
      task && (task.failedMessage = '');
    },
  },
});
