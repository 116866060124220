<template>
    <div class="txtToImgIndex-introduce-item" :narrow="isNarrow" :reverse="hReverse">
        <div class="large" v-if="!isNarrow" :narrow="isNarrow" :reverse="hReverse">
            <img class="img" :src="imgSrc" alt="">
            <div class="space"></div>
            <div class="txt-area" :narrow="isNarrow" :reverse="hReverse">
                <h2 class="title">{{ title }}</h2>
                <p class="subtitle">{{ subtitle }}</p>
                <!-- <a v-if="actionText != null" class="action" href="" @click.prevent="actionHandle">
                    <p>{{ actionText }}</p>
                    <img class="arrow" :src="$utility.assetImage('button_detail')" alt="">
                </a> -->
                <div class="button-div" v-if="actionText != null" href="" @click.prevent="actionHandle">
                    <a class="button" @click="$analytic.log('desc_freetrial_tick');" href="/free-ai-image-generator" target="_blank">
                        <p class="button-txt">{{ $t('showcasehomeindex.buttontext') }}</p>
                        <img loading="lazy" class="button-detail-icon" :src="buttonDetailImgSrc" alt="">
                    </a>
                </div>
            </div>
        </div>
        <div class="narrow" v-else :narrow="isNarrow" :reverse="hReverse">
            <div class="txt-area" :reverse="hReverse">
                <p class="title">{{ title }}</p>
                <p class="subtitle">{{ subtitle }}</p>
            </div>
            <img class="img" :src="imgSrc" alt="">
            <!-- <a v-if="actionText != null" class="action" href="" @click.prevent="">
                <p>{{ actionText }}</p>
                <img class="arrow" :src="$utility.assetImage('button_detail')" alt="">
            </a> -->
            <div class="button-div" v-if="actionText != null" href="" @click.prevent="actionHandle">
                <a class="button" href="/free-ai-image-generator" target="_blank">
                    <p class="button-txt">{{ $t('showcasehomeindex.buttontext') }}</p>
                    <img loading="lazy" class="button-detail-icon" :src="buttonDetailImgSrc" alt="">
                </a>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, watch, onMounted, computed, getCurrentInstance, defineProps } from "vue";

const { hReverse, imgSrc, title, subtitle, actionText, action } = defineProps([
    'hReverse', 'imgSrc', 'title', 'subtitle', 'actionText', 'action'
])
const { $utility } = getCurrentInstance().appContext.config.globalProperties
const buttonDetailImgSrc = getCurrentInstance().appContext.config.globalProperties.$utility.assetImage('button_detail');
const isNarrow = computed(() => $utility.ishomeNarrow.value);

function actionHandle() {
    if (action) {
        action();
    }
}
</script>

<style scoped lang="scss">
.txtToImgIndex-introduce-item[narrow=true] {
    flex-direction: column;
}

@media (min-width: 1000px) {
    .txtToImgIndex-introduce-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .large[narrow=false][reverse=true] {
        flex-direction: row-reverse;
    }
    .large {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .img {
            width: 525px;
        }
        .space {
            min-width: 20px;
        }
        .txt-area {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: end;
            text-align: end;
        }
        .txt-area[narrow=false][reverse=true] {
            align-items: start;
            text-align: start;
        }
        .title {
            color: #F3F9FF;
            font-size: 48px;
            font-weight: 700;
            margin: 0;
            font-family: 'PingFang SC';
        }
        .subtitle { 
            color: #828589;
            font-size: 17px;
            font-weight: 500;
            margin: 20px 0px 0px 0px;
            font-family: 'PingFang SC';
        }
        .action {
            width: 388px;
            height: 63px;
            border-radius: 8px;
            margin-top: 34px;
            background: #FF7839;
            display: -webkit-flex;
            display: flex;
            -webkit-align-items: center;
            align-items: center;
            -webkit-justify-content: center;
            justify-content: center;
            cursor: pointer;
            border: 0;
            color: white;
            font-size: 22px;
            line-height: 25px;
            text-align: center;
            text-decoration: none;
            &:hover {
                background: #CF4D23;
            } 
        }
        .arrow {
            width: 19px;
            height: 14px;
            margin-left: 9px;
        }
        .button-div {
            display: flex;
            justify-content: center;
            margin-top: 40px;
            .button {
                padding: 0 12px;
                min-width: 336px;
                height: 64px;
                background-color: #FF7839;
                display: -webkit-flex;
                display: flex;
                -webkit-align-items: center;
                align-items: center;
                -webkit-justify-content: center;
                justify-content: center;
                border-radius: 8px;
                cursor: pointer;
                margin-bottom: 10px;
                text-decoration: none;
                &:hover {
                    background: #CF4D23;
                } 
            }
            .button-detail-icon {
                width: 19px;
                height: 14px;
                margin-left: 9px;
            }
            .button-txt {
                display: inline-block;
                font-family: 'PingFang SC';
                color: white;
                font-family: 'PingFang SC';
                font-size: 22px;
            }
        }
    }
}
}
@media (max-width: 999px) {
    .txtToImgIndex-introduce-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .narrow {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .img {
                max-width: 300px;
                margin-top: 14px;
            }
            .txt-area {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
                max-width: 685px;
            }
            .title {
                color: #F3F9FF;
                font-size: 26px;
                line-height: 34px;
                font-weight: bold;
                margin: 0;
                max-width: 540px;
                font-family: 'PingFang SC';
            }
            .subtitle {
                color: #828589;
                font-size: 16px;
                line-height: 24px;
                margin: 14px 0px 0px 0px;
                max-width: 540px;
                font-family: 'PingFang SC';
            }
            .action {
                padding: 0 12px;
                height: 48px;
                border-radius: 8px;
                margin-left: auto;
                margin-right: auto;
                margin-top: 20px;
                background: #F96130;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                border: 0;
                color: white;
                font-size: 16px;
                line-height: 19px;
                text-align: center;
                text-decoration: none;
            }
            // .action:hover {
            //     background: #CF4D23;
            // }
            .arrow {
                width: 13px;
                height: 9px;
                margin-left: 9px;
            }
            .button-div {
            display: flex;
            justify-content: center;
            margin-top: 20px;
            .button {
                padding: 0 12px;
                min-width: 150px;
                min-height: 48px;
                background-color: #FF7839;
                display: -webkit-flex;
                display: flex;
                -webkit-align-items: center;
                align-items: center;
                -webkit-justify-content: center;
                justify-content: center;
                border-radius: 8px;
                cursor: pointer;
                margin-bottom: 10px;
                text-decoration: none;
				&:active {
                    background: linear-gradient(to right, #A82D27, #CC7633);
                }
            }
            .button-detail-icon {
                width: 13px;
                height: 9px;
                margin-left: 10px;
            }
            .button-txt {
                display: inline-block;
                color: white;
                font-family: 'PingFang SC';
                font-size: 16px;
            }
        }
        }
        .large {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .button-div {
            display: flex;
            justify-content: center;
            margin-top: 20px;
            .button {
                padding: 0 12px;
                min-width: 150px;
                min-height: 48px;
                background-color: #FF7839;
                display: -webkit-flex;
                display: flex;
                -webkit-align-items: center;
                align-items: center;
                -webkit-justify-content: center;
                justify-content: center;
                border-radius: 8px;
                cursor: pointer;
                margin-bottom: 10px;
                text-decoration: none;
				// &:hover {
                //     background: linear-gradient(to right, #A82D27, #CC7633);
                // }
            }
            .button-detail-icon {
                width: 13px;
                height: 9px;
                margin-left: 10px;
            }
            .button-txt {
                display: inline-block;
                color: white;
                font-family: 'PingFang SC';
                font-size: 16px;
            }
        }
        .img {
            width: 525px;
        }
        .space {
            min-width: 20px;
        }
        .txt-area {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: end;
            text-align: end;
        }
        .txt-area[narrow=false][reverse=true] {
            align-items: start;
            text-align: start;
        }
        .title {
            color: #F3F9FF;
            font-size: 48px;
            font-weight: 700;
            margin: 0;
            font-family: 'PingFang SC';
        }
        .subtitle { 
            color: #828589;
            font-size: 17px;
            font-weight: 500;
            margin: 20px 0px 0px 0px;
            font-family: 'PingFang SC';
        }
        .action {
            width: 388px;
            height: 63px;
            border-radius: 8px;
            margin-top: 34px;
            background: #FF7839;
            display: -webkit-flex;
            display: flex;
            -webkit-align-items: center;
            align-items: center;
            -webkit-justify-content: center;
            justify-content: center;
            cursor: pointer;
            border: 0;
            color: white;
            font-size: 20px;
            font-weight: 700;
            font-family: 'PingFang SC';
            text-align: center;
            text-decoration: none;
            &:active {
                background: #CF4D23;
            } 
        }
        .arrow {
            width: 19px;
            height: 14px;
            margin-left: 9px;
        }
    }
    }
}
</style>