<template>
  <div class="faceswap-edit-secondary-container" :style="{maxHeight: state.maxHeight}" >
    <div :class="['wrapper', { none: !state.wsWorkList.length }]">
      <div class="tip">
        <div class="for-you">
          {{
            activeIndexImage == 0 || activeIndexImage == 1
                ? $t("faceswapToHistory.tab1or2header")
                : $t("faceswapToHistory.tab3or4header")
          }}
          &nbsp;
        </div>
        <div class="to-history" @click="toHistory">
          {{ $t("faceswapToHistory.footer") }}↗
        </div>
      </div>
      <div class="none-work" v-if="!state.wsWorkList.length">
        <img :src="images[activeIndexImage]" alt=""/>
      </div>
      <MultWorkFaceSwap
          v-if="state.wsWorkList.length === 1"
          class="one-work"
          :work="state.wsWorkList[0]"
      >
      </MultWorkFaceSwap>
      <div
          v-if="
          (state.wsWorkList.length > 1 && !state.isMobile) ||
          (state.isMobile &&
            state.wsWorkList.length < 7 &&
            state.wsWorkList.length > 1)
        "
          class="mult-work-list" ref="workBoxRef"
      >
        <MultWorkFaceSwap
            class="mult-works"
            v-for="item in state.wsWorkList"
            :key="item.taskId"
            :work="item"
        ></MultWorkFaceSwap>
      </div>
      <div
          class="swiper-work"
          v-if="state.isMobile && state.splitWsWorkList.length > 1"
      >
        <Swiper
            @slideChange="onSwiper"
            :slides-per-view="1"
            :space-between="50"
        >
          <SwiperSlide
              v-for="(item, index) in state.splitWsWorkList"
              :key="index"
          >
            <div class="swiper-mult-work-list">
              <MultWorkFaceSwap
                  class="mult-works"
                  v-for="childitem in item"
                  :key="childitem.taskId"
                  :work="childitem"
              ></MultWorkFaceSwap>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <div
          class="swiper-nums"
          v-if="state.isMobile && state.splitWsWorkList.length > 1"
      >
        <div
            :class="['swiper-num', `${index === state.activeIndex ? 'act' : ''}`]"
            v-for="(item, index) in state.splitWsWorkList.length"
            :key="item"
        ></div>
      </div>
    </div>
  </div>
  <!-- 登录弹窗 -->
  <!-- <LoginDialog
    @signUp="toSignUp"
    @resetPassWord="toPassWord"
    v-if="state.isLoginShow"
    v-model:isShow="state.isLoginShow"
  />
  <SignUpDialog
    @back="backlogin"
    :isBack="state.upOnePage"
    v-if="state.isSignUp"
    v-model:isShow="state.isSignUp"
  />
  <ResetPassWordDialog
    @back="backlogin"
    :isBack="state.upOnePage"
    v-if="state.isResetPassWord"
    v-model:isShow="state.isResetPassWord"
  /> -->
</template>

<script setup>
import {
  getCurrentInstance,
  reactive,
  ref,
  computed,
  watch,
  onUpdated,
  inject,
} from 'vue';
import MultWorkFaceSwap from '@/components/MultWorkFaceSwap.vue';
import {useFaceSwapTaskManager} from '@/stores/useFaceSwapTaskManager';
import {Task} from '@/stores/task';
import _ from 'lodash';
import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/css';
import {Pagination} from 'swiper/modules';
import 'swiper/css/pagination';
import LoginDialog from '@/components/LoginDialog.vue';
import SignUpDialog from '@/components/SignUpDialog.vue';
import ResetPassWordDialog from '@/components/ResetPassWordDialog.vue';
import {User} from '@/script/user';
import {useFaceSwap} from '@/stores/useFaceSwap';
import {loginDialog} from '@/components/Dialog';

const modules = [Pagination];

let {$utility, $showErrorToast} =
    getCurrentInstance().appContext.config.globalProperties;
const signImgSwapFaceUrl = $utility.assetImage('sign_img_swap_face');
const multImgSwapFaceUrl = $utility.assetImage('mult_img_swap_face');
const signVideoSwapFaceUrl = $utility.assetImage('sign_video_swap_face');
const multVideoSwapFaceUrl = $utility.assetImage('mult_video_swap_face');
const images = [
  signImgSwapFaceUrl,
  multImgSwapFaceUrl,
  signVideoSwapFaceUrl,
  multVideoSwapFaceUrl,
];
const workBoxRef = ref(null)
const activeIndexImage = inject('tabCategory');
const state = reactive({
  wsWorkList: [],
  isMobile: window.mobileAndTabletCheck(),
  splitWsWorkList: [],
  activeIndex: 0,
  upOnePage: '',
  isLoginShow: false,
  isSignUp: false,
  isResetPassWord: false,
  maxHeight: '',
});
watch(() => useFaceSwap().faceswapEditPrimaryContainerHeight, () => {
  state.maxHeight = `${useFaceSwap().faceswapEditPrimaryContainerHeight}px`;
  if ($utility.isNarrow.value) {
    state.maxHeight = 'none';
  }
});

const toSignUp = (type) => {
  state.upOnePage = type;
  (state.isLoginShow = false), (state.isSignUp = true);
};
const toPassWord = (type) => {
  (state.isLoginShow = false), (state.isResetPassWord = true);
  state.upOnePage = type;
};
const backlogin = () => {
  state.upOnePage = null;
  state.isSignUp = false;
  state.isResetPassWord = false;
  state.isLoginShow = true;
};
watch(()=> state.wsWorkList.length, () => {
  if (workBoxRef.value) {
    workBoxRef.value.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
})
watch(useFaceSwapTaskManager().tasks, () => {
  const taskList = useFaceSwapTaskManager().tasks;
  state.wsWorkList = taskList;

  taskList.forEach((element) => {
    if (
        element.taskStatus === Task.STATUS_GENERATE_ERROR &&
        element.failedMessage
    ) {
      $showErrorToast(element.failedMessage);
      useFaceSwapTaskManager().clearItemFailedMessage(element.taskId);
    }
  });
  // 移动端 六页后分页
  if (state.isMobile && taskList.length > 5) {
    state.splitWsWorkList = _.chunk(taskList, 6);
  }
});
const onSwiper = (swiper) => {
  state.activeIndex = swiper.activeIndex;
};
const toHistory = () => {
  if (!User.getInstance.isLogin.value) {
    loginDialog();
    return;
  }
  window.open(`${window.location.protocol}//${window.location.host}/history?tab=2`);
};
</script>

<style scoped lang="scss">
.faceswap-edit-secondary-container .wrapper {
  padding: 20px 0px 0px;
  display: flex;
  flex-direction: column;

  .tip {
    display: flex;
    line-height: 18px;
    justify-content: center;
    margin-bottom: 20px;
    font-size: 14px;
    padding: 0 20px;
    flex-wrap: wrap;

    .for-you {
      text-wrap: nowrap;
      color: #999999;
    }

    .to-history {
      color: #ff9441;
      cursor: pointer;
      text-decoration: underline;
      text-decoration-color: #ff9441;
      text-wrap: nowrap;

      &:hover {
        color: #E05435;
        text-decoration-color: #E05435;

      }
    }
  }

  .none-work {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 20px;
    width: calc(100% - 40px);
    flex: 1;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .mult-work-list {
    flex: 1;
    width: calc(100% - 28px);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: start;
    margin-bottom: 14px;
    overflow-y: auto;
    padding: 0 14px;

    &::-webkit-scrollbar {
      color: rgba(255, 255, 255, 0.14);
      border-radius: 3px;
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.14);
      border-radius: 3px;
      cursor: pointer;
    }
  }

  .swiper-work {
    flex: 1;
    width: 100%;
    margin-bottom: 30px;

    .swiper {
      height: 100%;
    }

    .swiper-mult-work-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-content: start;
      height: 100%;
    }
  }

  .swiper-nums {
    position: absolute;
    bottom: 14px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;

    .swiper-num {
      width: 4px;
      height: 4px;
      background: rgba(255, 255, 255, 0.5);
      margin: 0 4px;
      border-radius: 2px;

      &.act {
        width: 14px;
        height: 4px;
        background: #ffffff;
      }
    }
  }
}

@media (max-width: 1300px) {
  .faceswap-edit-secondary-container .wrapper {
    padding: 16px 0px 0px;

    .tip {
      font-size: 12px;
      display: block;
      line-height: 14px;
      text-align: center;
      margin-bottom: 10px;

      .for-you {
        color: #999999;
      }

      .to-history {
        margin: 0 auto;
        width: max-content;
        color: #ff9441;
      }
    }
  }
}

@media (min-width: 1071px) {
  .faceswap-edit-secondary-container {
    width: 47%;
    max-height: 720px;
  }

  .faceswap-edit-secondary-container .wrapper {
    background-color: #20222b;
    border-radius: 24px;
    height: calc(100% - 20px);
    position: relative;
  }
}

@media (max-width: 1070px) {
  .faceswap-edit-secondary-container .wrapper {
    margin-top: 20px;
    background-color: #20222b;
    border-radius: 16px;
    height: 605px;
    position: relative;
    // .none-work {
    //   position: absolute;
    //   top: 50%;
    //   margin-top: 0;
    //   transform: translateY(-50%);
    // }
  }
  .faceswap-edit-secondary-container .wrapper.none {
    height: 272px;

    .none-work {
      width: 280px;
      height: 208px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: 20px;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
