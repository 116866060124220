export default function HLSLoader () {
    return new Promise((resolve, reject) => {
        if (window.Hls) {
            resolve(window.Hls);
        }else {
            let script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.src = 'https://cdnjs.cloudflare.com/ajax/libs/hls.js/1.5.13/hls.min.js';
            script.onerror = reject;
            document.head.appendChild(script);
            script.onload = () => {
                resolve(window.Hls);
            };
        }
    });
}