<template>
    <div class="faceswap_edit_file_operate_container" :fixedHeight="hasSelectedFile">
        <FaceSwapEditFileListView class="file_container" :inputFileType="inputFileType"/>
        <FaceSwapEditUploadFileInputView class="upload_container" v-if="!hasSelectedFile" :inputFileType="inputFileType"/>
        <FaceSwapEditFilePreviewView class="preview_container" v-else/>
    </div>
</template>

<script setup>
import FaceSwapEditFileListView from '@/components/faceSwapIndex/edit/FaceSwapEditFileListView';
import FaceSwapEditUploadFileInputView from '@/components/faceSwapIndex/edit/FaceSwapEditUploadFileInputView';
import FaceSwapEditFilePreviewView from '@/components/faceSwapIndex/edit/FaceSwapEditFilePreviewView';
import { useFaceSwap } from '@/stores/useFaceSwap';
import { computed, inject } from 'vue';

const faceSwapVM = useFaceSwap();
const hasSelectedFile = computed(() => {
    return faceSwapVM.selectedFiledIndex >= 0;
});
const tabCategory = inject('tabCategory');
const inputFileType = (tabCategory == 0 || tabCategory == 1) ? 'image' : 'video';
</script>

<style scoped lang="scss">

@media (min-width: 1070px) {
    .faceswap_edit_file_operate_container[fixedHeight=true] {
        height: 320px;
    }
    .faceswap_edit_file_operate_container {
        width: 100%;
        height: 100%;
        background-color: #20222B;
        border-radius: 24px;
        display: flex;
        .upload_container {
            margin: auto;
        }
        .preview_container {
            margin: auto;
            height: 100%;
        }
    }
}
@media (max-width: 1069px) {
    .faceswap_edit_file_operate_container {
        width: 100%;
        background-color: #20222B;
        border-radius: 16px;
        display: flex;
        flex-direction: column-reverse;
        .preview_container {
            height: 180px;
        }
    }
}


</style>