export default {
  meta: {
    faceswapTitle:
      'Troca de rosto de vídeo grátis, melhores vídeos e fotos de IA Troca de rosto online',
    faceswapKeywords:
      'Troca de rosto, Gerador de imagem AI, Troca de rosto de vídeo,',
    faceswapDescription:
      '3 créditos gratuitos para novos usuários registrados, troca de rosto de vídeo AI da mais alta qualidade on-line, qualquer pessoa pode trocar rosto por fotos e vídeos facilmente com apenas alguns cliques',
    faceswapTab1title:
      'Troca de rosto e corpo on-line de fotos e imagens de alta qualidade',
    faceswapTab1keywords:
      'Troca de rosto, Gerador de imagem AI, Troca de rosto de vídeo',
    faceswapTab1description:
      'Avaliação gratuita, este é um gerador de arte de IA para troca de rosto para site de fotos e imagens, você pode usar este software Editor de troca de corpo para troca de rosto, duas fotos ou criador de fotos',
    faceswapTab2title:
      'Editor on-line de troca de rosto e corpo de imagem AI de alta qualidade',
    faceswapTab2keywords:
      'Troca de rosto, Gerador de imagem AI, Troca de rosto de vídeo',
    faceswapTab2description:
      'Avaliação gratuita, este é um gerador de arte ai de troca de rosto para site de fotos e fotos, você pode usar este software de troca de rosto de corpo para troca de rosto de celebridade nsfw ou criador de fotos de meme',
    faceswapTab3title:
      'Criador de vídeo Troca de rosto de alta qualidade e troca de rosto de vídeo online',
    faceswapTab3keywords:
      'Troca de rosto, Gerador de imagem AI, Troca de rosto de vídeo',
    faceswapTab3description:
      'Avaliação gratuita, este é um gerador ai troca de rosto para site de troca de rosto de vídeo, você pode usar este software editor de vídeo de troca de rosto no PC para filme de troca de rosto, troca de rosto de celebridade nsfw ou criador de vídeo troca de rosto',
    faceswapTab4title:
      'Troca de rosto de vídeo de alta qualidade e vídeos on-line de troca de rosto',
    faceswapTab4keywords:
      'Troca de rosto, Gerador de imagem AI, Troca de rosto de vídeo',
    faceswapTab4description:
      'Avaliação gratuita, este é um vídeo de troca de rosto ai online Grátis, você pode usar este software editor de vídeo de troca de rosto no PC para troca de rosto de gerador de arte ai, troca de rosto de celebridade nsfw',
  },
  siteName: 'Beauty AI',
  creation: 'Garota IA',
  faceSwap: 'Troca de Rosto',
  product: 'Produto',
  magicBrush: 'Pincel Mágico',
  pricing: 'Preços',
  freeCredits: 'Créditos Grátis',
  credits: 'Créditos',
  getMoreCredits: 'Obtenha mais créditos',
  left: 'Esquerda',
  upgradeToPremium: 'Atualizar para Premium',
  go: 'Ir!',
  accountInformation: 'Informação da conta',
  signOut: 'Sair',
  getMore: 'Pegue mais',
  contactSupport: 'Contate o suporte',
  history: 'Histórico',
  login: 'Entrar',
  signup: 'Inscrever-se',
  aiFuture: 'Recurso de IA',
  photoFaceSwap: 'Troca de rosto de foto',
  photoMutipleFaceSwap: 'Troca de rosto de várias imagens',
  videoFaceSwap: 'Criador de vídeos troca de rosto',
  videoMutipleFaceSwap: 'Troca de múltiplos rostos de vídeo',
  multipleFaceSwap: 'Troca Múltipla de Rostos',
  aIGirlGenerator: 'Gerador de Imagens de IA',
  aIImageGenerator: 'Gerador de Imagens de IA',
  support: 'Suporte',
  language: 'língua',
  userPolicy: 'Política de Usuário',
  privacyPolicy: 'Política de Privacidade',
  helpAndFAQS: 'Ajuda e FAQs',
  siteMap: 'Mapa do Site',
  bussiness: 'Negócios',
  affiliateProgram: 'Programa de Afiliados',
  api: 'API',
  contactUs: 'Contate-nos',
  aiFaceFunTitle: 'Troca de rosto de vídeo AI on-line',
  aiFaceFunSubTitle:
    'Troque rostos instantaneamente com Beauty AI Video Face Swap. Mude os rostos em fotos e vídeos para quem você escolher. Crie memes engraçados com nosso produto baseado em IA.',
  startFaceButtonTitle: 'Começar Troca de Rosto',
  details: 'Detalhes  >',
  chooseFace: 'Escolher rosto',
  faceSwaps: 'trocas de rosto',
  faceswapUploadArea: {
    tab1Desc1: 'Carregue a foto original para trocar o rosto.',
    tab1Desc2: 'Arraste e solte a foto ou escolha a foto',
    tab2Desc1: 'Carregue a imagem original para trocar de rosto.',
    tab2Desc2: 'Arraste e solte a imagem ou escolha a imagem',
    tab3Desc1: 'Carregue o vídeo original para troca profunda de rosto falso.',
    tab3Desc2: 'Arraste e solte o vídeo ou escolha o vídeo',
    tab4Desc1: 'Carregue o vídeo original para trocar de rosto.',
    tab4Desc2: 'Arraste e solte o vídeo ou escolha o vídeo',
    tab1title: '3 etapas para trocar rosto em fotos ou imagens online',
    tab1step1: '1. Carregue sua foto original do rosto.',
    tab1step2:
      '2. Carregue uma foto do rosto alvo da pessoa que substituirá o rosto na foto original. Você também pode escolher entre as demonstrações fotográficas fornecidas.',
    tab1step3:
      '3. Inicie o Foto de troca de rostos, visualize e baixe os resultados em HD.',
    tab2title: '3 etapas para trocar vários rostos em fotos online',
    tab2step1: '1. Carregue sua foto original do rosto.',
    tab2step2:
      '2. Carregue uma foto do rosto alvo da pessoa que substituirá o rosto na foto original. Você também pode escolher entre as demonstrações de imagens fornecidas.',
    tab2step3:
      '3. Inicie a imagem de troca de rosto e, em seguida, visualize e baixe os resultados em HD.',
    tab3title: '3 etapas para trocar rosto em vídeos online',
    tab3step1: '1. Envie seu vídeo de rosto original.',
    tab3step2:
      '2. Carregue um rosto alvo da pessoa que substituirá o rosto no vídeo original. Você também pode escolher entre as demonstrações fornecidas.',
    tab3step3:
      '3. Inicie o Video Face Swap, visualize e baixe os resultados em HD.',
    tab4title: '3 etapas para trocar rosto em vídeos online',
    tab4step1: '1. Envie seu vídeo de rosto original.',
    tab4step2:
      '2. Carregue um rosto alvo da pessoa que substituirá o rosto no vídeo original. Você também pode escolher entre as demonstrações fornecidas.',
    tab4step3:
      '3. Inicie o Video Face Swap, visualize e baixe os resultados em HD.',
    chooseFiles: 'Escolher os arquivos',
    photoLimit: 'Máximo de 10 MB',
    videoLimit: 'Máx. 500M, 10min',
    uploading: 'Enviando <xx1>',
    faceAnalyzing: 'Análise facial',
    queuingAbout: 'Na fila...Sobre <xx1>s',
    analyzingAbout: 'Analisando...Sobre <xx1>s',
    somethingError: 'Algo deu errado. Tente novamente mais tarde.',
  },
  faceswapUploadFilePopup: {
    title: 'Todos os arquivos analisados',
    subtitle: 'Arquivos armazenados apenas por 7 dias',
    try: 'Tentar',
  },
  faceswapFaceRecognized: 'Rosto reconhecido',
  swapFaces: 'Trocar rostos',
  useCredit: 'usar <xx1> crédito',
  useCredits: 'use <xx1> créditos',
  faceSwapDetail: {
    firstSectionItem1Title: 'Introdução à troca de rosto de vídeo',
    firstSectionItem1Text:
      'Um trocador de rosto de IA é um aplicativo de software que usa inteligência artificial (IA) e técnicas de aprendizado de máquina para modificar ou trocar rostos em imagens e vídeos.',
    firstSectionItem2Title: 'Público-alvo',
    firstSectionItem2Text:
      'Espera-se que os principais usuários desta tecnologia sejam pesquisadores de troca facial, desenvolvedores envolvidos na troca facial e entusiastas da troca facial.',
    firstSectionItem3Title: 'Introdução ao AI Video Face Swap',
    firstSectionItem3Text:
      'Video Face Swap é uma tendência popular de vídeo em que você troca seu rosto pelo de outra pessoa. Com o Beauty AI, você pode trocar rostos em vídeos com amigos, celebridades ou até mesmo figuras históricas. Crie vídeos, memes e colagens engraçados ou simplesmente divirta-se experimentando sua aparência.',
    secondSectionTitle: 'O que você pode fazer com a troca de rosto de vídeo',
    secondSectionItem1Title: 'Explorar possibilidades',
    secondSectionItem1Text:
      'Descubra as possibilidades intrigantes e aplicações inesperadas desta técnica inovadora. Experimente um mundo onde as transformações faciais combinam criatividade e tecnologia, oferecendo mais do que você espera inicialmente.',
    secondSectionItem2Title: 'Troca de rosto de vídeo',
    secondSectionItem2Text:
      'Mergulhe nas possibilidades intrigantes e aplicações inesperadas desta técnica. Experimente um mundo de transformações faciais que vão muito além das expectativas iniciais, mesclando criatividade com tecnologia.',
    secondSectionItem3Title: 'Troca de gênero',
    secondSectionItem3Text:
      'Junte-se à tendência de troca de gênero no TikTok, Snapchat e Instagram. Compartilhe suas fotos com gênero trocado para atrair curtidas e fãs, dando uma atualização vibrante à sua presença nas redes sociais.',
    secondSectionItem4Title: 'Troca de rosto de vídeo de celebridade',
    secondSectionItem4Text:
      'Transforme sua aparência colocando seu rosto no vídeo de qualquer celebridade. Combine suas características com as de uma celebridade para criar conteúdo intrigante.',
    secondSectionItem5Title: 'Troca de rosto de meme',
    secondSectionItem5Text:
      'Crie memes engraçados usando o recurso de troca de rosto. Gere memes novos e cômicos com seu próprio rosto ou qualquer outro rosto e compartilhe as risadas nas plataformas de mídia social.',
    secondSectionItem6Title: 'Trocar com papéis de filmes',
    secondSectionItem6Text:
      'Troque de rosto com seus personagens de filmes favoritos, tornando-se um super-herói destemido ou um protagonista romântico encantador. Compartilhe suas imagens para entusiasmar os fãs de filmes.',
    secondSectionItem7Title: 'Troca de rosto AI em pinturas',
    secondSectionItem7Text:
      'Substitua os rostos das pinturas clássicas pelos seus. Compartilhe essas criações exclusivas com amigos e nas redes sociais, cultivando um estilo pessoal distinto.',
  },
  faceswapIntroduce: {
    sectionTitle: 'Troca de rosto falso profundo de IA on-line',
    item1Title: 'Criador de fotos troca de rosto',
    item1Subtitle:
      'Trocador de rosto online gratuito que permite trocar rostos em fotos.',
    item1ActionText: 'Troca de rosto agora',
    item2Title: 'Gerador de múltiplas fotos Troca de rosto',
    item2Subtitle:
      'Troque vários rostos em fotos de família, fotos de casamento, fotos de festas no PC ou no iPhone. etc.',
    item2ActionText: 'Troca de vários rostos agora',
    item3Title: 'Criador de vídeos troca de rosto',
    item3Subtitle:
      'Troque rostos em vídeos, aprimorando a produção de filmes ou criando conteúdo para mídias sociais.',
    item3ActionText: 'Troca de rosto agora',
    item4Title: 'Troca de múltiplos rostos de vídeo',
    item4Subtitle:
      'Substituindo vários rostos em vídeos com facilidade, oferecendo uma maneira divertida e criativa de transformar o conteúdo visual.',
    item4ActionText: 'Troca de vários rostos agora',
  },
  faceswapGuide: {
    tab1SectionTitle: 'Como funciona a troca de rosto de imagem AI',
    tab1item1Title: 'Envie sua foto original do rosto',
    tab1item1Subtitle:
      'Comece enviando uma foto contendo seu rosto para servir como entrada para a troca de rosto.',
    tab1item2Title: 'Escolha a foto do rosto alvo para troca de rosto',
    tab1item2Subtitle:
      'Carregue uma foto do rosto alvo da pessoa que substituirá o rosto na foto original. Você também pode escolher entre as demonstrações fotográficas fornecidas.',
    tab1item3Title: 'Iniciar foto de troca de rosto',
    tab1item3Subtitle:
      'Clique em \'Trocar rosto agora\' para permitir que o trocador de rosto faça sua mágica. Leva apenas 5 a 10 segundos para concluir a troca de rosto.',
    tab1item4Title: 'Pré-visualizar e baixar o resultado em HD',
    tab1item4Subtitle:
      'Assim que a troca de rostos for concluída, baixe a imagem resultante com os rostos trocados para uso pessoal ou compartilhamento.',
    tab2SectionTitle: 'Como funciona o AI Picture Face Swap Maker',
    tab2item1Title: 'Envie sua foto original do rosto',
    tab2item1Subtitle:
      'Comece enviando uma foto contendo seu rosto para servir como entrada para a troca de rosto.',
    tab2item2Title: 'Escolha a imagem do rosto alvo para troca de rosto',
    tab2item2Subtitle:
      'Carregue uma foto do rosto alvo da pessoa que substituirá o rosto na foto original. Você também pode escolher entre as demonstrações de imagens fornecidas.',
    tab2item3Title: 'Iniciar imagem de troca de rosto',
    tab2item3Subtitle:
      'Clique em \'Trocar rosto agora\' para permitir que o trocador de rosto faça sua mágica. Leva apenas 5 a 10 segundos para concluir a troca de rosto.',
    tab2item4Title: 'Pré-visualizar e baixar o resultado em HD',
    tab2item4Subtitle:
      'Assim que a troca de rostos for concluída, baixe a imagem resultante com os rostos trocados para uso pessoal ou compartilhamento.',
    tab3SectionTitle: 'Como funciona a troca de rosto de vídeo AI',
    tab3item1Title: 'Envie seu vídeo de rosto original',
    tab3item1Subtitle:
      'Comece enviando um vídeo contendo seu rosto para servir como entrada para a troca de rosto.',
    tab3item2Title: 'Escolha a foto do rosto alvo para troca de rosto',
    tab3item2Subtitle:
      'Envie um rosto alvo da pessoa que substituirá o rosto no vídeo original. Você também pode escolher entre as demonstrações fornecidas.',
    tab3item3Title: 'Iniciar vídeo de troca de rosto',
    tab3item3Subtitle:
      'Clique em \'Trocar rosto agora\' para permitir que o trocador de rosto faça sua mágica. Leva apenas 5 a 10 segundos para concluir a troca de rosto.',
    tab3item4Title: 'Pré-visualizar e baixar o resultado em HD',
    tab3item4Subtitle:
      'Assim que a troca de rostos for concluída, baixe o vídeo resultante com os rostos trocados para uso pessoal ou compartilhamento.',
    tab4SectionTitle: 'Como funciona a troca de rosto de vídeo AI',
    tab4item1Title: 'Envie seu vídeo de rosto original',
    tab4item1Subtitle:
      'Comece enviando um vídeo contendo seu rosto para servir como entrada para a troca de rosto.',
    tab4item2Title: 'Escolha a foto do rosto alvo para troca de rosto',
    tab4item2Subtitle:
      'Envie um rosto alvo da pessoa que substituirá o rosto no vídeo original. Você também pode escolher entre as demonstrações fornecidas.',
    tab4item3Title: 'Iniciar vídeo de troca de rosto',
    tab4item3Subtitle:
      'Clique em \'Trocar rosto agora\' para permitir que o trocador de rosto faça sua mágica. Leva apenas 5 a 10 segundos para concluir a troca de rosto.',
    tab4item4Title: 'Pré-visualizar e baixar o resultado em HD',
    tab4item4Subtitle:
      'Assim que a troca de rostos for concluída, baixe o vídeo resultante com os rostos trocados para uso pessoal ou compartilhamento.',
    sectionActionText: 'Comece a troca de rosto',
    howToUse: 'Como usar >',
  },
  review: {
    title: 'Eles nos amam, você também vai',
    subtitle:
      'Junte-se à equipe de mais de 10.000 parceiros em todo o mundo. As recompensas que eles recebem do site são contínuas e estáveis.',
    data1: {
      title: 'Eu adoro usar o Beauty AI!',
      content:
        'Eu adoro usar o Beauty AI! É a minha ferramenta favorita para adicionar um toque divertido às minhas fotos. Seja trocando rostos com amigos ou experimentando diferentes aparências de celebridades, os resultados são sempre hilários e divertidos.',
      name: 'Cliff Quan',
      dateText: '2 de março de 2024',
    },
    data2: {
      title: 'Como entusiasta de maquiagem',
      content:
        'Como entusiasta de maquiagem, eu uso o Beauty AI para experimentar novos looks antes de me comprometer com eles na vida real. Trocar meu rosto com diferentes personagens me ajuda a visualizar como vários estilos ficarão em mim. É uma ferramenta divertida e prática para quem adora experimentar maquiagem!',
      name: 'Anli Brevo',
      dateText: '16 de maio de 2024',
    },
    data3: {
      title: 'É fácil usar a troca de rostos em vídeo',
      content:
        'É fácil usar a função de troca de rostos em vídeo para trocar seu rosto com o de outra pessoa. Quer você queira colocar o rosto de uma estrela de cinema na sua cabeça ou substituir seu rosto pelo de um jogador de basquete, esta ferramenta faz o trabalho perfeitamente. Continuem assim!',
      name: 'Ada Wu',
      dateText: '19 de abril de 2024',
    },
    data4: {
      title: 'Esta ferramenta incrível detecta inteligentemente',
      content:
        'Esta ferramenta incrível detecta inteligentemente rostos em fotos e os substitui perfeitamente por qualquer rosto que você escolher. É como ter seu próprio estúdio criativo pessoal na ponta dos dedos! Com o Beauty AI, trocar rostos em qualquer foto é fácil, permitindo que eu libere minha imaginação sem qualquer dificuldade.',
      name: 'Mario Kuper',
      dateText: '24 de janeiro de 2024',
    },
    data5: {
      title: 'Sempre achei a edição de fotos desafiadora',
      content:
        'Sempre achei a edição de fotos desafiadora, mas o Beauty AI torna isso fácil! Adoro experimentar as diferentes opções de troca de rostos da IA e ver as combinações hilárias. É uma maneira fantástica de adicionar humor às minhas fotos e manter meus amigos rindo.',
      name: 'Chris Jin',
      dateText: '12 de dezembro de 2023',
    },
    data6: {
      title: 'A troca de rostos com IA é ideal para criar memes engraçados',
      content:
        'A troca de rostos com IA é ideal para criar memes engraçados, fotos de perfil e vídeos de troca de rostos. O Beauty AI é perfeito não apenas para entretenimento, mas também para a criação de conteúdo envolvente. Ajuda você a captar mais atenção nas redes sociais.',
      name: 'Kotláriková',
      dateText: '24 de março de 2024',
    },
  },
  faq: {
    title: 'Perguntas Frequentes',
    details: 'Detalhes  >',
    data1: {
      question: 'O que é o Beauty AI?',
      answer:
        'O Beauty AI é uma plataforma de IA visual que utiliza tecnologia avançada para trocar rostos, retocar, melhorar, colorir, corrigir e cartoonizar imagens automaticamente, sem a necessidade de ferramentas complexas de edição de fotos, como o Photoshop. Utilizando algoritmos de IA de ponta, o Beauty AI simplifica o processamento de fotos, economizando tempo, energia e custos para usuários individuais e empresariais que precisam de edição em massa de fotos.',
    },
    data2: {
      question: 'Como funciona uma ferramenta de Troca de Rostos com IA?',
      answer:
        'A Troca de Rostos com IA depende de algoritmos sofisticados de inteligência artificial para analisar minuciosamente as imagens de origem, reconhecer características faciais e substituí-las perfeitamente por outro rosto, mantendo a iluminação, os ângulos e as expressões naturais. Isso resulta em trocas de rosto realistas geradas pela IA.',
    },
    data3: {
      question: 'Como usar a Troca de Rostos em Vídeo?',
      answer:
        'Primeiro, selecione uma imagem clara de frente como base. Em seguida, escolha o rosto que deseja trocar, seja o seu ou o de uma celebridade. Por fim, inicie o processo de substituição, e o rosto escolhido será colocado perfeitamente na imagem base.',
    },
    data4: {
      question: 'Posso substituir meu rosto por qualquer imagem?',
      answer:
        'Sim, com certeza. O Beauty AI permite que você substitua seu rosto por qualquer imagem selecionada gratuitamente. Por exemplo, você pode colocar o rosto de um homem em um retrato feminino ou um rosto envelhecido na foto de um bebê. As possibilidades são infinitas, permitindo que você se torne quem quiser, sem limitações.',
    },
    data5: {
      question: 'Como obter o melhor resultado?',
      answer:
        'Para obter resultados ideais com a troca de rostos, siga estas dicas: Selecione uma foto clara com características faciais bem definidas, evitando imagens borradas. Certifique-se de que há apenas um rosto na foto e que ele esteja diretamente voltado para a câmera, sem obstruções de objetos como mãos.',
    },
    data6: {
      question: 'Posso trocar rostos online gratuitamente?',
      answer:
        'Absolutamente! Você pode trocar rostos online gratuitamente usando o Beauty AI. Oferecemos planos gratuitos e premium para atender às suas necessidades. Como novo usuário, você receberá três créditos ao se registrar, proporcionando acesso total ao nosso serviço de troca de rostos e recursos adicionais, como recorte de rosto e criação de avatar.',
    },
  },
  faceswapToHistory: {
    tab1or2header:
      'Mantivemos o histórico de fotos de troca de rosto para você,',
    tab3or4header:
      'Mantivemos o histórico de vídeos de troca de rosto para você,',
    footer: 'mais detalhes para ver a História.',
  },
  faceswapOnlineSection: {
    tab1Title: 'Troca de rosto com foto AI on-line',
    tab2Title: 'Troca de rosto de imagem AI on-line',
    tab3Title: 'Troca de rosto de vídeo AI on-line',
    tab4Title: 'Criador de vídeo de troca de rosto com IA on-line',
    tab1Desc:
      'Troque de rosto instantaneamente com o Beauty AI Photo Face Swap. Substitua o rosto em fotos e vídeos por quem você escolher. Crie memes engraçados com nosso.',
    tab2Desc:
      'Troque rostos instantaneamente com Beauty AI Picture Face Swap. Mude os rostos em fotos e vídeos para quem você escolher. Crie memes engraçados com nosso gerador de arte de troca de rosto.',
    tab3Desc:
      'Troque rostos instantaneamente com Beauty AI Video Face Swap. Mude os rostos em fotos e vídeos para quem você escolher. Crie memes engraçados com nosso produto baseado em IA.',
    tab4Desc:
      'Troque rostos instantaneamente com Beauty AI Video Face Swap. Mude o rosto nos vídeos para quem você escolher. Crie memes engraçados com nosso criador de troca de rosto com gerador de arte ai.',
    action: 'Comece a troca de rosto',
  },
  choose: {
    delText: 'Tem certeza de que deseja excluir este rosto?',
    leftBtnText: 'Continuar',
    rightBtnText: 'Cancelar',
    overFaces:
      'O upload de rostos atingiu o limite. Exclua outros rostos para continuar.',
    faceAn: 'Análise facial...',
    facesuccess: 'Sucesso na análise facial',
    facefailed: 'Falha na análise facial',
    feedback: 'Obrigado pelo seu feedback!',
    copy: 'Cópia bem-sucedida!',
    batchsupport:
      'Suporte em lote! Você pode fazer uma nova troca de rosto, independentemente do resultado atual.',
    batchsupportAIGirl:
      'Suporte em lote! Você pode criar uma nova Imagem de IA, independentemente do resultado atual.',
    submitting: 'Enviando',
    queuing: 'Na fila',
    creating: 'Criando',
    downloading: 'Baixando',
    retryText: 'Algo deu errado. Tente novamente mais tarde.',
    retry: 'Tentar novamente',
    emailTip:
      'Insira um sufixo de nome de domínio válido (como .com, .net, etc.)',
    noaccount: 'Ainda não tem conta?',
    enterPassWord: 'Digite a senha',
    loginPassWordErr: 'A senha deve conter pelo menos 8 caracteres',
    passWordErr: 'Defina a senha com no mínimo 8 caracteres',
    forgotpass: 'Esqueceu sua senha?',
    googlesign: 'Login com login do Google?',
    resetpassword: 'Redefinir senha',
    readyaccount: 'já tem uma conta?',
    confirm: 'confirme',
    resetpasswordsuccess: 'Redefinição de senha com sucesso!',
    senderror: 'Erro de envio!',
    sendsuccess: 'Envie sucesso!',
    signupfree: 'Inscreva-se gratuitamente',
    signupsuccess: 'Inscreva-se com sucesso!',
    sendcode: 'Enviar Código',
    loginsuccess: 'Entrar com sucesso!',
    logingerror: 'Erro de Login!',
    aboutS: 'cerca de {leftTime}s',
    keepPatient: 'Mantenham paciência!',
    needMoretime: 'Preciso de mais tempo!',
    aimostDoneKeep: 'Quase pronto! Mantenham paciência!',
    usernamePlace: 'user{\'@\'}example.com',
    emailCode: 'Código de verificação',
  },
  feedbackDialog: {
    title: 'Precisamos do seu feedback para melhorar o Beauty AI.',
    subTitle1: 'Quer melhorar?',
    option1: 'Análise facial imprecisa sobre arquivos enviados',
    option2: 'Não parece',
    option3: 'Problemas de detalhes do rosto (distorcidos, olhos, boca etc.)',
    option4: 'Tempo lento',
    option5: 'Nitidez do resultado',
    txtOption1: 'Conteúdo delicado ou perturbador',
    txtOption2: 'Violação dos termos de serviço',
    txtOption3: 'Relevância imediata',
    txtOption4: 'Rosto distorcido',
    txtOption5: 'Conteúdo da imagem',
    txtOption6: 'Nitidez da imagem',
    txtOption7: 'Tempo lento',
    subTitle2: 'Quer dizer mais?',
    placeholder: 'Outras sugestões...',
    submit: 'Enviar',
    reportTitle: 'Denunciar a foto',
    reportOptionTitle: 'Categoria',
    reportOption1: 'Conteúdo sensível ou perturbador',
    reportOption2: 'Spam',
    reportOption3: 'Violação dos Termos de Uso',
  },
  infoDialog: {
    advancedFeatures: 'Características avançadas',
    advancedFeaturesText:
      'Pague por nossos planos para desbloquear o recurso realista de troca de rosto em vídeo.',
    choosePlan: 'Escolha o plano',
    insufficientCredits: 'Créditos insuficientes',
    insufficientCreditsText: 'Seu saldo de crédito:',
    getMoreCredits: 'Obtenha mais créditos',
    videoTooBig: 'O vídeo é muito grande',
    videoTooLong: 'O vídeo é muito longo',
    videoCannotExceed: 'O vídeo não pode exceder',
    inFileSize: 'em tamanho de arquivo',
    selectAnotherFile: 'Selecione outro arquivo',
    photoTooBig: 'A foto é muito grande',
    photoCannotExceed: 'A foto não pode exceder',
    tenMin: '10 minutos',
    deleteFile: 'Tem certeza de que deseja excluir este arquivo?',
    cancel: 'Cancelar',
    confirm: 'confirme',
  },
  fileUpload: {
    demo: 'demo',
    historicalFiles: 'Todos os arquivos',
    uploading: 'Enviando',
    queuing: 'Na fila',
    analyzing: 'Analisando',
    photoFileFormatError:
      'Formato de arquivo não suportado! Por favor carregue a foto.',
    videoFileFormatError:
      'Formato de arquivo não suportado! Por favor, carregue o vídeo.',
    resolutionExceed1920: 'A resolução não pode exceder 1920*1920',
    videoIsProcessed:
      'O vídeo está sendo processado, pode demorar um pouco devido ao consumo de recursos, mas está tudo bem. Por favor, seja paciente!',
    filesLoading: 'Carregando, tente novamente mais tarde',
  },
  submitSamePhotoTaskHint:
    'Aviso! Você enviou os mesmos rostos com a mesma foto. Altere seus rostos para enviar. (Observe que este envio não custa seu crédito)',
  submitSameVideoTaskHint:
    'Aviso! Você enviou os mesmos rostos com o mesmo vídeo. Altere seus rostos para enviar. (Observe que este envio não custa seu crédito)',
  allRightsReserved: 'Todos os direitos reservados.',
  aiGirlHowToUse: 'Imagem AI como usar',
  aiGirlFAQDetails: 'Detalhes das perguntas frequentes sobre imagens AI',
  lookslikeUploadOpps: {
    title: 'Ops!',
    subtitle:
      'Ops! Ultrapasse os limites de créditos gratuitos!\nAssine o plano para usar todos os recursos de forma ilimitada.',
    unlockLimit: 'Limite de desbloqueio',
  },
  selectStyle: 'Selecione o estilo',
};
