import { defineStore } from 'pinia';
import { Task } from '@/stores/task';
import { API } from '@/script/api';
import { watch } from 'vue';
import { WSChannel } from '@/script/ws_channel';
import { ENV } from '@/script/proj_env';
import { VOCImageProvider,
  VOCImageRequestInfo,
  VOCImageRef, } from '@/script/voc_img_tool';
import { downImage, downVideo } from '@/utils/downLoadFile';
import { AnalyticHub } from '@/script/analytic';
import { User } from '@/script/user';

const channel = new WSChannel(
  ENV.currentConfig.apiHost,
  '/ai/prompt_taskid_channel'
);
export const useTxtToImgTaskManager = defineStore('txtToImgTaskManager', {
  state: () => {
    return {
      tasks: [],
      watchChannelEventStopHandler: null,

      backupInterval: 5,
      backupTime: 0,
      backupTimerId: null,
    };
  },
  getters: {
    /// 是否有生成中的任务(包含提交、通道)
    hasGenerateProcessingTask: (state) => {
      let processing = false;
      for (let task of state.tasks) {
        if (task.inGenerateProcessing) {
          processing = true;
          break;
        }
      }
      return processing;
    },
    /// 获取所有在通道中生成的数据
    allInChannelProcessingTasks: (state) => {
      let tasks = [];
      for (let task of state.tasks) {
        if (task.inChannelProcessing) {
          tasks.push(task);
        }
      }
      return tasks;
    },
  },
  actions: {
    /// 提交任务，原始参数提交
    async submit ({ prompt, styleKey, ratio, faceId, batch }) {
      const submitParams = {
        prompt,
        styleKey,
        ratio,
        faceId,
        batch,
      };
      const tasks = [];
      const indexs = [];
      let baseIndex = batch - 1;
      for (let i = 0; i < batch; i++) {
        const task = new Task(null, submitParams);
        this.tasks.unshift(task);
        tasks.push(task);
        indexs.push(baseIndex);
        baseIndex -= 1;
      }
      await this.submitSameBatchTasks(tasks, indexs);
      return this.tasks;
    },
    /// 下载资源到本地
    downloadToLocal (task) {
      if (!task.fileData) return;
      if (task.fileData.fileType == 'image') {
        downImage(task.imageBlobUrl);
      } else {
        downVideo(task.fileData.fileUrl, task.fileData.fileSize);
      }
    },
    /// 提交任务, 基于同一批次tasks提交
    async submitSameBatchTasks (tasks, indexs) {
      for (const task of tasks) {
        task.analytiBeginTime = parseInt(Date.now());
      }
      const task = tasks[0];
      const submitParams = JSON.parse(JSON.stringify(task.submitParams));
      if (tasks.length == 1) {
        submitParams.taskId = task.taskId;
      }
      submitParams.batch = tasks.length;
      let resp = null;
      try {
        resp = await API.txtToImgSubmit(submitParams);
      } catch (_) {
        resp = { code: -1 };
      }
      if (resp.code == 200) {
        AnalyticHub.getInstance.log('creation_uploaded_success');
        if (tasks.length == 1) {
          this.tasks[indexs[0]].leftTime = resp.data.leftTime;
          this.tasks[indexs[0]].paintingTime = resp.data.paintingTime;
          this.tasks[indexs[0]].taskId = resp.data.taskId;
          this.tasks[indexs[0]].submitParams.taskId =
            this.tasks[indexs[0]].taskId;
          this.tasks[indexs[0]].updateStatus(Task.STATUS_QUEUE);
          if (resp.data.loyal == 1) {
            AnalyticHub.getInstance.log('creation_loyal_user');
          }
        } else {
          for (let i = 0; i < tasks.length; i++) {
            const index = indexs[i];
            this.tasks[index].leftTime = resp.data[i].leftTime;
            this.tasks[index].paintingTime = resp.data[i].paintingTime;
            this.tasks[index].taskId = resp.data[i].taskId;
            this.tasks[index].updateStatus(Task.STATUS_QUEUE);
            this.tasks[index].submitParams.taskId = this.tasks[index].taskId;
          }
          if (resp.data[0].loyal == 1) {
            AnalyticHub.getInstance.log('creation_loyal_user');
          }
        }
        this.channelBindTask(tasks);
      } else {
        AnalyticHub.getInstance.log('creation_uploaded_unsuccess');
        AnalyticHub.getInstance.log('creation_unsuccess');
        for (let i = 0; i < tasks.length; i++) {
          const index = indexs[i];
          if (resp.code == -1) {
            this.tasks[index].updateStatus(Task.STATUS_GENERATE_ERROR);
          } else {
            this.tasks[index].updateStatus(
              Task.STATUS_GENERATE_ERROR,
              resp.message
            );
          }
        }
      }
    },
    /// 重试任务
    async retry (retryTask) {
      for (let i = 0; i < this.tasks.length; i++) {
        const task = this.tasks[i];
        if (task.localTaskId == retryTask.localTaskId) {
          if (task.taskStatus == Task.STATUS_GENERATE_ERROR) {
            await this.submitSameBatchTasks([task], [i]);
          } else if (task.taskStatus == Task.STATUS_DONWLOAD_ERROR) {
            this.downloadTaskImage(task);
          }
          break;
        }
      }
    },
    /// 绑定任务和通道，确保通道能正常推送数据到当前通道
    async channelBindTask (tasks) {
      if (!channel.active) {
        let success = await channel.open();
        if (!success) {
          return false;
        }
        let event = channel.listener();
        this.watchChannelEventStopHandler = watch(event.statusCode, () => {
          switch (event.statusCode.value.code) {
            case -1:
              {
                // 后端任务生成错误
                let task = this.getTaskByIds(event.data.data.taskId);
                AnalyticHub.getInstance.log('creation_unsuccess');
                if (typeof event.data === 'object') {
                  task?.updateStatus(
                    Task.STATUS_GENERATE_ERROR,
                    event.data.data.message || null
                  );
                } else {
                  task?.updateStatus(
                    Task.STATUS_GENERATE_ERROR,
                    event.data || null
                  );
                }
              }
              break;
            case WSChannel.ConnectionErrorCode:
              {
                // 通道错误
                this.closeChannel();
                this.backupHandler();
              }
              break;
            case 2:
              {
                // 生成中
                let task = this.getTaskByIds(event.data.data.taskId);
                task?.updateStatus(Task.STATUS_PAINTING);
              }
              break;
            case 3:
              {
                // 完成
                let task = this.getTaskByIds(event.data.data.taskId);
                AnalyticHub.getInstance.log('creation_success', {
                  text: task.submitParams.prompt,
                  time: Math.ceil((parseInt(Date.now()) - task.analytiBeginTime) / 1000),
                  category: task.submitParams.styleKey,
                  pic: '0',
                  scale: task.submitParams.ratio,
                  face: task.submitParams.faceId ? '1' : '0',
                  createnum: `${task.submitParams.batch}`,
                });
                if (task) {
                  if (event.data.data.user) {
                    User.getInstance.syncUserInfo(event.data.data.user);
                  } else {
                    User.getInstance.loadUserInfo();
                  }
                  task.fileData = {
                    fileData: event.data.data.urls[0],
                    fileType: 'image',
                  };
                  task.fileData.fileUrl = event.data.data.urls[0];
                  task.fileData.fileType = 'image';

                  this.downloadTaskImage(task);
                }
                this.closeChannelIfNeed();
              }
              break;
            default:
              break;
          }
        });
      }
      for (const task of tasks) {
        channel.sendVerifyMessage(task.taskId, false);
      }
      return true;
    },
    /// 通道异常断开后，需要通过http轮询查任务状态
    async backupHandler () {
      let continueFlag = 1;
      const beginTimeStamp = parseInt(Date.now());
      let immediately = true;
      while (continueFlag == 1) {
        let processingTasks = this.allInChannelProcessingTasks;
        // 执行一次轮询，获取所有还在执行中任务的最新状态
        const tasks = await this.executeBackup(immediately, processingTasks);
        immediately = false;
        if (tasks && tasks.length > 0) {
          // 正常获取到期望任务的最新数据
          for (const respTask of tasks) {
            const task = this.getTaskByIds(respTask.taskId);
            if (respTask.taskStatus == 3) {
              AnalyticHub.getInstance.log('creation_success', {
                text: task.submitParams.prompt,
                time: Math.ceil((parseInt(Date.now()) - task.analytiBeginTime) / 1000),
                category: task.submitParams.styleKey,
                pic: '0',
                scale: task.submitParams.ratio,
                face: task.submitParams.faceId ? '1' : '0',
                createnum: `${task.submitParams.batch}`,
              });
              User.getInstance.loadUserInfo();
              task.fileData = {
                fileUrl: respTask.urls[0],
                fileType: 'image',
              };
              this.downloadTaskImage(task);
            } else if (respTask.taskStatus == 2) {
              task.updateStatus(Task.STATUS_PAINTING);
            } else if (respTask.taskStatus == -1) {
              AnalyticHub.getInstance.log('creation_unsuccess');
              task?.updateStatus(Task.STATUS_GENERATE_ERROR);
            }
          }
        }
        processingTasks = this.allInChannelProcessingTasks;
        if (processingTasks.length > 0) {
          // 还有剩余生成中的任务
          if (parseInt(Date.now()) - beginTimeStamp > 3600000) {
            // 虽然有生成中的任务，但轮询时间超过最大值
            for (const task of processingTasks) {
              AnalyticHub.getInstance.log('creation_unsuccess');
              task.updateStatus(Task.STATUS_GENERATE_ERROR);
            }
            continueFlag = -1;
          }
        } else {
          // 已经没有需要轮询查找的任务，停止循环
          continueFlag = 0;
        }
      }
    },
    /// 获取下一次轮询间隔时间
    nextBackupTimeInterval () {
      const value = this.backupInterval;
      this.backupTime++;
      if (this.backupTime % 3 == 1) {
        this.backupInterval *= 2;
      }
      return value * 1000;
    },
    /// 执行一次轮询
    async executeBackup (immediately, tasks) {
      const res = await new Promise(async (resolve, _) => {
        this.backupTimerId = setTimeout(
          async () => {
            const taskIds = tasks.map((task) => task.taskId);
            let resp = null;
            try {
              resp = await API.txtToImgTasksFetch(taskIds);
            } catch (_) {}
            if (resp?.code == 200) {
              resolve(resp.data);
            } else {
              resolve(null);
            }
          },
          immediately ? 0 : this.nextBackupTimeInterval()
        );
      });
      return res;
    },
    /// 符合条件则关闭ws通道
    closeChannelIfNeed () {
      if (this.hasGenerateProcessingTask) return;
      channel.dispose();
      if (this.watchChannelEventStopHandler) {
        this.watchChannelEventStopHandler();
      }
    },
    /// 关闭ws通道
    closeChannel () {
      channel.dispose();
      if (this.watchChannelEventStopHandler) {
        this.watchChannelEventStopHandler();
      }
    },
    /// 根据taskId、localTaskId获取任务
    getTaskByIds (taskId) {
      for (let task of this.tasks) {
        if (task.taskId == taskId) {
          return task;
        }
      }
      return null;
    },
    /// 下载任务中图片媒体数据
    async downloadTaskImage (task) {
      task.updateStatus(Task.STATUS_DOWNLOADING);
      let url = task.fileData.fileUrl;
      if (!url) {
        task.updateStatus(Task.STATUS_DONWLOAD_ERROR);
        return;
      }
      let request = new VOCImageRequestInfo({ url });
      let imgRef = VOCImageProvider.getInstance.loadImage({
        request,
        higherPriority: true,
      });
      const beginTime = parseInt(Date.now());
      let downloadStatusWatchStopHandler;
      downloadStatusWatchStopHandler = watch(imgRef.imageStatus, () => {
        if (imgRef.imageStatus.value == VOCImageRef.IMAGE_STATUS_DONE) {
          AnalyticHub.getInstance.log('creation_downloaded_success', {
            time: Math.ceil(parseInt(Date.now() - beginTime) / 1000),
            size: Math.ceil(imgRef.image.bytesLength / 1024),
          });
          downloadStatusWatchStopHandler();
          task.imageBlobUrl = imgRef.image.url;
          task.updateStatus(Task.STATUS_COMPLETE);
        } else if (
          imgRef.imageStatus.value == VOCImageRef.IMAGE_STATUS_FAILED
        ) {
          AnalyticHub.getInstance.log('creation_unsuccess');
          task.updateStatus(Task.STATUS_DONWLOAD_ERROR);
          downloadStatusWatchStopHandler();
        }
      });
    },
    /** 设置是否点击反馈 */
    setItemHasFeedback (taskId) {
      const task = this.tasks.find((item) => item.taskId === taskId);
      task && (task.hasFeedback = true);
    },
    /** 设置是否点击举报 */
    setItemHasReport (taskId) {
      const task = this.tasks.find((item) => item.taskId === taskId);
      task && (task.hasReport = true);
    },
    /** 设置是发布的状态 */
    setItemHasPublish (taskId) {
      const task = this.tasks.find((item) => item.taskId === taskId);
      task && (task.hasPublish = true);
    },
    /** 清空错误信息 */
    clearItemFailedMessage (taskId) {
      const task = this.tasks.find((item) => item.taskId === taskId);
      task && (task.failedMessage = '');
    },
  },
});
