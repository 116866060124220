<template>
  <div class="txt-to-img">
    <div class="txt-to-img-left">
      <div ref="leftConetentRef" class="left_cconetnt_box">

        <!--   Describe Creation   -->
        <Describe></Describe>

        <!-- Select Style -->
        <SelectStyle></SelectStyle>

        <!--      barchSize块-->
        <div class="batch_size_box">
          <BatchSize></BatchSize>
        </div>

        <!--      AspectRatio块-->
        <div class="hint_ar_box">
          <AspectRatio></AspectRatio>
        </div>
      </div>
      <div class="left_btn_comit">
        <div :class="['btn_comit', {btn_comit_true: txtToImgStore.prompt}]" @click="comitBtn()">
          {{ $t('txtToImg.create') }}
        </div>
        <div class="credit">
          {{ useCredisCountText }}
        </div>
      </div>
    </div>
    <div class="txt-to-img-right">
      <TxtToImgEditSecondaryContainer class="secondary"></TxtToImgEditSecondaryContainer>
    </div>
  </div>
</template>

<script setup>
import { ref,
  computed,
  watch,
  getCurrentInstance, } from 'vue';
import Describe from './Describe.vue';
import SelectStyle from './SelectStyle.vue';
import BatchSize from './BatchSize.vue';
import AspectRatio from './AspectRatio.vue';
// 导入页面数据
import { useTxtToImg } from '@/stores/useTxtToImg';
import TxtToImgEditSecondaryContainer from '@/components/txtToimg/TxtToImgEditSecondaryContainer.vue';
import { User } from '@/script/user';
import { signUpDialog } from '@/components/Dialog';

const { $showErrorToast, $showInfoDialog, $jumpOtherPage, i18n, $analytic, $utility } = getCurrentInstance().appContext.config.globalProperties;
import { InfoDialogType } from '@/components/common/dialog/infoDialog';
// 加载页面数据
const txtToImgStore = useTxtToImg();

const leftConetentRef = ref(null);

// 显示扣除积分文案
const useCredisCountText = computed(() => {
  const count = useTxtToImg().useCredisCount;
  if (count == 1) {
    return i18n.global.t('use1Credit');
  }
    return i18n.global.t('useXCredits').replace('<xx1>', `${count}`);
});

watch(()=> txtToImgStore.aiGirlShowcaseTriggerToTop, () => {
  if (leftConetentRef.value) {
    leftConetentRef.value.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }
});

/**
 * 提交按钮
 */
const comitBtn = async () => {
  if (txtToImgStore.prompt) {
    $analytic.log('creation_create_tick');
    if (!User.getInstance.isLogin.value) {
      // loginDialog();
      signUpDialog();
      return;
    }

    //  积分不足 ： 判断是否订阅f，引导订阅。 t消耗品不足
    if (txtToImgStore.useCredisCount > User.getInstance.allLeftNumber.value) {
      if (User.getInstance.isSubscribe.value) {
        $showInfoDialog(InfoDialogType.InsufficientCredits, { value: ` ${User.getInstance.allLeftNumber.value || '0'}` }).then((result) => {
          if (result) {
            $jumpOtherPage('/pricing', true, false);
          }
        });
        return;
      } 
        $showInfoDialog(InfoDialogType.InsufficientCredits, { value: ` ${User.getInstance.allLeftNumber.value || '0'}` }).then((result) => {
          if (result) {
            $jumpOtherPage('/pricing', true, false);
          }
        });
        return;
    }

    if (User.getInstance.swords.value) {
      const patternSymbolStr = '[\\s`~!@#\$%^&*()_\\-+=<>?:"{}|,.\\\\/;\'\\[\\]、·~！@#￥%……&*（）——\\-+={}|《》？：“”【】、；‘\'，。、]';
      const swords = User.getInstance.swords.value;
      let match = null;
      for (const word of swords) {
        const pattern = new RegExp(`(${patternSymbolStr})+${word}(${patternSymbolStr})?\$`, 'gi'); // 开头必须有分割符，结尾在最后的
        const pattern2 = new RegExp(`^(${patternSymbolStr})?${word}(${patternSymbolStr})+`, 'gi'); // 开头在最前面，结尾必须有分隔符
        const pattern3 = new RegExp(`(${patternSymbolStr})+${word}(${patternSymbolStr})+`, 'gi'); // 开头结尾都必须要有分隔符
        const pattern4 = new RegExp(`^(${patternSymbolStr})?${word}(${patternSymbolStr})?\$`, 'gi'); // 开头在最前面，结尾在最后
        match = pattern.exec(txtToImgStore.prompt);
        if (match) break;
        match = pattern2.exec(txtToImgStore.prompt);
        if (match) break;
        match = pattern3.exec(txtToImgStore.prompt);
        if (match) break;
        match = pattern4.exec(txtToImgStore.prompt);
        if (match) break;
      }
      if (match) {
        let keyword = match[0];
        let res = keyword.split(' ');
        res.sort((a, b) => b.length - a.length);
        $showErrorToast(i18n.global.t('skeywordsHint').replace('<xx1>', res[0]));
        return;
      }
    }


    $analytic.log('creation_creating_express');
    if ($utility.isNarrow.value) {
      const target = document.querySelector('.txt-to-img-right');
      window.scrollTo({
          top: target.offsetTop - 100,
          behavior: 'smooth',
      });
    }

    //  提交
    await txtToImgStore.submit();
  }
};

</script>

<style scoped lang="scss">
div {
  box-sizing: border-box;
}
.txt-to-img {
  display: flex;
  width: 100%;
  justify-content: space-between;
  max-width: 1400px;
  margin: 0 auto;
  padding: 28px 13px 100px 13px;
  height: 100vh;
  // background: blue;
  .txt-to-img-left {
    //flex: 47;
    width: 49%;
    //margin-right: 3%;
    max-width: 640px;
    padding: 10px;
    height: 100%;
    padding-bottom: 0;
    position: relative;

    .left_cconetnt_box {
      width: 100%;
      max-height: 100%;
      overflow-y: scroll;
      padding-bottom: 100px;
    }

    .left_btn_comit {
      cursor: pointer;
      width: 100%;
      height: auto;
      margin-top: 21px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0;
      bottom: 0;
      background: linear-gradient(180deg, rgba(7, 10, 20, 0) 0%, #070A14 60%, #070A14 100%, #070A14 100%, #070A14 100%);
      .btn_comit {
        width: 100%;
        height: 63px;
        background: #FF7839;
        border-radius: 8px;
        opacity: 0.5;
        display: flex;
        justify-content: center;
        align-items: center;


      }

      .credit {
        font-weight: 400;
        font-size: 12px;
        color: rgba($color: #FFFFFF, $alpha: 0.66);
        line-height: 16px;
        text-align: center;
        font-style: normal;
        padding-top: 6px;
      }

      .btn_comit_true {
        background: #FF7839;
        opacity: 1;
      }
    }
  }

  .txt-to-img-right {
    width: 49%;
    max-width: 640px;
    height: 100%;
    box-sizing: border-box;
    .secondary {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
    }
  }
}

.batch_size_box {
  margin: 24px 0;
}

@media (any-hover: hover){
  .btn_comit:hover {
    background: #CF4D23;
    opacity: 1;
  }

}

@media screen and (max-width: 1070px) {
  .txt-to-img {
    display: block;
    position: relative;
    width: 100%;
    height: auto;
    max-width: 540px;
    //min-width: 375px;
    margin: 0 auto;
    padding: 0 13px;
    position: relative;
    padding-bottom: 80px;
    // background: blue;
    .txt-to-img-left {
      margin: 0;
      width: 100%;
      padding: 0;

      height: max-content;
      max-width: 540px;

      .left_cconetnt_box {
        height: max-content;
        padding-bottom: 10px;
        .looks_like {
          width: 100%;
          overflow: hidden;
        }
      }

      .left_btn_comit {
        cursor: pointer;
        width: 100%;
        height: 92px;
        margin-top: 21px;
        display: flex;
        justify-content: center;
        position: fixed;
        z-index: 50;
        left: 0;
        bottom: 0;
        background: linear-gradient(180deg, rgba(7, 10, 20, 0) 0%, #070A14 60%, #070A14 100%, #070A14 100%, #070A14 100%);

        .btn_comit {
          width: 100%;
          max-width: 349px;
          height: 43px;
          background: #F96130;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
          font-size: 14px;
          opacity: 0.9;
          color: #FFFFFF;
          line-height: 16px;
          text-align: center;
          font-style: normal;

        }

        .credit {
          font-weight: 500;
          font-size: 10px;
          color: #FFFFFF;
          line-height: 13px;
          text-align: center;
          font-style: normal;
          margin-top: 2px;
        }

        .btn_comit_true {
          background: #F96130;
          opacity: 1;
        }
      }

    }

    .txt-to-img-right {
      //background: #FFF;
      padding: 0;
      margin: 0;
      width: 100%;
      height: max-content;
      min-height: 425px;
      margin-top: 20px;
    }
  }
}

</style>
