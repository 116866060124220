<template>
    <div class="section-container">
        <div>
            <div class="div-left">
                <div class="content">
                    <h2 class="section-title">{{ $t('faceswaphomeindex.titlebefore') }}&nbsp<span>{{ $t('faceswaphomeindex.titleafter') }}</span></h2>
                    <h5 class="sub-title">{{ $t('faceswaphomeindex.subtitle') }}</h5>
                    <div class="button-div">
                        <a class="button" @click="$analytic.log('desc_faceswap_tick');" :href="$otherPageUrl('/photo-face-swap')" target="_blank">
                            <p class="button-txt">{{ $t('faceswaphomeindex.buttontext') }}</p>
                            <img class="button-detail-icon" :src="buttonDetailImgSrc" alt="">
                        </a>
                    </div>
                </div>
            </div>
            <!-- <div class="div-middle">
                <div v-for="(item, index) in faceSwapCarousList" :key=item.carouselurl >
                    <div style="position: relative;" v-show="clickitem == index">
                        <img class="arrow-img" :src="item.arrowurl" alt="">
                    </div>
                </div>
            </div> -->
            <div class="div-right">
                <div class="carousel-div">
                    <div v-for="(item, index) in faceSwapCarousList" :key=item.carouselurl >
                        <div style="position: relative;" v-show="clickitem == index">
                            <img class="arrow-img" :src="item.arrowurl" alt="">
                            <el-image fit="contain" class="carousel-img" :src="item.carouselurl" alt="" />
                            <!-- <div :style="{ background: '@/assets/images/webp/homefaceclickimg1.webp'}"></div> -->
                        </div>
                    </div>
                </div>
                <div class="clickimg-div">
                    <div class="clickimgdiv-content">
                        <div class="clickimgdiv-right">
                            <div v-for="(item, index) in faceSwapImgList" :key="item">
                                <el-image :class="{ 'select-img' : clickitem == index}" :src="item" fit="cover" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { getCurrentInstance, ref, onMounted, onUnmounted } from 'vue';
// 轮播图
const carousimg1 = {
    carouselurl: getCurrentInstance().appContext.config.globalProperties.$utility.assetImage('homefaceimg1'),
    arrowurl: getCurrentInstance().appContext.config.globalProperties.$utility.assetImage('homefacearrowimg1'),
};
const carousimg2 = {
    carouselurl: getCurrentInstance().appContext.config.globalProperties.$utility.assetImage('homefaceimg2'),
    arrowurl: getCurrentInstance().appContext.config.globalProperties.$utility.assetImage('homefacearrowimg2'),
};
const carousimg3 = {
    carouselurl: getCurrentInstance().appContext.config.globalProperties.$utility.assetImage('homefaceimg3'),
    arrowurl: getCurrentInstance().appContext.config.globalProperties.$utility.assetImage('homefacearrowimg3'),
};
const carousimg4 = {
    carouselurl: getCurrentInstance().appContext.config.globalProperties.$utility.assetImage('homefaceimg4'),
    arrowurl: getCurrentInstance().appContext.config.globalProperties.$utility.assetImage('homefacearrowimg4'),
};

// 点击图
const clickimg1 = getCurrentInstance().appContext.config.globalProperties.$utility.assetImage('homefaceclickimg1');
const clickimg2 = getCurrentInstance().appContext.config.globalProperties.$utility.assetImage('homefaceclickimg2');
const clickimg3 = getCurrentInstance().appContext.config.globalProperties.$utility.assetImage('homefaceclickimg3');
const clickimg4 = getCurrentInstance().appContext.config.globalProperties.$utility.assetImage('homefaceclickimg4');
const buttonDetailImgSrc = getCurrentInstance().appContext.config.globalProperties.$utility.assetImage('button_detail');
const faceSwapCarousList = [carousimg1, carousimg2, carousimg3, carousimg4];
const faceSwapImgList = [clickimg1, clickimg2, clickimg3, clickimg4];
const carousel = ref(null);

let clickitem = ref(0);
// function selectItem (index) {
//     clickitem.value = index;
//     carousel.value.setActiveItem(index);
// }

// function carouselChange (cur) {
//     clickitem.value = cur;
// }

let timeer = null;

onMounted(() => {
    timeer = setInterval(() => {
        if (clickitem.value < 3) {
            clickitem.value++;
        } else {
            clickitem.value = 0;
        }
    }, 3000);
    // window.addEventListener('resize', () => {
        // let domarr = document.getElementsByClassName('carousel-img')
        // console.log(domarr);
        // Array.prototype.forEach.call(domarr, function (element) {
        //     element.style.height = (element.offsetWidth * 1.1) + 'px';
        // });
    // })
});

onUnmounted(() => {
    clearInterval(timeer);
    timeer = null;
})
</script>

<style scoped lang="less">
@media (hover: hover) {
    .button {
        &:hover {
            background: #CF4D23;
        }
    }
}
@media (min-width: 1000px) {
    .section-container {
        margin-left: auto;
        margin-right: auto;
        display: flex;
        background-color: #171717;
        padding: 90px 120px;
        justify-content: center;
        > div {
            max-width: 1400px;
            display: flex;
        }
        .div-left {
            width: 50%;
            display: flex;
            align-items: center;
            .content {
                width: 100%;
                text-align: left;
                .section-title {
                    font-family: 'PingFang SC';
                    font-size: 48px;
                    font-weight: bold;
                    color: #FFF;
                    padding: 0;
                    margin: 0;
                    line-height: 57px;
                        span {
                            color: #FF7839;
                            margin-right: 20px;
                            word-wrap: break-word;
                            white-space: normal;
                            display: inline-block;
                        }
                }

                .sub-title {
                    font-family: 'PingFang SC';
                    font-size: 17px;
                    color: #707177;
                    padding: 0;
                    margin-top: 26px;
                    line-height: 26px;
                }
                .button-div {
                    margin-top: 40px;
                    .button {
                        padding: 0 12px;
                        width: 336px;
                        min-height: 64px;
                        background-color: #FF7839;
                        display: -webkit-flex;
                        display: flex;
                        -webkit-align-items: center;
                        align-items: center;
                        -webkit-justify-content: center;
                        justify-content: center;
                        border-radius: 8px;
                        cursor: pointer;
                        margin-bottom: 10px;
                        text-decoration: none;
                        &:hover {
                            background: #CF4D23;
                        }
                    }
                    .button-detail-icon {
                        width: 19px;
                        height: 14px;
                        margin-left: 9px;
                    }
                    .button-txt {
                        font-family: 'PingFang SC';
                        display: inline-block;
                        color: white;
                        font-size: 22px;
                        line-height: 25px;
                    }
                }
            }
        }
        .div-right {
            margin-left: 10%;
            width: 40%;
            .carousel-div {
                margin: 0 auto;
                z-index: 1;
                .carousel-img {
                    width: 100%;
                    max-width: 440px;
                    height: auto;
                    max-height: 550px;
                    float: right;
                    border-radius: 8px;
                    min-height: 480px;
                    min-width: 380px;
                    object-fit: contain;
                }
                :deep(.el-carousel) {
                    border-radius: 8px;
                    height: 500px;
                }
                :deep(.el-carousel__indicators) {
                    display: none;
                }
                :deep(.el-carousel__arrow) {
                    display: none;
                }
                :deep(.el-carousel__container) {
                    height: 500px;
                }
                .arrow-img {
                    position: absolute;
                    top: 66px;
                    max-width: 200px;
                    left: calc(50% - 300px);
                    z-index: 2;
                }
            }
            .clickimg-div {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                padding-top: 25px;
                .clickimgdiv-content {
                    width: 100%;
                    max-width: 440px;
                    .clickimgdiv-right {
                        display: flex;
                        justify-content: center;
                        div {
                            display: inline-block;
                            .el-image {
                                padding: 0 15px;
                                border-radius: 8px;
                                :deep(img) {
                                    width: 48px;
                                    border-radius: 8px;
                                }
                            }
                            .select-img {
                                width: 55px;
                                // height: 55px;
                                :deep(img) {
                                    width: 55px;
                                    border: 1px solid #FF9441;
                                }
                            }
                        }
                    }

                }
            }
        }
    }
}

@media (max-width: 999px) {
    .section-container {
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-wrap: wrap;
        background-color: #171717;
        padding: 40px 12px;
        justify-content: center;
        .div-left {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            .content {
                max-width: 640px;
                // min-width: 350px;
                text-align: center;
                .section-title {
                    font-family: 'PingFang SC';
                    font-size: 26px;
                    font-weight: bold;
                    color: #FFF;
                    padding: 0;
                    margin: 0;
                    line-height: 34px;
                        span {
                            color: #FF7839;
                            margin-right: 20px;
                        }
                }

                .sub-title {
                    font-family: 'PingFang SC';
                    font-size: 16px;
                    color: #707177;
                    padding: 0;
                    margin-top: 20px;
                    line-height: 24px;
                }
                .button-div {
                    margin-top: 20px;
                    display: flex;
                    justify-content: center;
                    .button {
                        padding: 0 12px;
                        min-width: 150px;
                        min-height: 48px;
                        background-color: #FF7839;
                        display: -webkit-flex;
                        display: flex;
                        -webkit-align-items: center;
                        align-items: center;
                        -webkit-justify-content: center;
                        justify-content: center;
                        border-radius: 8px;
                        cursor: pointer;
                        margin-bottom: 10px;
                        text-decoration: none;
                        &:active {
                            background: #CF4D23;
                        }
                    }
                    .button-detail-icon {
                        width: 13px;
                        height: 9px;
                        margin-left: 3px;
                    }
                    .button-txt {
                        font-family: 'PingFang SC';
                        display: inline-block;
                        color: white;
                        font-size: 16px;
                        line-height: 19px;
                    }
                }
            }
        }
        .div-right {
            width: 100%;
            margin: 30px auto 0 auto;
            .carousel-div {
                margin: 0 auto;
                z-index: 1;
                .carousel-img {
                    width: 65%;
                    max-width: 382px;
                    height: 100%;
                    float: right;
                    border-radius: 8px;
                    max-height: 478px;
                    object-fit: contain;
                    margin-right: 5%;
                }
                :deep(.el-carousel) {
                    border-radius: 8px;
                }
                :deep(.el-carousel__indicators) {
                    display: none;
                }
                :deep(.el-carousel__arrow) {
                    display: none;
                }
                .arrow-img {
                    position: absolute;
                    top: 66px;
                    max-width: 230px;
                    // right: 50%;
                    // min-width: 120px;
                    width: 33%;
                    z-index: 100;
                    margin-left: 5%;
                }
            }
            .clickimg-div {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                padding-top: 10px;
                .clickimgdiv-content {
                    width: 65%;
                    max-width: 382px;
                    display: flex;
                    justify-content: center;
                    margin-right: 5%;
                    .clickimgdiv-right {
                        float: right;
                        display: flex;
                        justify-content: space-around;
                        align-items: flex-end;
                        width: 100%;
                        padding: 0 20px;
                        div {
                            display: inline-block;
                            .el-image {
                                padding: 0 5px;
                                border-radius: 8px;
                                max-width: 42px;
                                :deep(img) {
                                    border-radius: 8px;
                                }
                            }
                            .select-img {
                                max-width: 48px;
                                :deep(img) {
                                    border: 1px solid #FF9441;
                                }
                            }
                        }
                    }

                }
            }
        }
    }

}
</style>
