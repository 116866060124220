export default {
    meta: {
        faceswapTitle: 'Free Video Face Swap, Best AI Videos&Photos Face Swap Online',
        faceswapKeywords: 'Face Swap,AI Image Generator,Video Face Swap,',
        faceswapDescription: '3 Free Credits For New Registered Users, Highest Quality AI Video Face Swap Online, Anyone Can Swap Face For Photos, Videos Easily With Just a Few Clicks',
        faceswapTab1title: 'High quality Photo&Picture Face Swap and Body Swap Online',
        faceswapTab1keywords: 'Face Swap,AI Image Generator,Video Face Swap',
        faceswapTab1description: 'Free trial, this is an ai art generator face swap for photo&pictures website, you can use this body swap Editor software for face swap two photos or pics maker',
        faceswapTab2title: 'High quality AI Picture Face Swap and Body Swap Editor Online',
        faceswapTab2keywords: 'Face Swap,AI Image Generator,Video Face Swap',
        faceswapTab2description: 'Free trial, this is an ai art generator face swap for pictures&photo website, you can use this body face swaper software for nsfw celebrity face swap or meme pics maker',
        faceswapTab3title: 'High quality Face Swap Video Maker&Video Face Swap Online',
        faceswapTab3keywords: 'Face Swap,AI Image Generator,Video Face Swap',
        faceswapTab3description: 'Free trial, this is an ai face swap generator for video face swap website, you can use this face swap video Editor software on pc for face swap movie, nsfw celebrity face swap or face swap video maker',
        faceswapTab4title: 'High quality Video Face Swap&Face Swap Videos Online',
        faceswapTab4keywords: 'Face Swap,AI Image Generator,Video Face Swap',
        faceswapTab4description: 'Free trial, this is an ai face swap video online Free, you can use this face swap video Editor software on pc for ai art generator face swap, nsfw celebrity face swap',
    },
    siteName: 'Beauty AI',
    creation: 'AI Image',
    product: 'Product',
    faceSwap: 'Face Swap',
    magicBrush: 'Magic Brush',
    pricing: 'Pricing',
    freeCredits: 'Free Credits',
    credits: 'Credits',
    getMoreCredits: 'Get More Credits',
    left: 'Left',
    upgradeToPremium : 'Upgrade to Premium',
    go: 'Go!',
    accountInformation: 'Account Information',
    signOut: 'Sign Out',
    getMore: 'Get More',
    contactSupport: 'Contact Support',
    history: 'History',
    login: 'Log in',
    signup: 'Sign up',
    aiFuture: 'AI Feature',
    photoFaceSwap: 'Photo Face Swap',
    photoMutipleFaceSwap: 'Multiple Picture Face Swap',
    videoFaceSwap: 'Video Face Swap',
    videoMutipleFaceSwap: 'Multiple Video Face Swap',
    multipleFaceSwap: 'Multiple Face Swap',
    aIGirlGenerator: 'AI Image Generator',
    aIImageGenerator: 'AI Image Generator',
    language: 'Language',
    support: 'Support',
    userPolicy: 'User Policy',
    privacyPolicy: 'Privacy Policy',
    helpAndFAQS: 'Help & FAQS',
    siteMap: 'SiteMap',
    bussiness: 'Bussiness',
    affiliateProgram: 'Affiliate Program',
    api: 'API',
    contactUs: 'Contact Us',
    aiFaceFunTitle: 'AI Video Face Swap Online',
    aiFaceFunSubTitle: 'Instantly swap faces with Beauty AI Video Face Swap. Change faces in photos and videos to anyone you choose. Create funny memes with our AI-powered product.',
    startFaceButtonTitle: 'Start Face Swapping',
    details: 'Details  >',
    chooseFace: 'Choose Face',
    faceSwaps: 'Face Swaps',
    faceswapUploadArea: {
        tab1Desc1: 'Upload original photo to face swap into.',
        tab1Desc2: 'Drag and drop photo or Choose Photo',
        tab2Desc1: 'Upload original picture to face swap into.',
        tab2Desc2: 'Drag and drop picture or Choose Picture',
        tab3Desc1: 'Upload original video to face swap into.',
        tab3Desc2: 'Drag and drop video or Choose Video',
        tab4Desc1: 'Upload original video to face swap into.',
        tab4Desc2: 'Drag and drop video or Choose Video',
        tab1title: '3 steps to Swap Face in Photos or Pictures online',
        tab1step1: '1. Upload your original face photo.',
        tab1step2: '2. Upload an target face photo of the person that will replace the face in the original photo. You can also choose from the provided photo demos.',
        tab1step3: '3. Start Face Swap Photo, then Preview and Download HD results.',
        tab2title: '3 steps to Swap Mutiple Face in Pictures online',
        tab2step1: '1. Upload your original face picture.',
        tab2step2: '2. Upload an target face picture of the person that will replace the face in the original picture. You can also choose from the provided picture demos.',
        tab2step3: '3. Start Face Swap Picture, then Preview and Download HD results.',
        tab3title: '3 steps to Swap Face in Videos online',
        tab3step1: '1. Upload your original face video.',
        tab3step2: '2. Upload an target face of the person that will replace the face in the original video. You can also choose from the provided demos.',
        tab3step3: '3. Start Video Face Swap, then Preview and Download HD results.',
        tab4title: '3 steps to Swap Face in Videos online',
        tab4step1: '1. Upload your original face video.',
        tab4step2: '2. Upload an target face of the person that will replace the face in the original video. You can also choose from the provided demos.',
        tab4step3: '3. Start Video Face Swap, then Preview and Download HD results.',
        chooseFiles: 'Choose Files',
        photoLimit: 'Max 10MB',
        videoLimit: 'Max 500M, 10Min',
        uploading: 'Uploading <xx1>',
        faceAnalyzing: 'Face analyzing',
        queuingAbout: 'Queuing...About <xx1>s',
        analyzingAbout: 'Analyzing...About <xx1>s',
        somethingError: 'Something went wrong, please try again later.',
    },
    faceswapUploadFilePopup: {
        title: 'All Analyzed Files',
        subtitle: 'Files stored only for 7 days',
        try: 'Try',
    },
    faceswapFaceRecognized: 'Face Recognized',
    swapFaces: 'Swap faces',
    useCredit: 'use <xx1> credit',
    useCredits: 'use <xx1> credits',
    faceSwapDetail: {
        firstSectionItem1Title: 'Video Face Swap Introduction',
        firstSectionItem1Text: 'An AI face changer is a software application that uses artificial intelligence (AI) and machine learning techniques to modify or swap faces in images and videos.',
        firstSectionItem2Title: 'Target Audience',
        firstSectionItem2Text: 'The primary users of this technology are expected to be face-swapping researchers, developers involved in face-swapping, and face-swapping enthusiasts.',
        firstSectionItem3Title: 'Introduction to AI Video Face Swap',
        firstSectionItem3Text: 'Video Face Swap is a popular video trend where you switch your face with someone else\'s. With Beauty AI, you can swap faces in videos with friends, celebrities, or even historical figures. Create funny videos, memes, and collages, or simply have fun experimenting with your appearance.',
        secondSectionTitle: 'What You Can Do with Video Face Swap',
        secondSectionItem1Title: 'Explore Possibilities',
        secondSectionItem1Text: 'Discover the intriguing possibilities and unexpected applications of this innovative technique. Experience a world where facial transformations blend creativity and technology, offering more than you initially expect.',
        secondSectionItem2Title: 'Video Face Swap',
        secondSectionItem2Text: 'Dive into the intriguing possibilities and unexpected applications of this technique. Experience a world of facial transformations that extend far beyond initial expectations, blending creativity with technology.',
        secondSectionItem3Title: 'Gender Swap',
        secondSectionItem3Text: 'Join the gender swap trend on TikTok, Snapchat, and Instagram. Share your gender-swapped photos to attract likes and fans, giving your social media presence a vibrant update.',
        secondSectionItem4Title: 'Celebrity Video Face Swap',
        secondSectionItem4Text: 'Transform your appearance by placing your face onto any celebrity\'s video. Blend your features with those of a celebrity to create intriguing content.',
        secondSectionItem5Title: 'Meme Face Swapping',
        secondSectionItem5Text: 'Create funny memes using the face swap feature. Generate fresh, comedic memes with your own face or any other visage and share the laughter across social media platforms.',
        secondSectionItem6Title: 'Swap with Movie Roles',
        secondSectionItem6Text: 'Swap faces with your favorite movie characters, becoming a fearless superhero or a charming romantic lead. Share your images to excite the movie fandom.',
        secondSectionItem7Title: 'AI Face Swap on Paintings',
        secondSectionItem7Text: 'Replace faces in classic paintings with your own. Share these unique creations with friends and on social media, cultivating a distinctive personal style.',
    },
    faceswapIntroduce: {
        sectionTitle: 'AI Face Swap Online',
        item1Title: 'Face Swap Photo Maker',
        item1Subtitle: 'Free online face changer that allows you to swap faces in pictures.',
        item1ActionText: 'Face Swap Now',
        item2Title: 'Multiple Face Swap Photo Generator',
        item2Subtitle: 'Swap multiple faces in family photos, wedding photos, party photos on PC or on iPhone. etc.',
        item2ActionText: 'Multiple Face Swap Now',
        item3Title: 'Video Face Swap',
        item3Subtitle: 'Swap faces in videos, enhancing filmmaking or creating content for social media.',
        item3ActionText: 'Face Swap Now',
        item4Title: 'Multiple Video Face Swap',
        item4Subtitle: 'Replacing multiple faces in videos with ease, offering a fun and creative way to transform visual content.',
        item4ActionText: 'Multiple Face Swap Now',
    },
    faceswapGuide: {
        tab1SectionTitle: 'How AI Picture Face Swap Works',
        tab1item1Title: 'Upload your original face photo',
        tab1item1Subtitle: 'Start by uploading a photo contains your face to serve as the input for face swapping. ',
        tab1item2Title: 'Choose target face photo for face swapping',
        tab1item2Subtitle: 'Upload an target face photo of the person that will replace the face in the original photo. You can also choose from the provided photo demos. ',
        tab1item3Title: 'Start Face Swap Photo',
        tab1item3Subtitle: 'Click "Face Swap Now" to let the face swapper work its magic. It takes only 5-10 seconds to complete  face swapping.',
        tab1item4Title: 'Preview and Download HD result',
        tab1item4Subtitle: 'Once the face swapping is complete, download the resulting image with the swapped faces for personal use or sharing.',

        tab2SectionTitle: 'How AI Picture Face Swap Maker Works',
        tab2item1Title: 'Upload your original face picture',
        tab2item1Subtitle: 'Start by uploading a picture contains your face to serve as the input for face swapping. ',
        tab2item2Title: 'Choose target face picture for face swapping',
        tab2item2Subtitle: 'Upload an target face picture of the person that will replace the face in the original picture. You can also choose from the provided picture demos. ',
        tab2item3Title: 'Start Face Swap Picture',
        tab2item3Subtitle: 'Click "Face Swap Now" to let the face swapper work its magic. It takes only 5-10 seconds to complete face swapping. ',
        tab2item4Title: 'Preview and Download HD result',
        tab2item4Subtitle: 'Once the face swapping is complete, download the resulting picture with the swapped faces for personal use or sharing.',

        tab3SectionTitle: 'How AI Video Face Swap Works',
        tab3item1Title: 'Upload your original face video',
        tab3item1Subtitle: 'Start by uploading a video contains your face to serve as the input for face swapping. ',
        tab3item2Title: 'Choose target face photo for face swapping',
        tab3item2Subtitle: 'Upload an target face of the person that will replace the face in the original video. You can also choose from the provided demos.',
        tab3item3Title: 'Start Video Face Swap',
        tab3item3Subtitle: 'Click "Face Swap Now" to let the face swapper work its magic. It takes only 5-10 seconds to complete face swapping. ',
        tab3item4Title: 'Preview and Download HD result',
        tab3item4Subtitle: 'Once the face swapping is complete, download the resulting video with the swapped faces for personal use or sharing.',

        tab4SectionTitle: 'How AI Video Face Swap Works',
        tab4item1Title: 'Upload your original face video',
        tab4item1Subtitle: 'Start by uploading a video contains your face to serve as the input for face swapping. ',
        tab4item2Title: 'Choose target face photo for face swapping',
        tab4item2Subtitle: 'Upload an target face of the person that will replace the face in the original video. You can also choose from the provided demos.',
        tab4item3Title: 'Start Video Face Swap',
        tab4item3Subtitle: 'Click "Face Swap Now" to let the face swapper work its magic. It takes only 5-10 seconds to complete face swapping. ',
        tab4item4Title: 'Preview and Download HD result',
        tab4item4Subtitle: 'Once the face swapping is complete, download the resulting video with the swapped faces for personal use or sharing.',

        sectionActionText: 'Start Face Swapping',
        howToUse: 'How to Use  >',
    },
    review: {
        title: 'They Love Us, You Will Too',
        subtitle: 'Join the team of more than 10,000 partners around the world. The rewards they receive from the website are continuous and stable.',
        data1: {
            title: 'I love using Beauty AI!',
            content: 'I love using Beauty AI! It\'s my favorite tool for adding a fun twist to my photos. Whether I\'m swapping faces with friends or trying out different celebrity looks, the results are always hilarious and entertaining.',
            name: 'Cliff Quan',
            dateText: 'March 2, 2024',
        },
        data2: {
            title: 'As a makeup enthusiast',
            content: 'As a makeup enthusiast, I use Beauty AI to try out new looks before committing to them in real life. Swapping my face with different characters helps me visualize how various styles will look on me. It\'s a fun and practical tool for anyone who loves experimenting with makeup!',
            name: 'Anli Brevo',
            dateText: 'May 16, 2024',
        },
        data3: {
            title: 'It\'s easy to use video face swap',
            content: 'It\'s easy to use video face swap function to switch your face with someone else\'s. Whether you want to put a movie star\'s face on your head or replace your face with a basketball player\'s, this tool does the job perfectly. Keep it up!',
            name: 'Ada Wu',
            dateText: 'April 19, 2024',
        },
        data4: {
            title: 'This amazing tool intelligently',
            content: 'This amazing tool intelligently detects faces in photos and seamlessly replaces them with any face you choose. It\'s like having your own personal creative studio at your fingertips! With Beauty AI, swapping faces in any photo is effortless, allowing me to unleash my imagination without any hassle.',
            name: 'Mario Kuper',
            dateText: 'January 24, 2024',
        },
        data5: {
            title: 'I\'ve always found photo editing challenging',
            content: 'I\'ve always found photo editing challenging, but Beauty AI makes it easy! I love experimenting with the different ai face swap options and seeing the hilarious combinations. It\'s a fantastic way to add humor to my photos and keep my friends laughing.',
            name: 'Chris jin',
            dateText: 'December 12, 2023',
        },
        data6: {
            title: 'AI face swap is ideal for creating funny memes',
            content: 'AI face swap is ideal for creating funny memes, headshots, and face swap videos. Beauty AI is perfect not only for entertainment but also for engaging content creation. It helps you capture more attention on social media.',
            name: 'Kotláriková',
            dateText: 'March 24, 2024',
        },
    },
    faq: {
        title: 'Frequently Asked Questions',
        details: 'Details  >',
        data1: {
            question: 'What is Beauty AI?',
            answer: 'Beauty AI is a visual AI platform leveraging advanced technology to automatically swap faces, retouch, enhance, colorize, correct, and cartoonize images without the need for complex photo editing tools like Photoshop. Utilizing cutting-edge AI algorithms, Beauty AI streamlines photo processing, saving time, energy, and costs for both individual and business users requiring bulk photo editing.',
        },
        data2: {
            question: 'How does an AI Face Swapping tool work?',
            answer: 'AI Face Swapping relies on sophisticated artificial intelligence algorithms to meticulously analyze source images, recognize facial features, and seamlessly replace them with another face while maintaining natural lighting, angles, and expressions. This results in AI-generated, realistic face swaps.',
        },
        data3: {
            question: 'How to use the Video Face Swap?',
            answer: 'Firstly, select a clear front-facing image as your base. Secondly, choose the face you want to swap with, whether it\'s yours or a celebrity\'s. Thirdly, initiate the replacement process, and the chosen face will be seamlessly placed onto the base image.',
        },
        data4: {
            question: 'Can I replace my face with any image?',
            answer: 'Yes, indeed. Beauty AI allows you to replace your face with any selected image for Free. For example, you can place a male face on a female portrait or an aging face on a baby\'s photo. The possibilities are endless, allowing you to become anyone you desire without limitations.',
        },
        data5: {
            question: 'How to achieve the best result?',
            answer: 'To achieve optimal results with face swap, follow these tips: Select a clear photo with well-defined facial features, avoiding blurry images. Ensure there is only one face in the photo, and the face is directly facing the camera without obstruction from objects like hands.',
        },
        data6: {
            question: 'Can I swap faces online for free?',
            answer: 'Absolutely! You can swap faces online for free using Beauty AI. We offer both free and premium plans to cater to your needs. As a new user, you\'ll receive three credits upon registration, providing full access to our face swapping service and additional features such as face cutting and avatar creation.',
        },
    },
    faceswapToHistory: {
        tab1or2header: 'We have kept the History of face swap photos for you,',
        tab3or4header: 'We have kept the History of face swap videos for you,',
        footer: 'more detail to see History.',
    },
    faceswapOnlineSection: {
        tab1Title: 'AI Photo Face Swap Online',
        tab2Title: 'AI Picture Face Swap Online',
        tab3Title: 'AI Video Face Swap Online',
        tab4Title: 'AI Face Swap Video Maker Online',
        tab1Desc: 'Instantly swap face with Beauty AI Photo Face Swap. Replace face in photos and videos to anyone you choose. Create funny memes with our.',
        tab2Desc: 'Instantly swap faces with Beauty AI Picture Face Swap. Change faces in Pictures and videos to anyone you choose. Create funny memes with our ai art generator face swap.',
        tab3Desc: 'Instantly swap faces with Beauty AI Video Face Swap. Change faces in photos and videos to anyone you choose. Create funny memes with our AI-powered product.',
        tab4Desc: 'Instantly swap faces with Beauty AI Video Face Swap. Change face in videos to anyone you choose. Create funny memes with our ai art generator face swap maker.',
        action: 'Start Face Swapping',
    },
    choose: {
        delText: 'Are you sure to delete this face?',
        leftBtnText: 'Continue',
        rightBtnText: 'Cancel',
        overFaces: 'Uploading faces have reached limit, please delete other faces to continue.',
        faceAn: 'Face analyzing...',
        facesuccess: 'Face analysis success',
        facefailed: 'Face analysis Failed',
        feedback: 'Thanks for your feedback!',
        copy: 'Copy successful!',
        batchsupport: 'Batch Supported! You can make new face swap no matter the current result.',
        batchsupportAIGirl: 'Batch Supported! You can make new AI Image no matter the current result.',
        submitting: 'Submitting',
        queuing: 'Queuing',
        creating: 'Creating',
        downloading: 'Downloading',
        retryText: 'Something went wrong, please try again later.',
        retry: 'Retry',
        emailTip: 'Please enter a valid domain name suffix (such as .com, .net, etc.)',
        noaccount: 'No account yet? ',
        enterPassWord: 'Enter Password',
        passWordErr: 'Set password 8 characters minimum',
        loginPassWordErr:'Password must be at least 8 characters',
        forgotpass: 'Forgot password?',
        googlesign: 'Login with Google Sign-In?',
        resetpassword: 'Reset Password',
        readyaccount: 'Already have an account?',
        confirm: 'Confirm',
        resetpasswordsuccess: 'Reset Password Success!',
        senderror: 'Send Error!',
        sendsuccess: 'Send Success!',
        signupfree: 'Sign Up for Free ',
        signupsuccess: 'Sign Up Success!',
        sendcode: 'Send Code',
        loginsuccess: 'Log in successfully!',
        logingerror: 'Login Error!',
        aboutS: 'about {leftTime}s',
        keepPatient: 'Keep patient!',
        needMoretime: 'Need more time!',
        aimostDoneKeep: 'Almost done! Keep patient!',
        usernamePlace: 'user{\'@\'}example.com',
        emailCode: 'Verification code',
    },
    feedbackDialog: {
        title: 'We need your feedback to improve Beauty AI.',
        subTitle1: 'Want to improve?',
        option1:'Inaccurate Face analysis about uploaded files',
        option2:'Not looks like',
        option3:'Face detail issues( Distorted, eyes, mouth etc.)',
        option4:'Slow Time',
        option5:'Result Sharpness',
        txtOption1: 'Sensitive or disturbing content',
        txtOption2: 'Violation of terms of service',
        txtOption3: 'Prompt Relevance',
        txtOption4: 'Face Distorted',
        txtOption5: 'Image Content',
        txtOption6: 'Image Sharpness',
        txtOption7: 'Slow Time',
        subTitle2: 'Want to say more?',
        placeholder: 'Other suggestions...',
        submit: 'Submit',
        reportTitle: 'Report the photo',
        reportOptionTitle: 'Category',
        reportOption1: 'Sensitive or disturbing content',
        reportOption2: 'Spam',
        reportOption3: 'Violation of Terms of Use',
    },
    infoDialog: {
        advancedFeatures: 'Advanced Features',
        advancedFeaturesText: 'Please pay for our plans to unlock realistic face swap feature in video.',
        choosePlan: 'Choose Plan',
        insufficientCredits: 'Insufficient Credits',
        insufficientCreditsText: 'Your credit balance:',
        getMoreCredits: 'Get more Credits',
        videoTooBig: 'Video is too big',
        videoTooLong: 'Video is too long',
        videoCannotExceed: 'Video cannot exceed',
        inFileSize: 'in file size',
        selectAnotherFile: 'Select another file',
        photoTooBig: 'Photo is too big',
        photoCannotExceed: 'Photo cannot exceed',
        tenMin: '10Min',
        deleteFile: 'Are you sure to delete this file?',
        cancel: 'Cancel',
        confirm: 'Confirm',
    },
    fileUpload: {
        demo: 'Demo',
        historicalFiles: 'All Files',
        uploading: 'Uploading',
        queuing: 'Queuing',
        analyzing: 'Analyzing',
        photoFileFormatError: 'File format is not supported! Please upload photo.',
        videoFileFormatError: 'File format is not supported! Please upload video.',
        resolutionExceed1920 : 'The resolution cannot exceed 1920*1920',
        videoIsProcessed: 'Video is being processed, it may takes a few moment because of resources consuming, but everything is ok. Please be patient!',
        filesLoading: 'Loading,try again later',
    },
    submitSamePhotoTaskHint: 'Warning! You have submitted the same faces with same photo, please change your faces to submit. (Please note this submission doesn\'t cost your credit)',
    submitSameVideoTaskHint: 'Warning! You have submitted the same faces with same video, please change your faces to submit. (Please note this submission doesn\'t cost your credit)',
    allRightsReserved: 'All rights reserved.',
    aiGirlHowToUse: 'AI Image How to Use',
    aiGirlFAQDetails: 'AI Image FAQ Details',
    lookslikeUploadOpps: {
        title: 'Oops!',
        subtitle: 'Oops! Exceed free credits limits!\nPlease subscribe plan to use all features unlimitedly.',
        unlockLimit: 'Unlock limit',
    },
    selectStyle: 'Select Style',
};
