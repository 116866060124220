<template>

    <template v-if="logined">
        <div class="account-popup_n">
            <div class="sub">
                <img class="icon" :src="assetImage('crown_w')" />
                <div v-if="true" style="display: flex;flex-direction: column;">
                    <div>
                        {{ $t('getMoreCredits') }}
                    </div>
                    <div style="height: 2px;"></div>
                    <div>
                        {{ $t('left') }}:{{ allLeftNumber }}
                    </div>
                </div>
                <div v-else>
                    {{ $t('upgradeToPremium') }}
                </div>

                <div style="flex:1"></div>
                <div class="go" @click="pay">
                    {{ $t('go') }}
                </div>

            </div>
            <div class="user-title" @click="pay">
                <img class="icon" :src="assetImage('icon_pricing')" />
                {{ $t('pricing') }}
            </div>
            <div class="user-title" @click="goFreeCredits">
                <img class="icon" :src="assetImage('icon_free_credits')" />
                {{ $t('freeCredits') }}
            </div>
            <div class="user-title" @click="goHistory">
                <img class="icon" :src="assetImage('icon_history')" />
                {{ $t('history') }}
            </div>
            <div class="user-title" @click="goToAccount">
                <img class="icon" :src="assetImage('person2')" />
                {{ $t('accountInformation') }}
            </div>
            <div class="user-title" @click="signOut">
                <img class="icon" :src="assetImage('logout')" />
                {{ $t('signOut') }}
            </div>
        </div>

    </template>

    <template v-else>
        <div class="narrow-tool-container">
            <div style="cursor: pointer; display: flex; align-items: center; height: 36px; margin-top: 0px; padding-left: 7px;"
                @click="loginClick">
                <img style="width: 22px; height: 22px;" :src="assetImage('user_login_avatar')" alt="">
                <p
                    style="margin-top: 3px; margin-bottom: 3px; margin-left: 6px; font-size: 14px; font-weight: 500; font-family: 'PingFang SC';">
                    {{ $t('login') }}</p>
            </div>
            <div style="cursor: pointer; display: flex; align-items: center; height: 36px; margin-top: 10px; padding-left: 7px;"
                @click="signinClick">
                <img style="width: 22px; height: 22px;" :src="assetImage('user_signup_icon')" alt="">
                <p
                    style="margin-top: 3px; margin-bottom: 3px; margin-left: 6px; font-size: 14px; font-weight: 500; font-family: 'PingFang SC';">
                    {{ $t('signup') }}</p>
            </div>
        </div>
    </template>

</template>

<script>

export default {
    data () {
        return {};
    },
    props: {
        logined: Boolean,
        userHasPaid:Boolean,
        allLeftNumber:Number,
    },
    methods: {
        assetImage (name) {
            return this.$utility.assetImage(name);
        },
        signinClick () {
            this.$emit('signinClick');
        },
        loginClick () {
            this.$emit('loginClick');
        },
        signOut () {
            this.$emit('signOut');
        },
        pay () {
            this.$emit('pay');
        },
        goToAccount () {
            this.$emit('goToAccount');
        },
        goHistory () {
            window.open(`${window.location.protocol}//${window.location.host}/history`, '_blank');
        },
        goFreeCredits () {
            window.open(`${window.location.protocol}//${window.location.host}/invite`, '_blank');
        },
    },
};
</script>

<style lang="scss">

.account-popup_n {
    width: 264px;
    height: 346px;
    background: #27282D;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    position: fixed;
    top: 56px;
    right: 16px;
    display: flex;
    box-sizing: border-box;
    padding: 12px;
    color: #FFFFFF;
    font-family: 'PingFang SC';
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;

    .sub {
        width: 240px;
        height: 46px;
        background: linear-gradient(11deg, #D33A31 0%, #FF9441 100%);
        border-radius: 7px;
        backdrop-filter: blur(10px);
        display: flex;
        flex-direction: row;
        padding: 0 10px 0 0;
        justify-content: start;
        align-items: center;
        box-sizing: border-box;
    }

    .user-title {
        display: flex;
        flex-direction: row;
        width: 240px;
        height: 36px;
        align-items: center;

        &:hover {
            background: rgba(255, 255, 255, 0.1);
            border-radius: 10px;
        }
    }

    .icon{
        width: 22px; height: 22px;margin: 0 10px
    }

    .go {
        width: 43px;
        height: 26px;
        border-radius: 20px;
        background: #FFFFFF;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 15px;
        color: rgba(211, 58, 49, 1);
        display: flex;
        justify-content: center;
        align-items: center;

    }
}

.narrow-tool-container {
    background-color: #27282D;
    width: 216px;
    padding: 10px 12px;
    position: absolute;
    top: 56px;
    right: 13px;
    border-radius: 14.4px;
}

.narrow-tool-lang-container {
    background-color: #3D3D42;
    border-radius: 7.2px;
    width: 216px;
}

.narrow-tool-lang-ul {
    margin: 0% 0%;
    padding: 0% 0%;
}

.narrow-tool-lang-li {
    list-style-type: none;
    cursor: pointer;
    font-size: 12px;
    font-weight: 400;
    font-family: 'PingFang SC';
    margin-left: 40px;
    width: 226px;
    vertical-align: middle;

}
</style>