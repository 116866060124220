<template>
    <div style="position: relative;" class="video_box">
        <video class="hls_video" ref="hls_video" :offstage="hlsSDKFailed">
        </video>
        <div class="hls_failed" v-if="hlsSDKFailed" @click="failedRetry">
            <img class="icon" :src="retry_url">
            <p class="txt">{{ "Retry" }}</p>
        </div>
    </div>
</template>

<script setup>
import HLSLoader from '@/script/loadHls.js'
import { ref, computed, watch, getCurrentInstance, defineProps, onMounted, onBeforeUnmount } from 'vue'

const { $utility } = getCurrentInstance().appContext.config.globalProperties
let hlsHandler = null
const hlsSDKStatus = ref(0)
const retry_url = ref($utility.assetImage('failed_retry'))
let retryTime = 10
const hls_video = ref(null)

const { videoSrc, autoPlay } = defineProps({
    videoSrc: String,
    autoPlay: {
        type: Boolean,
        default: true,
    },
});

const hlsSDKFailed = computed(() => {
    return hlsSDKStatus.value < 0
})

watch(hlsSDKStatus, () => {
    if (hlsSDKStatus < 0) {
        hls_video.value.removeAttribute('controls')
    }else {
        hls_video.value.controls = true
    }
})

onMounted(() => {
    hls_video.value.controls = true
    hls_video.value.preload = "auto"
    hls_video.value.autoplay = autoPlay
    hls_video.value.setAttribute('playsinline', '')
    hls_video.value.setAttribute('webkit-playsinline', '')
    hls_video.value.setAttribute('x5-playsinline', '')
    hls_video.value.setAttribute('x-webkit-airplay', '')
    initHls()
})

onBeforeUnmount(() => {
    dispose()
})

function initHls() {
    hlsSDKStatus.value = 0
    HLSLoader().then((hls) => {
        hlsHandler = hls
        initMedia()
    }).catch(() => {
        hlsSDKStatus.value = -1
    })
}
function initMedia() {
    if (window.Hls.isSupported()) {
        var config = {
            autoStartLoad: true,
            manifestLoadingTimeOut: 120000,
            manifestLoadingMaxRetry: 10,
            manifestLoadingRetryDelay: 1000,
            manifestLoadingMaxRetryTimeout: 120000,
            levelLoadingTimeOut: 120000,
            levelLoadingMaxRetry: 10,
            levelLoadingRetryDelay: 1000,
            levelLoadingMaxRetryTimeout: 120000,
            fragLoadingTimeOut: 120000,
            fragLoadingMaxRetry: 10,
            fragLoadingRetryDelay: 1000,
            fragLoadingMaxRetryTimeout: 120000,
        };
        hlsHandler = new window.Hls(config)
        hlsHandler.on(window.Hls.Events.ERROR, hlsErrorListener)
        hlsHandler.attachMedia(hls_video.value);
        hlsHandler.loadSource(videoSrc);
    } else if (hls_video.value.canPlayType('application/vnd.apple.mpegurl')) {
        hls_video.value.src = videoSrc;
    }
}

function hlsParsedListener() {
    hlsSDKStatus = 1
}
function hlsErrorListener(_, data) {
    if (data.details == window.Hls.ErrorDetails.INTERNAL_EXCEPTION == window.Hls.ErrorDetails.KEY_LOAD_ERROR) {
        if (retryTime > 0) {
            retryTime -= 1
            dispose()
            setTimeout(() => {
                initMedia()
            }, 1000)
        }else {
            dispose()
            hlsSDKStatus = -2
        }
    }
}
function failedRetry() {
    retryTime = 10
    if (hlsSDKStatus == -1) {
        initHls()
    }else if (hlsSDKStatus == -2) {
        hlsSDKStatus = 0
        initMedia()
    }
}
function pause() {
    hls_video.value.pause()
}
function dispose() {
    if (hlsHandler) {
        hlsHandler.off(window.Hls.Events.MANIFEST_PARSED, hlsParsedListener)
        hlsHandler.off(window.Hls.Events.ERROR, hlsErrorListener)
        hlsHandler.destroy()
    }
    if (hls_video.value) {
        hls_video.value.removeAttribute('src')
        hls_video.value.load()
    }
}

defineExpose({
    pause,
})
</script>

<style scoped lang="scss">
.hls_video {
    width: 100%;
    height: 100%;
}
.video_box {
    width: 100%;
    height: 100%;
}
.hls_failed {
    background-color: transparent;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .icon {
        width: 72px;
        height: 60px;
    }
    .txt {
        font-size: 16px;
        font-weight: 700;
        color: #888A98;
        margin: 14px 0 0;
    }
}
@media (max-width: 1070px) {
    .hls_failed {
        .icon {
            width: 40px;
            height: 33px;
        }
        .txt {
            color: #888A98;
            font-size: 12px;
            font-weight: 500;
        }
    }
}
.hls_video[offstage="true"] {
    display: none;
}
</style>
