import { API } from '@/script/api';
import { reactive, ref, watch } from 'vue';
import { WSChannel, WSChannelEvent } from '@/script/ws_channel';
import { ENV } from '@/script/proj_env';
import { devMockUploadFiles, prodMockUploadFiles, devMockFaces, prodMockFaces } from '@/vm/mock_data';
import { User } from '@/script/user';
import { AnalyticHub } from '@/script/analytic';

export class ReplaceFacesVM {
    constructor () {
        this.loadStatus = ref(API.REQUEST_STATUS_NONE);
        this.faces = reactive([]);
        watch(User.getInstance.isLogin, () => {
            this.loadFaces();
        });
    }

    /// 加载人脸头像列表
    async loadFaces () {
        this.loadStatus.value = API.REQUEST_STATUS_LOADING;
        if (!User.getInstance.isLogin.value) {
            this.faces.splice(0, this.faces.length);
            for (const face of (ENV.isProd ? prodMockFaces : devMockFaces)) {
                this.faces.push(face);
            }
            this.loadStatus.value = API.REQUEST_STATUS_SCUCESS;
            return;
        }
        try {
            let resp = await API.facesList();
            if (resp.code == 200) {
                this.faces.splice(0, this.faces.length);
                // Vocimage组件需要key
                resp.data.faces = resp.data.faces.map(item => ({
                    key: item.id,
                    ...item,
                }));
                for (const face of resp.data.faces) {
                    this.faces.push(face);
                }
                this.loadStatus.value = API.REQUEST_STATUS_SCUCESS;
            }else {
                this.loadStatus.value = API.REQUEST_STATUS_FAILED;
            }
        }catch (_) {
            this.loadStatus.value = API.REQUEST_STATUS_FAILED;
        }
    }

    /// 删除人脸头像
    async deleteFace (value) {
        let deleteIndex = -1;
        let deleteFace = {};
        try {
            deleteIndex = this.faces.findIndex(item => item.id == value);
            if (deleteIndex === -1) return false;
            deleteFace = this.faces[deleteIndex];
            this.faces.splice(deleteIndex, 1);
            let resp = await API.faceDelete(deleteFace.id);
            return resp.code == 200;
        }catch (err) {
            return false;
        }
    }

    /// 上传+识别人脸头像
    async uploadFace (file) {
        try {
            let resp = await API.faceUpload(file);
            if (resp.code === 200 && resp.data.faces.length) {
                this.faces.unshift(...resp.data.faces);
                return resp.data.faces;
            }
            return [];
        }catch (_) {
            return [];
        }
    }
}
export const replaceFacesVm = new ReplaceFacesVM();


export class UploadFileDataStatus {
    static UPLOADFILE_STATUS_NONE = 0;
    static UPLOADFILE_STATUS_UPLOAD = 1;
    static UPLOADFILE_STATUS_QUEUE = 2;
    static UPLOADFILE_STATUS_ANALYSE = 3;
    static UPLOADFILE_STATUS_DONE = 4;
    static UPLOADFILE_STATUS_FAILED = -1;

    constructor () {
        this.status = ref(UploadFileDataStatus.UPLOADFILE_STATUS_NONE); // 整体上传+分析中当前的阶段
        this.failedMessage = ''; // status == UPLOADFILE_STATUS_FAILED 时错误消息，可能为空串
        this.progress = ref(0.0); // 上传文件进度 0 ~ 0.99
        this.currentLeftTime = ref(0); // 当前倒计时秒数
        this.queueTime = 0; // 预计排队时间，单位s
        this.analyseTime = 0; // 预计分析时间，单位s
        this.taskId = ''; // 上传任务id
        this.timerId = null;

        watch(this.status, () => {
            this.timerDispose();
            switch (this.status.value) {
                case UploadFileDataStatus.UPLOADFILE_STATUS_QUEUE: {
                    this.nextTimer(this.queueTime);
                }break;
                case UploadFileDataStatus.UPLOADFILE_STATUS_ANALYSE: {
                    this.nextTimer(this.analyseTime);
                }break;
                case UploadFileDataStatus.UPLOADFILE_STATUS_DONE:
                case UploadFileDataStatus.UPLOADFILE_STATUS_FAILED: {
                    this.reset();
                }
                default:break;
            }
        });
    }

    reset () {
        this.progress.value = 0.0;
        this.currentLeftTime.value = 0;
        this.queueTime = 0;
        this.analyseTime = 0;
        this.taskId = '';
        this.timerDispose();
    }

    timerDispose () {
        if (this.timerId) {
            clearInterval(this.timerId);
            this.timerId = null;
        }
    }

    nextTimer (seconds) {
        this.currentLeftTime.value = seconds;
        this.timerId = setInterval(() => {
            if (this.currentLeftTime.value > 0) {
                this.currentLeftTime.value -= 1;
            }
        }, 1000);
    }
}


export class UploadFilesVM {
    constructor () {
        this.acceptFileType = 'image';
        this.tabCategory = 0;
        // 文件列表
        this.files = reactive([]);
        // 拉取数据状态
        this.loadStatus = ref(API.REQUEST_STATUS_NONE);
        // 上传状态
        this.uploadFileStatus = new UploadFileDataStatus();
        // 分析人脸通道
        this.detectFaceChannel = new WSChannel(ENV.currentConfig.swapAPIHost, '/ai/analyser_channel');
        
        this.backupInterval = 5;
        this.backupTime = 0;
        this.backupTimerId = null;
    }

    /// 加载历史上传文件数据
    async loadFiles () {
        this.loadStatus.value = API.REQUEST_STATUS_LOADING;
        if (!User.getInstance.isLogin.value) {
            this.files.splice(0, this.files.length);
            const mockFiles = ENV.isProd ? prodMockUploadFiles : devMockUploadFiles;
            for (const file of mockFiles) {
                if (file.file.fileType == this.acceptFileType) {
                    if (file.demoFlag && file.demoIndex == this.tabCategory) {
                        this.files.push(file);
                    }else if (!file.demoFlag) {
                        this.files.push(file);
                    }
                }
            }
            this.loadStatus.value = API.REQUEST_STATUS_SCUCESS;
            return;
        }
        try {
            let resp = await API.swapUploadHistory();
            if (resp.code == 200) {
                this.files.splice(0, this.files.length);
                for (const file of resp.data) {
                    if (file.file.fileType == this.acceptFileType) {
                        if (file.demoFlag && file.demoIndex == this.tabCategory) {
                            this.files.push(file);
                        }else if (!file.demoFlag) {
                            this.files.push(file);
                        }
                    }
                }
                this.loadStatus.value = API.REQUEST_STATUS_SCUCESS;
            }else {
                this.loadStatus.value = API.REQUEST_STATUS_FAILED;    
            }
        }catch (_) {
            this.loadStatus.value = API.REQUEST_STATUS_FAILED;
        }
    }

    /*
    删除历史上传文件
    value: 索引 | 数组元素
    */
    async deleteFile (value) {
        let deleteIndex;
        let deleteFile;
        try {
            if (typeof value === 'number') {
                deleteIndex = value;
                deleteFile = this.files[value];
            }else {
                deleteIndex = this.files.indexOf(value);
                deleteFile = value;
            }
            this.files.splice(deleteIndex, 1);
            let resp = await API.swapDeleteUploadHistoryFile(deleteFile.taskId);
            return resp.code == 200;
        }catch (_) {
            return false;
        }
    }

    /// 识别+上传换脸历史文件
    async uploadFile (file) {
        return new Promise(async (resolve, reject) => {
            try {
                if (this.backupTimerId) {
                    clearTimeout(this.backupTimerId);
                }
                this.uploadFileStatus.status.value = UploadFileDataStatus.UPLOADFILE_STATUS_UPLOAD;
                let resp = await API.swapUploadDetectFace({
                    progress: (value) => {
                        this.uploadFileStatus.progress.value = Math.min(0.99, value);
                    },
                }, file);
                if (resp.code == 200) {
                    this.uploadFileStatus.taskId = resp.data.taskId;
                    this.uploadFileStatus.queueTime = resp.data.leftTime;
                    this.uploadFileStatus.analyseTime = resp.data.paintingTime;
                    this.uploadFileStatus.currentLeftTime.value = this.uploadFile.queueTime;
                    this.uploadFileStatus.status.value = UploadFileDataStatus.UPLOADFILE_STATUS_QUEUE;
    
                    let success = await this.detectFaceChannel.open();
                    if (success) {
                        this.detectFaceChannel.sendVerifyMessage(this.uploadFileStatus.taskId);
                        let event = this.detectFaceChannel.listener();
                        let watchStopHandle;
                        watchStopHandle = watch(event.statusCode, async () => {
                            if (event.statusCode.value.code == WSChannel.ConnectionErrorCode) { // 通道连接错误, 使用http接口轮询查
                                watchStopHandle();
                                this.detectFaceChannel.dispose();
                                await this.backupHandler(resolve, reject, event);
                            }else if (event.statusCode.value.code < 0) { // 错误
                                watchStopHandle();
                                this.detectFaceChannel.dispose();
                                if ((typeof event.data) === 'object') {
                                    this.uploadFileStatus.failedMessage = event.data.data.message;
                                }else {
                                    this.uploadFileStatus.failedMessage = event.data;
                                }
                                this.uploadFileStatus.status.value = UploadFileDataStatus.UPLOADFILE_STATUS_FAILED;
                                reject(UploadFileDataStatus.UPLOADFILE_STATUS_FAILED);
                            }else if (event.statusCode.value.code == 2) { // 排队中
                                this.uploadFileStatus.status.value = UploadFileDataStatus.UPLOADFILE_STATUS_ANALYSE;
                            }else if (event.statusCode.value.code == 3) { // 完成
                                if (event.data.taskId == this.uploadFile.taskId) {
                                    watchStopHandle();
                                    this.detectFaceChannel.dispose();
                                    event.data.data.file.newFile = true;
                                    this.files.unshift(event.data.data); // 将识别出的数据追加到第一个
                                    this.uploadFileStatus.status.value = UploadFileDataStatus.UPLOADFILE_STATUS_DONE;
                                    resolve(UploadFileDataStatus.UPLOADFILE_STATUS_DONE);
                                }
                            }
                        });
                    }
                }else if ((typeof resp.code) === 'number') {
                    this.uploadFileStatus.failedMessage = resp.message;
                    this.uploadFileStatus.status.value = UploadFileDataStatus.UPLOADFILE_STATUS_FAILED;
                    reject(UploadFileDataStatus.UPLOADFILE_STATUS_FAILED);
                }else {
                    this.uploadFileStatus.status.value = UploadFileDataStatus.UPLOADFILE_STATUS_FAILED;
                    reject(UploadFileDataStatus.UPLOADFILE_STATUS_FAILED);
                }
            }catch (_) {
                this.uploadFileStatus.status.value = UploadFileDataStatus.UPLOADFILE_STATUS_FAILED;
                reject(UploadFileDataStatus.UPLOADFILE_STATUS_FAILED);
            }
        });
    }

    async backupHandler (resolve, reject, event) {
        let continueFlag = 1;
        const beginTimeStamp = parseInt(Date.now());
        let immediately = true;
        while (continueFlag == 1) {
            const res = await this.executeBackup(immediately);
            immediately = false;
            if (res) {
                if (res.taskStatus == 3) {
                    event.data = { data: res };
                    event.data.data.file.newFile = true;
                    this.files.unshift(event.data.data); // 将识别出的数据追加到第一个
                    this.uploadFileStatus.status.value = UploadFileDataStatus.UPLOADFILE_STATUS_DONE;
                    resolve(UploadFileDataStatus.UPLOADFILE_STATUS_DONE);
                    continueFlag = 0;
                }else if (res.taskStatus == 2) {
                    this.uploadFileStatus.status.value = UploadFileDataStatus.UPLOADFILE_STATUS_ANALYSE;
                }else if (res.taskStatus == -1) {
                    this.uploadFileStatus.failedMessage = null;
                    this.uploadFileStatus.status.value = UploadFileDataStatus.UPLOADFILE_STATUS_FAILED;
                    reject(UploadFileDataStatus.UPLOADFILE_STATUS_FAILED);
                    continueFlag = -1;
                }
            }else if (parseInt(Date.now()) - beginTimeStamp > 3600000) {
                this.uploadFileStatus.failedMessage = null;
                this.uploadFileStatus.status.value = UploadFileDataStatus.UPLOADFILE_STATUS_FAILED;
                reject(UploadFileDataStatus.UPLOADFILE_STATUS_FAILED);
                continueFlag = -1;
            }
        }
    }
    nextBackupTimeInterval () {
        const value = this.backupInterval;
        this.backupTime++;
        if (this.backupTime % 3 == 0) {
            this.backupInterval *= 2;
        } 
        return value * 1000;
    }
    async executeBackup (immediately) {
        const res = await new Promise(async (resolve, _) => {
            this.backupTimerId = setTimeout(async () => {
                let resp = null;
                try {
                    resp = await API.swapUploadTaskFetch([this.uploadFileStatus.taskId]);
                }catch (_) {}
                let task = null;
                if (resp?.code == 200) {    
                    for (const element of resp.data) {
                        if (element.taskId == this.uploadFileStatus.taskId) {
                            task = element;
                            break;
                        }
                    }
                }
                resolve(task);
            }, immediately ? 0 : this.nextBackupTimeInterval());
        });
        return res;
    }

    /// 上传分析，基于文生图taskId
    async uploadByTxtToImgTaskId (taskId) {
        return new Promise(async (resolve, reject) => {
            this.uploadFileStatus.status.value = UploadFileDataStatus.UPLOADFILE_STATUS_UPLOAD;
            try {
                let resp = await API.swapUploadDetectFace({
                    promptTaskId: taskId,
                    progress: (value) => {
                        this.uploadFileStatus.progress.value = Math.min(0.99, value);
                    },
                });
                if (resp.code == 200) {
                    this.uploadFileStatus.taskId = resp.data.taskId;
                    this.uploadFileStatus.queueTime = resp.data.leftTime;
                    this.uploadFileStatus.analyseTime = resp.data.paintingTime;
                    this.uploadFileStatus.currentLeftTime.value = this.uploadFile.queueTime;
                    this.uploadFileStatus.status.value = UploadFileDataStatus.UPLOADFILE_STATUS_QUEUE;
    
                    let success = await this.detectFaceChannel.open();
                    if (success) {
                        this.detectFaceChannel.sendVerifyMessage(this.uploadFileStatus.taskId);
                        let event = this.detectFaceChannel.listener();
                        let watchStopHandle;
                        watchStopHandle = watch(event.statusCode, () => {
                            if (event.statusCode.value.code < 0) { // 错误
                                watchStopHandle();
                                this.detectFaceChannel.dispose();
                                if ((typeof event.data) === 'object') {
                                    this.uploadFileStatus.failedMessage = event.data.data.message;
                                }else {
                                    this.uploadFileStatus.failedMessage = event.data;
                                }
                                this.uploadFileStatus.status.value = UploadFileDataStatus.UPLOADFILE_STATUS_FAILED;
                                reject(UploadFileDataStatus.UPLOADFILE_STATUS_FAILED);
                            }else if (event.statusCode.value.code == 2) { // 排队中
                                this.uploadFileStatus.status.value = UploadFileDataStatus.UPLOADFILE_STATUS_ANALYSE;
                            }else if (event.statusCode.value.code == 3) { // 完成
                                if (event.data.taskId == this.uploadFile.taskId) {
                                    watchStopHandle();
                                    this.detectFaceChannel.dispose();
                                    event.data.data.file.newFile = true;
                                    this.files.unshift(event.data.data); // 将识别出的数据追加到第一个
                                    this.uploadFileStatus.status.value = UploadFileDataStatus.UPLOADFILE_STATUS_DONE;
                                    resolve(UploadFileDataStatus.UPLOADFILE_STATUS_DONE);
                                }
                            }
                        });
                    }
                }else if ((typeof resp.code) === 'number') {
                    this.uploadFileStatus.failedMessage = resp.message;
                    this.uploadFileStatus.status.value = UploadFileDataStatus.UPLOADFILE_STATUS_FAILED;
                    reject(UploadFileDataStatus.UPLOADFILE_STATUS_FAILED);
                }else {
                    this.uploadFileStatus.status.value = UploadFileDataStatus.UPLOADFILE_STATUS_FAILED;
                    reject(UploadFileDataStatus.UPLOADFILE_STATUS_FAILED);
                }
            }catch (_) {
                this.uploadFileStatus.status.value = UploadFileDataStatus.UPLOADFILE_STATUS_FAILED;
            }
        });
    }    

    /// 获取图片元数据
    async takeImageMetadata (file) {
        return new Promise((resolve, reject) => {
            let url = URL.createObjectURL(file);
            let imgDOMElement = document.createElement('img');
            imgDOMElement.src = url;
            imgDOMElement.addEventListener('load', () => {
                imgDOMElement.remove();
                resolve({
                    type: file.type,
                    width: imgDOMElement.naturalWidth,
                    height: imgDOMElement.naturalHeight,
                    size: file.size,
                    url,
                });
            });
            imgDOMElement.addEventListener('error', (e) => {
                imgDOMElement.remove();
                reject(e);
            });
        });
    }

    /// 获取视频元数据
    async takeVideoMetadata (file) {
        return new Promise((resolve, reject) => {
            let url = URL.createObjectURL(file);
            let videoDOMElement = document.createElement('video');
            videoDOMElement.src = url;
            videoDOMElement.volume = 0;
            videoDOMElement.addEventListener('loadedmetadata', () => {
                videoDOMElement.remove();
                resolve({
                    type: file.type,
                    width: videoDOMElement.videoWidth,
                    height: videoDOMElement.videoHeight,
                    size: file.size,
                    url,
                    duration: videoDOMElement.duration,
                });
            });
            videoDOMElement.addEventListener('error', (e) => {
                videoDOMElement.remove();
                reject(e);
            });
            document.body.append(videoDOMElement);
            videoDOMElement.load();
        });
    }

    /// 重置上传文件数据状态
    resetUploadStatus () {
        this.uploadFile.reset();
    }
}
export const 
uploadFilesVM = new UploadFilesVM();


export class FaceMapping {
    constructor (sourceFace, targetFace) {
        this.sourceFace = ref(sourceFace || null);
        this.targetFace = ref(targetFace || null);
    }
}
