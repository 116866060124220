

<template>

  <div class="bs_box">
<!--    批量处理标题-->
    <div class="bs_title">
      {{$t('txtToImg.batchSize')}}
    </div>
<!--    批量处理列表选择-->
    <div class="bs_content_box">
      <div class="content_list_box">
        <div :class="['item_box', {item_box_check: txtToImgStore.currentBatchSizeIndex == index}]" v-for="(item, index) in txtToImgStore.batchSizes" :key="index" @click="checkBatch(item, index)">
            {{item}}
        </div>
      </div>
    </div>
  </div>

</template>

<script setup>
import {
  reactive,
  ref,
  computed,
  watch,
  onMounted,
  getCurrentInstance,
} from "vue";
// 导入页面数据
import { useTxtToImg } from "@/stores/useTxtToImg";
//  导入图片加载器
import { CommonUtility } from "@/script/utility";
import {textProps} from "element-plus";

// 加载页面数据
const txtToImgStore = useTxtToImg();


/**
 * 列表选中处理
 * @param item
 * @param index
 */
const checkBatch = (item, index) => {
  //  更新批量处理选中索引
  txtToImgStore.changeCurrentBatchSizeIndex(index)
}

</script>

<style scoped lang="scss">
@import "@/assets/overallScss/scssVar";
.bs_box{
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  color: $font-color-wh;
  .bs_title{
    //width: 116px;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    text-align: left;
    font-style: normal;
  }
  .bs_content_box{
    margin-right: auto;
    height: auto;
    .content_list_box{
      display: flex;
      align-items: center;
      padding-left: 38px;
      .item_box{
        cursor: pointer;
        width: 46px;
        height: 30px;
        border-radius: $btnRadiusSmall;
        border: 1px solid $btn-color-tran;
        background-color: $btn-back-color-b;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        &:hover{
          border: 1px solid $btn-color-def;
        }
      }
      .item_box_check{
        border: 1px solid $btn-color-def;
      }
    }

  }
}

@media screen and (max-width: 1070px) {
  .bs_box {
    .bs_title {
      //width: 116px;
      font-weight: 600;
      font-size: 20px;
      color: #FFFFFF;
      line-height: 24px;
      text-align: left;
      font-style: normal;
      min-width: max-content;
    }
  }
}
</style>
