import { ENV } from '@/script/proj_env';

/**
 * 下载视频
 * @param {*} blob 
 */
export const downVideo = (url, fileByteSize) => {
    const jumpUrl = `${ENV.currentConfig.downloadHost}?url=${url}&fileByteSize=${fileByteSize}`;
    const link = document.createElement('a');
    link.href = jumpUrl;
    link.target = 'open';
    link.download = `BeautyAI_${parseInt(Date.now())}.mp4`; // 设置下载文件的名称
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
/**
 * 下载图片
 * @param {*} blob | blobUrl 
 */
export const downImage = (value) => {
    let url;
    if ((typeof value) === 'string') {
        url = value;
    }else {
        const imageData = new Blob([...blob], { type: 'image/png' }); // 替换为实际的 Blob 数据
        url = URL.createObjectURL(imageData);
    }
    const link = document.createElement('a');
    link.href = url;
    link.download = `BeautyAI_${parseInt(Date.now())}.png`; // 设置下载文件的名称
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};