import { md5 } from 'js-md5';
import { ref } from 'vue';

export class VOCImageProvider {
    static get getInstance () {
        if (!this.instance) {
            this.instance = new VOCImageProvider({});
        }
        return this.instance;
    }
    constructor () {
        this.downloader = new VOCImageDownload(4);
        this.higherPriorityDownloader = new VOCImageDownload(4);
    }

    loadImage ({
        request,
        higherPriority = false,
    }) {
        const imgRef = new VOCImageRef({
            key: request.key,
            originUrl: request.url,
            resizeMinSide: request.resizeMinSide,
            quality: request.quality,
            requiredPNG: request.requiredPNG,
        });
        let downloader = higherPriority ? this.higherPriorityDownloader : this.downloader;
        downloader.download(request).then((resp) => {
            if (resp.bytes) {
                let blob = new Blob([resp.bytes]);
                let blobUrl = URL.createObjectURL(blob);
                imgRef.image = new VOCImage({
                    url: blobUrl,
                    bytesLength: resp.bytes.length,
                });
                imgRef.imageStatus.value = VOCImageRef.IMAGE_STATUS_DONE;
            }else {
                imgRef.imageStatus.value = VOCImageRef.IMAGE_STATUS_FAILED;
            }
        }).catch((_) => {
            imgRef.imageStatus.value = VOCImageRef.IMAGE_STATUS_FAILED;
        });
        return imgRef;
    }
}

export class VOCImageRef {
    static IMAGE_STATUS_REQUEST = 0;
    static IMAGE_STATUS_DONE = 1;
    static IMAGE_STATUS_FAILED = -1;

    constructor ({
        key,
        originUrl,
        resizeMinSide = 0,
        quality = 1,
        requiredPNG = false,
        image,
    }) {
        this.key = key;
        this.originUrl = originUrl;
        this.resizeMinSide = resizeMinSide;
        this.quality = quality;
        this.requiredPNG = requiredPNG;
        this.image = image;
        this.imageStatus = ref(image ? VOCImageRef.IMAGE_STATUS_DONE : VOCImageRef.IMAGE_STATUS_REQUEST);
    }

    dispose () {
        if (this.image) {
            URL.revokeObjectURL(this.image.url);
        }
    }
}

export class VOCImage {
    constructor ({
        url,
        bytesLength,
    }) {
        this.url = url;
        this.bytesLength = bytesLength;
    }
}

class WorkerRef {
    constructor (worker) {
        this.worker = worker;
    }
}

export class VOCImageDownload {
    constructor (concurrentBatch) {
        this.concurrentBatch = concurrentBatch;
        this.idleWorkerList = new Set();
        this.busyWorkerList = new Set();
        this.queueRequestList = [];
        this.handleRequestList = [];
        this.responsePromises = {};
    }

    createWorkerHandle (workerRef) {
        return new Promise((resolve) => {
            const worker = new Worker(`${process.env.BASE_URL}script/voc_img_worker.js`);
            worker.onerror = function () {
                resolve(workerRef);
            };
            worker.onmessage = async (event) => {
                if (event.data == 200) {
                    workerRef.worker = worker;
                    resolve(workerRef);
                    return;
                }
                let taskIndex = this.handleRequestList.findIndex((ele) => ele.key == event.data.key);
                this.handleRequestList.splice(taskIndex, 1);
                let promiseHandles = this.responsePromises[event.data.key];
                delete this.responsePromises[event.data.key];
                for (let promiseHandle of promiseHandles) {
                    if (event.data.err_code == 1001) {
                        promiseHandle.reject(1001);
                    }else {
                        promiseHandle.resolve(event.data);
                    }
                }
                this.busyWorkerList.delete(workerRef);
                if (this.queueRequestList.length != 0) {
                    this.idleWorkerList.add(workerRef);
                    this.nextExecute();
                }else {
                    workerRef.worker.terminate();
                }
            };
            worker.postMessage('connect');
        });
    }

    async download (request) {
        const key = request.key;
        const queueFindIndex = this.queueRequestList.findIndex((ele) => ele.key == key);
        const handleFindIndex = this.handleRequestList.findIndex((ele) => ele.key == key);
        if (queueFindIndex != -1) { // 在排队中
            this.queueRequestList.splice(queueFindIndex, 1);
            this.queueRequestList.unshift(request);
        }else if (handleFindIndex == -1) { // 不在排队中也不在处理中
            this.queueRequestList.push(request);
            this.nextExecute();
        }
        const promiseHandle = {};
        const promise = new Promise((resolve, reject) => {
            promiseHandle.resolve = resolve;
            promiseHandle.reject = reject
        });
        promiseHandle.ref = promise;

        if (!this.responsePromises[key]) {
            this.responsePromises[key] = [];
        }
        this.responsePromises[key].push(promiseHandle);
        return await promise;
    }

    nextExecute () {
        if (this.queueRequestList.length == 0) return;
        if (this.idleWorkerList.size == 0 && this.busyWorkerList.size == this.concurrentBatch) return;
        let execute = (workerRef) => {
            const task = this.queueRequestList.shift();
            if (!task) {
                return;
            }
            if (workerRef.worker) {
                this.handleRequestList.push(task);
                workerRef.worker.postMessage({
                    key: task.key,
                    url: task.url,
                    compressInfo: {
                        resizeMinSide: task.resizeMinSide,
                        quality: task.quality,
                        requiredPNG: task.requiredPNG,
                    },
                });
            }else {
                this.busyWorkerList.delete(workerRef);
                let promises = this.responsePromises[task.key];
                delete this.responsePromises[task.key];
                for (let promiseHandle of promises) {
                    promiseHandle.resolve({
                        url: task.url,
                        key: task.key,
                        err_code: 1001,
                    });
                }
                this.nextExecute();
            }
        };
        
        let workerRef;
        if (this.idleWorkerList.size != 0) {
            workerRef = [...this.idleWorkerList][0];
            this.idleWorkerList.delete(workerRef);
            this.busyWorkerList.add(workerRef);
            execute(workerRef);
        }else {
            workerRef = new WorkerRef();
            this.busyWorkerList.add(workerRef);
            this.createWorkerHandle(workerRef).then((workerRef) => {
                execute(workerRef);
            });
        }
    }
}

export class VOCImageRequestInfo {
    constructor ({
        url,
        resizeMinSide = 0,
        quality = 1,
        requiredPNG = false,
    }) {
        this.url = url;
        this.resizeMinSide = resizeMinSide;
        this.quality = quality;
        this.requiredPNG = requiredPNG;
        this.key = md5(`${this.url}&${this.resizeMinSide}&${this.quality}$${this.requiredPNG}`);
    }
}