<template>
    <div>
        <div class="card-img-container">
            <img :src=src alt="" class="card-img">
        </div>
        <h3 class="card-title">{{ title }}</h3>
        <p class="card-subtitle">{{ subTitle }}</p>
        <a class="card-btn" @click.prevent="jump" :href="href">
            <p>{{ actionText }}</p>
        </a>
    </div>
</template>

<script>
export default {
    data () {
        return {};
    },
    props: {
        src: String,
        title: String,
        subTitle: String,
        actionText: String,
        action: Function,
        href: String,
    },
    methods: {
        jump () {
            this.action();
        },
    },
};
</script>

<style scoped lang="scss"> 
/*去除a标签下划线*/
a {
        text-decoration: none;
    }
    /*去除未被访问的a标签的下划线*/
    a:link {
        text-decoration: none;
    }
    /*去除已经被访问过的a标签的下划线*/
    a:visited {
        text-decoration: none;
    }
    /*去除鼠标悬停时的a标签的下划线*/
    a:hover {
        text-decoration: none;
    }
    /*去除正在点击的a标签的下划线（鼠标按下，尚未松开）*/
    a:active {
        text-decoration: none;
    }
    /*去除获得焦点的a标签的下划线（被鼠标点击过）*/
    a:focus {
        text-decoration: none;
    }
@media (min-width: 1071px) {
    .card-img-container {
        height: 296px;
        margin: auto;
        padding: auto;
    }
    .card-img {
        width: 100%;
    }
    .card-title {
        margin-left: auto;
        margin-right: auto;
        margin-top: 14px;
        font-size: 24px;
        font-weight: 700;
        font-family: 'PingFang SC';
        color: white;
        text-align: center;
    }
    .card-subtitle {
        margin-left: auto;
        margin-right: auto;
        margin-top: 10px;
        font-weight: 400;
        font-family: 'PingFang SC';
        font-size: 17px;
        color: white;
        text-align: center;
    }
    .card-btn {
        margin-left: auto;
        margin-right: auto;
        margin-top: 40px;
        width: 230px;
        height: 47px;
        background: linear-gradient(to right, #D33A31, #FF9441);
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        align-items: center;
        -webkit-justify-content: center;
        justify-content: center;
        border-radius: 24px;
        cursor: pointer;
        margin-bottom: 10px;
        margin-left: auto;
        margin-right: auto;
        border: 0;
        color: white;
        font-size: 18px;
        font-weight: 700;
        font-family: 'PingFang SC';
        text-align: center;
    }
    .card-btn:hover {
        background: linear-gradient(to right, #A82D27, #CC7633);
    }
}
@media (max-width: 1070px) {
    .card-img {
        width: 335px;
        height: auto;
        aspect-ratio: 14/10;
        object-fit: contain;
    }
    .card-title {
        margin-left: auto;
        margin-right: auto;
        margin-top: 23px;
        font-weight: 700;
        font-family: 'PingFang SC';
        font-size: 14px;
        color: white;
        text-align: center;
        max-width: 600px;
        padding: 0 48px;
    }
    .card-subtitle {
        margin-left: auto;
        margin-right: auto;
        margin-top: 14px;
        font-weight: 400;
        font-family: 'PingFang SC';
        font-size: 12px;
        color: white;
        text-align: center;
        max-width: 600px;
        padding: 0 48px;
    }
    .card-btn {
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
        width: 241px;
        height: 48px;
        background: linear-gradient(to right, #D33A31, #FF9441);
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        align-items: center;
        -webkit-justify-content: center;
        justify-content: center;
        border-radius: 24px;
        cursor: pointer;
        margin-bottom: 10px;
        margin-left: auto;
        margin-right: auto;
        border: 0;
        color: white;
        font-size: 16px;
        font-weight: 700;
        font-family: 'PingFang SC';
        text-align: center;
    }
    .card-btn:hover {
        background: linear-gradient(to right, #A82D27, #CC7633);
    }
}
</style>