<template>
    <div ref="faceSwapRef" class="faceswap-edit-primary-container" :fixedMaxH="faceSwap.selectedFiledIndex < 0">
        <FaceSwapEditFileOperateContainer class="operate_box"/>
        <FaceSwapEditRecognizedFaceListView class="recognize_box" v-if="faceSwap.selectedFiledIndex >= 0"/>
        <FaceSwapEditChooseFaceListView class="choose_box" v-if="faceSwap.selectedFiledIndex >= 0"/>
        <div class="submit_container" :shadow="faceSwap.selectedFiledIndex >= 0">
            <button
                class="submit"
                :disable="!enable"
                v-if="faceSwap.selectedFiledIndex >= 0"
                @click="submitTask">
                {{ $t('swapFaces') }}
            </button>
            <p>
                {{ useCredisHint }}
            </p>
        </div>
    </div>
</template>

<script setup>
import FaceSwapEditFileOperateContainer from '@/components/faceSwapIndex/edit/FaceSwapEditFileOperateContainer.vue'
import FaceSwapEditRecognizedFaceListView from '@/components/faceSwapIndex/edit/FaceSwapEditRecognizedFaceListView.vue'
import FaceSwapEditChooseFaceListView from './FaceSwapEditChooseFaceListView.vue'
import { useFaceSwap } from '@/stores/useFaceSwap'
import { useFaceSwapTaskManager } from '@/stores/useFaceSwapTaskManager'
import { ref, computed, inject, getCurrentInstance, onMounted, watchEffect, watch } from 'vue'
import { User } from '@/script/user'
import { InfoDialogType } from '@/components/common/dialog/infoDialog'
import { replaceFacesVm } from '@/vm/faceswap_vm'

import { Task } from '@/stores/task'
import eventBus from '@/eventBus/index'

const faceSwapRef = ref(null)
const toSignUpHandle = inject('toSignUpHandle')
const tabCategory = inject('tabCategory')
const faceSwap = useFaceSwap()
const faceSwapTaskManager = useFaceSwapTaskManager()
const { $showInfoDialog, $jumpOtherPage, $analytic, $utility, $showToast, i18n } = getCurrentInstance().appContext.config.globalProperties
watch(()=> faceSwap.selectedFiledIndex, () => {
    setTimeout(() =>{
        faceSwap.faceswapEditPrimaryContainerHeight = faceSwapRef?.value?.offsetHeight;
    }, 5);
});

const useCredisHint = computed(() => {
    if (faceSwap.currentSelectedFile) {
        if (faceSwap.currentSelectedFile.file.fileType == 'image') {
            return i18n.global.t('useCredit').replace('<xx1>', 1)
        }else {
            let count = (Math.floor(Math.max(1, (faceSwap.currentSelectedFile.file.fileDuration - 1)) / 15) + 1) * 10
            return i18n.global.t('useCredits').replace('<xx1>', count)
        }
    }else {
        return ''
    }
})
const useCredisCount = computed(() => {
    if (faceSwap.currentSelectedFile.file.fileType == 'image') {
        return 1
    }else {
        return (Math.floor(Math.max(1, (faceSwap.currentSelectedFile.file.fileDuration - 1)) / 15) + 1) * 10
    }
})
const enable = computed(() => {
    let value = faceSwap.mappings.length > 0
    if (value) {
        for (const mapping of faceSwap.mappings) {
            if (mapping.targetFace != null) {
                return value
            }
        }
        return false
    }
    return value
})
async function submitTask() {
    if (!enable.value) return
    if (!User.getInstance.isLogin.value) {
        toSignUpHandle()
        return
    }
    if (!User.getInstance.hasPaid.value && faceSwap.currentSelectedFile.file.fileType == 'video') {
        $showInfoDialog(InfoDialogType.AdvancedFeatures).then((result) => {
            if (result) {
                $jumpOtherPage('/pricing', true, false)
            }
        })
        return
    }
    if (useCredisCount.value > User.getInstance.allLeftNumber.value) {
        $showInfoDialog(InfoDialogType.InsufficientCredits, { value: ` ${User.getInstance.allLeftNumber.value || "0"}` }).then((result) => {
            if (result) {
                $jumpOtherPage('/pricing', true, false)
            }
        })
        return
    }
    let faceMappingParams = []
    for (const mapping of faceSwap.mappings) {
        if (mapping.targetFace != null) {
            faceMappingParams.push(`${mapping.sourceFace.id}:${mapping.targetFace.id}`)
        }
    }
    faceMappingParams = `{${faceMappingParams.join(',')}}`
    $analytic.log('faceswap_creating_express', {
        category: faceSwap.currentSelectedFile.file.fileType == 'video' ? 'video' : 'photo',
    })
    if ($utility.isNarrow.value) {
        let target = document.querySelector('.faceswap-edit-secondary-container .wrapper')
        window.scrollTo({
            top: target.offsetTop - 100,
            behavior: "smooth",
        })
    }
    faceSwap.clearMappingsTargetFace()
    const task = faceSwapTaskManager.checkSameSubmitPramsTask(faceSwap.currentSelectedFile, faceMappingParams)
    if (task) {
        if (tabCategory == 0 || tabCategory == 1) {
            $showToast(i18n.global.t('submitSamePhotoTaskHint'))
        }else {
            $showToast(i18n.global.t('submitSameVideoTaskHint'))
        }
        if (task.taskStatus == Task.STATUS_GENERATE_ERROR || task.taskStatus == Task.STATUS_DONWLOAD_ERROR) {
            faceSwapTaskManager.retry(task)
            eventBus.emit('retrySameParamsTask', task.submitParamsIdentify)
        }
    }else {
        await faceSwapTaskManager.submit(faceSwap.currentSelectedFile, faceMappingParams)
    }
}
</script>

<style scoped lang="scss">
@media (min-width: 1070px) {
    .faceswap-edit-primary-container[fixedMaxH=true] {
        min-height: 720px;
        max-height: 720px;   
    }
    .faceswap-edit-primary-container {
        .recognize_box {
            margin-top: 20px;
            margin-bottom: 20px;
        }
        .submit_container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            p {
                margin: 7px auto 0px auto;
                font-size: 12px;
                font-weight: 500;
                font-family: 'PingFang SC';
                min-height: 16px;
                color: rgba(255, 255, 255, 0.7);
            }
            
            .submit {
                border: none;
                margin-top: 41px;
                height: 63px;
                width: 100%;
                border-radius: 31.5px;
                font-size: 20px;
                color: white;
                font-weight: 700;
                font-family: 'PingFang SC';
                cursor: pointer;
                background: linear-gradient( 11deg, #D33A31 0%, #FF9441 100%);
                &:hover {
                    background: linear-gradient( 11deg, #A82D27 0%, #CC7633 100%);
                }
            }
            .submit[disable=true] {
                background: linear-gradient( 90deg, #541613 0%, #673C1A 100%);
                color: rgba(255, 255, 255, 0.5);
            }
        }
    }   
}
@media (max-width: 1069px) {
    .faceswap-edit-primary-container {
        .recognize_box {
            margin-top: 20px;
            margin-bottom: 20px;
        }
        .submit_container[shadow=true] {
            background: linear-gradient( 180deg, rgba(7,10,20,0) 0%, #070A14 100%);
        }
        .submit_container {
            position: fixed;
            left: 13px;
            right: 13px;
            bottom: 0px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: transparent;
            z-index: 8;

            p {
                margin: 2px auto 1px auto;
                font-size: 10px;
                font-weight: 500;
                font-family: 'PingFang SC';
                min-height: 16px;
                color: rgba(255, 255, 255, 0.7);
            }

            .submit {
                left: 20px;
                border: none;
                height: 43px;
                max-width: 575px;
                width: 100%;
                margin-left: auto;
                margin-right: auto;
                border-radius: 21.5px;
                font-size: 14px;
                color: white;
                font-weight: 700;
                font-family: 'PingFang SC';
                cursor: pointer;
                background: linear-gradient( 11deg, #D33A31 0%, #FF9441 100%);
                &:hover {
                    background: linear-gradient( 11deg, #A82D27 0%, #CC7633 100%);
                }
            }
            .submit[disable=true] {
                background: linear-gradient( 90deg, #541613 0%, #673C1A 100%);
                color: rgba(255, 255, 255, 0.5);
            }
        }
    }   
}
</style>