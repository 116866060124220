<template>
  <div class="choose-face-list">
    <div class="title">{{ $t('chooseFace') }}</div>
    <div class="list">
      <div class="item add" @click="onChangeFile">
        <input
          type="file"
          accept="image/png, image/jpeg"
          ref="fileInput"
          @change="onFileChange"
          style="display: none"
        />
      </div>
      <div
        class="item select"
        v-for="item in state.faceList"
        :class="{
          active:
            item.id ==
            (useFaceSwap().currentSelectedMapping?.targetFace?.id || -1),
        }"
        :key="item.id"
        @touchstart="()=>{
          if ($utility.isNarrow.value) {
            startPress(item)
          }
        }"
        @touchend="()=>{
          if ($utility.isNarrow.value) {
            cancelPress();
          }
        }"
      >
        <img
          v-if="!item.demoFlag && !$utility.isNarrow.value"
          class="del-icon"
          src="@/assets/images/png/del_icon.png"
          @click="delface(item)"
          alt=""
        />
        <div class="main" @click="() => {
            setface(item)
        }">
          <VOCImageView
            ref="imgViewList"
            :defaultLoading="true"
            :key="item.id"
            :requestInfo="new VOCImageRequestInfo({url: item.url})"
          >
                <template #failed>
                        <div @click.stop="reloadImage(item)" class="retry-item">
                            <img class="retry-img" :src="$utility.assetImage('retry_small')" />
                        </div>
                </template>
          </VOCImageView>
        </div>
      </div>
    </div>
    <div class="jianbian"></div>
  </div>
  <Dialog :isShow="state.isUpdateShow">
    <div class="dialog-main">
      <div class="an">
        <div class="anref" ref="angridref"></div>
        <div class="anref yuan" ref="ansuccessref"></div>
        <div class="anref yuan" ref="ancircleref"></div>
        <div class="anref yuan" ref="anfailedref"></div>
        <img
          class="img"
          src="@/assets/images/png/load_person_icon.png"
          alt=""
        />
      </div>
      <div class="text">{{ statusText }}</div>
    </div>
  </Dialog>
  <!-- <Dialog :isShow="state.isDelShow">
    <div class="dialog-del-main">
      <div class="img">
        <img src="@/assets/images/png/face_del_icon.png" alt="" />
      </div>
      <div class="text">Are you sure to delete this face?</div>
      <div class="flex">
        <div class="item" @click="deleteFace">Continue</div>
        <div
          class="item cancel"
          @click="
            () => {
              state.isDelShow = false;
              state.isDelFaceID = -1;
            }
          "
        >
          Cancel
        </div>
      </div>
    </div>
  </Dialog> -->
  <!-- 登录弹窗 -->
  <!-- <LoginDialog
    @signUp="toSignUp"
    @resetPassWord="toPassWord"
    v-if="state.isLoginShow"
    v-model:isShow="state.isLoginShow"
  />
  <SignUpDialog
    @back="backlogin"
    :isBack="state.upOnePage"
    v-if="state.isSignUp"
    v-model:isShow="state.isSignUp"
  />
  <ResetPassWordDialog
    @back="backlogin"
    :isBack="state.upOnePage"
    v-if="state.isResetPassWord"
    v-model:isShow="state.isResetPassWord"
  /> -->
</template>
  
<script setup>
import { reactive,
  ref,
  computed,
  watch,
  onMounted,
  getCurrentInstance, } from 'vue';
import VOCImageView from '@/components/VOCImageView.vue';
import { replaceFacesVm } from '@/vm/faceswap_vm';
import Dialog from '@/components/Dialog.vue';
import angrid from '@/assets/animationJson/detect_grid.json';
import ansuccess from '@/assets/animationJson/detect_success.json';
import ancircle from '@/assets/animationJson/detect_circle.json';
import anfailed from '@/assets/animationJson/detect_failed.json';
import lottie from 'lottie-web';
import { useFaceSwap } from '@/stores/useFaceSwap';
import { User } from '@/script/user';
// import LoginDialog from '@/components/LoginDialog.vue';
// import SignUpDialog from '@/components/SignUpDialog.vue';
// import ResetPassWordDialog from '@/components/ResetPassWordDialog.vue';
import { InfoDialogType } from '@/components/common/dialog/infoDialog';

import { loginDialog } from '@/components/Dialog';
import { VOCImageRequestInfo } from '@/script/voc_img_tool';

let { $utility, $showFeedbackDialog, $showToast, $analytic, $showInfoDialog, i18n } =
  getCurrentInstance().appContext.config.globalProperties;
const state = reactive({
  faceList: replaceFacesVm.faces,
  selectedFile: {},
  isUpdateShow: false,
  isSuccess: 'pending',
  isDelShow: false,
  isDelFaceID: -1,
  // upOnePage: '',
  // isLoginShow: false,
  // isSignUp: false,
  // isResetPassWord: false,
});
const delface = async (item) => {
  const next = await $showInfoDialog(InfoDialogType.Normal, {
    title: i18n.global.t('choose.delText'),
    imageSrc: 'dialog_delete',
    leftBtnText: i18n.global.t('choose.leftBtnText'),
    rightBtnText: i18n.global.t('choose.rightBtnText'),
  });
  if (!next) {
    if (item.id == useFaceSwap().currentSelectedMapping.targetFace?.id) {
      useFaceSwap().setTargetFace(null);
    }
    await replaceFacesVm.deleteFace(item.id);
  }
};
const toSignUp = (type) => {
  state.upOnePage = type;
  (state.isLoginShow = false), (state.isSignUp = true);
};
const toPassWord = (type) => {
  (state.isLoginShow = false), (state.isResetPassWord = true);
  state.upOnePage = type;
};
const backlogin = () => {
  state.upOnePage = null;
  state.isSignUp = false;
  state.isResetPassWord = false;
  state.isLoginShow = true;
};
let timer = 0;
const startPress = (face) => {
      timer = setTimeout(() => {
        !face.demoFlag && delface(face);
      }, 500);
    };
    const cancelPress = () => {
      clearTimeout(timer);
    };
const imgViewList = ref(null);
// 文件上传
const fileInput = ref(null);

// 动画dom实例
const angridref = ref(null);
const ansuccessref = ref(null);
const ancircleref = ref(null);
const anfailedref = ref(null);

const onChangeFile = () => {
  if (!User.getInstance.isLogin.value) {
    loginDialog();
    return;
  }
  if (replaceFacesVm.faces.length > 100) {
    $showToast(i18n.global.t('choose.overFaces'));
    return;
  }
  fileInput.value.click();
};
const setface = (face) => {
  useFaceSwap().setTargetFace(face);
};
const onFileChange = async (event) => {
  const file = event.target.files[0];
  // state.selectedFile = file;
  state.isUpdateShow = true;
  state.isSuccess = 'pending';
  let lottieancircleRes = {};
  let lottiegridRes = {};
  // 使用延迟避免获取不到dom
  setTimeout(() => {
    lottiegridRes = lottie.loadAnimation({
      container: angridref.value,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: angrid,
    });
    lottieancircleRes = lottie.loadAnimation({
      container: ancircleref.value,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: ancircle,
    });
  }, 0);

  const res = await replaceFacesVm.uploadFace(file);
  state.isSuccess = res.length > 0 ? 'success' : 'error';
  lottiegridRes.stop();
  ancircleref.value.remove();
  let lottiedone = {};
  if (res.length > 0) {
    lottiedone = lottie.loadAnimation({
      container: ansuccessref.value,
      renderer: 'svg',
      autoplay: true,
      loop: false,
      animationData: ansuccess,
    });
  } else {
    lottiedone = lottie.loadAnimation({
      container: anfailedref.value,
      renderer: 'svg',
      autoplay: true,
      loop: false,
      animationData: anfailed,
    });
  }
  // 动画结束回调
  lottiedone.addEventListener('complete', () => {
    lottiedone.stop();
    state.isUpdateShow = false;
    fileInput.value.value = '';
    state.isSuccess = 'pending';
  });
};
// 计算对应的显示文案
const statusText = computed(() => {
  switch (state.isSuccess) {
    case 'pending':
      return i18n.global.t('choose.faceAn');
    case 'success':
      return i18n.global.t('choose.facesuccess');
    case 'error':
      return i18n.global.t('choose.facefailed');
  }
});

const reloadImage = (item) => {
  const index = state.faceList.indexOf(item);
  if (index == -1) return;
  imgViewList.value[index].loadImage();
};
</script>
  
<style scoped lang="scss">
.choose-face-list {
  max-width: 690px;
  font-weight: bold;
  font-size: 16px;
  color: #ffffff;
  line-height: 21px;
  text-align: left;
  position: relative;
  .box {
    height: 100px;
    overflow: auto;
    border: 1px solid red;
  }
  // // 滚动条
  // .box::-webkit-scrollbar {
  //   /*滚动条整体样式*/
  //   width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
  //   height: 1px;
  // }
  // .box::-webkit-scrollbar-thumb {
  //   /*滚动条里面小方块*/
  //   border-radius: 10px;
  //   height: 20px;
  //   -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  //   // background: #E5E5E5;
  //   background: red;
  // }
  // .box::-webkit-scrollbar-track {
  //   /*滚动条里面轨道*/
  //   -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  //   border-radius: 10px;
  //   background: #ffffff;
  // }

  .list {
    margin-top: 14px;
    display: flex;
    overflow-x: auto;
    &::-webkit-scrollbar {
      color: rgba(255, 255, 255, 0.14);
      border-radius: 3px;
      height: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.14);
      border-radius: 3px;
      cursor: pointer;
    }
    .item {
      width: 64px;
      height: 64px;
      flex: 0 0 auto;
      cursor: pointer;
      position: relative;

      &.add {
        background-image: url("@/assets/images/png/add_icon.png");
        background-size: 64px 64px;

        &:hover {
          background-image: url("@/assets/images/png/add_icon_act.png");
          background-size: 64px 64px;
        }
      }

      &.select {
        width: 56px;
        height: 56px;
        padding: 4px;
        border: 1px solid #20222b;
        border-radius: 50%;
        margin-left: 10px;

        .main {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          overflow: hidden;
        }

        &.active {
          border: 1px solid #e26d39;
        }
      }

      .del-icon {
        display: none;
        position: absolute;
        right: 0;
        top: 0;
        width: 16px;
        height: 16px;
      }

      &:hover {
        .del-icon {
          display: block;
        }
      }
    }
  }

  .jianbian {
    position: absolute;
    bottom: 6px;
    right: -1px;
    width: 24px;
    height: 78px;
    background: linear-gradient(270deg, #070a14 0%, rgba(7, 10, 20, 0) 100%);
  }

  .retry-item {
      height: 56px;
      width: 56px;
      background-color: #20222B;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .retry-img {
          width: 50%;
          height: 50%;
          object-fit: contain;
      }
  }
}

.dialog-main {
  width: 350px;
  height: 280px;

  .an {
    width: 150px;
    height: 150px;
    border: 1px solid #ffffff;
    border-radius: 50%;
    position: relative;
    margin: 30px auto;
    margin-bottom: 0;

    .yuan {
      position: absolute;
      top: -14px;
      left: -14px;
      width: 178px;
      height: 178px;
      z-index: 1;
    }

    .img {
      width: 50px;
      height: 50px;
      position: absolute;
      z-index: 0;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .text {
    margin-top: 30px;
    font-family: SFPro, SFPro;
    font-weight: bold;
    font-size: 20px;
    color: #ffffff;
    line-height: 28px;
    text-align: center;
  }
}

@media all and (max-width: 400px) {
  .dialog-main {
    width: 195px;
    height: 160px;
    .an {
      width: 90px;
      height: 90px;

      .yuan {
        position: absolute;
        top: -14px;
        left: -14px;
        width: 118px;
        height: 118px;
        z-index: 1;
      }

      .img {
        width: 50px;
        height: 50px;
        position: absolute;
        z-index: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .text {
      font-size: 18px;
    }
  }
}

.dialog-del-main {
  width: 350px;
  height: 280px;

  .img {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    margin-top: 10px;
  }

  .text {
    margin: 0 auto;
    margin-top: 30px;
    width: 286px;
    height: 56px;
    font-family: SFPro, SFPro;
    font-weight: bold;
    font-size: 20px;
    color: #f3f9ff;
    line-height: 28px;
    text-align: center;
    font-style: normal;
  }

  .flex {
    display: flex;
    justify-content: space-between;
    margin-top: 26px;

    .item {
      cursor: pointer;
      width: 157px;
      height: 45px;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 23px;
      font-weight: 500;
      font-size: 16px;
      color: #ffffff;
      line-height: 45px;
      text-align: center;

      &.cancel {
        background: linear-gradient(11deg, #d33a31 0%, #ff9441 100%);
      }
    }
  }
}

@media all and (max-width: 450px) {
  .del-icon {
    width: 14px;
    height: 14px;
  }
  .dialog-del-main {
    width: 200px;

    .text {
      width: 220px;
    }
  }
}
</style>
  