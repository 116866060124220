import { createApp } from 'vue';
import App from './App.vue';
import { CommonUtility }  from '@/script/utility';
import setup from '@/script/setup';
import i18n from '@/i18n/txtToImg/index';


CommonUtility.checkWebpSupport().then(() => {
    let app = createApp(App);
    app.use(setup);
    app.use(i18n);
    // app.directive('dragscroll-x', draggableContent)
    app.mount('#app');
});
