<template>
    <div class="container">
        <div class="bottomlanguage">
            <div class="bottomlanguage-left">© 2024 Beauty AI. {{ $t('allRightsReserved') }}</div>
            <div class="bottomlanguage-right">
                <div class="bottomlanguageOption" @click.stop="clickLangHandle" @mouseenter="itemHoverLanguage()"
                    @mouseleave="itemHoverOutLanguage()">

                    <img :src="assetImage('language_option')" width="16px" height="16px" style="margin-right:4px">
                    <p>{{ currentLanguage }}</p>
                    <span style="width: 5px;"></span>
                    <img v-bind:class="{ transformArrow: languageHiddeen == true }" :src="assetImage('icon_up')"
                        width="7px" height="4px">

                    <div class="bottomlanguageContainer" v-bind:class="{ languageContainerHidden: languageHiddeen }"
                        @mouseenter="itemHoverLanguage()" @mouseleave="itemHoverOutLanguage()">
                        <div style="height: 10px;"></div>
                        <div class="bottomlanguageItem" v-on:click="languageClick('en', 'English')">
                            <p>English</p>
                        </div>
                        <div class="bottomlanguageItem" v-on:click="languageClick('fr', 'Français')">
                            <p>Français</p>
                        </div>
                        <div class="bottomlanguageItem" v-on:click="languageClick('pt', 'Português')">
                            <p>Português</p>
                        </div>
                        <div class="bottomlanguageItem" v-on:click="languageClick('ja', '日本語')">
                            <p>日本語</p>
                        </div>
                        <div class="bottomlanguageItem" v-on:click="languageClick('es', 'Español')">
                            <p>Español</p>
                        </div>
                        <div style="height: 10px;"></div>
                    </div>

                </div>
            </div>
        </div>
        <el-divider />
        <div class="contentContainer">
            <div style="display: flex;flex-direction: row; flex-flow: nowrap; justify-content: flex-start;align-items: flex-start;  width: auto; cursor: pointer;"
                @click="toHome">
                <img :src="butterflyImgSrc" class="butterflyIcon">
                <div class="butterflyText">Beauty AI</div>
            </div>
            <div class="contentSpace1"></div>
            <div class="contentItemsContainer ">
                <div class="itemColumn" style="margin-left: 16px;">
                    <div style="color: rgba(255, 255, 255, 1);">
                        <p>{{ $t("product") }}</p>
                    </div>
                    <span style="height: 20px;"></span>
                    <div class="itemCursor"><a :href="photoSwapUrl" target="_blank">{{ $t("photoFaceSwap") }}</a></div>
                    <span style="height: 20px;"></span>
                    <div class="itemCursor"><a :href="multiPhotoSwapUrl" target="_blank">{{ $t("photoMutipleFaceSwap") }}</a></div>
                    <span style="height: 20px;"></span>
                    <div class="itemCursor"><a :href="videoSwapUrl" target="_blank">{{ $t("videoFaceSwap") }}</a></div>
                    <span style="height: 20px;"></span>
                    <div class="itemCursor"><a :href="multiVideoSwapUrl" target="_blank">{{ $t("videoMutipleFaceSwap") }}</a></div>
                    <span style="height: 20px;"></span>
                    <div class="itemCursor"><a :href="paintingUrl" target="_blank">{{ $t("aIImageGenerator") }}</a></div>
                    <span style="height: 20px;"></span>
                    <div class="itemCursor"><a :href="brushUrl" target="_blank">{{ $t("magicBrush") }}</a></div>
                </div>
                <div style="min-width: 20px;"></div>
                <div class="itemColumn">
                    <div style="color: rgba(255, 255, 255, 1);">
                        <p>{{ $t("support") }}</p>
                    </div>
                    <span style="height: 20px;"></span>
                    <div class="itemCursor"><a :href="aiGirlHowToUseUrl" target="_blank">{{ $t("aiGirlHowToUse") }}</a></div>
                    <span style="height: 20px;"></span>
                    <div class="itemCursor"><a :href="aiGirlFAQUrl" target="_blank">{{ $t("aiGirlFAQDetails") }}</a></div>
                    <span style="height: 20px;"></span>
                    <div class="itemCursor"><a :href="userPolicyUrl" target="_blank">{{ $t("userPolicy") }}</a></div>
                    <span style="height: 20px;"></span>
                    <div class="itemCursor"><a :href="privacyPolicyUrl" target="_blank">{{ $t("privacyPolicy") }}</a></div>
                    <span style="height: 20px;"></span>
                    <div class="itemCursor"><a :href="faqUrl" target="_blank">{{ $t("helpAndFAQS") }}</a></div>
                    <span style="height: 20px;"></span>
                    <div class="itemCursor"><a :href="sitemapUrl" target="_blank">{{ $t("siteMap") }}</a></div>
                </div>
                <div style="min-width: 20px;"></div>
                <div class="itemColumn" style="margin-right: 16px;">
                    <div style="color: rgba(255, 255, 255, 1);">
                        <p>{{ $t('bussiness') }}</p>
                    </div>
                    <span style="height: 20px;"></span>
                    <div class="itemCursor"><a :href="affiliateUrl" @click="affiliateAnalytic" target="_blank">{{ $t("affiliateProgram") }}</a></div>
                    <span style="height: 20px;"></span>
                    <div class="itemCursor"><a href="https://forms.gle/iKaPr7TeuLnDj3JSA" target="_blank">{{ $t("api")
                            }}</a></div>
                </div>
            </div>
            <div class="contentSpace2"></div>
            <div
                style="display: flex;flex-direction: column; justify-content: flex-start;align-items: flex-start;font-size:14px;align-self: flex-start; width: auto;color: white; margin-left: 16px;">
                <div>
                    <p style="font-family: 'PingFang SC';color: rgba(255, 255, 255, 1);font-weight:400">{{
                        $t("contactUs") }}</p>
                </div>
                <span style="height: 20px;"></span>
                <div class="itemCursor" @click="toContactUs">{{ $t("contactSupport") }}</div>
                <span style="height: 20px;"></span>
                <div class="contactUS" v-on:click="toEmail">
                    <div
                        style="font-family: 'PingFang SC'; font-size: 14px;text-align: center; text-decoration: underline; text-decoration-color: #DBDBDBFF;">
                        support@beautyai.fun</div>
                    <div class="gradient-background">
                        <img :src="buttonDetailImgSrc" width="12px" height="9px">
                    </div>
                </div>
                <span style="height: 20px;"></span>
                <div class="language-txt">
                    <p style="font-family: 'PingFang SC';color: rgba(255, 255, 255, 0.5);font-weight:400">{{
                        $t("language") }}</p>
                </div>
                <span style="height: 20px;"></span>
                <div class="languageOption" @click.stop="clickLangHandle" @mouseenter="itemHoverLanguage()"
                    @mouseleave="itemHoverOutLanguage()">

                    <img :src="assetImage('language_option')" width="16px" height="16px" style="margin-right:4px">
                    <p>{{ currentLanguage }}</p>
                    <span style="width: 5px;"></span>
                    <img v-bind:class="{ transformArrow: languageHiddeen == true }" :src="assetImage('icon_up')"
                        width="7px" height="4px">

                    <div class="languageContainer" v-bind:class="{ languageContainerHidden: languageHiddeen }"
                        @mouseenter="itemHoverLanguage()" @mouseleave="itemHoverOutLanguage()">
                        <div style="height: 10px;"></div>
                        <div class="languageItem" v-on:click="languageClick('en', 'English')">
                            <p>English</p>
                        </div>
                        <div class="languageItem" v-on:click="languageClick('fr', 'Français')">
                            <p>Français</p>
                        </div>
                        <div class="languageItem" v-on:click="languageClick('pt', 'Português')">
                            <p>Português</p>
                        </div>
                        <div class="languageItem" v-on:click="languageClick('ja', '日本語')">
                            <p>日本語</p>
                        </div>
                        <div class="languageItem" v-on:click="languageClick('es', 'Español')">
                            <p>Español</p>
                        </div>
                        <div style="height: 10px;"></div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <!-- 登录弹窗 -->
    <!-- <LoginDialog @signUp="toSignUp" @resetPassWord="toPassWord" v-if="isLoginShow" v-model:isShow="isLoginShow" />
    <SignUpDialog @back="backlogin" :isBack="upOnePage" v-if="isSignUp" v-model:isShow="isSignUp" />
    <ResetPassWordDialog @back="backlogin" :isBack="upOnePage" v-if="isResetPassWord"
        v-model:isShow="isResetPassWord" /> -->
</template>

<script>
import { User } from '@/script/user';
import { loginDialog } from './Dialog';
let languageHoverTimer;

export default {
    name: 'BottomBar',
    data () {
        return {
            butterflyImgSrc: '',
            buttonDetailImgSrc: '',
            currentHover: '',
            aiGirlIntroduceUrl: this.$otherPageUrl('/free-ai-image-generator-introduce', true, true),
            aiGirlHowToUseUrl: this.$otherPageUrl('/free-ai-image-generator-howToUse', true, true),
            aiGirlFAQUrl: this.$otherPageUrl('/free-ai-image-generator-faq', true, true),
            paintingUrl: this.$otherPageUrl('/free-ai-image-generator', true, true),
            photoSwapUrl: this.$otherPageUrl('/photo-face-swap', true),
            multiPhotoSwapUrl: this.$otherPageUrl('/multiple-picture-face-swap', true),
            videoSwapUrl: this.$otherPageUrl('/video-face-swap', true),
            multiVideoSwapUrl: this.$otherPageUrl('/multiple-video-face-swap', true),
            brushUrl: this.$otherPageUrl('/brush', false),
            faqUrl: this.$otherPageUrl('/faq', false),
            sitemapUrl: this.$otherPageUrl('/sitemap.xml', false),
            userPolicyUrl: this.$otherPageUrl('/userserver', false),
            privacyPolicyUrl: this.$otherPageUrl('/privacypolicy', false),
            affiliateUrl: this.$otherPageUrl('/ai-video-face-swap-affiliate-program', true),
            languageHiddeen: true,
            currentLanguage: 'English',
            isLoginShow: false,
            upOnePage: null,
            isSignUp: false,
            isResetPassWord: false,

        };
    },

    created () {
        if (this.$i18n.locale == 'en') {
            this.currentLanguage = 'English';
        } else if (this.$i18n.locale == 'fr') {
            this.currentLanguage = 'Français';
        } else if (this.$i18n.locale == 'es') {
            this.currentLanguage = 'Español';
        } else if (this.$i18n.locale == 'pt') {
            this.currentLanguage = 'Português';
        } else if (this.$i18n.locale == 'ja') {
            this.currentLanguage = '日本語';
        }
    },
    mounted () {
        this.butterflyImgSrc = this.$utility.assetImage('butterfly');
        this.buttonDetailImgSrc = this.$utility.assetImage('button_detail');
        document.addEventListener('click', this.closeLangPopup);
        document.addEventListener('scroll', this.closeLangPopup);
    },
    beforeUnmount () {
        document.removeEventListener('click', this.closeLangPopup);
        document.removeEventListener('scroll', this.closeLangPopup);
    },
    methods: {
        affiliateAnalytic () {
            this.$analytic.log('navigation_bottom_affiliate_tick');
        },
        toHome () {
            window.open(`${window.location.protocol}//${window.location.host}`, '_blank');
        },
        toContactUs () {
            if(User.getInstance.isLogin.value) {
                window.open(`${window.location.protocol}//${window.location.host}/feedback`, '_blank');
            }else{
                loginDialog();
            }
        },

        toEmail () {
            window.location.href = 'mailto:support@beautyai.fun';
        },
        assetImage (name) {
            return this.$utility.assetImage(name);
        },
        clickLangHandle () {
            if (this.languageHiddeen) {
                this.languageHiddeen = false;
            } else {
                this.languageHiddeen = true;
            }
        },
        closeLangPopup () {
            this.languageHiddeen = true;
        },
        changeCurLang (locale) {
            this.languageClick(locale);
        },
        languageClick (locale) {
            this.languageHiddeen = true;
            if (this.$i18n.locale != locale) {
                let lastPath = this.$utility.getUrlRelativeLastComponentPath();
                let langs = [
                    'en', 'fr', 'ja', 'zh', 'es', 'pt',
                ];
                let relativePath = this.$utility.getUrlRelativePath();
                if (langs.indexOf(lastPath) != -1) {
                    relativePath = relativePath.replace(lastPath, locale);
                    window.location.href = `${window.location.protocol}//${window.location.host}${relativePath}`;
                } else {
                    let comps = relativePath.split('/');
                    comps.shift();
                    let newComps = [locale];
                    for (const comp of comps) {
                        newComps.push(comp);
                    }
                    relativePath = newComps.join('/');
                    window.location.href = `${window.location.protocol}//${window.location.host}/${relativePath}`;
                }
            }
        },
        itemHoverLanguageSelector () {
            let isMobile = window.mobileAndTabletCheck();
            if (isMobile) return;
        },
        itemHoverOutLanguageSelector () {
            let isMobile = window.mobileAndTabletCheck();
            if (isMobile) return;
            this.languageHiddeen = true;
        },
        itemHoverLanguage () {
            let isMobile = window.mobileAndTabletCheck();
            if (isMobile) return;
            clearTimeout(languageHoverTimer);
            this.languageHiddeen = false;
        },
        itemHoverOutLanguage () {
            let isMobile = window.mobileAndTabletCheck();
            if (isMobile) return;
            if (this.languageHiddeen === true) {
                return;
            }
            languageHoverTimer =  setTimeout(() => {
                this.languageHiddeen = true;
            }, 300);
        },
        backlogin () {
            this.upOnePage = null;
            this.isSignUp = false;
            this.isResetPassWord = false;
            this.isLoginShow = true;
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
p {
    margin: 0;
}

/*去除a标签下划线*/
a {
    text-decoration: none;
    color: #6A6A6A;
}

/*去除未被访问的a标签的下划线*/
a:link {
    text-decoration: none;
}

/*去除已经被访问过的a标签的下划线*/
a:visited {
    text-decoration: none;
}

/*去除鼠标悬停时的a标签的下划线*/
@media (any-hover: hover) {
    a:hover {
        text-decoration: none;
        color: #fff;
    }
}

/*去除正在点击的a标签的下划线（鼠标按下，尚未松开）*/
a:active {
    text-decoration: none;
}

/*去除获得焦点的a标签的下划线（被鼠标点击过）*/
a:focus {
    text-decoration: none;
}

.itemCursor {
    font-weight: 400;
    font-family: 'PingFang SC';
    color: #6A6A6A;
    cursor: pointer;
    @media (any-hover: hover) {
        &:hover{
            color: #fff;
        }
    }
}

.itemColumn {
    font-weight: 400;
    font-family: 'PingFang SC';
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: white;
    font-size: 14px;
}


.contactUS {
    color: rgba(255, 255, 255, 0.8);
    background-color:#0D0D0D;
    width: 196px;
    height: 40px;
    border-radius: 4px;
    border: 0.5px solid #888A8C;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.gradient-background {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    border-radius: 4px;
    background: #F96130;
    display: flex;
    align-items: center;
    justify-content: center;
}

.languageOption {
    width: 126px;
    height: 40px;
    display: flex;
    flex-direction: row;
    background: #FFFFFF1a;
    border-radius: 32px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.transformArrow {
    transform: rotateZ(180deg) translateX(1px);
}

.languageContainer {
    width: 138px;
    height: 200px;
    border-radius: 20px;
    position: absolute;
    transform: translateY(-65%);
    background-color: #27282DFF;

}

.languageContainerHidden {
    display: none;
}

.languageItem {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;

    height: 36px;
    text-align: center;
    align-content: center;
    font-size: 14px;

    &:hover {
        background-color: #FFFFFF1A;
        margin: 0 10px;
        border-radius: 10px;
    }
}

.container {
    background: #0D0D0D;
    will-change: transform;

}


@media (min-width: 970px) {
    .container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-flow: wrap-reverse;
        width: 100%;
        padding: 50px 0px 50px 0;
        .bottomlanguage {
            width: 1400px;
            margin-left: 16px;
            margin-right: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .bottomlanguage-left {
                color:#6A6A6A;
            }
            .bottomlanguage-right {
                color:#6A6A6A;
                .bottomlanguageOption {
                    color: #fff;
                    width: 126px;
                    height: 40px;
                    display: flex;
                    flex-direction: row;
                    // background: #FFFFFF1a;
                    border-radius: 32px;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                }
                .bottomlanguageContainer {
                    width: 138px;
                    height: 200px;
                    border-radius: 20px;
                    position: absolute;
                    transform: translateY(-65%);
                    background-color: #27282DFF;

                }

                .bottomlanguageContainerHidden {
                    display: none;
                }

                .bottomlanguageItem {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-weight: 400;
                    font-family: 'PingFang SC';
                    height: 36px;
                    text-align: center;
                    align-content: center;
                    font-size: 14px;

                    &:hover {
                        background-color: #FFFFFF1A;
                        margin: 0 10px;
                        border-radius: 10px;
                    }
                }
            }
        }
    }
    .languageOption {
        display: none;
    }
    .language-txt {
        display: none;
    }
    :deep(.el-divider--horizontal) {
        margin: 30px 16px;
        z-index: -1;
        opacity: 0.12;
    }
    .contentContainer {
        max-width: 1400px;
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        justify-items: flex-start;
        margin-left: 16px;
        margin-right: 16px;
    }

    .butterflyIcon {
        width: 54px;
        height: 46px;
        margin-right: 10px;
    }

    .butterflyText {
        font-family: "Silom";
        font-size: 30px;
        color: #F3F9FFFF;
        white-space: nowrap;
        font-weight: bold;
    }

    .contentSpace1 {
        flex: 249;
        min-width: 40px;
    }

    .contentSpace2 {
        flex: 120;
        min-width: 40px;
    }

    .contentItemsContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        justify-items: flex-start;
        align-items: flex-start;
        color: white;
        max-width: 600px;
        width: 90%;
    }

}

@media (max-width: 969px) {
    .bottomlanguage {
        display: none;
    }
    :deep(.el-divider--horizontal) {
        display: none;
    }
    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 30px 0px;
      margin-bottom: 75px;
    }

    .contentContainer {
        max-width: 969px;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        justify-items: flex-start;
        width: 100%;
    }

    .butterflyIcon {
        width: 32px;
        height: 27px;
        margin-right: 6px;
    }

    .languageOption {
        border: 0.5px solid #888A8C;
        border-radius: 4px;
        // opacity: 0.5;
        background-color: #000;
        p {
            opacity: 0.5;
        }
    }

    .butterflyText {
        font-family: "Silom";
        font-size: 18px;
        color: #F3F9FFFF;
        white-space: nowrap;
        font-weight: bold;
    }

    .contentSpace1 {
        height: 30px;
    }

    .contentSpace2 {
        height: 30px;
    }

    .contentItemsContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        justify-items: flex-start;
        align-items: flex-start;
        color: white;
        width: 100%;
    }

}
</style>
