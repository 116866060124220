<template>
    <div :class="{'video-example-popup':true, 'offstage': offstage}">
        <div :class="{body: true, popin: popin, popout: !popin}">
            <img class="close" ref="closeView" :src="$utility.assetImage('close_icon')" alt="" @click="dismiss" @mouseenter="closeHover(true)" @mouseleave="closeHover(false)">
            <p class="title">{{ $t('examplePopup.title') }}</p>
            <p class="tip">{{ $t('examplePopup.videoTip') }}</p>
            <div class="content">
                <div class="good">
                    <div class="txt-row">
                        <p class="title">{{ $t('examplePopup.goodExample') }}</p>
                        <img class="icon" :src="$utility.assetImage('faceswap_example_good_icon')" alt="">
                    </div>
                    <div class="video-row first">
                        <VideoItem class="video" ref="videoView1" :videoData="videoDatas[0]" @playCallback="playCallback(videoView1)"/>
                        <VideoItem class="video" ref="videoView2" :videoData="videoDatas[1]" @playCallback="playCallback(videoView2)"/>
                    </div>
                </div>
                <div class="bad">
                    <div class="txt-row">
                        <p class="title">{{ $t('examplePopup.badExample') }}</p>
                        <img class="icon" :src="$utility.assetImage('faceswap_example_bad_icon')" alt="">
                    </div>
                    <div class="video-row">
                        <VideoItem class="video" ref="videoView3" :videoData="videoDatas[2]" @playCallback="playCallback(videoView3)"/>
                        <VideoItem class="video" ref="videoView4" :videoData="videoDatas[3]" @playCallback="playCallback(videoView4)"/>
                        <VideoItem class="video" ref="videoView5" :videoData="videoDatas[4]" @playCallback="playCallback(videoView5)"/>
                    </div>
                </div>
            </div>
            <div class="ok-btn" @click="dismiss">
                <p>{{ $t('ok') }}</p>
            </div>
        </div>
    </div>
</template>

<script setup>
import VideoItem from '@/components/faceSwapIndex/examplePopup/VideoItem'
import { ref, watch, getCurrentInstance } from 'vue'
const { i18n, $utility } = getCurrentInstance().appContext.config.globalProperties
const offstage = ref(true);
const popin = ref(false);
let animating = false;
const videoView1 = ref(null)
const videoView2 = ref(null)
const videoView3 = ref(null)
const videoView4 = ref(null)
const videoView5 = ref(null)
let lastPlayView = null
const closeView = ref(null)
const videoDatas = [
    {
        id: 1,
        videoSrc: require('@/assets/videos/face_swap_video_demo_good_1.mp4'),
        imgSrc: require('@/assets/images/faceswap_example_video_good_1.jpg'),
        title: i18n.global.t('examplePopup.highQuality'),
    },
    {
        id: 2,
        videoSrc: require('@/assets/videos/face_swap_video_demo_good_2.mp4'),
        imgSrc: require('@/assets/images/faceswap_example_video_good_2.jpg'),
        title: i18n.global.t('examplePopup.lookingAtViewer'),
    },
    {
        id: 3,
        videoSrc: require('@/assets/videos/face_swap_video_demo_bad_1.mp4'),
        imgSrc: require('@/assets/images/faceswap_example_video_bad_1.jpg'),
        title: i18n.global.t('examplePopup.sideFaces'),
        title2: i18n.global.t('examplePopup.tooFar')
    },
    {
        id: 4,
        videoSrc: require('@/assets/videos/face_swap_video_demo_bad_2.mp4'),
        imgSrc: require('@/assets/images/faceswap_example_video_bad_2.jpg'),
        title: i18n.global.t('examplePopup.blurry'),
        title2: i18n.global.t('examplePopup.lowResolution')
    },
    {
        id: 5,
        videoSrc: require('@/assets/videos/face_swap_video_demo_bad_3.mp4'),
        imgSrc: require('@/assets/images/faceswap_example_video_bad_3.jpg'),
        title: i18n.global.t('examplePopup.lessCover'),
    },
]

watch(offstage, () => {
    document.body.style.overflow = !offstage.value ? 'hidden' : 'auto';    
});

function closeHover(value) {
    if (value) {
        closeView.value.src = $utility.assetImage('close_icon_hover')
    }else {
        closeView.value.src = $utility.assetImage('close_icon')
    }
}

function show () {
    if (animating) return;
    animating = true;
    offstage.value = false;
    popin.value = true;
    setTimeout(() => animating = false, 500);
}
function dismiss () {
    if (lastPlayView) {
        lastPlayView.pause();
    }
    if (animating) return;
    animating = true;
    popin.value = false;
    setTimeout(() => {
        animating = false;
        offstage.value = true;
    }, 500);
}
function playCallback(view) {
    const tempView = lastPlayView
    lastPlayView = view
    if (tempView) {
        tempView.pause()
    }
}

defineExpose({
    show,
    dismiss,
});
</script>

<style scoped lang="scss">
@keyframes popin {
    0% {
        transform: translate(0%, 150%);
    }
    100% {
        transform: none;
    }
}
@keyframes popout {
    0% {
        transform: none;
    }
    100% {
        transform: translate(0%, 150%);
    }
}
.popin {
    animation: popin .5s;
    animation-fill-mode: forwards;
}
.popout {
    animation: popout .5s;
    animation-fill-mode: forwards;
}
.video-example-popup.offstage {
    display: none;
}
@media (min-width: 1070px) {
    .video-example-popup {
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        background-color: rgba(0, 0, 0, 0.6);
        z-index: 1000;
        display: flex;
        align-items: center;
        justify-content: center;
        .body {
            position: relative;
            width: 519px;
            border-radius: 8px;
            background: #27282D;
            .close {
                position: absolute;
                top: 20px;
                right: 20px;
                width: 32px;
                height: 32px;
                cursor: pointer;
            }
            .title {
                max-width: 396px;
                text-align: center;
                font-size: 20px;
                font-family: 'PingFang SC';
                font-weight: 700;
                color: white;
                margin-left: auto;
                margin-right: auto;
                margin-top: 30px;
                margin-bottom: 0px;
            }
            .tip {
                max-width: 460px;
                text-align: center;
                font-size: 14px;
                font-family: 'PingFang SC';
                font-weight: 400;
                color: #F3F9FF;
                margin-left: auto;
                margin-right: auto;
                margin-top: 20px;
                margin-bottom: 0px;
            }
            .content {
                margin-top: 20px;
                overflow-x: hidden;
                max-height: 566px;
            }
            .good {
                display: flex;
                flex-direction: column;
                margin-top: 10px;
            }
            .bad {
                display: flex;
                flex-direction: column;
                margin-top: 20px;
                .txt-row {
                    .title {
                        color: #FF0016;
                    }
                }
            }
            .txt-row {
                display: flex;
                justify-content: space-between;
                margin-left: 30px;
                margin-right: 30px;
                margin-bottom: 16px;
                .title {
                    font-size: 16px;
                    font-weight: 500px;
                    font-family: 'PingFang SC';
                    color: #43FF1F;
                    margin: 0px;
                }
                .icon {
                    width: 22px;
                    height: 22px;
                    object-fit: fill;
                }
            }
            .video-row {
                display: flex;
                justify-content: center;
            }
            .video {
                width: 146px;
                height: 261px;
                margin-right: 15px;
            }
            .video:last-child {
                margin-right: 0px;
            }
            .ok-btn {
                margin-top: 30px;
                margin-bottom: 30px;
                margin-left: auto;
                margin-right: auto;
                width: 330px;
                height: 45px;
                border-radius: 8px;
                background: #FF7839;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                &:hover {
                    background: #CF4D23;
                }
                p {
                    margin: 0;
                    font-size: 16px;
                    font-weight: 500;
                    color: white;
                    font-family: 'PingFang SC';
                }
            }
        }
    }
}
@media (max-width: 1069px) {
    .video-example-popup {
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        background-color: rgba(0, 0, 0, 1);
        z-index: 1000;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        .body {
            display: flex;
            flex-direction: column;
            position: relative;
            width: 335px;
            max-height: min(90%, 704px);
            border-radius: 8px;
            background: #27282D;
            .close {
                position: absolute;
                top: 10px;
                right: 10px;
                width: 24px;
                height: 24px;
                cursor: pointer;
            }
            .title {
                max-width: 255px;
                text-align: center;
                font-size: 18px;
                font-family: 'PingFang SC';
                font-weight: 600;
                color: white;
                margin-left: auto;
                margin-right: auto;
                margin-top: 20px;
                margin-bottom: 0px;
            }
            .tip {
                max-width: 295px;
                text-align: center;
                font-size: 12px;
                font-family: 'PingFang SC';
                font-weight: 400;
                color: #F3F9FF;
                margin-left: auto;
                margin-right: auto;
                margin-top: 10px;
                margin-bottom: 0px;
            }
            .content {
                margin-top: 20px;
                overflow-x: hidden;
                flex-grow: 1;
            }
            .good {
                display: flex;
                flex-direction: column;
            }
            .bad {
                display: flex;
                flex-direction: column;
                margin-top: 16px;
                .txt-row {
                    .title {
                        color: #FF0016;
                    }
                }
            }
            .txt-row {
                display: flex;
                justify-content: space-between;
                margin-left: 20px;
                margin-right: 20px;
                margin-bottom: 16px;
                .title {
                    font-size: 12px;
                    font-weight: 500px;
                    font-family: 'PingFang SC';
                    color: #43FF1F;
                    margin: 0px;
                }
                .icon {
                    width: 16px;
                    height: 16px;
                    object-fit: fill;
                }
            }
            .video-row {
                display: flex;
                overflow-y: hidden;
            }
            .video-row.first {
                justify-content: center;
                margin-left: 0px;
                margin-right: 0px;
            }
            .video {
                width: 123px;
                min-width: 123px;
                height: 220px;
                margin-right: 12px;
            }
            .video:first-child {
                margin-left: 20px;
            }
            .video:last-child {
                margin-right: 20px;
            }
            .ok-btn {
                margin-top: 20px !important;
                margin-bottom: 20px !important;
                margin-left: auto;
                margin-right: auto;
                width: 200px;
                min-height: 36px !important;
                border-radius: 8px;
                background: #FF7839;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                &:hover {
                    background: #CF4D23;
                }
                p {
                    margin: 0;
                    font-size: 14px;
                    font-weight: 500;
                    color: white;
                    font-family: 'PingFang SC';
                }
            }
        }
    }
}
</style>