import { ref } from 'vue';

export class CommonUtility {
    static assetImgType = 'png';
    static isNarrow = ref(false);
    static ishomeNarrow = ref(false);
    static narrowBoundaryValue = 1070;
    static narrowHomeValue = 1000;
    static isMobile = false;

    static init () {
        this.isMobile = window.mobileAndTabletCheck();
        this.narrowChangeListener();
    }

    static getUrlRelativePath () {
        let url = document.location.toString();
        let arrUrl = url.split('//');
        let start = arrUrl[1].indexOf('/');
        let relUrl = arrUrl[1].substring(start);
        if (relUrl.indexOf('?') != -1) {
            relUrl = relUrl.split('?')[0];
        }
        return relUrl;
    }

    static getUrlRelativeLastComponentPath () {
        let path = this.getUrlRelativePath();
        let arr = path.split('/');
        return arr[1]
    }

    static geti18nComponentPath () {
        let path = this.getUrlRelativePath().toLowerCase();
        let arr = path.split('/');
        let langs = [
            'en', 'fr', 'ja', 'es', 'pt',
        ];
        for (let lang of langs) {
            if (arr.indexOf(lang) != '-1') {
                return lang;
            }
        }
    }

    static getQueryVariable (variable) {
        let query = window.location.search.substring(1);
        let vars = query.split('&');
        for (let i = 0;i < vars.length;i++) {
            let pair = vars[i].split('=');
            if(pair[0] == variable) {
return pair[1];
}
        }
        return(false);
    }

    static narrowChangeListener () {
        this.isNarrow.value = window.innerWidth < this.narrowBoundaryValue;
        this.ishomeNarrow.value = window.innerWidth < this.narrowHomeValue;
        window.addEventListener('resize', () => {
            this.isNarrow.value = window.innerWidth < this.narrowBoundaryValue;
            this.ishomeNarrow.value = window.innerWidth < this.narrowHomeValue;
        });
    }

    static async checkWebpSupport () {
        let handle = new Promise((resolve) => {
            let img = new Image();
            img.onload = function () {
                let isSupported = (img.width > 0) && (img.height > 0);
                if (isSupported) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            };
            img.onerror = function () {
                resolve(false);
            };
            img.src = 'data:image/webp;base64,UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==';
        });
        let supoort = await handle;
        this.assetImgType = supoort ? 'webp' : 'png';
        return supoort;
    }

    static assetImage (name) {
        return require(`@/assets/images/${this.assetImgType}/${name}.${this.assetImgType}`);
    }

    static getUuid () {
        let uuid = window.localStorage.uuid;
        if (!uuid) {
            uuid = this.makeUuid();
            window.localStorage.uuid = uuid;
        }
        return uuid;
    }

    static makeUuid () {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
            let r = (Math.random() * 16) | 0;
            let v = c == 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    }

    /**
     * 过滤中文
     * @param str 需要过滤的目标串
     * @returns {string} 处理结果
     */
    static replaceUtZh (str = '') {
     return  str.replace(/[\u4e00-\u9fa5]/g, '')
    }

    static regUserName(str = '') {
        return str.replace( /\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/g)
    }
}
