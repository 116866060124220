<template>
    <div class="video-example-popup-item" @click="controlClick">
        <img class="img" :src="videoData.imgSrc" alt="">
        <video class="video" ref="videoView" v-show="playing" :src="videoData.videoSrc" control="false" loop muted playsinline webkit-playsinline x5-playsinline x-webkit-airplay></video>
        <div class="mask"></div>
        <div class="title-column">
            <p class="title">{{ videoData.title }}</p>
            <p class="title" v-if="videoData.title2">{{ videoData.title2 }}</p>
        </div>
        <img class="control" ref="controlView" :src="$utility.assetImage('faceswap_example_play_icon')" alt="">
    </div>
</template>

<script setup>
import { defineProps, defineExpose, defineEmits, getCurrentInstance, ref } from 'vue'
const {
    videoData
} = defineProps(['videoData'])
const emit = defineEmits(['playCallback'])
const { $utility } = getCurrentInstance().appContext.config.globalProperties
const playing = ref(false)
const videoView = ref(null)
const controlView = ref(null)
function controlClick() {
    if (playing.value) {
        pause()
    }else {
        play()
    }
}

function pause() {
    playing.value = false
    videoView.value.pause()
    controlView.value.src = $utility.assetImage('faceswap_example_play_icon')
}

function play() {
    emit('playCallback')
    playing.value = true
    videoView.value.play()
    controlView.value.src = $utility.assetImage('faceswap_example_pause_icon')
}
defineExpose({
    pause,
})
</script>

<style scoped lang="scss">
    .video-example-popup-item {
        cursor: pointer;
        position: relative;
        .img {
            position: absolute;
            top: 0px;
            left: 0px;
            bottom: 0px;
            right: 0px;
            width: 100%;
            height: 100%;
        }
        .video {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .mask {
            position: absolute;
            left: 0px;
            width: 100%;
            bottom: 0px;
            height: 40px;
            text-align: center;
            background: linear-gradient( 180deg, rgba(0,0,0,0) 0%, #000000 100%);
        }
        .title-column {
            position: absolute;
            left: 8px;
            right: 8px;
            bottom: 12px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        .title {
            color: white;
            font-size: 12px;
            font-weight: 500;
            text-align: center;
            font-family: 'PingFang SC';
            margin: 0;
        }
        .control {
            position: absolute;
            top: 6px;
            right: 6px;
            width: 22px;
            height: 22px;
        }
    }
</style>