<template>
    <div>
        <img class="voc_img" draggable="false" v-if="isDone" :onload="onload" :onerror="onerror" v-lazy="{src: imgSrc}">
        <slot v-else-if="isFailed" name="failed"></slot>
        <ShimmerCard v-else-if="defaultLoading"/>
        <slot v-else name="loading"></slot>
    </div>
</template>

<script>
import { watch } from 'vue';
import ShimmerCard from '@/components/common/ShimmerCard.vue';
import * as imgTool from '@/script/voc_img_tool';
import { onUpdated } from 'vue';

export default {
    components: { ShimmerCard },
    data () {
        return {
            imgSrc: '',
            imageRef: null,
            imgLoadError: false,
        };
    },
    props: {
        requestInfo: Object,
        defaultLoading: {
            type: Boolean,
            default: true,
        },
        objectFit: {
            type: String,
            default: 'cover',
        },
    },
    methods: {
        onerror () {
            this.imgLoadError = true;
        },
        onload (e) {
            e.target.style.objectFit = this.objectFit;
        },
        onUpdated () {
            e.target.style.objectFit = this.objectFit;
        },
        loadImage () {
            if (!this.requestInfo) {
                return;
            }
            this.imageRef = imgTool.VOCImageProvider.getInstance.loadImage({ request: this.requestInfo });
            watch(this.imageRef, () => {
                if (this.imageRef.imageStatus == imgTool.VOCImageRef.IMAGE_STATUS_DONE) {
                    this.imgSrc = this.imageRef.image.url;
                }
            });
        },
    },
    watch: {
        requestInfo (newInfo, oldInfo) {
            if(newInfo.key !== oldInfo.key) {
                this.loadImage();
            }
        },
    },
    computed: {
        isFailed () {
            return this.imageRef && (this.imageRef.imageStatus == imgTool.VOCImageRef.IMAGE_STATUS_FAILED || this.imgLoadError);
        },
        isDone () {
            return this.imageRef && (this.imageRef.imageStatus == imgTool.VOCImageRef.IMAGE_STATUS_DONE && !this.imgLoadError);
        },
        isRequest () {
            return this.imageRef && (this.imageRef.imageStatus == imgTool.VOCImageRef.IMAGE_STATUS_REQUEST);
        },
    },
    created () {
        this.loadImage();
    },
    unmounted () {
    },
};
</script>

<style scoped lang="scss"> 
.voc_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    -webkit-touch-callout: none; /* 禁用长按菜单 */
  -webkit-user-select: none;   /* 禁用用户选择 */
  -webkit-tap-highlight-color: rgba(0,0,0,0); /* 禁用点击高亮 */
}
</style>