import { createApp } from 'vue';
import installI18n, { i18n } from '@/i18n/basic/index';

import LoginDialog from './LoginDialog.vue';
import ResetPassWordDialog from './ResetPassWordDialog.vue';
import SignUpDialog from './SignUpDialog.vue';
import SelectStyleDialog from './SelectStyleDialog.vue';
import LooksLikeDialog from './LooksLikeDialog.vue';
import InfoDialogPlugin from './common/dialog/infoDialog';
import ToastPlugin from './common/toast/toast';
import FeedbackDialogPlugin from './common/dialog/feedbackDialog';
import LooksLikeGuideDialog from './LooksLikeGuideDialog.vue';

import { CommonUtility } from '@/script/utility';
import { User } from '@/script/user';
import { Config } from '@/script/config';
import { ENV } from '@/script/proj_env';
import { API } from '@/script/api';
import { AnalyticHub } from '@/script/analytic';


/**
 *
 * @param {*} param0
 * element: vue组件
 * props 传参
 */
export function dialog ({ element, props }) {
    const close = props.close;
    const id = Math.random().toString(36) + Date.now();
    let ToastConstructor = {};
    props.close = () => {
        close?.();
        ToastConstructor.unmount();
        document.getElementById(id)?.remove();
    };
    ToastConstructor = createApp(element, props);
    const container = document.createElement('div');
    container.id = id;
    document.body.appendChild(container);
    ToastConstructor.use(installI18n);
    ToastConstructor.use(InfoDialogPlugin);
    ToastConstructor.use(ToastPlugin);
    ToastConstructor.use(FeedbackDialogPlugin);
    ToastConstructor.config.globalProperties.$utility = CommonUtility;
    ToastConstructor.config.globalProperties.$user = User.getInstance;
    ToastConstructor.config.globalProperties.$config = Config.getInstance;
    ToastConstructor.config.globalProperties.$env = ENV;
    ToastConstructor.config.globalProperties.$api = API;
    ToastConstructor.config.globalProperties.$analytic = AnalyticHub.getInstance;
    ToastConstructor.config.globalProperties.i18n = i18n;
    CommonUtility.init();
    AnalyticHub.initAnalytic();
    ToastConstructor.mount(container);
};

/** 登录页弹窗 转为方法使用 */
export function loginDialog () {
    dialog({ element: LoginDialog, props: {} });
};

/** 重置密码弹窗 */
export function resetPassWordDialog () {
    dialog({ element: ResetPassWordDialog, props: {} });
};

/** 注册弹窗 */
export function signUpDialog () {
    dialog({ element: SignUpDialog, props: {} });
};
/** 选择风格 */
export function selectStyleDialog () {
    dialog({ element: SelectStyleDialog, props: {} });
};

export function looksLikeDialog () {
    dialog({ element: LooksLikeDialog, props: {} });
};

export function guideDialog () {
    dialog({ element: LooksLikeGuideDialog, props: {} });
};

/** 产品需求 页面弹窗能够互相返回上一级  初步是三个类型 */
export let pageNextList = [];
export const pageDialog = {
    loginDialog,
    resetPassWordDialog,
    signUpDialog,
};
/** 清空页面栈 */
export const clearPageNextList = () => {
    pageNextList = [];
};
