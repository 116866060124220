import basic from '../basic/en'
export default {
    ...basic,
    ...{
        ok: "OK",
        tutorialEntrance: "How to make a quality creation",
        examplePopup: {
            title: "How to make high quality creation?",
            photoTip: "Tips: To get best ai face swap photo, please ensure the quality of the photo you want to reface online",
            videoTip: "Tips: To get best ai face swap video, please ensure the quality of the video you want to reface online",
            goodExample: "Good  examples",
            badExample: "Bad examples",
            highQuality: "High Quality",
            lookingAtViewer: "Looking at Viewer",
            sideFaces: "·Side faces",
            tooFar: "·Too Far",
            blurry: "Blurry",
            lessCover: "Less Cover",
            lowResolution: "Low Resolution",
        },
        youtubeSectionTitle: "How to use Face Swap?",
        tutorial: "Tutorial",
    },
}