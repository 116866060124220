<template>
<teleport to="#app">
  <div v-if="isShow" class="dialog-mask" @click.self="close">
    <div class="dialog-main">
      <slot></slot>
    </div>
  </div>
</teleport>
</template>

<script setup>
const { isShow } = defineProps({
    isShow: {
        type: Boolean,
        required: true,
        default: false,
    },
});
const emit = defineEmits(['update:isShow']);
const close = () => {
    emit('update:isShow', false);
};
</script>

  <style scoped lang="scss"> 
.dialog-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
}

.dialog-main {
  background: #27282d;
  border-radius: 20px;
  padding: 20px;
}
</style>
