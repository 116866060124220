<template>
  <div class="select-style">
    <div class="h1">{{ $t("selectStyle") }}</div>
    <div class="main">
<!--     样式列表-->


      <div class="list_box">

        <div class="list_box" v-if="txtToImgStore && txtToImgStore.styles.length > 0">
          <ScrollableView>
            <template #default>
              <div class="list"  >
                <div
                    class="item" ref="itemRef"
                    v-for="(item, index) in txtToImgStore.styles"
                    :key="item.styleKey"
                >
                  <div
                      :class="[
              'item-child',
              { act: index === txtToImgStore.currentStyleIndex },
            ]"
                      @click="setStyle($event, index)"
                  >
                    <ImageView class="item-child-img" :error-modul="'smBack'" :img-url=" item.styleImageUrl"></ImageView>
                  </div>
                  <div class="item-text">{{ item.styleName }}</div>
                </div>
              </div>
            </template>
          </ScrollableView>

        </div>

        <div class="list"  v-else>
          <div class="item item_def" v-for="item in 6">
            <ShimmerCard></ShimmerCard>
          </div>
        </div>
      </div>


<!--      更多-->
      <div class="more" @click="dialogShow">
        <div class="add">
          <img class="img" :src="txt_to_img_more" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { CommonUtility } from '@/script/utility';
import { selectStyleDialog } from '../Dialog';
import { useTxtToImg } from '@/stores/useTxtToImg';
import { getCurrentInstance, ref, watch } from 'vue';
import ImageView from '@/components/ImageView.vue';
import ShimmerCard from '@/components/common/ShimmerCard.vue';
import ScrollableView from '@/components/ScrollableView.vue';

const { $analytic } = getCurrentInstance().appContext.config.globalProperties;
const txtToImgStore = useTxtToImg();
const txt_to_img_more = CommonUtility.assetImage('txt_to_img_more');
const itemRef = ref(null);


const dialogShow = () => {
  selectStyleDialog();
};

watch(()=> txtToImgStore.currentStyleIndex, () => {
  itemRef?.value[txtToImgStore.currentStyleIndex].scrollIntoView({
    behavior: 'smooth',
    block: 'nearest',
    inline: 'center',
  });
});
const setStyle = (e, index) => {
  $analytic.log('creation_style_tick');
  txtToImgStore.changeCurrentStyleIndex(index);
  scrollToItem(e);
};
const scrollToItem = (event) => {
  const target = event.currentTarget;
  target.scrollIntoView({
    behavior: 'smooth',
    block: 'nearest',
    inline: 'center',
  });
};

</script>

<style scoped lang="scss">
.select-style {
  color: #ffffff;
  margin-top: 20px;
  .h1 {
    //font-family: 'PingFang SC';
    font-weight: bold;
    font-size: 24px;
    color: #ffffff;
    line-height: 28px;
  }
  .main {
    display: flex;
    margin-top: 20px;
    .list_box{
      flex: 1;
      overflow: hidden;
    }
    .list {
      width: max-content;
      display: flex;
      overflow-y: hidden;
      .item {
        cursor: pointer;
        width: 100px;
        margin-right: 17px;
        &:last-child {
          margin-right: 0;
        }
        .item-child {
          flex-shrink: 0;
          width: auto;
          height: auto;
          border-radius: 4px;
          border: 1px solid rgba($color: #ffffff, $alpha: 0.1);
          padding: 4px;
          background: #000;
          &:hover {
            border: 1px solid #ff7839;
          }
          .add {
            height: 100%;
            background: rgba(255, 255, 255, 0.1);
            border-radius: 9px;
            text-align: center;
            line-height: 70px;
            cursor: pointer;
          }
          .item-child-img {
            width: 92px;
            height: 74px;
            border-radius: 2px;
          }
          &.act {
            border: 1px solid #ff7839;
          }
        }
        .source_img{
          width: 50px;
          height: 50px;
          border-radius: 20px;
          overflow: hidden;
        }
        .item-text {
          font-weight: 500;
          font-size: 12px;
          color: #ffffff;
          line-height: 14px;
          text-align: center;
          margin-top: 8px;
          max-width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .item:hover {
          .item-child {
            border: 1px solid #ff7839;
          }
        }
      }
      .item_def{
        background: #282A33;
        border-radius: 20px;
        width: 92px;
        height: 74px;
      }


    }
    .more {
      width: 92px;
      height: 74px;
      border-radius: 4px;
      border: 1px solid rgba($color: #ffffff, $alpha: 0.1);
      padding: 4px;
      background: #090a15;
      margin-left: 17px;
      cursor: pointer;
      &:hover {
        border: 1px solid #ff7839;
        .img {
          content: url("@/assets/images/png/txt_to_img_more_act.png");
        }
      }
      .add {
        height: 100%;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 2px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        .img {
          width: 22px;
          height: 4px;
        }
      }
    }
  }
}
.aaaa{
  background-color: #43FF1F;
  color: #FFFFFF;
}
@media screen and (max-width: 1070px) {
  .select-style {
    .h1{
      font-weight: 600;
      font-size: 20px;
      color: #FFFFFF;
      line-height: 24px;
      text-align: left;
      font-style: normal;
    }
    .main{
      .list{
        .item{
          width: 79px;
          .item-child{
            padding: 2px;
            .item-child-img{
              width: 73px;
              height: 58px;
              border-radius: 2px;
            }
          }
          .item-text{
            max-width: 100%;
            display: block;
          }
        }
      }

      .more{
        width: 73px;
        height: 58px;
        padding: 2px;
        .img {
          width: 17px;
          height: 3px;
        }
      }
    }
  }
}
</style>
