export const devMockUploadFiles = [
    {
        file: {
            fileKey: 'HxwcjrhgnAGboVrf5',
            fileUrl: 'https://cf-cdn-aiimageweb-test.cfcdnfile.com/faceswap/analyserdemo_video4.m3u8',
            fileThumbUrl: 'https://gg-cdn-aiimageweb.test987.com/faceswap/analyserdemo_video4_thumb.voc',
            fileType: 'video',
            fileSize: 20000,
            fileWidth: 540,
            fileHeight: 540,
            fileDuration: 5.0,
            fileFps: 22.756410256410255,
            taskId: 'HxwcjrhgnAGboVrf5',
            demoTaskId: 'uagiTuLxOQZSsGbd5',
            file: null,
            demoFlag: true,
            demoIndex: 3,
            urlSource: 'google',
            analyserTaskId: 'uagiTuLxOQZSsGbd5',
        },
        demoIndex: 3,
        demoFlag: true,
        ctime: 1719543489,
        faces: [
            {
                id: 11322,
                faceKey: '0',
                url: 'https://gg-cdn-aiimageweb.test987.com/faceswap/analyserdemo_video4_face1.voc',
                ctime: 1719543489,
                urlSource: 'google',
            },
            {
                id: 11323,
                faceKey: '1',
                url: 'https://gg-cdn-aiimageweb.test987.com/faceswap/analyserdemo_video4_face2.voc',
                ctime: 1719543489,
                urlSource: 'google',
            },
            {
                id: 11324,
                faceKey: '2',
                url: 'https://gg-cdn-aiimageweb.test987.com/faceswap/analyserdemo_video4_face3.voc',
                ctime: 1719543489,
                urlSource: 'google',
            },
            {
                id: 11325,
                faceKey: '3',
                url: 'https://gg-cdn-aiimageweb.test987.com/faceswap/analyserdemo_video4_face4.voc',
                ctime: 1719543489,
                urlSource: 'google',
            },
            {
                id: 11326,
                faceKey: '4',
                url: 'https://gg-cdn-aiimageweb.test987.com/faceswap/analyserdemo_video4_face5.voc',
                ctime: 1719543489,
                urlSource: 'google',
            },
        ],
        taskId: 'HxwcjrhgnAGboVrf5',
    },
    {
        file: {
            fileKey: 'TtMeLDzROHWJoLkO5',
            fileUrl: 'https://cf-cdn-aiimageweb-test.cfcdnfile.com/faceswap/analyserdemo_video3.m3u8',
            fileThumbUrl: 'https://gg-cdn-aiimageweb.test987.com/faceswap/analyserdemo_video3_thumb.voc',
            fileType: 'video',
            fileSize: 20000,
            fileWidth: 540,
            fileHeight: 540,
            fileDuration: 5.0,
            fileFps: 23.954269122584158,
            taskId: 'TtMeLDzROHWJoLkO5',
            demoTaskId: 'RrhHDPpzbUFrtegu5',
            file: null,
            demoFlag: true,
            demoIndex: 3,
            urlSource: 'google',
            analyserTaskId: 'RrhHDPpzbUFrtegu5',
        },
        demoIndex: 3,
        demoFlag: true,
        ctime: 1719543489,
        faces: [
            {
                id: 11320,
                faceKey: '0',
                url: 'https://gg-cdn-aiimageweb.test987.com/faceswap/analyserdemo_video3_face1.voc',
                ctime: 1719543489,
                urlSource: 'google',
            },
            {
                id: 11321,
                faceKey: '1',
                url: 'https://gg-cdn-aiimageweb.test987.com/faceswap/analyserdemo_video3_face2.voc',
                ctime: 1719543489,
                urlSource: 'google',
            },
        ],
        taskId: 'TtMeLDzROHWJoLkO5',
    },
    {
        file: {
            fileKey: 'oQqhnLZKPjbRgEZY5',
            fileUrl: 'https://cf-cdn-aiimageweb-test.cfcdnfile.com/faceswap/analyserdemo_video2.m3u8',
            fileThumbUrl: 'https://gg-cdn-aiimageweb.test987.com/faceswap/analyserdemo_video2_thumb.voc',
            fileType: 'video',
            fileSize: 20000,
            fileWidth: 540,
            fileHeight: 540,
            fileDuration: 5.0,
            fileFps: 25.0,
            taskId: 'oQqhnLZKPjbRgEZY5',
            demoTaskId: 'TCHUnvBVYDCgXQvN5',
            file: null,
            demoFlag: true,
            demoIndex: 2,
            urlSource: 'google',
            analyserTaskId: 'TCHUnvBVYDCgXQvN5',
        },
        demoIndex: 2,
        demoFlag: true,
        ctime: 1719543489,
        faces: [
            {
                id: 11319,
                faceKey: '0',
                url: 'https://gg-cdn-aiimageweb.test987.com/faceswap/analyserdemo_video2_face.voc',
                ctime: 1719543489,
                urlSource: 'google',
            },
        ],
        taskId: 'oQqhnLZKPjbRgEZY5',
    },
    {
        file: {
            fileKey: 'eEycWatwVwxaxSCD5',
            fileUrl: 'https://cf-cdn-aiimageweb-test.cfcdnfile.com/faceswap/analyserdemo_video1.m3u8',
            fileThumbUrl: 'https://gg-cdn-aiimageweb.test987.com/faceswap/analyserdemo_video1_thumb.voc',
            fileType: 'video',
            fileSize: 20000,
            fileWidth: 540,
            fileHeight: 540,
            fileDuration: 5.0,
            fileFps: 25.0,
            taskId: 'eEycWatwVwxaxSCD5',
            demoTaskId: 'pEamAtnRAXxQqhrv5',
            file: null,
            demoFlag: true,
            demoIndex: 2,
            urlSource: 'google',
            analyserTaskId: 'pEamAtnRAXxQqhrv5',
        },
        demoIndex: 2,
        demoFlag: true,
        ctime: 1719543489,
        faces: [
            {
                id: 11318,
                faceKey: '0',
                url: 'https://gg-cdn-aiimageweb.test987.com/faceswap/analyserdemo_video1_face.voc',
                ctime: 1719543489,
                urlSource: 'google',
            },
        ],
        taskId: 'eEycWatwVwxaxSCD5',
    },
    {
        file: {
            fileKey: 'VBkquewSglbcKuxd5',
            fileUrl: 'https://gg-cdn-aiimageweb.test987.com/faceswap/analyserdemo4.jpg',
            fileThumbUrl: 'https://gg-cdn-aiimageweb.test987.com/faceswap/analyserdemo4.jpg',
            fileType: 'image',
            fileSize: 20000,
            fileWidth: 540,
            fileHeight: 540,
            fileDuration: 0.0,
            fileFps: 0.0,
            taskId: 'VBkquewSglbcKuxd5',
            demoTaskId: 'hZBxnQOUdfFdcZtA5',
            file: null,
            demoFlag: true,
            demoIndex: 1,
            urlSource: 'google',
            analyserTaskId: 'hZBxnQOUdfFdcZtA5',
        },
        demoIndex: 1,
        demoFlag: true,
        ctime: 1719543489,
        faces: [
            {
                id: 11314,
                faceKey: '0',
                url: 'https://gg-cdn-aiimageweb.test987.com/faceswap/analyserdemo4_face1.voc',
                ctime: 1719543489,
                urlSource: 'google',
            },
            {
                id: 11315,
                faceKey: '1',
                url: 'https://gg-cdn-aiimageweb.test987.com/faceswap/analyserdemo4_face2.voc',
                ctime: 1719543489,
                urlSource: 'google',
            },
            {
                id: 11316,
                faceKey: '2',
                url: 'https://gg-cdn-aiimageweb.test987.com/faceswap/analyserdemo4_face3.voc',
                ctime: 1719543489,
                urlSource: 'google',
            },
            {
                id: 11317,
                faceKey: '3',
                url: 'https://gg-cdn-aiimageweb.test987.com/faceswap/analyserdemo4_face4.voc',
                ctime: 1719543489,
                urlSource: 'google',
            },
        ],
        taskId: 'VBkquewSglbcKuxd5',
    },
    {
        file: {
            fileKey: 'csfIoZynrtJJuNmg5',
            fileUrl: 'https://gg-cdn-aiimageweb.test987.com/faceswap/analyserdemo3.jpg',
            fileThumbUrl: 'https://gg-cdn-aiimageweb.test987.com/faceswap/analyserdemo3.jpg',
            fileType: 'image',
            fileSize: 20000,
            fileWidth: 540,
            fileHeight: 540,
            fileDuration: 0.0,
            fileFps: 0.0,
            taskId: 'csfIoZynrtJJuNmg5',
            demoTaskId: 'wUcPFkmapTjACqfM5',
            file: null,
            demoFlag: true,
            demoIndex: 1,
            urlSource: 'google',
            analyserTaskId: 'wUcPFkmapTjACqfM5',
        },
        demoIndex: 1,
        demoFlag: true,
        ctime: 1719543489,
        faces: [
            {
                id: 11312,
                faceKey: '0',
                url: 'https://gg-cdn-aiimageweb.test987.com/faceswap/analyserdemo3_face1.voc',
                ctime: 1719543489,
                urlSource: 'google',
            },
            {
                id: 11313,
                faceKey: '1',
                url: 'https://gg-cdn-aiimageweb.test987.com/faceswap/analyserdemo3_face2.voc',
                ctime: 1719543489,
                urlSource: 'google',
            },
        ],
        taskId: 'csfIoZynrtJJuNmg5',
    },
    {
        file: {
            fileKey: 'ThvPvToiebGKlrgi5',
            fileUrl: 'https://gg-cdn-aiimageweb.test987.com/faceswap/analyserdemo2.jpg',
            fileThumbUrl: 'https://gg-cdn-aiimageweb.test987.com/faceswap/analyserdemo2.jpg',
            fileType: 'image',
            fileSize: 20000,
            fileWidth: 540,
            fileHeight: 540,
            fileDuration: 0.0,
            fileFps: 0.0,
            taskId: 'ThvPvToiebGKlrgi5',
            demoTaskId: 'iGmoYMwSCunVMkOq5',
            file: null,
            demoFlag: true,
            demoIndex: 0,
            urlSource: 'google',
            analyserTaskId: 'iGmoYMwSCunVMkOq5',
        },
        demoIndex: 0,
        demoFlag: true,
        ctime: 1719543489,
        faces: [
            {
                id: 11311,
                faceKey: '0',
                url: 'https://gg-cdn-aiimageweb.test987.com/faceswap/analyserdemo2_face.voc',
                ctime: 1719543489,
                urlSource: 'google',
            },
        ],
        taskId: 'ThvPvToiebGKlrgi5',
    },
    {
        file: {
            fileKey: 'EOmqWKHSZkpuKLeS5',
            fileUrl: 'https://gg-cdn-aiimageweb.test987.com/faceswap/analyserdemo1.jpg',
            fileThumbUrl: 'https://gg-cdn-aiimageweb.test987.com/faceswap/analyserdemo1.jpg',
            fileType: 'image',
            fileSize: 20000,
            fileWidth: 540,
            fileHeight: 540,
            fileDuration: 0.0,
            fileFps: 0.0,
            taskId: 'EOmqWKHSZkpuKLeS5',
            demoTaskId: 'JDWBLrhNyYNoGaqb5',
            file: null,
            demoFlag: true,
            demoIndex: 0,
            urlSource: 'google',
            analyserTaskId: 'JDWBLrhNyYNoGaqb5',
        },
        demoIndex: 0,
        demoFlag: true,
        ctime: 1719543489,
        faces: [
            {
                id: 11310,
                faceKey: '0',
                url: 'https://gg-cdn-aiimageweb.test987.com/faceswap/analyserdemo1_face.voc',
                ctime: 1719543489,
                urlSource: 'google',
            },
        ],
        taskId: 'EOmqWKHSZkpuKLeS5',
    },
];

export const devMockFaces = [
    {
        key: 6258,
        id: 6258,
        url: 'https://gg-cdn-aiimageweb.test987.com/ai/lookslike/demo1.jpg',
        ctime: 1719543486,
        demoFlag: true,
        urlSource: 'google',
    },
    {
        key: 6257,
        id: 6257,
        url: 'https://gg-cdn-aiimageweb.test987.com/ai/lookslike/demo2.jpg',
        ctime: 1719543486,
        demoFlag: true,
        urlSource: 'google',
    },
];

export const prodMockUploadFiles = [
    {
        file: {
            fileKey: 'HxwcjrhgnAGboVrf5',
            fileUrl: 'https://beautyfile.cfcdnfile.com/faceswap/analyserdemo_video4.m3u8',
            fileThumbUrl: 'https://fileg.beautyai.fun/faceswap/analyserdemo_video4_thumb.voc',
            fileType: 'video',
            fileSize: 20000,
            fileWidth: 540,
            fileHeight: 540,
            fileDuration: 5.0,
            fileFps: 22.756410256410255,
            taskId: 'HxwcjrhgnAGboVrf5',
            demoTaskId: 'uagiTuLxOQZSsGbd5',
            file: null,
            demoFlag: true,
            demoIndex: 3,
            urlSource: 'google',
            analyserTaskId: 'uagiTuLxOQZSsGbd5',
        },
        demoIndex: 3,
        demoFlag: true,
        ctime: 1719543489,
        faces: [
            {
                id: 11322,
                faceKey: '0',
                url: 'https://fileg.beautyai.fun/faceswap/analyserdemo_video4_face1.voc',
                ctime: 1719543489,
                urlSource: 'google',
            },
            {
                id: 11323,
                faceKey: '1',
                url: 'https://fileg.beautyai.fun/faceswap/analyserdemo_video4_face2.voc',
                ctime: 1719543489,
                urlSource: 'google',
            },
            {
                id: 11324,
                faceKey: '2',
                url: 'https://fileg.beautyai.fun/faceswap/analyserdemo_video4_face3.voc',
                ctime: 1719543489,
                urlSource: 'google',
            },
            {
                id: 11325,
                faceKey: '3',
                url: 'https://fileg.beautyai.fun/faceswap/analyserdemo_video4_face4.voc',
                ctime: 1719543489,
                urlSource: 'google',
            },
            {
                id: 11326,
                faceKey: '4',
                url: 'https://fileg.beautyai.fun/faceswap/analyserdemo_video4_face5.voc',
                ctime: 1719543489,
                urlSource: 'google',
            },
        ],
        taskId: 'HxwcjrhgnAGboVrf5',
    },
    {
        file: {
            fileKey: 'TtMeLDzROHWJoLkO5',
            fileUrl: 'https://beautyfile.cfcdnfile.com/faceswap/analyserdemo_video3.m3u8',
            fileThumbUrl: 'https://fileg.beautyai.fun/faceswap/analyserdemo_video3_thumb.voc',
            fileType: 'video',
            fileSize: 20000,
            fileWidth: 540,
            fileHeight: 540,
            fileDuration: 5.0,
            fileFps: 23.954269122584158,
            taskId: 'TtMeLDzROHWJoLkO5',
            demoTaskId: 'RrhHDPpzbUFrtegu5',
            file: null,
            demoFlag: true,
            demoIndex: 3,
            urlSource: 'google',
            analyserTaskId: 'RrhHDPpzbUFrtegu5',
        },
        demoIndex: 3,
        demoFlag: true,
        ctime: 1719543489,
        faces: [
            {
                id: 11320,
                faceKey: '0',
                url: 'https://fileg.beautyai.fun/faceswap/analyserdemo_video3_face1.voc',
                ctime: 1719543489,
                urlSource: 'google',
            },
            {
                id: 11321,
                faceKey: '1',
                url: 'https://fileg.beautyai.fun/faceswap/analyserdemo_video3_face2.voc',
                ctime: 1719543489,
                urlSource: 'google',
            },
        ],
        taskId: 'TtMeLDzROHWJoLkO5',
    },
    {
        file: {
            fileKey: 'oQqhnLZKPjbRgEZY5',
            fileUrl: 'https://beautyfile.cfcdnfile.com/faceswap/analyserdemo_video2.m3u8',
            fileThumbUrl: 'https://fileg.beautyai.fun/faceswap/analyserdemo_video2_thumb.voc',
            fileType: 'video',
            fileSize: 20000,
            fileWidth: 540,
            fileHeight: 540,
            fileDuration: 5.0,
            fileFps: 25.0,
            taskId: 'oQqhnLZKPjbRgEZY5',
            demoTaskId: 'TCHUnvBVYDCgXQvN5',
            file: null,
            demoFlag: true,
            demoIndex: 2,
            urlSource: 'google',
            analyserTaskId: 'TCHUnvBVYDCgXQvN5',
        },
        demoIndex: 2,
        demoFlag: true,
        ctime: 1719543489,
        faces: [
            {
                id: 11319,
                faceKey: '0',
                url: 'https://fileg.beautyai.fun/faceswap/analyserdemo_video2_face.voc',
                ctime: 1719543489,
                urlSource: 'google',
            },
        ],
        taskId: 'oQqhnLZKPjbRgEZY5',
    },
    {
        file: {
            fileKey: 'eEycWatwVwxaxSCD5',
            fileUrl: 'https://beautyfile.cfcdnfile.com/faceswap/analyserdemo_video1.m3u8',
            fileThumbUrl: 'https://fileg.beautyai.fun/faceswap/analyserdemo_video1_thumb.voc',
            fileType: 'video',
            fileSize: 20000,
            fileWidth: 540,
            fileHeight: 540,
            fileDuration: 5.0,
            fileFps: 25.0,
            taskId: 'eEycWatwVwxaxSCD5',
            demoTaskId: 'pEamAtnRAXxQqhrv5',
            file: null,
            demoFlag: true,
            demoIndex: 2,
            urlSource: 'google',
            analyserTaskId: 'pEamAtnRAXxQqhrv5',
        },
        demoIndex: 2,
        demoFlag: true,
        ctime: 1719543489,
        faces: [
            {
                id: 11318,
                faceKey: '0',
                url: 'https://fileg.beautyai.fun/faceswap/analyserdemo_video1_face.voc',
                ctime: 1719543489,
                urlSource: 'google',
            },
        ],
        taskId: 'eEycWatwVwxaxSCD5',
    },
    {
        file: {
            fileKey: 'VBkquewSglbcKuxd5',
            fileUrl: 'https://fileg.beautyai.fun/faceswap/analyserdemo4.jpg',
            fileThumbUrl: 'https://fileg.beautyai.fun/faceswap/analyserdemo4.jpg',
            fileType: 'image',
            fileSize: 20000,
            fileWidth: 540,
            fileHeight: 540,
            fileDuration: 0.0,
            fileFps: 0.0,
            taskId: 'VBkquewSglbcKuxd5',
            demoTaskId: 'hZBxnQOUdfFdcZtA5',
            file: null,
            demoFlag: true,
            demoIndex: 1,
            urlSource: 'google',
            analyserTaskId: 'hZBxnQOUdfFdcZtA5',
        },
        demoIndex: 1,
        demoFlag: true,
        ctime: 1719543489,
        faces: [
            {
                id: 11314,
                faceKey: '0',
                url: 'https://fileg.beautyai.fun/faceswap/analyserdemo4_face1.voc',
                ctime: 1719543489,
                urlSource: 'google',
            },
            {
                id: 11315,
                faceKey: '1',
                url: 'https://fileg.beautyai.fun/faceswap/analyserdemo4_face2.voc',
                ctime: 1719543489,
                urlSource: 'google',
            },
            {
                id: 11316,
                faceKey: '2',
                url: 'https://fileg.beautyai.fun/faceswap/analyserdemo4_face3.voc',
                ctime: 1719543489,
                urlSource: 'google',
            },
            {
                id: 11317,
                faceKey: '3',
                url: 'https://fileg.beautyai.fun/faceswap/analyserdemo4_face4.voc',
                ctime: 1719543489,
                urlSource: 'google',
            },
        ],
        taskId: 'VBkquewSglbcKuxd5',
    },
    {
        file: {
            fileKey: 'csfIoZynrtJJuNmg5',
            fileUrl: 'https://fileg.beautyai.fun/faceswap/analyserdemo3.jpg',
            fileThumbUrl: 'https://fileg.beautyai.fun/faceswap/analyserdemo3.jpg',
            fileType: 'image',
            fileSize: 20000,
            fileWidth: 540,
            fileHeight: 540,
            fileDuration: 0.0,
            fileFps: 0.0,
            taskId: 'csfIoZynrtJJuNmg5',
            demoTaskId: 'wUcPFkmapTjACqfM5',
            file: null,
            demoFlag: true,
            demoIndex: 1,
            urlSource: 'google',
            analyserTaskId: 'wUcPFkmapTjACqfM5',
        },
        demoIndex: 1,
        demoFlag: true,
        ctime: 1719543489,
        faces: [
            {
                id: 11312,
                faceKey: '0',
                url: 'https://fileg.beautyai.fun/faceswap/analyserdemo3_face1.voc',
                ctime: 1719543489,
                urlSource: 'google',
            },
            {
                id: 11313,
                faceKey: '1',
                url: 'https://fileg.beautyai.fun/faceswap/analyserdemo3_face2.voc',
                ctime: 1719543489,
                urlSource: 'google',
            },
        ],
        taskId: 'csfIoZynrtJJuNmg5',
    },
    {
        file: {
            fileKey: 'ThvPvToiebGKlrgi5',
            fileUrl: 'https://fileg.beautyai.fun/faceswap/analyserdemo2.jpg',
            fileThumbUrl: 'https://fileg.beautyai.fun/faceswap/analyserdemo2.jpg',
            fileType: 'image',
            fileSize: 20000,
            fileWidth: 540,
            fileHeight: 540,
            fileDuration: 0.0,
            fileFps: 0.0,
            taskId: 'ThvPvToiebGKlrgi5',
            demoTaskId: 'iGmoYMwSCunVMkOq5',
            file: null,
            demoFlag: true,
            demoIndex: 0,
            urlSource: 'google',
            analyserTaskId: 'iGmoYMwSCunVMkOq5',
        },
        demoIndex: 0,
        demoFlag: true,
        ctime: 1719543489,
        faces: [
            {
                id: 11311,
                faceKey: '0',
                url: 'https://fileg.beautyai.fun/faceswap/analyserdemo2_face.voc',
                ctime: 1719543489,
                urlSource: 'google',
            },
        ],
        taskId: 'ThvPvToiebGKlrgi5',
    },
    {
        file: {
            fileKey: 'EOmqWKHSZkpuKLeS5',
            fileUrl: 'https://fileg.beautyai.fun/faceswap/analyserdemo1.jpg',
            fileThumbUrl: 'https://fileg.beautyai.fun/faceswap/analyserdemo1.jpg',
            fileType: 'image',
            fileSize: 20000,
            fileWidth: 540,
            fileHeight: 540,
            fileDuration: 0.0,
            fileFps: 0.0,
            taskId: 'EOmqWKHSZkpuKLeS5',
            demoTaskId: 'JDWBLrhNyYNoGaqb5',
            file: null,
            demoFlag: true,
            demoIndex: 0,
            urlSource: 'google',
            analyserTaskId: 'JDWBLrhNyYNoGaqb5',
        },
        demoIndex: 0,
        demoFlag: true,
        ctime: 1719543489,
        faces: [
            {
                id: 11310,
                faceKey: '0',
                url: 'https://fileg.beautyai.fun/faceswap/analyserdemo1_face.voc',
                ctime: 1719543489,
                urlSource: 'google',
            },
        ],
        taskId: 'EOmqWKHSZkpuKLeS5',
    },
];

export const prodMockFaces = [
    {
        key: 10,
        id: 10,
        url: 'https://aiimageweb-global.beautyai.fun/ai/lookslike/demo1.jpg',
        ctime: 1694780087,
        demoFlag: true,
        urlSource: '',
    },
    {
        key: 9,
        id: 9,
        url: 'https://aiimageweb-global.beautyai.fun/ai/lookslike/demo2.jpg',
        ctime: 1694780087,
        demoFlag: true,
        urlSource: '',
    },
];