<template>
  <teleport to="#app">
    <div class="dialog-mask">
      <div :class="['dialog-main', { refersignup : isrefer }]">
        <div class="close-left">
          <img v-if="pageNextList.length" :src="back_icon" @click="backPage" alt="" />
          <div @click.self="close"
          class="img"></div>
        </div>
        <div class="tit-login">{{ $t("signup") }}</div>
        <div class="tit-no-acc">
          <div>{{ $t("choose.readyaccount") }}</div>
          <div @click="toLogin" class="sign">&nbsp;{{ $t("login") }}</div>
        </div>
        <div :class="['user-name', { err: emailErr }]">
          <input
              ref="userNameRef"
            type="text"
            class="input"
            maxlength="36"
            @input="validateInput"
            @focus="nameInputFocus"
            @blur="nameBlur"
            v-model="state.userName"
            :placeholder="$t('choose.usernamePlace')"
          />
          <div class="clear-img" @click="() => {
            state.userName = '';
          }"></div>
          <div v-if="emailErr" class="email-err">
            {{$t('choose.emailTip')}}
          </div>
          <div
            class="tip-mask"
            v-if="userName && userName[userName.length - 1] == '@'"
          >
            <div
              @click="setUserNameEmail(item)"
              class="select"
              v-for="item in emailList"
              :key="item"
            >
              {{ item }}
            </div>
          </div>
        </div>
        <!-- 邮箱验证 -->
        <div v-if="isrefer" :class="['user-name', 'code']">
          <input
            type="text"
            @input="validateCode"
            :disabled="!codeSended"
            autocomplete='off'
            class="input"
            maxlength="6"
            v-model="code"
            :placeholder="$t('choose.emailCode')"
          />
          <!-- 掩盖密码管理 -->
          <input type="password" autocomplete="new-password" style="display: none;" />
          <div @click="sendCode" :class="['send-code', {act: getnBtnShow}]">{{ statusText ==='Send Code'? $t('choose.sendcode') : statusText }}</div>
        </div>
        <div :class="['user-name', 'pass-word', { err: passWordErr }]">
          <input
            class="input"
            @blur="wordBlur"
            @focus="pwdInputFocus"
            :type="isShowPassWord ? 'text' : 'password'"
            v-model="passWord"
            maxlength="100"
            minlength="8"
            :placeholder="$t('choose.passWordErr')"
          />
          <div class="clear-img" @click="() => {
            state.passWord = '';
          }"></div>
          <img
            class="img"
            @click="setShowpassWord"
            v-if="isShowPassWord"
            :src="show_pass_word"
            alt=""
          />
          <img
            class="img"
            @click="setShowpassWord"
            v-if="!isShowPassWord"
            :src="hidd_pass_word"
            alt=""
          />
          <div v-if="passWordErr" class="email-err">
            {{$t('choose.passWordErr')}}
          </div>
        </div>
        <div ref="loginBtn" :class="['login-btn', { act: isCanLoging }]" @click="loginfun">
          {{ $t("choose.signupfree") }}
        </div>
      </div>
    </div>
  </teleport>
</template>

<script setup>
import {reactive, toRefs, getCurrentInstance, computed, watchEffect, onMounted, ref, onUnmounted} from 'vue';
import { validateEmail } from '@/utils';
import { User } from '@/script/user';

import lottieLoadingAnimate from '@/assets/animationJson/white_circle_loading';
import lottie from 'lottie-web';
import { clearPageNextList, loginDialog, pageDialog, pageNextList } from './Dialog';
import {CommonUtility} from "@/script/utility";
let { $utility, $showToast, $showErrorToast, $analytic, i18n } = getCurrentInstance().appContext.config.globalProperties;
const google = $utility.assetImage('google');
const back_icon = $utility.assetImage('back_icon');
const show_pass_word = $utility.assetImage('show_pass_word');
const hidd_pass_word = $utility.assetImage('hidd_pass_word');
const isrefer = computed(() => (window.localStorage['referKey'] || '').length > 0)
const codeSended = ref(false);
// 用户名输入框ref
const userNameRef = ref(null);
const props = defineProps({
    close: {
      type: Function,
    },
});

const emailList = ['@gmail.com', '@outlook.com', '@yahoo.com', '@hotmail.com', '@icloud.com', '@aol.com', '@protonmail.com'];
const loginBtn = ref(null);
const state = reactive({
  userName: '',
  passWord: '',
  isShowPassWord: false,
  isCanLoging: false,
  emailErr: false,
  passWordErr: false,
  code: '',
  statusText: 'Send Code',
});
const getnBtnShow = ref(true)
// 获取验证码状态；
let getCodeSta= ref(true)
watchEffect(() => {
  state.isCanLoging = !(
      (isrefer.value ? code.value : true) &&
    state.userName &&
    validateEmail(state.userName) &&
    !state.emailErr &&
    state.passWord &&
    state.passWord.length >= 8 &&
    !state.passWordErr
);
  getnBtnShow.value = !(validateEmail(state.userName) && getCodeSta.value);
});

const emit = defineEmits(['update:isShow', 'back']);
const close = () => {
  clearPageNextList();
  props.close?.();
};
const setShowpassWord = () => {
  state.isShowPassWord = !state.isShowPassWord;
};
const setUserNameEmail = (val) => {
  const str = state.userName.slice(0, state.userName.length - 1);
  state.userName = str + val;
  userNameRef.value.focus()
};
const validateInput = () => {
      const newValue = state.userName.replace(/\s+/g, '');
  state.userName = CommonUtility.replaceUtZh(newValue);

};
const validateCode = () => {
      const newValue = state.code.replace(/[^-?\d]/g, '');
      state.code = newValue;
};
const nameBlur = () => {
  setTimeout(() => {
    state.emailErr = state.userName && !validateEmail(state.userName);
  }, 300);
};


/**
 * 邮箱框获得焦点
 */
const nameInputFocus = () => {
  if (state.emailErr) {
    state.emailErr = false
  }
}
const wordBlur = () => {
  state.passWordErr = state.passWord && state.passWord.length < 8;
};
/**
 * 密码框获得焦点
 */
const pwdInputFocus = () => {
  if (state.passWordErr) {
    state.passWordErr = false
  }
}
/**
 * 清空计时器
 */
const clearIntervalGetCode = () => {
  clearInterval(interval);
  state.statusText = 'Send Code';
  getCodeSta.value = true
}
const loginfun = () => {
  //  清空计时器: 此处逻辑是点击提交后重置验证码获取时间
  // clearIntervalGetCode()
  setTimeout(async () => {
    if (!state.isCanLoging) {
        const text = loginBtn.value.innerText;
  loginBtn.value.innerText = '';
    const lottieAnim = lottie.loadAnimation({
    container: loginBtn.value,
    renderer: 'svg',
    loop: true,
    autoplay: true,
    animationData: lottieLoadingAnimate,
  });
      $analytic.log('register_button_tick', { site: isrefer.value ? 'referal' : $analytic.page });
      let res = null;
      // 是否被其他人邀请注册
      if (isrefer.value) {
        res = await User.getInstance.referSignupUser({
          email: state.userName,
          password: state.passWord,
          verifyCode: state.code,
          referralKey: window.localStorage['referKey'],
        });
        window.localStorage.removeItem('referKey')
      } else {
        res = await User.getInstance.signupUser({
          email: state.userName,
          password: state.passWord,
        });
      }
        lottieAnim.destroy();
        loginBtn.value.innerText = text;
      if (res.code === 200) {
        $analytic.log('register_finished', { site: isrefer.value ? 'referal' : $analytic.page });
        $analytic.log('register_login_finished', { site: isrefer.value ? 'referal' : $analytic.page });
        if (isrefer.value) {
          $analytic.log('register_login_referral_finished');
        }else if ($analytic.page == 'faceswap') {
          $analytic.log('register_login_faceswap_finished');
        }else if ($analytic.page == "creation") {
          $analytic.log('register_login_creation_finished')
        }
      }
      if (res.code === 200) {
        $showToast(i18n.global.t('choose.signupsuccess'));

        close();
        return;
      }
      $showErrorToast(res.message || i18n.global.t('choose.logingerror'));
    }
  });
};
const toLogin = () => {
  pageNextList.push('signUpDialog');
  props.close();
  loginDialog();
};
const backPage = () => {
  const type = pageNextList.pop();
  pageDialog[type]?.();
  props.close();
};


let interval = null;
// 邮箱验证
const sendCode = async () => {
  if (state.statusText !== 'Send Code' || !state.userName || state.emailErr || !validateEmail(state.userName)) return;
  $analytic.log('register_button_code_tick', { site: isrefer.value ? 'referal' : $analytic.page });
  const res = await User.getInstance.sendSignupVerifyCode({ email: state.userName });
  if (res === false) {
    $showErrorToast(i18n.global.t('choose.senderror'));
    return;
  }
  if(res.code === 200) {
    $showToast(i18n.global.t('choose.sendsuccess'));
  } else {
    $showErrorToast(res.message || i18n.global.t('choose.logingerror'));
    return;
  }
  getnBtnShow.value = true
  getCodeSta.value = false
  codeSended.value = true
  clearInterval(interval);
  let timeLeft = 60;
  state.statusText = `${timeLeft}S`;
  timeLeft --
  interval = setInterval(() => {
    state.statusText = `${timeLeft}S`;
    getnBtnShow.value = true
    if (timeLeft <= 0) {
      clearIntervalGetCode();
    }
    timeLeft --
  }, 1000);
};
onUnmounted(()=>{
  clearInterval(interval);
})
const {
  userName,
  passWord,
  isCanLoging,
  isShowPassWord,
  emailErr,
  passWordErr,
  code,
  statusText,
} = toRefs(state);


onMounted(() => {
  $analytic.log('register_screen_express', { site: isrefer.value ? 'referal' : $analytic.page });
  if (isrefer.value) {
    $analytic.log('register_screen_referral_express');
  }else if ($analytic.page == 'faceswap') {
    $analytic.log('register_screen_faceswap_express');
  }else if ($analytic.page == 'creation') {
    $analytic.log('register_screen_creation_express');
  }
});
</script>

  <style scoped lang='scss'>
  input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-text-fill-color: #ededed !important; //这个地方的颜色是字体颜色，可以根据实际情况修改
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important; //设置input输入框的背景颜色为透明色
  background-color: transparent; //设置input输入框的背景颜色为透明色
  background-image: none;
  transition: background-color 50000s ease-in-out 0s;
}
input {
  background-color: transparent; //设置input输入框的背景颜色为透明色
}
.dialog-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
  .dialog-main {
    width: 320px;
    height: 330px;
    background: #27282d;
    border-radius: 20px;
    padding: 20px;
    .close-left {
      overflow: hidden;
      img{
        width: 32px;
        height: 32px;
      }
      .img {
        width: 32px;
        height: 32px;
        float: right;
        background: url('@/assets/images/png/close_icon.png');
        background-size: contain;
        &:hover {
          background: url('@/assets/images/png/close_icon_hover.png');
          background-size: contain;
        }
      }
    }
    .tit-login {
      font-weight: bold;
      font-size: 30px;
      color: #ffffff;
      line-height: 36px;
      text-align: center;
    }
    .tit-no-acc {
      display: flex;
      justify-content: center;
      margin-top: 10px;
      font-weight: 500;
      font-size: 14px;
      color: #f3f9ff;
      line-height: 17px;
      text-align: center;
      .sign {
        color: #ff9441;
        cursor: pointer;
      }
    }
    .user-name {
      height: 44px;
      border-radius: 22px;
      margin-top: 30px;
      background: rgba(0, 0, 0, 0.2);
      position: relative;
      .input {
        height: 100%;
        width: calc(100% - 28px);
        color: #f3f9ff;
        border: none;
        outline: none;
        border-radius: 22px;
        padding: 0 14px;
        background: none;
        &::placeholder,
        &::-webkit-input-placeholder {
          font-weight: 500;
          font-size: 14px;
          color: #494c52;
        }
      }
      .clear-img {
        cursor: pointer;
        position: absolute;
        top: 8px;
        width: 28px;
        height: 28px;
        right: 14px;
        transform: scale(0.5);
        background: url('@/assets/images/png/clear_icon.png') no-repeat center center;
        &:hover {
          background: url('@/assets/images/png/clear_icon_act.png') no-repeat center center;
        }
      }
      &.err .input {
        color: #e05435;
      }
      .email-err {
        position: absolute;
        bottom: -14px;
        left: 15px;
        height: 13px;
        font-family: 'PingFang SC';
        font-weight: 500;
        font-size: 10px;
        color: #e05435;
        line-height: 13px;
      }
      .tip-mask {
        position: absolute;
        bottom: -112px;
        z-index: 1;
        left: 0;
        width: calc(100% - 20px);
        height: 90px;
        padding: 10px;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 18px;
        backdrop-filter: blur(4px);
        display: flex;
        flex-wrap: wrap;
        .select {
          cursor: pointer;
          padding: 0 10px;
          font-weight: 500;
          font-size: 10px;
          color: #ffffff;
          line-height: 24px;
          text-align: center;
          background: rgba(255, 255, 255, 0.1);
          border-radius: 19px;
          margin-bottom: 10px;
          margin-right: 10px;
          &:hover {
            background: linear-gradient(11deg, #d33a31 0%, #ff9441 100%);
          }
        }
      }
      &.code {
        background: none;
        .input {
          width: calc(100% - 125px);
          background: rgba(0, 0, 0, 0.2);
        }
        .send-code {
          cursor: pointer;
          width: 85px;
          height: 38px;
          margin-top: 3px;
          background: linear-gradient(11deg, #d33a31 0%, #ff9441 100%),
            linear-gradient(90deg, #541613 0%, #673c1a 100%);
          border-radius: 22px;
          float: right;
          font-family: 'PingFang SC';
          font-weight: bold;
          font-size: 12px;
          color: #ffffff;
          line-height: 38px;
          text-align: center;
          &.act {
                    color: #af9486;

        background: linear-gradient(90deg, #541613 0%, #673c1a 100%);
          }
        }
      }
    }
    .pass-word {
      display: flex;
      align-content: center;
      margin-top: 25px;
      .input {
        width: calc(100% - 70px);
      }
      .clear-img {
        right: 46px;
      }
      .img {
        cursor: pointer;
        width: 28px;
        height: 28px;
        padding-top: 8px;
      }
      &.err .input {
        color: #e05435;
      }
    }
    .login-btn {
      margin-top: 35px;
      font-weight: bold;
      font-size: 16px;
      color: #ffffff;
      line-height: 30px;
      text-align: center;
      height: 30px;
      padding: 10px 0;
      background: linear-gradient(11deg, #d33a31 0%, #ff9441 100%);
      cursor: pointer;
      border-radius: 25px;
      &:hover {
        background:linear-gradient( 11deg, #A82D27 0%, #CC7633 100%);
      }
      &.act {
        color: #af9486;

        background: linear-gradient(90deg, #541613 0%, #673c1a 100%);
      }
    }
    .fargot-pass-word {
      margin-top: 4px;
      font-weight: 500;
      font-size: 12px;
      color: #ff9441;
      line-height: 16px;
      text-align: center;
      cursor: pointer;
    }
    .google-login {
      margin-top: 30px;
      height: 22px;
      font-family: 'PingFang SC';
      font-weight: 400;
      font-size: 14px;
      color: #ff9441;
      line-height: 22px;
      text-align: center;
      cursor: pointer;
      .google-img {
        width: 16px;
        height: 16px;
        vertical-align: text-bottom;
      }
    }
  }
  .refersignup {
    height: 400px !important;
  }
}
</style>
