import basic from '../basic/fr';
export default {
  ...basic,
  ...{
    meta: {
      title:
        'Générateur d\'images IA gratuit, texte en ligne vers une image de fille mignonne et chaude',
      description:
        'Produisez des images et des œuvres d\'art générées par l\'IA avec une invite de texte à l\'aide des applications de génération de photos AI Beautyai.fun : outil d\'IA de conversion de texte en image en temps réel',
      keywords:
        'Échange de visage, générateur d\'images AI, échange de visage vidéo,',
    },
    recent: 'Récent',
    txtToImg: {
      history: 'Histoire',
      clear: 'Clair',
      describeCreation: 'Décrire la création',
      promptPlace: 'Entrez une invite de texte, créez votre création !',
      looksLike: 'Ressemble à',
      batchSize: 'Taille du lot',
      hintTags: 'Balises d\'indice',
      unselect: 'Désélectionner',
      create: 'Créer',
      aspectRatio: 'Ratio d\'aspect',
      optional: '(Facultatif)',
      pro: 'PRO',
    },
    use1Credit: 'Utilisez 1 crédit.',
    useXCredits: 'Utilisez <xx1> crédits.',
    guide: {
      title: 'Comment faire une meilleure création',
      headTip:
        'Veuillez télécharger une photo de meilleure qualité, une photo de mauvaise qualité entraînera une mauvaise création.',
      goodTitle: 'De bons exemples',
      badTitle: 'Mauvais exemples',
    },
    examplePopup: {
      title: 'Comment faire une meilleure création',
      photoTip:
        'Veuillez télécharger une photo de meilleure qualité, une photo de mauvaise qualité entraînera une mauvaise création.',
      goodExample: 'De bons exemples',
      badExample: 'Mauvais exemples',
      btnTip: 'J\'ai compris',
    },
    AiGirlShowcase: {
      title: 'Images générées par l\'IA',
      hovertitle: 'Essayez cette invite',
      buttontext: 'Btn Btn Btn',
    },
    textToImgToHistory: {
      tab1or2header: 'Nous avons conservé pour vous le record de génération',
      footer: 'allez dans l\'historique pour vérifier',
    },
    txtToImgIndex: {
      introduce: {
        item1Title: 'Générateur d\'images IA inspiré par votre idée',
        item1Subtitle:
          'Partagez simplement votre idée et regardez la technologie avancée de l\'IA créer un personnage de fille personnalisé rien que pour vous.',
        item1ActionText: 'Commencez à créer des images',
        item2Title: 'Personnalisez votre image de fille animée',
        item2Subtitle:
          'Beauty AI est un générateur d\'images d\'art IA qui vous permet de créer des créations d\'animation dans tous les styles. Décidez de votre inspiration, décrivez-la rapidement et transformez-la en images d\'anime. N\'oubliez pas que vos créations vous appartiennent lorsque vous créez votre âme sœur avec le personnage d\'anime unique !',
        item3Title: 'Générez une véritable âme sœur avec de l\'inspiration',
        item3Subtitle:
          'En tant que générateur d\'images IA facile à utiliser, nous visons à réaliser votre rêve avec beaucoup d\'efforts. Décrivez la fille de vos rêves avec des mots simples et nous générerons votre art en quelques secondes !',
      },
      howToUse: {
        sectionTitle: 'Comment créer des images IA dans Beauty AI',
        item1Title: 'Écrivez une invite de texte',
        item1Subtitle:
          'Tapez une description de ce que vous souhaitez voir dans le champ d\'invite.',
        item2Title: 'Créez votre image',
        item2Subtitle:
          'Lorsque vous êtes satisfait des invites, cliquez sur Générer. Les résultats apparaîtront dans quelques secondes. Si vous aimez l\'image générée par Beauty AI, cliquez sur le bouton Télécharger dans le coin supérieur droit pour télécharger cette image.',
        item3Title: 'Affiner, réviser et recréer',
        item3Subtitle:
          'Jouez avec les paramètres pour explorer différentes variations. Dans le panneau de gauche, vous pouvez tout régler, du rapport hauteur/largeur au type de contenu. Si vous le souhaitez, vous pouvez ajouter plus de détails à l\'invite de texte pour générer une toute nouvelle image. Assurez-vous simplement de sauvegarder toutes les images que vous aimez avant d’en créer une nouvelle.',
        actionText: 'Commencez à créer des images',
      },
      review: {
        sectionTitle: 'Ils nous aiment, vous aussi',
        card1Title: 'Après avoir utilisé Beauty AI',
        card1Subtitle:
          'Après avoir utilisé la fonction de générateur d\'images Beauty AI, j\'ai profondément ressenti sa fonction puissante. Non seulement il est facile à utiliser, mais les images générées sont d\'une qualité extrêmement élevée, ce qui a grandement amélioré mon efficacité au travail et j\'en suis très satisfait !',
        card1UserName: 'Michael Johnson',
        card1Date: '6 juin 2023',
        card2Title: 'AI Image Generator est vraiment un outil magique !',
        card2Subtitle:
          'Le générateur d\'images AI est vraiment un outil magique ! Je suis impressionné par son algorithme intelligent. Il peut générer des images AI précises en fonction de mes besoins à chaque fois. C\'est une aide indispensable dans mon travail.',
        card2UserName: 'Sarah Thompson',
        card2Date: '6 mai 2024',
        card3Title:
          'Depuis que j\'ai commencé à utiliser le générateur d\'images AI',
        card3Subtitle:
          'Depuis que j\'utilise le générateur d\'images AI, mes compétences en conception se sont considérablement améliorées. Ses puissantes capacités de génération d\'images permettent à ma créativité d\'être parfaitement présentée. Il est vraiment facile à utiliser !',
        card3UserName: 'Emilie Davis',
        card3Date: '4 décembre 2023',
        card4Title: 'La conception de l\'interface du générateur d\'images AI',
        card4Subtitle:
          'L\'interface du générateur d\'images AI est très conviviale et très pratique à utiliser. De plus, les images qu\'il génère sont de haute qualité et rapides, ce qui me permet d\'obtenir deux fois plus de résultats avec la moitié des efforts au travail. Je le recommande vivement !',
        card4UserName: 'Mario Kuper',
        card4Date: '1 janvier 2024',
        card5Title: 'Cet outil étonnant détecte intelligemment les visages',
        card5Subtitle:
          'Cet outil étonnant détecte intelligemment les visages sur les photos et les remplace de manière transparente par le visage de votre choix. C\'est comme avoir votre propre studio de création personnel à portée de main ! Avec Beauty AI, échanger des visages sur n’importe quelle photo se fait sans effort, ce qui me permet de laisser libre cours à mon imagination sans aucun problème.',
        card5UserName: 'William Smith',
        card5Date: '12 février 2023',
        card6Title: 'Un grand merci au générateur d\'images AI',
        card6Subtitle:
          'Un grand merci à AI image generator, le logiciel qu\'ils ont développé m\'a fait gagner beaucoup de temps au travail. Les images générées sont non seulement belles, mais répondent également parfaitement à mes besoins. Un compliment cinq étoiles !',
        card6UserName: 'Christophe Anderson',
        card6Date: '19 juillet 2023',
      },
      faq: {
        sectionTitle: 'Questions fréquemment posées',
        item1Q: 'Qu\'est-ce qu\'un générateur d\'IA ?',
        item1A:
          'Le générateur d\'images IA est un type de technologie générative d\'IA qui vous permet de créer des images avec seulement quelques mots. Le moteur d\'IA reçoit chaque mot d\'une description textuelle sous forme d\'instruction et construit l\'image en fonction de la combinaison des mots et de leurs relations entre eux.',
        item2Q: 'Comment fonctionne le générateur d\'images AI ?',
        item2A:
          'Le générateur d\'images AI fonctionne comme d\'autres types d\'intelligence artificielle, en utilisant des modèles d\'apprentissage automatique et d\'énormes ensembles de données pour produire ses résultats. En d\'autres termes, la technologie d\'IA apprend à partir de milliers et de milliers d\'images. Elle crée de toutes nouvelles images en appliquant ce qu\'elle a appris au langage courant que vous saisissez dans le champ d\'invite.',
        item3Q: 'Qu\'est-ce qui fait un générateur d\'images IA incroyable ?',
        item3A:
          'Lorsque vous choisissez votre générateur d\'images IA, recherchez-en un qui produit des images de haute qualité, vous permet un contrôle créatif pour adapter ces images à vos besoins et permet de les utiliser à des fins commerciales. Beauty AI est un excellent choix car il fait tout cela.',
        item4Q: 'Puis-je remplacer mon visage par n’importe quelle image ?',
        item4A:
          'Oui en effet. Beauty AI vous permet de remplacer gratuitement votre visage par n’importe quelle image sélectionnée. Par exemple, vous pouvez placer un visage masculin sur un portrait féminin ou un visage vieillissant sur une photo de bébé. Les possibilités sont infinies, vous permettant de devenir qui vous désirez sans limites.',
        item5Q:
          'Les images Beauty AI sont-elles disponibles pour un usage commercial ?',
        item5A:
          'Oui, vous pouvez utiliser les images générées par la fonctionnalité Beauty AI à des fins commerciales.',
        item6Q: 'Puis-je créer des images en ligne gratuitement ?',
        item6A:
          'Bien sûr vous pouvez! Vous pouvez créer des images gratuitement avec Beauty AI. Nous proposons des forfaits gratuits et premium adaptés à vos besoins. En tant que nouvel utilisateur, vous recevrez trois crédits lors de votre inscription, ce qui vous donnera un accès complet à notre fonction de création d\'image ainsi qu\'à d\'autres fonctionnalités telles que Face Swap et Magic Brush.',
      },
    },
    realisticToFaceSwapPopup: {
      status1Text: 'File d\'attente... À propos de <xx1>s',
      status2Text: 'Analyse du visage... À propos de <xx1>s',
    },
    skeywordsHint:
      'Mots clés inappropriés détectés par rapport à notre politique : <xx1>',
    introducehomeindex: {
      item1title: 'Personnalisez vos images artistiques IA',
      item1subtitle:
        'Beauty AI est un générateur d\'images d\'art IA qui vous permet de créer des créations IA dans tous les styles. Choisissez votre inspiration, décrivez-la avec invite et transformez-la en images IA. N\'oubliez pas que vos créations vous appartiennent lorsque vous réalisez votre création artistique.',
      item1buttontext: 'Essai gratuit maintenant',
      item2buttontext: 'Essai gratuit maintenant',
    },
    showcasehomeindex: {
      title: 'Images générées par l\'IA',
      buttontext: 'Essai gratuit maintenant',
      hovertitle: 'Essayez cette invite',
    },
    publishToast:
      'L\'image sera examinée par notre équipe, si elle est approuvée, l\'image s\'affichera dans la galerie.',
  },
};
