<template>
    <div :class="{
            file_list_popup: true,
            offstage: offstage,
        }">
        <div :class="{
            body: true,
            popin: popin, 
            popout: !popin
        }">
            <img 
                class="close"
                :src="$utility.assetImage('dialog_close')"
                @click="dismiss">
            <p class="title">{{ $t('faceswapUploadFilePopup.title') }}</p>
            <p class="subtitle">{{ $t('faceswapUploadFilePopup.subtitle') }}</p>
            <div class="content">
                <FileListPopupItem
                    v-for="(item, index) of uploadFilesVM.files"
                    class="cell"
                    :key="item.file.fileKey"
                    :fileItem="item"
                    :selected="currentSelectedIndex == index"
                    @deleteFile="deleteFile(index)"
                    @full="full(index)"
                    @click="cellClick(index)"/>
            </div>
            <button 
                class="button"
                :disabled="disabled"
                @click="tryFile">
                {{ $t('faceswapUploadFilePopup.try') }}
            </button>
        </div>
    </div>
    <template v-if="swiperVisible">
        <FileSwiper v-model:visible="swiperVisible" :files="uploadFilesVM.files" :index="fullIndex" :emptyCallback="emptyCallback"/>
    </template>
</template>

<script setup>
import FileListPopupItem from '@/components/faceSwapIndex/edit/filePopup/FileListPopupItem';
import { ref, watch, getCurrentInstance, defineEmits, defineExpose, computed } from 'vue';
import { useFaceSwap } from '@/stores/useFaceSwap';
import { uploadFilesVM } from '@/vm/faceswap_vm';
import FileSwiper from '@/components/common/swiper/FileSwiper.vue';
import { InfoDialogType } from '@/components/common/dialog/infoDialog';

let emit = defineEmits(['close']);

let { $utility, $showInfoDialog } = getCurrentInstance().appContext.config.globalProperties;
let faceSwap = useFaceSwap();
let currentSelectedIndex = ref(0);
let offstage = ref(true);
let popin = ref(false);
const swiperVisible = ref(false);
const fullIndex = ref(0);
const disabled = computed(() => {
    return uploadFilesVM.files.length == 0;
});
let animating = false;
watch(offstage, () => {
    document.body.style.overflow = !offstage.value ? 'hidden' : 'auto';    
});
function deleteFile (index) {
    $showInfoDialog(InfoDialogType.Normal, { title: 'Are you sure to delete this file?', imageSrc: 'dialog_delete', leftBtnText: 'Confirm', rightBtnText: 'Cancel' }).then((result) => {
        if (!result) {
            const reduce = (index != 0) && (index == uploadFilesVM.files.length - 1);
            uploadFilesVM.deleteFile(index).then((result) => {
            });
            if (uploadFilesVM.files.length == 0) {
                useFaceSwap().setSelectedFiledIndex(-1);
            }else {
                useFaceSwap().setSelectedFiledIndex(reduce ? useFaceSwap().selectedFiledIndex - 1 : useFaceSwap().selectedFiledIndex);
            }
        }
    });
}
function full (index) {
    fullIndex.value = index;
    swiperVisible.value = true;
}
function emptyCallback() {
    swiperVisible.value = false;
}
function show () {
    if (animating) return;
    animating = true;
    offstage.value = false;
    popin.value = true;
    setTimeout(() => animating = false, 500);
}
function dismiss () {
    if (animating) return;
    animating = true;
    popin.value = false;
    setTimeout(() => {
        animating = false;
        offstage.value = true;
    }, 500);
}
function tryFile () {
    faceSwap.setSelectedFiledIndex(currentSelectedIndex.value);
    dismiss();
}
function cellClick (index) {
    if (index == currentSelectedIndex.value) return;
    currentSelectedIndex.value = index;
}

defineExpose({
    show,
    dismiss,
});

</script>

<style scoped lang="scss">
@keyframes popin {
    0% {
        transform: translate(0%, 150%);
    }
    100% {
        transform: none;
    }
}
@keyframes popout {
    0% {
        transform: none;
    }
    100% {
        transform: translate(0%, 150%);
    }
}
.popin {
    animation: popin .5s;
    animation-fill-mode: forwards;
}
.popout {
    animation: popout .5s;
    animation-fill-mode: forwards;
}

@media (min-width: 1070px) {
    .file_list_popup {
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        background-color: rgba(0, 0, 0, 0.6);
        z-index: 1000;
        display: flex;
        align-items: center;

        ::-webkit-scrollbar {
            margin-left: 10px;
            width: 4px;
        }
        ::-webkit-scrollbar-thumb {
            background: #E05435;
            border-radius: 2px;
        }

        .body {
            position: relative;
            margin: auto;
            width: 840px;
            max-height: min(calc(100% - 64px), 609px);
            height: 100%;
            border-radius: 20px;
            background-color: #27282D;
            display: flex;
            flex-direction: column;

            .close {
                width: 32px;
                height: 32px;
                position: absolute;
                top: 20px;
                right: 20px;
                cursor: pointer;
            }

            .title {
                color: white;
                font-size: 20px;
                font-weight: 700;
                font-family: 'PingFang SC';
                text-align: center;
                margin: 30px 12px 0px 12px;
            }

            .subtitle {
                color: #F3F9FF;
                font-size: 14px;
                font-weight: 400;
                font-family: 'PingFang SC';
                text-align: center;
                margin: 20px 12px 0px 12px;
            }

            .content {
                margin: 20px 0px 0px 20px;
                height: 100%;
                display: flex;
                flex-wrap: wrap;
                overflow-y: hidden;
                overflow-x: hidden;
                .cell {
                    margin: 10px;
                }
                &:hover {
                    overflow-y: auto;
                }
            }

            .button {
                width: 330px;
                height: 45px;
                min-height: 45px;
                border-radius: 22.5px;
                border: none;
                background: linear-gradient( 11deg, #D33A31 0%, #FF9441 100%);
                font-size: 16px;
                font-weight: 500;
                color: white;
                text-align: center;
                margin: 30px auto 30px auto;
                cursor: pointer;
                &:hover {
                    background: linear-gradient( 11deg, #A82D27 0%, #CC7633 100%);
                }
            }
            .button[disabled=true] {
                background: linear-gradient( 90deg, #541613 0%, #673C1A 100%);
            }
        }
    }
}
@media (max-width: 1069px) {
    .file_list_popup {
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        background-color: rgba(0, 0, 0, 0.6);
        z-index: 1000;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        ::-webkit-scrollbar {
            margin-left: 10px;
            width: 3px;
        }
        ::-webkit-scrollbar-thumb {
            background: #E05435;
            border-radius: 1.5px;
        }

        .body {
            position: relative;
            margin: auto;
            width: 335px;
            max-height: min(calc(100% - 64px), 476px);
            height: 100%;
            border-radius: 15px;
            background-color: #27282D;
            display: flex;
            flex-direction: column;

            .close {
                width: 24px;
                height: 24px;
                position: absolute;
                top: 10px;
                right: 10px;
                cursor: pointer;
            }

            .title {
                color: white;
                font-size: 18px;
                font-weight: 600;
                font-family: 'PingFang SC';
                text-align: center;
                margin: 30px 12px 0px 12px;
            }

            .subtitle {
                color: #F3F9FF;
                font-size: 12px;
                font-weight: 400;
                font-family: 'PingFang SC';
                text-align: center;
                margin: 10px 12px 0px 12px;
            }

            .content {
                margin: 20px 0px 0px 12.5px;
                height: 100%;
                display: flex;
                flex-wrap: wrap;
                overflow-y: scroll;
                overflow-x: hidden;
                .cell {
                    margin: 7.5px;
                }
            }

            .button {
                width: 200px;
                height: 36px;
                min-height: 36px;
                border-radius: 18px;
                border: none;
                background: linear-gradient( 11deg, #D33A31 0%, #FF9441 100%);
                font-size: 16px;
                font-weight: 500;
                color: white;
                text-align: center;
                margin: 20px auto 20px auto;
                cursor: pointer;
                &:hover {
                    background: linear-gradient( 11deg, #A82D27 0%, #CC7633 100%);
                }
            }
            .button[disabled=true] {
                background: linear-gradient( 90deg, #541613 0%, #673C1A 100%);
            }
        }
    }
}
.offstage {
    display: none;
}
</style>