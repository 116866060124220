import { initializeApp } from '@firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { ENV } from './proj_env.js';
import { User } from './user.js';

export class AnalyticHub {
    static get getInstance () {
        if (!this.instance) {
            this.instance = new AnalyticHub();
        }
        return this.instance;
    }

    static initAnalytic () {
        AnalyticHub.getInstance.initAnalytic();
    }

    initAnalytic () {
        this.initFirebase();
        this.initMeta();
    }

    initFirebase () {
        const firebaseConfig = ENV.currentConfig.firebaseConfig;
        const app = initializeApp(firebaseConfig);
        this.firAnalytic = getAnalytics(app);
    }
    
    async initMeta () {
        await this.setupPixel();
        const token = window.localStorage.token;
        this.fbSetup(ENV.currentConfig.fbID, token | '');
    }
    
    async setupPixel () {
        return new Promise((resolve) => {
          !(function (f, b, e, v, n, t, s) {
              if (f.fbq) return; n = f.fbq = function () {
                  n.callMethod
                  ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
              };
              if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
              n.queue = []; t = b.createElement(e); t.async = !0;
              t.src = v; s = b.getElementsByTagName(e)[0];
              t.async = true;
              t.defer = true;
              t.onload = function () {
                resolve();
              };
              s.parentNode.insertBefore(t, s);
          }(
window, document, 'script',
              'https://connect.facebook.net/en_US/fbevents.js'
));
        });
    }        

    log (name, params) {
        if (!params) {
            params = {};
        }
        if (!ENV.isProd) {
            if (User.getInstance.isLogin.value) {
                params.memid = User.getInstance.token.value;
            }
            params.cost = User.getInstance.isSubscribe.value ? 1 : 0;
            console.log(`🐳 埋点: ${name} `, params);
        }
        logEvent(AnalyticHub.getInstance.firAnalytic, name, params);
        this.fbLogEvent(name, params);
    }

    fbSetup (pixelId, token) {
        if (token) {
            window.fbq('init', pixelId, { external_id : token });
        }else {
            window.fbq('init', pixelId);
        }
        window.fbq('track', 'PageView');
    }

    fbLogEvent (name, params) {
        window.fbq('trackCustom', name, params);
    }
}
