<template>
  <div ref="containerRef" class="scrollable-container">
    <div ref="contentRef" class="scrollable-content">
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
import {ref, onMounted, onUnmounted, watch} from 'vue';

const containerRef = ref(null);
const contentRef = ref(null);

const isDragging = ref(false);
const initialX = ref(0);
const scrollLeft = ref(0);
const contentWidth = ref(0);
const containerWidth = ref(0);

const handleMouseDown = (event) => {
  // console.log("handleMouseDown-----------")
  isDragging.value = true;
  initialX.value = event.clientX;
  scrollLeft.value = contentRef.value.scrollLeft;
  event.preventDefault();
  document.addEventListener('mousemove', handleMouseMove);
};

const handleMouseMove = (event) => {
  // console.log("h滑动")
  // console.log(event)
  // console.log(contentRef.value)
  if (isDragging.value) {
    // 鼠标移动距离
    const delta = event.clientX - initialX.value;
    const newScrollLeft = Math.max(0, Math.min(scrollLeft.value - delta, contentWidth.value - containerWidth.value));
    // console.log(newScrollLeft)
    // console.log(contentRef.value.scrollLeft)
    // console.log(delta)
    // console.log(scrollLeft.value)
    contentRef.value.scrollLeft = -delta + scrollLeft.value;
  }
};

const handleMouseUp = () => {
  isDragging.value = false;
  document.removeEventListener('mousemove', handleMouseMove);
  if (contentRef.value) {
    contentRef.value.removeEventListener('mousemove', handleMouseMove);  
  }
};

const updateDimensions = () => {
  contentWidth.value = contentRef.value.offsetWidth;
  // console.log("contentWidth:-----")
  // console.log(contentWidth.value)
  // console.log(contentRef.value.offsetWidth)
  containerWidth.value = containerRef.value.offsetWidth;
  // console.log("containerWidth:-----")
  // console.log(containerWidth.value)
  // console.log(containerWidth.value.offsetWidth)
};

onMounted(() => {
  if (contentRef.value) {
    contentRef.value.addEventListener('mousedown', handleMouseDown);
    // updateDimensions();
    contentRef.value.addEventListener('mouseup', handleMouseUp);
    // updateDimensions();
    document.addEventListener('mouseup', handleMouseUp);
  }
})
// watch(contentRef, (newValue) => {
//   if (newValue) {
//     newValue.addEventListener('mousedown', handleMouseDown);
//     updateDimensions();
//   }
// }, {immediate: true});
//
// watch(containerRef, (newValue) => {
//   if (newValue) {
//     newValue.addEventListener('mousemove', handleMouseMove);
//     newValue.addEventListener('mouseup', handleMouseUp);
//     updateDimensions();
//   }
// }, {immediate: true});

onUnmounted(() => {
  if (contentRef.value) {
    contentRef.value.removeEventListener('mousedown', handleMouseDown);
  }
  if (containerRef.value) {
    containerRef.value.removeEventListener('mousemove', handleMouseMove);
    containerRef.value.removeEventListener('mouseup', handleMouseUp);
  }
});
</script>

<style scoped lang="scss"> 
.scrollable-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.scrollable-content {
  overflow-x: scroll;
  white-space: nowrap;
}
</style>
