import basic from '../basic/es';
export default {
  ...basic,
  ...{
    meta: {
      title:
        'Generador gratuito de imágenes de chicas con IA, texto en línea para una imagen de chica linda y atractiva',
      description:
        'Produzca imágenes y arte generados por IA con una solicitud de texto usando las aplicaciones de generación de fotos con IA de Beautyai.fun: herramienta de IA para convertir texto a imagen en tiempo real',
      keywords:
        'Intercambio de caras, Generador de imágenes AI, Intercambio de caras de vídeo,',
    },
    recent: 'Reciente',
    txtToImg: {
      history: 'Historia',
      clear: 'Claro',
      describeCreation: 'Describir la creación',
      promptPlace: 'Ingrese el mensaje de texto, ¡cree su creación!',
      looksLike: 'Parece',
      batchSize: 'Tamaño del lote',
      hintTags: 'Etiquetas de sugerencia',
      unselect: 'Deseleccionar',
      create: 'Crear',
      aspectRatio: 'Relación de aspecto',
      optional: '(Opcional)',
      pro: 'PRO',
    },
    use1Credit: 'Utilice 1 crédito.',
    useXCredits: 'Utilice <xx1> créditos.',
    guide: {
      title: 'Cómo hacer una mejor creación',
      headTip:
        'Sube una foto con mejor calidad; una foto de mala calidad dará lugar a una mala creación.',
      goodTitle: 'Buenos ejemplos',
      badTitle: 'Malos ejemplos',
    },
    examplePopup: {
      title: 'Cómo hacer una mejor creación',
      photoTip:
        'Sube una foto con mejor calidad; una foto de mala calidad dará lugar a una mala creación.',
      goodExample: 'Buenos ejemplos',
      badExample: 'Malos ejemplos',
      btnTip: 'Entiendo',
    },
    AiGirlShowcase: {
      title: 'Imágenes generadas por IA',
      hovertitle: 'Pruebe este mensaje',
      buttontext: 'Por favor, por favor, por favor',
    },
    textToImgToHistory: {
      tab1or2header: 'Hemos guardado el registro de generación para usted.',
      footer: 've al historial para comprobarlo',
    },
    txtToImgIndex: {
      introduce: {
        item1Title: 'Generador de imágenes de IA inspirado en tu idea',
        item1Subtitle:
          'Simplemente comparte tu idea y observa cómo la avanzada tecnología de inteligencia artificial crea un personaje femenino personalizado solo para ti.',
        item1ActionText: 'Comience a crear imágenes',
        item2Title: 'Personaliza tu imagen de chica anime',
        item2Subtitle:
          'Beauty AI es un generador de imágenes de arte con inteligencia artificial que te permite crear animaciones en todos los estilos. Elige tu inspiración, descríbela con indicaciones y conviértela en imágenes de anime. ¡Recuerda que tus creaciones te pertenecen a medida que creas tu alma gemela con el personaje de anime único!',
        item3Title: 'Genera alma gemela realmente atractiva con inspiración',
        item3Subtitle:
          'Como generador de imágenes de chicas con IA fácil de usar, nuestro objetivo es cumplir tu sueño con gran esfuerzo. ¡Describe la chica de tus sueños con simples palabras y generaremos tu arte en segundos!',
      },
      howToUse: {
        sectionTitle: 'Cómo crear imágenes de IA en Beauty AI',
        item1Title: 'Escribe un mensaje de texto',
        item1Subtitle:
          'Escriba una descripción de lo que desea ver en el campo de solicitud.',
        item2Title: 'Crea tu imagen',
        item2Subtitle:
          'Cuando esté satisfecho con las indicaciones, haga clic en Generar. Los resultados aparecerán en unos segundos. Si le gusta la imagen generada por Beauty AI, haga clic en el botón Descargar en la esquina superior derecha para descargar esta imagen.',
        item3Title: 'Refinar, revisar y recrear',
        item3Subtitle:
          'Juega con la configuración para explorar diferentes variaciones. En el panel izquierdo, puedes ajustar todo, desde la relación de aspecto hasta el tipo de contenido. Si lo desea, puede agregar más detalles al mensaje de texto para generar una imagen completamente nueva. Solo asegúrate de guardar las imágenes que te gusten antes de crear una nueva.',
        actionText: 'Comience a crear imágenes',
      },
      review: {
        sectionTitle: 'Ellos nos aman, tú también lo harás',
        card1Title: 'Después de usar Beauty AI',
        card1Subtitle:
          'Después de utilizar la función de generación de imágenes Beauty AI, sentí profundamente su potente función. No solo es fácil de usar, sino que las imágenes generadas son de una calidad extremadamente alta, lo que mejoró enormemente mi eficiencia laboral y estoy muy satisfecho.',
        card1UserName: 'Michael Johnson',
        card1Date: '6 de junio de 2023',
        card2Title:
          '¡El generador de imágenes con IA es una herramienta realmente mágica! Me sorprende su algoritmo inteligente. Puede generar imágenes con IA precisas según mis necesidades en todo momento. Es una ayuda indispensable en mi trabajo.',
        card2UserName: 'Sara Thompson',
        card2Date: '6 de mayo de 2024',
        card3Title: 'Desde que comencé a usar el generador de imágenes de IA',
        card3Subtitle:
          'Desde que comencé a usar el generador de imágenes de IA, mis habilidades de diseño han mejorado significativamente. Sus potentes capacidades de generación de imágenes permiten que mi creatividad se presente a la perfección. ¡Es muy fácil de usar!',
        card3UserName: 'Emily Davis',
        card3Date: '4 de diciembre de 2023',
        card4Title: 'El diseño de la interfaz del generador de imágenes de IA',
        card4Subtitle:
          'El diseño de la interfaz del generador de imágenes con IA es muy fácil de usar y muy cómodo de usar. Además, las imágenes que genera son de alta calidad y rápidas, lo que me permite obtener el doble de resultados con la mitad del esfuerzo. ¡Lo recomiendo encarecidamente!',
        card4UserName: 'Mario Kuper',
        card4Date: '1 de enero de 2024',
        card5Title:
          'Esta increíble herramienta detecta rostros de forma inteligente',
        card5Subtitle:
          'Esta increíble herramienta detecta de forma inteligente rostros en fotografías y los reemplaza sin problemas con cualquier rostro que elijas. ¡Es como tener tu propio estudio creativo personal al alcance de tu mano! Con Beauty AI, intercambiar caras en cualquier foto es sencillo, lo que me permite dar rienda suelta a mi imaginación sin ningún problema.',
        card5UserName: 'Guillermo Smith',
        card5Date: '12 de febrero de 2023',
        card6Title: 'Un gran agradecimiento al generador de imágenes de IA.',
        card6Subtitle:
          'Muchas gracias al generador de imágenes con inteligencia artificial. El software que desarrollaron me ha ahorrado mucho tiempo en el trabajo. Las imágenes generadas no solo son hermosas, sino que también satisfacen completamente mis necesidades. ¡Un elogio de cinco estrellas!',
        card6UserName: 'Cristóbal Anderson',
        card6Date: '19 de julio de 2023',
      },
      faq: {
        sectionTitle: 'Preguntas frecuentes',
        item1Q: '¿Qué es el generador de IA?',
        item1A:
          'El generador de imágenes con IA es un tipo de tecnología de inteligencia artificial generativa que permite crear imágenes con tan solo unas pocas palabras. El motor de IA recibe cada palabra de una descripción de texto como una instrucción y crea la imagen basándose en la combinación de palabras y sus relaciones entre sí.',
        item2Q: '¿Cómo funciona el generador de imágenes de IA?',
        item2A:
          'El generador de imágenes de IA funciona como otros tipos de inteligencia artificial, utilizando modelos de aprendizaje automático y grandes conjuntos de datos para generar sus resultados. En otras palabras, la tecnología de IA aprende de miles y miles de imágenes. Crea imágenes completamente nuevas aplicando lo que ha aprendido al lenguaje cotidiano que ingresas en el campo de solicitud.',
        item3Q:
          '¿Qué hace que un generador de imágenes de IA sea sorprendente?',
        item3A:
          'A la hora de elegir un generador de imágenes con IA, busca uno que produzca imágenes de alta calidad, te permita un control creativo para que esas imágenes se adapten a tus necesidades y te permita utilizarlas con fines comerciales. Beauty AI es una gran opción porque hace todas estas cosas.',
        item4Q: '¿Puedo reemplazar mi cara con cualquier imagen?',
        item4A:
          'Sí, efectivamente. Beauty AI te permite reemplazar tu rostro con cualquier imagen seleccionada de forma gratuita. Por ejemplo, puedes colocar un rostro masculino en un retrato femenino o un rostro envejecido en la foto de un bebé. Las posibilidades son infinitas, permitiéndote convertirte en quien desees sin limitaciones.',
        item5Q:
          '¿Las imágenes de Beauty AI están disponibles para uso comercial?',
        item5A:
          'Sí, puede utilizar imágenes generadas por la función Beauty AI con fines comerciales.',
        item6Q: '¿Puedo crear imágenes en línea de forma gratuita?',
        item6A:
          '¡Por supuesto que puede! Puedes crear imágenes gratis con Beauty AI. Ofrecemos planes gratuitos y premium que se adaptan a sus necesidades. Como nuevo usuario, recibirá tres créditos al registrarse, lo que le dará acceso completo a nuestra función de creación de imágenes, así como a otras funciones como Face Swap y Magic Brush.',
      },
    },
    realisticToFaceSwapPopup: {
      status1Text: 'En cola...Acerca de <xx1>s',
      status2Text: 'Analizando rostros...Acerca de <xx1>s',
    },
    skeywordsHint:
      'Palabras clave inapropiadas detectadas en contra de nuestra política: <xx1>',
    introducehomeindex: {
      item1title: 'Personaliza tus imágenes artísticas de IA',
      item1subtitle:
        'Beauty AI es un generador de imágenes artísticas con IA que te permite crear creaciones con IA en todos los estilos. Decida su inspiración, descríbala rápidamente y conviértala en imágenes de IA. Recuerde que sus creaciones le pertenecen a usted mientras realiza su creación artística.',
      item1buttontext: 'Prueba gratuita ahora',
      item2buttontext: 'Prueba gratuita ahora',
    },
    showcasehomeindex: {
      title: 'Imágenes generadas por IA',
      buttontext: 'Prueba gratuita ahora',
      hovertitle: 'Pruebe este mensaje',
    },
    publishToast:
      'La imagen será revisada por nuestro equipo, si se aprueba, la imagen se mostrará en la galería.',
  },
};
