import basic from '../basic/pt'
export default {
    ...basic,
    ...{
        ok : "OK",
        tutorialEntrance : "Como fazer uma criação de qualidade",
        examplePopup : {
            title : "Como fazer uma criação de alta qualidade?",
            photoTip : "Dicas: Para obter a melhor foto de troca de rosto com IA, certifique-se da qualidade da foto que deseja refazer online",
            videoTip : "Dicas: Para obter o melhor vídeo de troca de rosto com IA, certifique-se da qualidade do vídeo que deseja refazer online",
            goodExample : "Bons exemplos",
            badExample : "Maus exemplos",
            highQuality : "Alta qualidade",
            lookingAtViewer : "Olhando para o visualizador",
            sideFaces : "· Faces laterais",
            tooFar : "·Muito longe",
            blurry : "Embaçado",
            lessCover : "Menos cobertura",
            lowResolution : "Baixa resolução",
        },
        youtubeSectionTitle : "Como usar a troca de rosto?",
        tutorial : "Tutorial",
    },
}