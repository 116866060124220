<template>
    <div class="file_list_item" :highlight="selected" @mouseenter="onHover(true)" @mouseleave="onHover(false)">
        <VOCImageView class="image" :requestInfo="imgRequest"/>
        <div class="action_box delete" :hover="hover" @click="deleteFile" v-if="!fileItem.demoFlag">
            <img class="delete" :src="$utility.assetImage('faceswap_file_delete')">
        </div>
        <div class="action_box full" :hover="hover" @click="full">
            <img class="full" :src="$utility.assetImage('faceswap_file_full')">
        </div>
        <div class="demo" v-if="fileItem.demoFlag">
            {{ $t('fileUpload.demo') }}
        </div>
        <div class="time" v-else-if="fileItem.file.fileType == 'video'">
            {{ videoTimeStr }}
        </div>
    </div>
</template>

<script setup>
import VOCImageView from '@/components/VOCImageView.vue'
import { ref, getCurrentInstance, defineProps, defineEmits, computed } from 'vue'
import { VOCImageRequestInfo } from '@/script/voc_img_tool'

let emit = defineEmits(['deleteFile', 'full'])
let { $utility } = getCurrentInstance().appContext.config.globalProperties
let { selected, fileItem } = defineProps({
    selected: {
        type: Boolean,
        default: false,
    },
    fileItem: {
        type: Object,
    },
})
const videoTimeStr = computed(() => {
    if (fileItem.file.fileType == 'video') {
        let seconds = Math.floor(fileItem.file.fileDuration)
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(remainingSeconds).padStart(2, '0');

        return `${formattedMinutes}:${formattedSeconds}`;
    }
    return '0:0'
})
let imgRequest = new VOCImageRequestInfo({
    url: fileItem.file.fileType == 'image' ? fileItem.file.fileUrl : fileItem.file.fileThumbUrl,
})
let hover = ref($utility.isMobile)

function onHover(value) {
    if ($utility.isMobile) return
    hover.value = value
}

function deleteFile() {
    emit('deleteFile')
}

function full() {
    emit('full')
}

</script>

<style scoped lang="scss">
@media (min-width: 1070px) {
    .file_list_item[highlight=true] {
        border: none;
        background-image: linear-gradient(#27282D, #27282D),
            linear-gradient(to right, #D33A31, #FF9441);
        background-origin: border-box;
        background-clip: content-box, border-box;
        padding: 2px;
        .action_box.delete {
            top: 16px;
            left: 16px;
        }
        .action_box.full {
            top: 16px;
            right: 16px;
        }
    }
    .file_list_item {
        position: relative;
        width: 176px;
        height: 176px;
        border-radius: 24px;
        border: 2px solid #3D3D42;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .image {
            width: calc(100% - 4px);
            height: calc(100% - 4px);
            border-radius: 20px;
            overflow: hidden;
        }
        .action_box[hover=false] {
            display: none;
        }
        .action_box {
            display: block;
            position: absolute;
            background-color: rgba(0, 0, 0, 0.45);
            border-radius: 8px;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            .delete {
                width: 20px;
                height: 20px;
            }
            .full {
                width: 20px;
                height: 20px;
            }
        }
        .action_box.delete {
            top: 14px;
            left: 14px;
        }
        .action_box.full {
            top: 14px;
            right: 14px;
        }
        .demo {
            display: block;
            position: absolute;
            width: 53px;
            height: 20px;
            border-radius: 10px;
            background: linear-gradient( 11deg, #D33A31 0%, #FF9441 100%);
            font-size: 15px;
            font-weight: 500;
            color: white;
            text-align: center;
            bottom: 14px;
        }
        .time {
            display: block;
            position: absolute;
            width: 53px;
            height: 20px;
            border-radius: 5px;
            background: rgba(0, 0, 0, 0.6);
            font-size: 15px;
            font-weight: 500;
            color: white;
            text-align: center;
            bottom: 14px;
        }
    }
}
@media (max-width: 1069px) {
    .file_list_item[highlight=true] {
        border: none;
        background-image: linear-gradient(#27282D, #27282D),
            linear-gradient(to right, #D33A31, #FF9441);
        background-origin: border-box;
        background-clip: content-box, border-box;
        padding: 1px;
        .action_box.delete {
            top: 10px;
            left: 10px;
        }
        .action_box.full {
            top: 10px;
            right: 10px;
        }
    }
    .file_list_item {
        position: relative;
        width: 134px;
        height: 134px;
        border-radius: 18px;
        border: 1.5px solid #3D3D42;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .image {
            width: calc(100% - 2px);
            height: calc(100% - 2px);
            border-radius: 16px;
            overflow: hidden;
        }
        .action_box[hover=false] {
            display: none;
        }
        .action_box {
            display: block;
            position: absolute;
            background-color: rgba(0, 0, 0, 0.45);
            border-radius: 6px;
            width: 24px;
            height: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            .delete {
                width: 15px;
                height: 15px;
            }
            .full {
                width: 15px;
                height: 15px;
            }
        }
        .action_box.delete {
            top: 9px;
            left: 9px;
        }
        .action_box.full {
            top: 9px;
            right: 9px;
        }
        .demo {
            display: block;
            position: absolute;
            width: 42px;
            height: 16px;
            border-radius: 8px;
            background: linear-gradient( 11deg, #D33A31 0%, #FF9441 100%);
            font-size: 12px;
            font-weight: 500;
            color: white;
            text-align: center;
            bottom: 9px;
        }
        .time {
            display: block;
            position: absolute;
            width: 42px;
            height: 16px;
            border-radius: 4px;
            background: rgba(0, 0, 0, 0.6);
            font-size: 12px;
            font-weight: 500;
            color: white;
            text-align: center;
            bottom: 9px;
        }
    }
}
</style>