<template>
    <div>
        <div :class="cssBinds.sectionContainerClsBind">
            <div :class="cssBinds.txtLeftCootainerClsBind">
                <h1 :class="cssBinds.titleClsBind">{{ texts[0] }}</h1>
                <p :class="cssBinds.subtitleClsBind">{{ texts[1] }}</p>
                <div :class="cssBinds.responseContainerClsBind">
                    <a :class="cssBinds.startButtonClsBind" @click.prevent="toFaceSwap" :href="$otherPageUrl('/photo-face-swap', true)">
                        <p :class="cssBinds.startButtonTxtClsBind">{{ texts[2] }}</p>
                        <img :class="cssBinds.startButtonDetailIconClsBind" :src="buttonDetailImgSrc" alt="">
                    </a>
                    <div v-if="!isDetails" :class="cssBinds.detailsLinkContainerClsBind">
                        <a :href="detailsUrl" target="_blank" :class="['details']">{{ $t('details') }}</a>
                    </div>
                </div>
            </div>
            <div class="header-h-space" style="width: 50px; min-width: 50px;"></div>
            <div :class="cssBinds.imgRightContainerClsBind">
                <img :class="cssBinds.imgBlokClsBind" :src="indexSectionTopImgSrc" alt="">
            </div>
            <div :class="cssBinds.narrowResponseContainerClsBind">
                <a :class="cssBinds.startButtonClsBind" @click.prevent="toFaceSwap" :href="$otherPageUrl('/photo-face-swap', true)">
                    <p :class="cssBinds.startButtonTxtClsBind">{{ $t('startFaceButtonTitle') }}</p>
                    <img :class="cssBinds.startButtonDetailIconClsBind" :src="buttonDetailImgSrc" alt="">
                </a>
                <div v-if="!isDetails" :class="cssBinds.detailsLinkContainerClsBind">
                    <a :href="detailsUrl" target="_blank" :class="['details']">{{ $t('details') }}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            buttonDetailImgSrc: '',
            indexSectionTopImgSrc: '',
            detailsUrl: '',
            cssBinds: {
                sectionContainerClsBind: ['sectionContainer'],
                txtLeftCootainerClsBind: ['left-container'],
                titleClsBind: ['gradient-txt', 'title'],
                subtitleClsBind: ['subtitle'],
                responseContainerClsBind: ['responseContainer'],
                narrowResponseContainerClsBind: ['responseContainer-narrow'],
                startButtonClsBind: ['startButton'],
                startButtonTxtClsBind: ['startButtonTxt'],
                startButtonDetailIconClsBind: ['startButtonDetailIcon'],
                detailsLinkContainerClsBind: ['detailsLinkContainer'],
                imgRightContainerClsBind: ['right-container'],
                imgBlokClsBind:['img-blok'],
            },
        };
    },
    created () {
        this.detailsUrl = this.$otherPageUrl('/video-swap-face-function', true, true);
    },
    mounted () {
        this.buttonDetailImgSrc = this.$utility.assetImage('button_detail');
        this.indexSectionTopImgSrc = this.$utility.assetImage('index_section_top');
        let sectionContainer = document.getElementsByClassName('sectionContainer')[0];
        if (sectionContainer && this.isDetails) {
            sectionContainer.style.flexDirection = 'row-reverse';
        }
        if (this.isDetails) {
            this.cssBinds.titleClsBind = ['white-color-txt', 'title'];
        }
    },
    inject: ['tabCategory'],
    props: { isDetails: Boolean },
    computed: {
        texts () {
            switch (this.tabCategory) {
                case 0:
                    return [
                        this.$t('faceswapOnlineSection.tab1Title'),
                        this.$t('faceswapOnlineSection.tab1Desc'),
                        this.$t('faceswapOnlineSection.action'),
                    ];
                case 1:
                    return [
                        this.$t('faceswapOnlineSection.tab2Title'),
                        this.$t('faceswapOnlineSection.tab2Desc'),
                        this.$t('faceswapOnlineSection.action'),
                    ];
                case 2:
                    return [
                        this.$t('faceswapOnlineSection.tab3Title'),
                        this.$t('faceswapOnlineSection.tab3Desc'),
                        this.$t('faceswapOnlineSection.action'),
                    ];
                default:
                    return [
                        this.$t('faceswapOnlineSection.tab4Title'),
                        this.$t('faceswapOnlineSection.tab4Desc'),
                        this.$t('faceswapOnlineSection.action'),
                    ];
            }
        },
    },
    methods: {
        toFaceSwap (event) {
            event.preventDefault();
            if (this.isDetails) {
                this.$jumpOtherPage('/photo-face-swap', true, true);
            }else {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
            }
        },
    },
};
</script>

<style scoped lang="scss"> 
.sectionContainer {
    /*去除a标签下划线*/
    a {
        text-decoration: none;
        color: white;
    }
    /*去除未被访问的a标签的下划线*/
    a:link {
        text-decoration: none;
    }
    /*去除已经被访问过的a标签的下划线*/
    a:visited {
        text-decoration: none;
    }
    /*去除鼠标悬停时的a标签的下划线*/
    a:hover {
        text-decoration: none;
    }
    /*去除正在点击的a标签的下划线（鼠标按下，尚未松开）*/
    a:active {
        text-decoration: none;
    }
    /*去除获得焦点的a标签的下划线（被鼠标点击过）*/
    a:focus {
        text-decoration: none;
    }
}
    @media (min-width: 1071px) {
        .sectionContainer {
            margin-left: auto;
            margin-right: auto;
            max-width: 1360px;
            display: -webkit-flex;
            display: flex;
            -webkit-justify-content: space-between;
            justify-content: space-between;
            padding: 0% 40px;
            -webkit-align-items: center;
            align-items: center;
        }
        
        .left-container {
            width: 500px;
            min-width: 500px;
            display: inline-block;
        }
        .right-container {
            max-width: 560px;
            max-height: 560px;
            display: inline-block;
        }
        .img-blok {
            width: 100%;
        }
        .gradient-txt {
            background: linear-gradient(to right, #D33A31, #FF9441);
            -webkit-background-clip: text;
            color: transparent;
        }
        .white-color-txt {
            color: white;
        }
        .title {
            font-size: 48px;
            font-weight: 700; 
            font-family: 'PingFang SC';
            padding: 0px;
            margin: 0px;
        }
        .subtitle {
            font-size: 17px;
            font-weight: 400; 
            font-family: 'PingFang SC';
            color: white;
            padding: 0px;
            margin-top: 20px;
            margin-bottom: 34px;
        }
        .responseContainer {
            width: 388px;
        }
        .responseContainer-narrow {
            display: none;
        }
        .startButton {
            padding: 0% 12px;
            width: 388px;
            height: 63px;
            background: linear-gradient(to right, #D33A31, #FF9441);
            display: -webkit-flex;
            display: flex;
            -webkit-align-items: center;
            align-items: center;
            -webkit-justify-content: center;
            justify-content: center;
            border-radius: 31.5px;
            cursor: pointer;
            margin-bottom: 10px;
        }
        .startButton:hover {
            background: linear-gradient(to right, #A82D27, #CC7633);
        }
        .startButtonTxt {
            display: inline-block;
            color: white;
            font-weight: 700; 
            font-family: 'PingFang SC';
            font-size: 20px;
            text-decoration: none;
        }
        .startButtonDetailIcon {
            width: 19px;
            height: 14px;
            margin-left: 9px;
        }
        .detailsContainer {
            width: 100%;
        }
        .detailsLinkContainer {
            width: 100%;
            display: -webkit-flex;
            display: flex;
            -webkit-align-items: center;
            align-items: center;
            -webkit-justify-content: center;
            justify-content: center;
        }
        .details {
            color: white;
            font-family: 'PingFang SC';
            font-size: 16px;
            display: inline-block;
            &:hover {
                color: #E05435;
            }
        }
    }

    @media (max-width: 1070px){
        .left-container {
            margin: 0 auto;
            padding: 0 18px;
        }
        .right-container {
            max-width: 428px;
            margin: 0 auto;
            padding-left: 38px;
            padding-right: 38px;
            padding-bottom: 30px;
        }
        .header-h-space {
            display: none;
        }
        .img-blok {
            width: 100%;
            height: auto;
            aspect-ratio: 1;
        }
        .gradient-txt {
            background: linear-gradient(to right, #D33A31, #FF9441);
            -webkit-background-clip: text;
            color: transparent;
        }
        .white-color-txt {
            color: white;
        }
        .title {
            font-size: 26px;
            font-weight: 700; 
            font-family: 'PingFang SC';
            padding: 0px;
            margin: 0 auto;
            text-align: center;
        }
        .subtitle {
            font-size: 12px;
            font-weight: 400; 
            font-family: 'PingFang SC';
            color: white;
            padding: 0px;
            margin-top: 14px;
            margin-bottom: 20px;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
        }
        .responseContainer {
            display: none;
        }
        .responseContainer-narrow {
            width: 241px;
            margin-left: auto;
            margin-right: auto;
        }
        .startButton {
            padding: 0% 12px;
            text-align: center;
            width: 241;
            height: 48px;
            background: linear-gradient(to right, #D33A31, #FF9441);
            display: -webkit-flex;
            display: flex;
            -webkit-align-items: center;
            align-items: center;
            -webkit-justify-content: center;
            justify-content: center;
            border-radius: 24px;
            cursor: pointer;
            margin-bottom: 10px;
            margin-left: auto;
            margin-right: auto;
        }
        .startButton:hover {
            background: linear-gradient(to right, #A82D27, #CC7633);
        }
        .startButtonTxt {
            display: inline-block;
            color: white;
            font-weight: 700; 
            font-family: 'PingFang SC';
            font-size: 16px;
        }
        .startButtonDetailIcon {
            width: 19px;
            height: 13px;
            margin-left: 8px;
        }
        .detailsContainer {
            width: 100%;
        }
        .detailsLinkContainer {
            width: 100%;
            display: -webkit-flex;
            display: flex;
            -webkit-align-items: center;
            align-items: center;
            -webkit-justify-content: center;
            justify-content: center;
        }
        .details {
            color: white;
            font-family: 'PingFang SC';
            font-size: 16px;
            display: inline-block;
            &:hover {
                color: #E05435;
            }
        }
    }
</style>