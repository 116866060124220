<template>
    <div class="shimmer"></div>
</template>

<script>

</script>

<style scoped lang="scss"> 

.shimmer {
    width: 100%;
    height: 100%;
    background: linear-gradient(-45deg, #2C2F37 30%, #454955 40%, #2C2F37 50%);
    background-size: 500%;
    background-position-x: 100%;
    animation: shimmer 1.5s infinite linear;
}

@keyframes shimmer {
   to {
      background-position-x: -5%
   }
}
</style>