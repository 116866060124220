<template>
    <div class="section-container">
        <h2 class="section-title">{{ $t('youtubeSectionTitle') }}</h2>
        <div class="card-container">
            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/CDvwuuh_Y6A?si=FraW0HnSxPgzfHfn" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
    </div>
</template>

<script setup>


</script>

<style scoped lang="scss"> 
@media (min-width: 1071px) {
    .section-container {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .section-title {
        font-size: 40px;
        font-weight: 700;
        font-family: 'PingFang SC';
        color: white;
        margin: 0;
        padding: 0;
    }

    .card-container {
        margin-left: auto;
        margin-right: auto;
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 38px;
        max-width: 960px;
        aspect-ratio: 960/540;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        padding: 0% 40px;
        -webkit-align-items: center;
        align-items: center;
        border-radius: 24px;
    }
    
    .ytb-video {
        width: 100%;
        height: 500px;
    }
}

@media (max-width: 1070px) {
    .section-container {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .section-title {
        font-size: 18px;
        font-weight: 700;
        font-family: 'PingFang SC';
        color: white;
        margin: 0;
    }

    .card-container {
        margin-top: 20px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 13px;
        padding-right: 13px;
        width: calc(100% - 26px);
        aspect-ratio: 1360/765;
    }

    .ytb-video {
        width: 100%;
        height: 500px;
    }
}
</style>