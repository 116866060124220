import { AnalyticHub } from './analytic.js'
import { ENV } from './proj_env.js'
import { User } from './user.js'
import { CommonUtility } from './utility.js'
import { md5 } from 'js-md5'

export class API {

    static REQUEST_STATUS_NONE = 0
    static REQUEST_STATUS_LOADING = 1
    static REQUEST_STATUS_SCUCESS = 2
    static REQUEST_STATUS_FAILED = -1

    static async post(url, inputParams, tiemout=30000) {
        if (!url) throw this.REQUEST_STATUS_FAILED
        try {
            let params = commonParams()
            if (inputParams) {
                Object.keys(inputParams).forEach(function(key) {
                    params[key] = inputParams[key]
                })
            }
            let keys = []
            Object.keys(params).forEach(function(key) {
                keys.push(key)
            })

            keys = keys.sort()
            let signPairs = []
            let bodyPairs = []
            for (let key of keys) {
                bodyPairs.push(`${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
                signPairs.push(`${key}=${params[key]}`)
            }
            let sign = signPairs.join('&')
            sign = md5(`${sign}FtA50esxi`)
            bodyPairs.push(`sign=${sign}`)
            let body = bodyPairs.join(`&`)

            let resp = await timeoutFetch(tiemout, fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: body,
            }))
            return await resp.json()
        }catch (e) {
            throw this.REQUEST_STATUS_FAILED
        }
    }

    static async upload(url, inputParams, progressListener, ...files) {
        if (!url) throw this.REQUEST_STATUS_FAILED
        try {
            const formData = new FormData()
            let params = commonParams()
            Object.keys(params).forEach(function(key) {
                formData.append(key, params[key])
            })
            if (inputParams) {
                Object.keys(inputParams).forEach(function(key) {
                    formData.append(key, inputParams[key])
                })
            }
            if (files) {
                for (let file of files) {
                    formData.append(file.name, file.file)
                }
            }
            let resp = await new Promise((resolve, reject) => {
                let xhr = new XMLHttpRequest();
                if (progressListener) {
                    xhr.upload.addEventListener('progress', (d) => {
                        if (d.lengthComputable) {
                            let uploadPercent = (d.loaded / d.total)
                            progressListener(uploadPercent)
                        }
                    }, false);
                }
                xhr.onload = () => {
                    try {
                        let resp = JSON.parse(xhr.response)
                        resolve(resp)
                    }catch (_) {
                        reject(this.REQUEST_STATUS_FAILED)
                    }
                };
                xhr.onerror = () => {
                    reject(this.REQUEST_STATUS_FAILED)
                };
                xhr.open('POST', url, true);
                xhr.send(formData);
            })
            return resp
        }catch (e) {
            throw this.REQUEST_STATUS_FAILED
        }
    }


    /// 站长邀请浏览量记录
    static async affiliateVisit(affiliateKey) {
        return await API.post(`${ENV.currentConfig.apiHost}/affiliate/visit`, {'affiliateKey' : affiliateKey})
    }

    /// 提交换脸上传
    static async swapUploadDetectFace({taskId, promptTaskId, editTaskId, progress}, file) {
        let params = {}
        if (taskId) {
            params['taskId'] = taskId
        }
        if (promptTaskId) {
            params['promptTaskId'] = promptTaskId
        }
        if (editTaskId) {
            params['editTaskId'] = editTaskId
        }
        return await API.upload(`${ENV.currentConfig.swapAPIHost}/swap/detect_face`, params, progress, {
            name: 'file',
            file: file
        })
    }

    /// 获取换脸文件上传任务详情
    static async swapUploadTaskFetch(taskIds) {
        return await API.post(`${ENV.currentConfig.swapAPIHost}/swap/get_face_analyser_task_detail`, {
            "taskIds" : taskIds.join(',')
        })
    }

    /// 获取换脸上传历史
    static async swapUploadHistory() {
        return await API.post(`${ENV.currentConfig.swapAPIHost}/swap/get_face_analyser_history`)
    }

    /// 删除换脸上传历史文件
    static async swapDeleteUploadHistoryFile(taskId) {
        return await API.post(`${ENV.currentConfig.swapAPIHost}/swap/delete_face_analyser_history`, {
            taskIds: taskId
        })
    }

    /// 获取换脸分析执行中任务列表
    static async swappingTasks() {
        return await API.post(`${ENV.currentConfig.swapAPIHost}/swap/get_face_swapper_tasks`)
    }

    /// 提交换脸任务
    static async swapSubmit({ fileKey, faceMappings, taskId, localTaskId }) {
        if (!fileKey) throw -1
        if (!faceMappings) throw -1
        let params = {
            'fileKey' : fileKey,
            'faceMappings' : faceMappings,
        }
        if (localTaskId) {
            params['localTaskId'] = localTaskId
        }
        if (taskId) {
            params['taskId'] = taskId
        }
        return await API.post(`${ENV.currentConfig.swapAPIHost}/swap/swap_face`, params, 300000)
    }

    /// 获取换脸任务详情
    static async swapTaskFetch(taskIds) {
        return await API.post(`${ENV.currentConfig.swapAPIHost}/swap/get_face_swapper_task_detail`, {
            'taskIds': taskIds.join(',')
        })
    }

    /// 上传人脸头像
    static async faceUpload(file) {
        return await API.upload(`${ENV.currentConfig.apiHost}/face/detect_face`, {
            'name': 'file',
            'file': file,
        })
    }

    /// 删除人脸头像
    static async faceDelete(faceId) {
        return await API.post(`${ENV.currentConfig.apiHost}/face/delete_face`, {
            'faceId' : faceId,
        })
    }

    /// 人脸头像历史
    static async facesList() {
        return await API.post(`${ENV.currentConfig.apiHost}/face/get_face`, null)
    }

    /// 获取用户数据
    static async loadUserInfo() {
        return await API.post(`${ENV.currentConfig.apiHost}/user/getinfo`)
    }

    /// 获取config配置数据
    static async loadConfigInfo() {
        return await API.post(`${ENV.currentConfig.apiHost}/setting/infos`)
    }

    /** 换脸反馈 */
    static async swapFeedBack(args) {
        return await API.upload(`${ENV.currentConfig.apiHost}/contactus/swap_feedback`, args)
    }

    /** 文生图反馈 */
    static async txtToImgFeedBack(args) {
        return await API.upload(`${ENV.currentConfig.apiHost}/contactus/image_feedback`, args)
    }

    /// google注册
    static async googleUserRegister({
        email,
        googleIdToken,
        googleAccessToken,
    }) {
        let params = {
            'email' : email,
        }
        if (googleIdToken) {
            params['googleIdToken'] = googleIdToken
        }else if (googleAccessToken) {
            params['googleAccessToken'] = googleAccessToken
        }
        return await API.post(`${ENV.currentConfig.apiHost}/user/register_google`, params)
    }

    /// 登录
    static async userLogin({
        email,
        password,
        code,
        googleIdToken,
        googleAccessToken,
    }) {
        let params = {
            'email': email,
        }
        if (password) {
            params['password'] = password
        }
        if (code) {
            params['code'] = code
        }
        if (googleIdToken) {
            params['googleIdToken'] = googleIdToken
        }
        if (googleAccessToken) {
            params['googleAccessToken'] = googleAccessToken
        }
        return await API.post(`${ENV.currentConfig.apiHost}/user/loginin`, params)
    }

    /// 邮箱注册不需要验证码
    static async userRegister({
        email,
        password,
        referralKey,
        affiliateKey,
    }) {
        let params = {
            'email' : email,
            'password' : password,
        }
        if (referralKey) {
            params['referralKey'] = referralKey
        }
        if (affiliateKey) {
            params['affiliateKey'] = affiliateKey
        }
        return await API.post(`${ENV.currentConfig.apiHost}/user/register_email_new`, params)
    }

    /// 注册发送验证码
    static async userSendRegisterCode({ email }) {
        let params = {
            'email' : email
        }
        return await API.post(`${ENV.currentConfig.apiHost}/user/sendregistercode`, params)
    }

    /// 邮箱验证码注册
    static async userRegisterVerify({
        email,
        password,
        verifyCode,
        referralKey
    }) {
        let params = {
            'email' : email,
            'password' : password,
            'code' : verifyCode,
        }
        if (referralKey) {
            params['referralKey'] = referralKey
        }
        return await API.post(`${ENV.currentConfig.apiHost}/user/register_email`, params)
    }

    /// 重置密码
    static async resetPassword({
        email,
        code,
        newPassword
    }) {
        let params = {
            'email' : email,
            'code' : code,
            'newPassword' : newPassword,
        }
        return await API.post(`${ENV.currentConfig.apiHost}/user/resetPassword`, params)
    }

    /// 重置密码验证码获取
    static async sendForgotEmailCode({ email }) {
        let params = {
            'email' : email,
        }
        return await API.post(`${ENV.currentConfig.apiHost}/user/sendforgotcode`, params)
    }

    /// 用户设备注册
    static async userDeviceOpen() {
        return await API.post(`${ENV.currentConfig.apiHost}/user/open`)
    }

    /// 获取所有hint tag
    /// http://123.57.94.25:8090/pages/viewpage.action?pageId=68364
    static async getHintTags() {
        return await API.post(`${ENV.currentConfig.apiHost}/ai/hinttags`)
    }
    /// 获取推荐prompt文本列表
    /// http://123.57.94.25:8090/pages/viewpage.action?pageId=68365
    static async getSuggestionList() {
        return await API.post(`${ENV.currentConfig.apiHost}/ai/suggestionList`)
    }
    /// 获取随机prompt文本
    /// http://123.57.94.25:8090/pages/viewpage.action?pageId=68367
    static async getSuggestionRandom() {
        return await API.post(`${ENV.currentConfig.apiHost}/ai/suggestionRandom`)
    }
    /// 获取所有风格列表
    /// http://123.57.94.25:8090/pages/viewpage.action?pageId=68368
    static async getStyleList() {
        return await API.post(`${ENV.currentConfig.apiHost}/ai/styleList`)
    }
    /// 提交文生图任务
    /// http://123.57.94.25:8090/pages/viewpage.action?pageId=68386
    static async txtToImgSubmit({
        prompt,
        styleKey,
        ratio,
        faceId,
        batch,
        taskId,
    }) {
        const params = {
            prompt: prompt,
            styleKey: styleKey,
            ratio: ratio,
            batch: batch,
        }
        if (faceId) {
            params['faceId'] = faceId
        }
        if (taskId) {
            params['taskId'] = taskId
        }
        return await API.post(`${ENV.currentConfig.apiHost}/ai/submitPromptInfoNoFile`, params)
    }

    /// 获取文生图任务详情
    /// http://123.57.94.25:8090/pages/viewpage.action?pageId=33259789
    static async txtToImgTasksFetch(taskIds) {
        return await API.post(`${ENV.currentConfig.apiHost}/ai/get_prompt_task_detail`, {
            taskIds: taskIds.join(',')
        })
    }
}

function commonParams() {
    var lang = navigator.language||navigator.userLanguage
    var country = lang.split('-')[0]
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    let timezoneOfset = Math.abs(new Date().getTimezoneOffset() * 60)
    let timezoneStr = `${timezone} ${timezoneOfset}`
    let params = {
        packageName: ENV.currentConfig.packageName,
        appVersion: ENV.currentConfig.version,
        language: lang,
        countryCode: country,
        region: country,
        deviceType: navigator.userAgent,
        timestamp: parseInt(((new Date()).valueOf()) / 1000),
        udid: CommonUtility.getUuid(),
        timezone: timezoneStr,
        platform: "web",
    }
    params["token"] = User.getInstance.token.value
    return params
}

function timeoutFetch(ms, promise) {
    return new Promise((resolve, reject) => {
      const timer = setTimeout(() => {
        AnalyticHub.getInstance.log('server_delay')
        reject(new Error('TIMEOUT'))
      }, ms)
      promise
        .then(value => {
          clearTimeout(timer)
          resolve(value)
        })
        .catch(reason => {
          clearTimeout(timer)
          reject(reason)
        })
    })
}
