const devConfig = {
    version: '3.8.5',
    packageName: 'fy.web.aiimage',
    apiHost: 'https://testweb-image-api.test987.com',
    swapAPIHost: 'https://testweb-image-api.test987.com',
    downloadHost: 'https://testweb-download.test987.com',
    mediaCDN: 'https://gg-cdn-aiimageweb.test987.com',
    fbID: '860449782231545',
    googleSignInClientId : '175957342235-leceu9jr33g1975nkq8rp6siaitff1d4.apps.googleusercontent.com',
    firebaseConfig: {
        apiKey: 'AIzaSyB_5y0JFZLBqO1HzdvAi-H2Mg_ILez4mMc',
        appId: '1:175957342235:web:beda6360860942485020be',
        messagingSenderId: '175957342235',
        projectId: 'fangyi-f2f7f',
        authDomain: 'fangyi-f2f7f.firebaseapp.com',
        storageBucket: 'fangyi-f2f7f.appspot.com',
        measurementId: 'G-TY9G8V457K',
    },
};

const prodConfig = {
    version: '3.8.5',
    packageName: 'com.ai.image.web',
    apiHost: 'https://webapi.beautyai.fun',
    swapAPIHost: 'https://faceswap.beautyai.fun',
    downloadHost: 'https://download.beautyai.fun',
    mediaCDN: 'https://fileg.beautyai.fun',
    fbID: '860449782231545',
    googleSignInClientId: '832304994996-m0922j3lenbmdbmu4bd9ar2etm4uieq8.apps.googleusercontent.com',
    firebaseConfig : {
        apiKey: 'AIzaSyBh8N08rdsiulXQ5a-H6GNBFqu65AKkytY',
        appId: '1:832304994996:web:39ffe891c3a888561255e1',
        messagingSenderId: '832304994996',
        projectId: 'beautyai-web',
        authDomain: 'beautyai-web.firebaseapp.com',
        storageBucket: 'beautyai-web.appspot.com',
        measurementId: 'G-B9NPMHR6LC',
    },
};

export const ENV = {
    get currentConfig () {
        if (this.isProd) {
            return prodConfig;
        }
            return devConfig;
    },
    get isDev () {
        return process.env.VUE_APP_ENV === 'dev';
    },
    get isUat () {
        return process.env.VUE_APP_ENV === 'uat';
    },
    get isProd () {
        return !this.isDev && !this.isUat;
    },
};
