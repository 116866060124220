export default {
  meta: {
    faceswapTitle:
      'Video Face Swap gratuito, los mejores videos y fotos de IA Intercambio cara en línea',
    faceswapKeywords:
      'Intercambio de caras, Generador de imágenes AI, Intercambio de caras de vídeo,',
    faceswapDescription:
      '3 créditos gratuitos para nuevos usuarios registrados, intercambio de caras de video AI de la más alta calidad en línea, cualquiera puede cambiar la cara por fotos y videos fácilmente con solo unos pocos clics',
    faceswapTab1title:
      'Intercambio de rostros y cuerpos de fotos e imágenes de alta calidad en línea',
    faceswapTab1keywords:
      'Intercambio de caras, Generador de imágenes de IA, Intercambio de caras de vídeo',
    faceswapTab1description:
      'Prueba gratuita, este es un sitio web de intercambio de caras con generador de arte AI para fotos e imágenes, puede usar este software de edición de intercambio de cuerpos para intercambiar dos fotos o crear imágenes',
    faceswapTab2title:
      'Editor de intercambio de caras y cuerpo de imágenes AI de alta calidad en línea',
    faceswapTab2keywords:
      'Intercambio de caras, Generador de imágenes de IA, Intercambio de caras de vídeo',
    faceswapTab2description:
      'Prueba gratuita, este es un generador de arte de inteligencia artificial para intercambio de caras para imágenes y sitio web de fotografías, puede usar este software de intercambio de caras corporales para intercambio de caras de celebridades nsfw o creador de fotos de memes.',
    faceswapTab3title:
      'Creador de videos Intercambio cara de alta calidad e intercambio de caras de video en línea',
    faceswapTab3keywords:
      'Intercambio de caras, Generador de imágenes de IA, Intercambio de caras de vídeo',
    faceswapTab3description:
      'Prueba gratuita, este es un generador de intercambio cara ai para el sitio web de intercambio de caras de video, puede usar este software de edición de video de intercambio de caras en la PC para películas de intercambio de caras, intercambio de caras de celebridades nsfw o creador de videos intercambio cara',
    faceswapTab4title:
      'Vídeos de alta calidad de intercambio de caras y vídeos de intercambio de caras en línea',
    faceswapTab4keywords:
      'Intercambio de caras, Generador de imágenes de IA, Intercambio de caras de vídeo',
    faceswapTab4description:
      'Prueba gratuita, este es un video de intercambio de caras de ai en línea Gratis, puedes usar este software de edición de video de intercambio de caras en la PC para el intercambio de caras del generador de arte ai, intercambio de caras de celebridades nsfw',
  },
  siteName: 'Beauty AI',
  creation: 'Chica IA',
  faceSwap: 'Cambio de cara',
  product: 'Producto',
  magicBrush: 'Pincel Mágico',
  pricing: 'Precios',
  freeCredits: 'Créditos gratis',
  credits: 'Créditos',
  getMoreCredits: 'Obtenga más créditos',
  left: 'Izquierda',
  upgradeToPremium: 'Mejorado a Premium',
  go: '¡Ir!',
  accountInformation: 'Información de la cuenta',
  signOut: 'Desconectar',
  getMore: 'Obtener más',
  contactSupport: 'Soporte de contacto',
  history: 'Historial',
  login: 'Iniciar sesión',
  signup: 'Registrarse',
  aiFuture: 'Función de IA',
  photoFaceSwap: 'Intercambio de caras de fotos',
  photoMutipleFaceSwap: 'Intercambio de caras de varias imágenes',
  videoFaceSwap: 'Creador de vídeos falsos',
  videoMutipleFaceSwap: 'Intercambio de caras de varios vídeos',
  multipleFaceSwap: 'Intercambio de Múltiples Rostros',
  aIGirlGenerator: 'Generador de Imágenes IA',
  aIImageGenerator: 'Generador de Imágenes IA',
  support: 'Soporte',
  language: 'lengua',
  userPolicy: 'Política de Usuario',
  privacyPolicy: 'Política de Privacidad',
  helpAndFAQS: 'Ayuda y Preguntas Frecuentes',
  siteMap: 'Mapa del Sitio',
  bussiness: 'Negocios',
  affiliateProgram: 'Programa de Afiliados',
  api: 'API',
  contactUs: 'Contáctanos',
  aiFaceFunTitle: 'Intercambio de caras de video AI en línea',
  aiFaceFunSubTitle:
    'Intercambia caras instantáneamente con el intercambio de caras en video Beauty AI. Cambia las caras en fotos y videos a quien elijas. Crea memes divertidos con nuestro producto impulsado por IA.',
  startFaceButtonTitle: 'Comenzar Intercambio de Rostros',
  details: 'Detalles  >',
  chooseFace: 'Elegir cara',
  faceSwaps: 'intercambios de caras',
  faceswapUploadArea: {
    tab1Desc1: 'Sube la foto original para cambiar la cara.',
    tab1Desc2: 'Arrastra y suelta una foto o elige una foto',
    tab2Desc1: 'Sube la imagen original para cambiar la cara.',
    tab2Desc2: 'Arrastra y suelta una imagen o elige una imagen',
    tab3Desc1:
      'Sube el video original para intercambiar caras profundamente falsas.',
    tab3Desc2: 'Arrastra y suelta vídeo o elige vídeo',
    tab4Desc1: 'Sube el vídeo original para cambiar la cara.',
    tab4Desc2: 'Arrastra y suelta vídeo o elige vídeo',
    tab1title: '3 pasos para intercambiar caras en fotos o imágenes en línea',
    tab1step1: '1. Sube tu foto de rostro original.',
    tab1step2:
      '2. Cargue una foto del rostro objetivo de la persona que reemplazará el rostro en la foto original. También puede elegir entre las demostraciones fotográficas proporcionadas.',
    tab1step3:
      '3. Inicie Foto de intercambio de caras, luego obtenga una vista previa y descargue los resultados en HD.',
    tab2title: '3 pasos para intercambiar varias caras en imágenes en línea',
    tab2step1: '1. Sube tu foto de cara original.',
    tab2step2:
      '2. Cargue una imagen de la cara objetivo de la persona que reemplazará la cara en la imagen original. También puede elegir entre las demostraciones de imágenes proporcionadas.',
    tab2step3:
      '3. Inicie Face Swap Picture, luego obtenga una vista previa y descargue los resultados en HD.',
    tab3title: '3 pasos para intercambiar caras en videos en línea',
    tab3step1: '1. Sube tu video de cara original.',
    tab3step2:
      '2. Sube una cara objetivo de la persona que reemplazará la cara en el video original. También puede elegir entre las demostraciones proporcionadas.',
    tab3step3:
      '3. Inicie Video Face Swap, luego obtenga una vista previa y descargue los resultados en HD.',
    tab4title: '3 pasos para intercambiar caras en videos en línea',
    tab4step1: '1. Sube tu video de cara original.',
    tab4step2:
      '2. Sube una cara objetivo de la persona que reemplazará la cara en el video original. También puede elegir entre las demostraciones proporcionadas.',
    tab4step3:
      '3. Inicie Video Face Swap, luego obtenga una vista previa y descargue los resultados en HD.',
    chooseFiles: 'Seleccionar archivos',
    photoLimit: 'Máximo 10 MB',
    videoLimit: 'Máximo 500 m, 10 min.',
    uploading: 'Subiendo <xx1>',
    faceAnalyzing: 'Análisis facial',
    queuingAbout: 'En cola...Acerca de <xx1>s',
    analyzingAbout: 'Analizando...Acerca de <xx1>s',
    somethingError: 'Algo salió mal, inténtalo de nuevo más tarde.',
  },
  faceswapUploadFilePopup: {
    title: 'Todos los archivos analizados',
    subtitle: 'Archivos almacenados solo durante 7 días.',
    try: 'Intentar',
  },
  faceswapFaceRecognized: 'Cara reconocida',
  swapFaces: 'intercambiar caras',
  useCredit: 'utilizar <xx1> crédito',
  useCredits: 'utilizar <xx1> créditos',
  faceSwapDetail: {
    firstSectionItem1Title: 'Introducción al intercambio de caras en vídeo',
    firstSectionItem1Text:
      'Un cambiador de rostros con IA es una aplicación de software que utiliza inteligencia artificial (IA) y técnicas de aprendizaje automático para modificar o intercambiar rostros en imágenes y videos.',
    firstSectionItem2Title: 'Público objetivo',
    firstSectionItem2Text:
      'Se espera que los principales usuarios de esta tecnología sean investigadores del intercambio de caras, desarrolladores involucrados en el intercambio de caras y entusiastas del intercambio de caras.',
    firstSectionItem3Title:
      'Introducción al intercambio de caras de vídeo con IA',
    firstSectionItem3Text:
      'Video Face Swap es una tendencia de video popular en la que cambias tu cara con la de otra persona. Con Beauty AI, puedes intercambiar caras en videos con amigos, celebridades o incluso personajes históricos. Crea videos divertidos, memes y collages, o simplemente diviértete experimentando con tu apariencia.',
    secondSectionTitle: 'Qué puedes hacer con el intercambio de caras en vídeo',
    secondSectionItem1Title: 'Explorar posibilidades',
    secondSectionItem1Text:
      'Descubra las fascinantes posibilidades y aplicaciones inesperadas de esta técnica innovadora. Experimente un mundo donde las transformaciones faciales combinan creatividad y tecnología, ofreciendo más de lo que inicialmente esperaba.',
    secondSectionItem2Title: 'Intercambio de caras de vídeo',
    secondSectionItem2Text:
      'Sumérgete en las fascinantes posibilidades y aplicaciones inesperadas de esta técnica. Experimente un mundo de transformaciones faciales que van mucho más allá de las expectativas iniciales, combinando creatividad con tecnología.',
    secondSectionItem3Title: 'Cambio de género',
    secondSectionItem3Text:
      'Únase a la tendencia del intercambio de género en TikTok, Snapchat e Instagram. Comparta sus fotos con intercambio de género para atraer Me gusta y fanáticos, dándole a su presencia en las redes sociales una actualización vibrante.',
    secondSectionItem4Title: 'Intercambio de caras de videos de celebridades',
    secondSectionItem4Text:
      'Transforma tu apariencia colocando tu rostro en el video de cualquier celebridad. Combina tus rasgos con los de una celebridad para crear contenido intrigante.',
    secondSectionItem5Title: 'Intercambio de caras de memes',
    secondSectionItem5Text:
      'Crea memes divertidos usando la función de intercambio de caras. Genere memes nuevos y cómicos con su propia cara o cualquier otra cara y comparta las risas en las plataformas de redes sociales.',
    secondSectionItem6Title: 'Intercambiar con roles de película',
    secondSectionItem6Text:
      'Intercambia caras con tus personajes de películas favoritos y conviértete en un superhéroe intrépido o en un protagonista romántico encantador. Comparte tus imágenes para entusiasmar al fandom del cine.',
    secondSectionItem7Title: 'Intercambio de caras de IA en pinturas',
    secondSectionItem7Text:
      'Reemplaza las caras de las pinturas clásicas por las tuyas. Comparte estas creaciones únicas con amigos y en las redes sociales, cultivando un estilo personal distintivo.',
  },
  faceswapIntroduce: {
    sectionTitle: 'Intercambio de caras profundamente falso con IA en línea',
    item1Title: 'Creador de fotos falsas',
    item1Subtitle:
      'Cambiador de caras en línea gratuito que te permite intercambiar caras en imágenes.',
    item1ActionText: 'Intercambiar cara ahora',
    item2Title: 'Generador de múltiples fotos intercambio cara',
    item2Subtitle:
      'Intercambie varias caras en fotos familiares, fotos de bodas, fotos de fiestas en PC o iPhone. etc.',
    item2ActionText: 'Intercambio de caras múltiples ahora',
    item3Title: 'Creador de vídeos falsos',
    item3Subtitle:
      'Intercambia caras en videos, mejora la realización de películas o crea contenido para las redes sociales.',
    item3ActionText: 'Intercambiar cara ahora',
    item4Title: 'Intercambio de caras de varios vídeos',
    item4Subtitle:
      'Reemplazar múltiples caras en videos con facilidad, ofreciendo una forma divertida y creativa de transformar contenido visual.',
    item4ActionText: 'Intercambio de caras múltiples ahora',
  },
  faceswapGuide: {
    tab1SectionTitle:
      'Cómo funciona el intercambio de caras de imágenes con IA',
    tab1item1Title: 'Sube tu foto de cara original',
    tab1item1Subtitle:
      'Comience cargando una foto que contenga su rostro para que sirva como entrada para el intercambio de rostros.',
    tab1item2Title:
      'Elija la foto de la cara de destino para intercambiar caras',
    tab1item2Subtitle:
      'Cargue una foto del rostro objetivo de la persona que reemplazará el rostro en la foto original. También puede elegir entre las demostraciones fotográficas proporcionadas.',
    tab1item3Title: 'Iniciar foto de intercambio de caras',
    tab1item3Subtitle:
      'Haga clic en \'Intercambiar cara ahora\' para dejar que el intercambiador de caras haga su magia. Solo se necesitan entre 5 y 10 segundos para completar el intercambio de caras.',
    tab1item4Title: 'Vista previa y descarga del resultado HD',
    tab1item4Subtitle:
      'Una vez que se complete el intercambio de caras, descargue la imagen resultante con las caras intercambiadas para uso personal o para compartir.',
    tab2SectionTitle: 'Cómo funciona AI Picture Face Swap Maker',
    tab2item1Title: 'Sube tu foto de cara original',
    tab2item1Subtitle:
      'Comience cargando una imagen que contenga su cara para que sirva como entrada para el intercambio de caras.',
    tab2item2Title:
      'Elija la imagen de la cara de destino para intercambiar caras',
    tab2item2Subtitle:
      'Cargue una imagen de la cara objetivo de la persona que reemplazará la cara en la imagen original. También puede elegir entre las demostraciones de imágenes proporcionadas.',
    tab2item3Title: 'Iniciar intercambio de caras',
    tab2item3Subtitle:
      'Haga clic en \'Intercambiar cara ahora\' para dejar que el intercambiador de caras haga su magia. Solo se necesitan entre 5 y 10 segundos para completar el intercambio de caras.',
    tab2item4Title: 'Vista previa y descarga del resultado HD',
    tab2item4Subtitle:
      'Una vez que se complete el intercambio de caras, descargue la imagen resultante con las caras intercambiadas para uso personal o para compartir.',
    tab3SectionTitle: 'Cómo funciona el intercambio de caras de vídeo con IA',
    tab3item1Title: 'Sube tu vídeo de cara original',
    tab3item1Subtitle:
      'Comience subiendo un video que contenga su cara para que sirva como entrada para el intercambio de caras.',
    tab3item2Title:
      'Elija la foto de la cara de destino para intercambiar caras',
    tab3item2Subtitle:
      'Sube una cara objetivo de la persona que reemplazará la cara en el video original. También puede elegir entre las demostraciones proporcionadas.',
    tab3item3Title: 'Iniciar video de intercambio de caras',
    tab3item3Subtitle:
      'Haga clic en \'Intercambiar cara ahora\' para dejar que el intercambiador de caras haga su magia. Solo se necesitan entre 5 y 10 segundos para completar el intercambio de caras.',
    tab3item4Title: 'Vista previa y descarga del resultado HD',
    tab3item4Subtitle:
      'Una vez que se complete el intercambio de caras, descargue el video resultante con las caras intercambiadas para uso personal o para compartir.',
    tab4SectionTitle: 'Cómo funciona el intercambio de caras de vídeo con IA',
    tab4item1Title: 'Sube tu vídeo de cara original',
    tab4item1Subtitle:
      'Comience subiendo un video que contenga su cara para que sirva como entrada para el intercambio de caras.',
    tab4item2Title:
      'Elija la foto de la cara de destino para intercambiar caras',
    tab4item2Subtitle:
      'Sube una cara objetivo de la persona que reemplazará la cara en el video original. También puede elegir entre las demostraciones proporcionadas.',
    tab4item3Title: 'Iniciar video de intercambio de caras',
    tab4item3Subtitle:
      'Haga clic en \'Intercambiar cara ahora\' para dejar que el intercambiador de caras haga su magia. Solo se necesitan entre 5 y 10 segundos para completar el intercambio de caras.',
    tab4item4Title: 'Vista previa y descarga del resultado HD',
    tab4item4Subtitle:
      'Una vez que se complete el intercambio de caras, descargue el video resultante con las caras intercambiadas para uso personal o para compartir.',
    sectionActionText: 'Iniciar el intercambio de caras',
    howToUse: 'Cómo utilizar >',
  },
  review: {
    title: 'Nos Aman, Tú También Lo Harás',
    subtitle:
      'Únete al equipo de más de 10,000 socios en todo el mundo. Las recompensas que reciben del sitio web son continuas y estables.',
    data1: {
      title: '¡Me encanta usar Beauty AI!',
      content:
        '¡Me encanta usar Beauty AI! Es mi herramienta favorita para agregar un toque divertido a mis fotos. Ya sea que esté intercambiando rostros con amigos o probando diferentes looks de celebridades, los resultados siempre son divertidos y entretenidos.',
      name: 'Cliff Quan',
      dateText: '2 de marzo de 2024',
    },
    data2: {
      title: 'Como entusiasta del maquillaje',
      content:
        'Como entusiasta del maquillaje, uso Beauty AI para probar nuevos looks antes de comprometerme con ellos en la vida real. Intercambiar mi rostro con diferentes personajes me ayuda a visualizar cómo se verán varios estilos en mí. ¡Es una herramienta divertida y práctica para cualquiera que ame experimentar con el maquillaje!',
      name: 'Anli Brevo',
      dateText: '16 de mayo de 2024',
    },
    data3: {
      title: 'Es fácil usar el intercambio de rostros en video',
      content:
        'Es fácil usar la función de intercambio de rostros en video para cambiar tu rostro con el de otra persona. Ya sea que quieras poner la cara de una estrella de cine en tu cabeza o reemplazar tu rostro con el de un jugador de baloncesto, esta herramienta hace el trabajo perfectamente. ¡Sigan así!',
      name: 'Ada Wu',
      dateText: '19 de abril de 2024',
    },
    data4: {
      title: 'Esta increíble herramienta detecta inteligentemente',
      content:
        'Esta increíble herramienta detecta inteligentemente los rostros en las fotos y los reemplaza sin problemas con cualquier rostro que elijas. ¡Es como tener tu propio estudio creativo personal al alcance de tu mano! Con Beauty AI, intercambiar rostros en cualquier foto es fácil, permitiéndome desatar mi imaginación sin problemas.',
      name: 'Mario Kuper',
      dateText: '24 de enero de 2024',
    },
    data5: {
      title: 'Siempre he encontrado desafiante la edición de fotos',
      content:
        'Siempre he encontrado desafiante la edición de fotos, ¡pero Beauty AI lo hace fácil! Me encanta experimentar con las diferentes opciones de intercambio de rostros de IA y ver las combinaciones hilarantes. Es una forma fantástica de agregar humor a mis fotos y mantener a mis amigos riendo.',
      name: 'Chris Jin',
      dateText: '12 de diciembre de 2023',
    },
    data6: {
      title:
        'El intercambio de rostros con IA es ideal para crear memes divertidos',
      content:
        'El intercambio de rostros con IA es ideal para crear memes divertidos, retratos y videos de intercambio de rostros. Beauty AI es perfecto no solo para el entretenimiento sino también para la creación de contenido atractivo. Te ayuda a captar más atención en las redes sociales.',
      name: 'Kotláriková',
      dateText: '24 de marzo de 2024',
    },
  },
  faq: {
    title: 'Preguntas Frecuentes',
    details: 'Detalles  >',
    data1: {
      question: '¿Qué es Beauty AI?',
      answer:
        'Beauty AI es una plataforma visual de IA que aprovecha la tecnología avanzada para intercambiar rostros, retocar, mejorar, colorear, corregir y caricaturizar imágenes automáticamente sin la necesidad de herramientas de edición de fotos complejas como Photoshop. Utilizando algoritmos de IA de última generación, Beauty AI agiliza el procesamiento de fotos, ahorrando tiempo, energía y costos tanto para usuarios individuales como para empresas que requieren edición de fotos en masa.',
    },
    data2: {
      question:
        '¿Cómo funciona una herramienta de Intercambio de Rostros con IA?',
      answer:
        'El Intercambio de Rostros con IA se basa en sofisticados algoritmos de inteligencia artificial para analizar meticulosamente las imágenes fuente, reconocer las características faciales y reemplazarlas sin problemas con otro rostro mientras se mantiene la iluminación natural, los ángulos y las expresiones. Esto da como resultado intercambios de rostros realistas generados por IA.',
    },
    data3: {
      question: '¿Cómo usar el Intercambio de Rostros en Video?',
      answer:
        'Primero, selecciona una imagen frontal clara como base. En segundo lugar, elige el rostro con el que deseas intercambiar, ya sea el tuyo o el de una celebridad. En tercer lugar, inicia el proceso de reemplazo, y el rostro elegido se colocará sin problemas en la imagen base.',
    },
    data4: {
      question: '¿Puedo reemplazar mi rostro con cualquier imagen?',
      answer:
        'Sí, por supuesto. Beauty AI te permite reemplazar tu rostro con cualquier imagen seleccionada de forma gratuita. Por ejemplo, puedes poner un rostro masculino en un retrato femenino o un rostro envejecido en la foto de un bebé. Las posibilidades son infinitas, permitiéndote convertirte en quien desees sin limitaciones.',
    },
    data5: {
      question: '¿Cómo lograr el mejor resultado?',
      answer:
        'Para lograr resultados óptimos con el intercambio de rostros, sigue estos consejos: Selecciona una foto clara con rasgos faciales bien definidos, evitando imágenes borrosas. Asegúrate de que solo haya un rostro en la foto y que el rostro esté mirando directamente a la cámara sin obstrucciones de objetos como manos.',
    },
    data6: {
      question: '¿Puedo intercambiar rostros en línea de forma gratuita?',
      answer:
        '¡Absolutamente! Puedes intercambiar rostros en línea de forma gratuita utilizando Beauty AI. Ofrecemos planes gratuitos y premium para satisfacer tus necesidades. Como nuevo usuario, recibirás tres créditos al registrarte, lo que te brinda acceso completo a nuestro servicio de intercambio de rostros y funciones adicionales como recorte de rostros y creación de avatares.',
    },
  },
  faceswapToHistory: {
    tab1or2header:
      'Hemos guardado el historial de fotos de intercambio de caras para usted.',
    tab3or4header:
      'Hemos guardado el historial de videos de intercambio de caras para usted.',
    footer: 'más detalle para ver Historia.',
  },
  faceswapOnlineSection: {
    tab1Title: 'Intercambio de caras de fotos con IA en línea',
    tab2Title: 'Intercambio de caras de imágenes con IA en línea',
    tab3Title: 'Intercambio de caras de video AI en línea',
    tab4Title: 'Creador de videos de intercambio de caras con IA en línea',
    tab1Desc:
      'Intercambia caras instantáneamente con Beauty AI Photo Face Swap. Reemplace la cara en fotos y videos con quien usted elija. Crea memes divertidos con nuestro.',
    tab2Desc:
      'Intercambia caras instantáneamente con Beauty AI Picture Face Swap. Cambie las caras en imágenes y videos a quien usted elija. Crea memes divertidos con nuestro intercambio de caras con nuestro generador de arte ai.',
    tab3Desc:
      'Intercambia caras instantáneamente con Beauty AI Video Face Swap. Cambia las caras en fotos y videos a quien elijas. Crea memes divertidos con nuestro producto impulsado por IA.',
    tab4Desc:
      'Intercambia caras instantáneamente con Beauty AI Video Face Swap. Cambia la cara en los videos a la que elijas. Crea memes divertidos con nuestro creador de intercambio de caras con generador de arte AI.',
    action: 'Iniciar el intercambio de caras',
  },
  choose: {
    delText: '¿Estás seguro de eliminar esta cara?',
    leftBtnText: 'Continuar',
    rightBtnText: 'Cancelar',
    overFaces:
      'La carga de caras ha alcanzado el límite. Elimina otras caras para continuar.',
    faceAn: 'Analizando la cara...',
    facesuccess: 'Éxito del análisis facial',
    facefailed: 'Error en el análisis facial',
    feedback: '¡Gracias por tus comentarios!',
    copy: '¡Copia exitosa!',
    batchsupport:
      '¡Soportado por lotes! Puedes realizar un nuevo intercambio de caras sin importar el resultado actual.',
    batchsupportAIGirl:
      '¡Soportado por lotes! Puedes crear una nueva Imagen de IA sin importar el resultado actual.',
    submitting: 'Sumisión',
    queuing: 'hacer cola',
    creating: 'Creando',
    downloading: 'Descargando',
    retryText: 'Algo salió mal, inténtalo de nuevo más tarde.',
    retry: 'Rever',
    emailTip:
      'Introduzca un sufijo de nombre de dominio válido (como .com, .net, etc.)',
    noaccount: '¿No tienes cuenta aún?',
    enterPassWord: 'Introducir la contraseña',
    passWordErr: 'Establecer contraseña de 8 caracteres mínimo',
    loginPassWordErr: 'La contraseña debe tener al menos 8 caracteres',
    forgotpass: '¿Has olvidado tu contraseña?',
    googlesign: '¿Iniciar sesión con el inicio de sesión de Google?',
    resetpassword: 'Restablecer la contraseña',
    readyaccount: '¿Ya tienes una cuenta?',
    confirm: 'Confirmar',
    resetpasswordsuccess: '¡Restablecer contraseña correctamente!',
    senderror: '¡Enviar error!',
    sendsuccess: '¡Envía éxito!',
    signupfree: 'Registrate gratis',
    signupsuccess: 'Regístrate ¡Éxito!',
    sendcode: 'Enviar código',
    loginsuccess: '¡Inicio de sesión exitoso!',
    logingerror: '¡Error de inicio de sesión!',
    aboutS: 'Sobre {leftTime}s',
    keepPatient: '¡Paciencia!',
    needMoretime: '¡¡ se necesita más tiempo!',
    aimostDoneKeep: '¡Casi se ha completado! ¡Paciencia!',
    usernamePlace: 'user{\'@\'}example.com',
    emailCode: 'Código de verificación',
  },
  feedbackDialog: {
    title: 'Necesitamos sus comentarios para mejorar Beauty AI.',
    subTitle1: '¿Quieres mejorar?',
    option1: 'Análisis facial inexacto sobre archivos cargados',
    option2: 'No parece',
    option3:
      'Problemas con los detalles de la cara (distorsionados, ojos, boca, etc.)',
    option4: 'Tiempo lento',
    option5: 'Resultado Nitidez',
    txtOption1: 'Contenido sensible o perturbador',
    txtOption2: 'Violación de los términos de servicio.',
    txtOption3: 'Relevancia inmediata',
    txtOption4: 'Cara distorsionada',
    txtOption5: 'Contenido de la imagen',
    txtOption6: 'Nitidez de la imagen',
    txtOption7: 'Tiempo lento',
    subTitle2: '¿Quieres decir más?',
    placeholder: 'Otras sugerencias...',
    submit: 'Entregar',
    reportTitle: 'Denunciar la foto',
    reportOptionTitle: 'Categoría',
    reportOption1: 'Contenido sensible o perturbador',
    reportOption2: 'Correo basura',
    reportOption3: 'Violación de las condiciones de uso',
  },
  infoDialog: {
    advancedFeatures: 'Características avanzadas',
    advancedFeaturesText:
      'Pague por nuestros planes para desbloquear la función de intercambio de caras realista en video.',
    choosePlan: 'Elige Plan',
    insufficientCredits: 'Créditos insuficientes',
    insufficientCreditsText: 'Su saldo de crédito:',
    getMoreCredits: 'Obtenga más créditos',
    videoTooBig: 'El vídeo es demasiado grande.',
    videoTooLong: 'El vídeo es demasiado largo.',
    videoCannotExceed: 'El vídeo no puede exceder',
    inFileSize: 'en tamaño de archivo',
    selectAnotherFile: 'Seleccione otro archivo',
    photoTooBig: 'La foto es demasiado grande.',
    photoCannotExceed: 'La foto no puede exceder',
    tenMin: '10 minutos',
    deleteFile: '¿Estás seguro de eliminar este archivo?',
    cancel: 'Cancelar',
    confirm: 'Confirmar',
  },
  fileUpload: {
    demo: 'demo',
    historicalFiles: 'Todos los archivos',
    uploading: 'Subiendo',
    queuing: 'hacer cola',
    analyzing: 'analizando',
    photoFileFormatError:
      '¡El formato de archivo no es compatible! Por favor sube una foto.',
    videoFileFormatError:
      '¡El formato de archivo no es compatible! Por favor sube vídeo.',
    resolutionExceed1920: 'La resolución no puede exceder 1920*1920',
    videoIsProcessed:
      'El video se está procesando, puede que tarde unos minutos debido al consumo de recursos, pero todo está bien. ¡Por favor sea paciente!',
    filesLoading: 'Cargando, inténtalo de nuevo más tarde',
  },
  submitSamePhotoTaskHint:
    '¡Advertencia! Has enviado las mismas caras con la misma foto, cambia tus caras para enviarlas. (Tenga en cuenta que este envío no le cuesta crédito)',
  submitSameVideoTaskHint:
    '¡Advertencia! Has enviado las mismas caras con el mismo vídeo, cambia tus caras para enviarlo. (Tenga en cuenta que este envío no le cuesta crédito)',
  allRightsReserved: 'Reservados todos los derechos.',
  aiGirlHowToUse: 'Cómo utilizar una imagen de IA',
  aiGirlFAQDetails: 'Preguntas frecuentes sobre imágenes con IA',
  lookslikeUploadOpps: {
    title: '¡Ups!',
    subtitle:
      '¡Ups! ¡Supere los límites de créditos gratuitos!\nSuscríbase al plan para utilizar todas las funciones de forma ilimitada.',
    unlockLimit: 'Límite de desbloqueo',
  },
  selectStyle: 'Seleccionar estilo',
};
