<template>
  <div class="dialog-mask">
    <div class="main">
      <div class="img" @click="() => {
        props.close();
      }"></div>
      <div class="h1">{{ $t('selectStyle') }}</div>
      <div class="list">
        <div
          class="item"
          v-for="(item, index) in txtToImgStore.styles"
          :key="item.styleKey"
        >
          <div
            :class="[
              'item-child',
              { act: index === txtToImgStore.currentStyleIndex },
            ]"
            @click="
              (e) => {
                $analytic.log('creation_style_tick')
                txtToImgStore.changeCurrentStyleIndex(index);
                props.close();
                // scrollToItem(e);
              }
            "
          >
            <img class="item-child-img" :src="item.styleImageUrl" alt="" />
          </div>
          <div class="item-text">{{ item.styleName }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { CommonUtility } from "@/script/utility";
import { useTxtToImg } from "@/stores/useTxtToImg";
import {i18n} from "@/i18n/txtToImg/index";
import { getCurrentInstance, onMounted, onBeforeUnmount } from 'vue';

const { $analytic } = getCurrentInstance().appContext.config.globalProperties
const clear_icon = CommonUtility.assetImage('clear_icon');
const txtToImgStore = useTxtToImg();
const props = defineProps({
  close: {
    type: Function,
  },
});

onMounted(() => {
  document.body.style.overflow = 'hidden';
})

onBeforeUnmount(() => {
  document.body.style.overflow = 'auto';    
})

</script>

<style scoped lang="scss">
.dialog-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 51;
  .main {
    max-width: 486px;
    height: 560px;
    background: #27282d;
    border-radius: 4px;
    padding: 20px;
    position: relative;
    margin: 20px;
    .close {
      position: absolute;
      right: 20px;
      top: 20px;
      width: 32px;
      height: 32px;
    }
    .h1 {
      margin-top: 10px;
      font-weight: bold;
      font-size: 20px;
      color: rgba(255, 255, 255, 0.95);
      line-height: 28px;
      text-align: center;
      font-style: normal;
    }
    .img {
      cursor: pointer;
      width: 32px;
      height: 32px;
      float: right;
      background: url('@/assets/images/png/close_icon.png');
      background-size: contain;
      &:hover {
        background: url('@/assets/images/png/close_icon_hover.png');
        background-size: contain;
      }
    }
    .list {
      margin-top: 30px;
      display: flex;
      flex-wrap: wrap;
      height: 89%;
      min-width: 500px;
      overflow-y: scroll;
      justify-content: start;
      .item {
        cursor: pointer;
        margin: 0 9px;
        margin-bottom: 20px;
        &:last-child {
          margin-right: 0;
        }
        .item-child {
          flex-shrink: 0;
          width: 92px;
          height: 74px;
          border-radius: 4px;
          border: 1px solid rgba($color: #ffffff, $alpha: 0.1);
          padding: 4px;
          background: #27282d;
          &:hover {
            border: 1px solid #ff7839;
          }
          .add {
            height: 100%;
            background: rgba(255, 255, 255, 0.1);
            border-radius: 9px;
            text-align: center;
            line-height: 70px;
          }
          &-img {
            width: 100%;
            height: 100%;
            border-radius: 2px;
          }
          &.act {
            border: 1px solid #ff7839;
          }
        }
        .item-text {
          font-family: 'PingFang SC';
          font-weight: 500;
          font-size: 12px;
          color: #ffffff;
          line-height: 14px;
          text-align: center;
          margin-top: 8px;
        }
        .item:hover {
          .item-child {
            border: 1px solid #ff7839;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1070px){
.dialog-mask{
  .main{
    border-radius: 15px;
    min-width: 335px;
    max-width: 335px;
    min-height: 411px;
    max-height: 411px;
    padding-bottom: 30px;
    .list{
      min-width: 310px;
      justify-content: space-between;
      .item {
        margin: 0px 0px 10px 0px;
        .item-child {
          width: 85px;
          height: 68px;
        }
      }      
    }
  }
}
}
</style>
