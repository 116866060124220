import { defineStore } from 'pinia';
import { API } from '@/script/api';

import { Config } from '@/script/config';
import { User } from '@/script/user';
import { useTxtToImgTaskManager } from './useTxtToImgTaskManager';
import { WSChannel } from '@/script/ws_channel';
import { ENV } from '@/script/proj_env';
import { watch } from 'vue';

export const useTxtToImg = defineStore('txtToImg', {
    state: () => {
        return {
            // 提交历史
            submitHistory: [],
            // 推荐提示
            suggestionPrompts: [],
            // 风格列表
            styles: [],
            // 批量列表
            batchSizes: [],
            // 二维数组
            hintTags: [],

            suggestionPromptLoadStatus: API.REQUEST_STATUS_NONE,
            stylesLoadStatus: API.REQUEST_STATUS_NONE,
            replaceFacesLoadStatus: API.REQUEST_STATUS_NONE,
            hintTagLoadStatus: API.REQUEST_STATUS_NONE,

            currentStyleIndex: 0,
            currentFaceIndex: -1,
            currentBatchSizeIndex: 0,
            currentTagCategoryIndex: 0,
            //  选中tag的数量，默认是0
            changeTagNUm:0,
            //
            currentRatioIndex: 0,

            prompt: '',
            hasUserChangedPrompt: false,

            aiGirlShowcaseTriggerToTop: 0,
        };
    },
    getters: {
        /// 当前选中style是否为写实
        currentRealisticStyle: (state) => {
            try {
                return state.styles[state.currentStyleIndex].styleKey == 'realistic';
            } catch (_) {
                return false;
            }
        },
        /// 当前ratio选中索引转成接口字段值
        currentRatioAPIValue: (state) => {
            switch (state.currentRatioIndex) {
                case 1:
                    return '11';
                case 2:
                    return '32';
                default:
                    return '23';
            }
        },
        /// 是否有最近使用tag
        hasRecentHintTag: (state) => {
            return state.hintTags[0].titleKey == 'recent';
        },
        /// 是否可以提交换脸任务
        submitEnable: (state) => {
            return state.prompt.length > 0;
        },

        /// 当前需要积分数
        useCredisCount: (state) => {
            let count = 1;
            return count * state.batchSizes[state.currentBatchSizeIndex];
        },
        /// hintTag模块当前选中类别下的tags
        currentCategoryTags: (state) => {
            return state.hintTags[state.currentTagCategoryIndex];
        },
    },
    actions: {
        // 重置prompt
        clearPrompt () {
            this.prompt = '';
        },
        setPrompt (text) {
            this.prompt = text;
        },
        /// 初始化
        init () {
            // 从本地加载恢复部分初始数据
            const jsonStr = localStorage['flutter.stored_items_json'];
            if (jsonStr) {
                const submitHistoryTem = JSON.parse(jsonStr);
                if (typeof (submitHistoryTem) === 'object') {
                    this.submitHistory = this.submitHistorySplice(submitHistoryTem);
                }
            }

            // 各模块数据拉取
            this.loadSuggestionRandom();
            this.loadSuggestionPrompts();
            this.loadStyles();

            // 处理初始batchSize
            this.updateBatchSize();

            if (User.getInstance.isLogin.value) {
                User.getInstance.loadUserInfo();
            }
            Config.getInstance.loadConfig().then(() => {
                this.updateBatchSize();
            });
        },
        /// 更新当前style选中索引
        changeCurrentStyleIndex (index) {
            this.currentStyleIndex = index;
        },
        /// 更新当前looks like人脸选中索引
        changeCurrentFaceIndex (index) {
            if(this.currentFaceIndex == index) {
                this.currentFaceIndex =  -1;
            } else {
                this.currentFaceIndex = index;
            }
        },
        /// 更新当前batch size选中索引
        changeCurrentBatchSizeIndex (index) {
            this.currentBatchSizeIndex = index;
        },
        /// 更新当前hint tag类别选中索引
        changeCurrentTagCategoryIndex (index) {
            this.currentTagCategoryIndex = index;
        },
        /// 更新当前图片比例类别选中索引
        changeCurrentRatioIndex (index) {
            this.currentRatioIndex = index;
        },
        /// 使用历史提交记录填充数据
        useSubmitHistoryItem (history) {
            this.prompt = history.submitPrompt;
            this.currentStyleIndex = Math.max(0, this.styles.indexOf((e) => e.styleKey == history.selectedStyleKey));
            this.currentFaceIndex = Math.max(0, this.faces.indexOf((e) => e.id == history.selectedFaceId));
            this.currentRatioIndex = history.selectedImageRatio == '23' ? 0 : (history.selectedImageRatio == '11' ? 1 : 2);
        },
        /// 获取一次随机Prompt
        async loadSuggestionRandom () {
            if (localStorage.txttoimgparams) {
                return;
            }
            try {
                // 跳转有提示词的时候不请求
                if(this.prompt !== '') {
                    return;
                }
                const resp = await API.getSuggestionRandom();
                const keyword = resp?.data?.suggestion?.suggestionKeyword;
                if (keyword && !this.hasUserChangedPrompt) {
                    this.prompt = keyword;
                }
            } catch (_) { }
        },
        /// 获取推荐prompt提示词列表
        async loadSuggestionPrompts () {
            this.suggestionPromptLoadStatus = API.REQUEST_STATUS_LOADING;
            try {
                this.suggestionPrompts.splice(0, this.suggestionPrompts.length);
                const resp = await API.getSuggestionList();
                if (resp.code == 200) {
                    this.suggestionPrompts.push(...resp.data.suggestions);
                    this.suggestionPromptLoadStatus = API.REQUEST_STATUS_SCUCESS;
                } else {
                    this.suggestionPromptLoadStatus = API.REQUEST_STATUS_FAILED;
                }
            } catch (_) {
                this.suggestionPromptLoadStatus = API.REQUEST_STATUS_FAILED;
            }
        },
        /// 选中suggestion prompt
        selecteSuggestionPrompt (suggestionPrompt) {
            this.prompt = suggestionPrompt.suggestionKeywordDetail;
            for (const item of this.suggestionPrompts) {
                item.selected = false;
            }
            suggestionPrompt.selected = true;
        },
        /// 清除当前suggestion prompt选中状态
        unselectSuggestionPrompt () {
            for (const item of this.suggestionPrompts) {
                item.selected = false;
            }
        },
        /// 获取style列表
        async loadStyles () {
            this.stylesLoadStatus = API.REQUEST_STATUS_LOADING;
            try {
                this.styles.splice(0, this.styles.length);
                const resp = await API.getStyleList();
                if (resp.code == 200) {
                    this.styles.push(...resp.data.styles);
                    this.stylesLoadStatus = API.REQUEST_STATUS_SCUCESS;
                } else {
                    this.stylesLoadStatus = API.REQUEST_STATUS_FAILED;
                }
            } catch (_) {
                this.stylesLoadStatus = API.REQUEST_STATUS_FAILED;
            }
        },
        unique (arr) {
            let obj = {};
            arr.forEach((item) => {
              obj[item] = '';
            });
            return Object.keys(obj);
        },
        /// 更新batchSize相关数据
        updateBatchSize () {
            this.batchSizes.splice(0, this.batchSizes.length);
            if (User.getInstance.hasPaid.value) {
                this.batchSizes.push(...Config.getInstance.paidBatchSize.value);
            } else {
                this.batchSizes.push(...Config.getInstance.freeBatchSize.value);
            }
            if (localStorage.txtToImgBatchSize) {
                let findIndex = -1;
                for (let i = 0; i < this.batchSizes.length; i++) {
                    if (this.batchSizes[i] == parseInt(localStorage.txtToImgBatchSize)) {
                        findIndex = i;
                        break;
                    }
                }
                if (findIndex != -1) {
                    this.currentBatchSizeIndex = findIndex;
                } else {
                    this.currentBatchSizeIndex = this.batchSizes.length - 1;
                }
            }else {
                this.currentBatchSizeIndex = this.batchSizes.length - 1;
            }
        },
        /// 更新当前batchSize 索引
        changeCurrentBatchSize (index) {
            if (index >= this.batchSizes.length) {
                this.currentBatchSizeIndex = this.batchSizes.length - 1;
            } else {
                this.currentBatchSizeIndex = index;
            }
            localStorage.txtToImgBatchSize = JSON.stringify(submitParams.batch);
        },
        /// 提交任务
        async submit () {
            let submitPrompt = this.prompt;
            const submitParams = {
                prompt: submitPrompt,
                styleKey: this.styles[this.currentStyleIndex].styleKey,
                ratio: this.currentRatioAPIValue,
                batch: this.batchSizes[this.currentBatchSizeIndex],
            };
            if (this.currentRealisticStyle && this.currentFaceIndex >= 0) {
                submitParams.faceId = this.faces[this.currentFaceIndex].id;
            }
            await useTxtToImgTaskManager().submit(submitParams);
            try {
                let index = -1;
                for (let i = 0; i < this.submitHistory.length; i++) {
                    const ele = this.submitHistory[i];
                    if (ele.submitPrompt == submitParams.prompt && ele.selectedStyleKey == submitParams.styleKey) {
                        index = i;
                        break;
                    }
                }
                if (index >= 0) {
                    this.submitHistory.splice(index, 1);
                }
            }catch (_) {}

            this.submitHistory.unshift({
                submitPrompt: submitParams.prompt,
                selectedStyleKey: submitParams.styleKey,
                selectedFaceId: submitParams.faceId,
                selectedImageRatio: submitParams.ratio,
            });
            if (this.submitHistory.length > 10) {
                this.submitHistory.splice(10, this.submitHistory.length - 10);
            }
            localStorage.setItem('flutter.stored_items_json', JSON.stringify(this.submitHistory));
            localStorage.setItem('txtToImgBatchSize', JSON.stringify(submitParams.batch));
        },
        submitHistorySplice (list = [], num = 11) {
            if (list.length > num) {
                return list.slice(0, num);
            }  
                return list;
        },
        syncRules (item) {
            // 同步
            this.prompt = item.promptKeyword || '';
            // 取消文本框下部选中
            this.unselectSuggestionPrompt();
            for (let i = 0; i < this.styles.length; i++) {
                let styleItem = this.styles[i];
                if (styleItem.styleKey == item.promptImageStyleKey) {
                   this.changeCurrentStyleIndex(i);
                }
            }

            this.changeCurrentRatioIndex(this.currentValueAPIRatio(item.promptImageSize));
        },
        // 当前ratio选中接口字段值转成索引
        currentValueAPIRatio: (state) => {
            switch (state) {
                case '11':
                    return 1;
                case '32':
                    return 2;
                default:
                    return 0;
            }
        },
        /// 文生图写实结果用于提交换脸分析
        async realistmicFaceAnalyticSubmit (task) {
            try {
                const resp = await API.swapUploadDetectFace({ promptTaskId: task.taskId });
                if (resp.code == 200) {
                    return resp.data;
                }
                return null;
            }catch (e) {
                return null;
            }
        },
        /// 文生图写实结果提交换脸分析后，监听分析状态
        async realisticFaceAnalyticStatusListener (taskId, listener) {
            const channel = new WSChannel(ENV.currentConfig.swapAPIHost, '/ai/analyser_channel');
            await channel.open();
            const event = channel.listener();
            let watchStop = null;
            watchStop = watch(event.statusCode, async () => {
                if (event.statusCode.value.code < 0) { // 错误
                    watchStop();
                    listener(-1);
                }else if (event.statusCode.value.code == 2) { // 排队中
                    listener(2);
                }else if (event.statusCode.value.code == 3) { // 完成
                    watchStop();
                    listener(3);
                }
            });
            channel.sendVerifyMessage(taskId);
        },
        /**
         * 用于监听 图册模块点击。图册点击时调用一次即可
         * 所有涉及图集点击后滑动顶部的地方，监听 aiGirlShowcaseTriggerToTop，发生改变则表明图册被点击了
         */
        aiGirlShowcaseTriggerToTopAction () {
            this.aiGirlShowcaseTriggerToTop++;
        },
    },
});
