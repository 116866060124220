import basic from '../basic/ja';
export default {
  ...basic,
  ...{
    meta: {
      title:
        '無料の AI 女の子画像ジェネレーター、オンラインテキストからホットでかわいい女の子画像を作成',
      description:
        'Beautyai.fun AI Gril写真生成アプリを使用して、テキストプロンプトでAI生成画像やアートを作成します。リアルタイムのテキストから画像へのAIツール',
      keywords:
        'フェイススワップ、AI画像ジェネレーター、ビデオフェイススワップ、',
    },
    recent: '最近の',
    txtToImg: {
      history: '歴史',
      clear: 'クリア',
      describeCreation: '創造を説明する',
      promptPlace: 'テキストプロンプトを入力して、作品を作成しましょう。',
      looksLike: '似ている',
      batchSize: 'バッチサイズ',
      hintTags: 'ヒントタグ',
      unselect: '選択解除',
      create: '作成する',
      aspectRatio: 'アスペクト比',
      optional: '（オプション）',
      pro: 'プロ',
    },
    use1Credit: '1クレジットを使用します。',
    useXCredits: '<xx1> クレジットを使用します。',
    guide: {
      title: 'より良い作品を作る方法',
      headTip:
        'より高品質の写真をアップロードしてください。品質の悪い写真は質の悪い作品につながります。',
      goodTitle: '良い例',
      badTitle: '悪い例',
    },
    examplePopup: {
      title: 'より良い作品を作る方法',
      photoTip:
        'より高品質の写真をアップロードしてください。品質の悪い写真は質の悪い作品につながります。',
      goodExample: '良い例',
      badExample: '悪い例',
      btnTip: 'わかった',
    },
    AiGirlShowcase: {
      title: 'AI生成画像',
      hovertitle: 'このプロンプトを試してください',
      buttontext: 'ボタン ボタン ボタン',
    },
    textToImgToHistory: {
      tab1or2header: '私たちはあなたのために世代記録を保存しました',
      footer: '履歴を確認して下さい',
    },
    txtToImgIndex: {
      introduce: {
        item1Title: 'あなたのアイデアから生まれたAI画像ジェネレーター',
        item1Subtitle:
          'あなたのアイデアを共有するだけで、高度な AI テクノロジーがあなただけのためにパーソナライズされた女の子のキャラクターを作成する様子を見ることができます。',
        item1ActionText: '画像の作成を開始する',
        item2Title: 'アニメの女の子の画像をカスタマイズする',
        item2Subtitle:
          'Beauty AI は、あらゆるスタイルのアニメーション作品を作成できる AI アート画像ジェネレーターです。インスピレーションを決め、プロンプトで説明し、アニメ画像に変換します。ユニークなアニメキャラクターとソウルメイトを作るとき、あなたの作品はあなたのものであることを忘れないでください。',
        item3Title: 'インスピレーションで本物のホットなソウルメイトを生み出す',
        item3Subtitle:
          '使いやすい AI ガール画像ジェネレーターとして、私たちはあなたの夢を叶えるために全力を尽くします。あなたの夢の女の子を簡単な言葉で説明してください。数秒でアートを生成します。',
      },
      howToUse: {
        sectionTitle: 'Beauty AIでAI画像を作成する方法',
        item1Title: 'テキストプロンプトを書く',
        item1Subtitle:
          'プロンプト フィールドに表示する内容の説明を入力します。',
        item2Title: 'イメージを作成する',
        item2Subtitle:
          'プロンプトに問題がなければ、「生成」をクリックします。結果は数秒で表示されます。Beauty AI によって生成された画像が気に入ったら、右上隅の「ダウンロード」ボタンをクリックしてこの画像をダウンロードしてください。',
        item3Title: '改良、修正、再作成',
        item3Subtitle:
          '設定をいろいろ試して、さまざまなバリエーションを試してみましょう。左側のパネルでは、アスペクト比からコンテンツ タイプまで、すべてを調整できます。必要に応じて、テキスト プロンプトに詳細を追加して、まったく新しい画像を生成することもできます。新しい画像を作成する前に、気に入った画像をすべて保存してください。',
        actionText: '画像の作成を開始する',
      },
      review: {
        sectionTitle: '彼らは私たちを愛しています、あなたもそうでしょう',
        card1Title: 'ビューティーAIを使用した後',
        card1Subtitle:
          'Beauty AI画像生成機能を使ってみて、その強力さを実感しました。操作が簡単なだけでなく、生成された画像は非常に高品質で、作業効率が大幅に向上し、とても満足しています。',
        card1UserName: 'マイケル・ジョンソン',
        card1Date: '2023年6月6日',
        card2Title: 'AI 画像ジェネレーターは本当に魔法のツールです!',
        card2Subtitle:
          'AI 画像ジェネレーターは本当に魔法のツールです。そのインテリジェントなアルゴリズムに驚かされました。いつでも私のニーズに合わせて、正確なビキニやかわいい女の子の写真を生成できます。私の仕事に欠かせない良いヘルパーです。',
        card2UserName: 'サラ・トンプソン',
        card2Date: '2024年5月6日',
        card3Title: 'AI画像ジェネレータを使い始めてから',
        card3Subtitle:
          'AI 画像ジェネレーターを使い始めてから、私のデザインスキルは大幅に向上しました。その強力な画像生成機能により、私の創造性を完璧に表現できます。本当に使いやすいです!',
        card3UserName: 'エミリー・デイビス',
        card3Date: '2023年12月4日',
        card4Title: 'AI画像ジェネレータのインターフェースデザイン',
        card4Subtitle:
          'AI 画像ジェネレータのインターフェース デザインは非常にユーザー フレンドリーで、非常に使いやすいです。さらに、生成される写真は高品質で高速なので、作業の労力を半分にして 2 倍の結果を得ることができます。強くお勧めします。',
        card4UserName: 'マリオ・クーパー',
        card4Date: '2024年1月1日',
        card5Title: 'この素晴らしいツールは顔を賢く検出します',
        card5Subtitle:
          'この素晴らしいツールは、写真内の顔をインテリジェントに検出し、選択した顔にシームレスに置き換えます。まるで、指先で自分専用のクリエイティブ スタジオを持っているようなものです。Beauty AI を使用すると、どの写真でも顔を簡単に入れ替えることができるので、手間をかけずに想像力を解き放つことができます。',
        card5UserName: 'ウィリアム・スミス',
        card5Date: '2023年2月12日',
        card6Title: 'AI 画像ジェネレーターに心から感謝します',
        card6Subtitle:
          'AI 画像ジェネレーターに心から感謝します。彼らが開発したソフトウェアのおかげで、仕事で多くの時間を節約できました。生成された写真は美しいだけでなく、私のニーズを完全に満たしています。5 つ星の賞賛です!',
        card6UserName: 'クリストファー・アンダーソン',
        card6Date: '2023年7月19日',
      },
      faq: {
        sectionTitle: 'よくある質問',
        item1Q: 'AIジェネレーターとは何ですか?',
        item1A:
          'AI 画像ジェネレーターは、数語を入力するだけで画像を作成できる生成 AI テクノロジの一種です。AI エンジンはテキスト説明内の各単語を指示として受け取り、単語の組み合わせと単語同士の関係に基づいて画像を構築します。',
        item2Q: 'AI画像ジェネレータはどのように機能しますか?',
        item2A:
          'AI 画像ジェネレーターは、他のタイプの人工知能と同様に、機械学習モデルと膨大なデータセットを使用して結果を生成します。言い換えると、AI テクノロジーは何千枚もの写真から学習します。学習した内容をプロンプト フィールドに入力した日常的な言語に適用することで、まったく新しい画像を作成します。',
        item3Q: '素晴らしい AI 画像ジェネレーターとはどのようなものでしょうか?',
        item3A:
          'AI 画像ジェネレーターを選ぶときは、高品質の画像を生成し、それらの画像を自分のニーズに合わせてクリエイティブにコントロールでき、それらの画像を商業的に使用できるものを探してください。Beauty AI は、これらすべての機能を備えているため、優れた選択肢です。',
        item4Q: '自分の顔をどんな画像でも置き換えることはできますか？',
        item4A:
          'はい、その通りです。Beauty AI を使用すると、無料で任意の選択した画像に自分の顔を置き換えることができます。たとえば、女性のポートレートに男性の顔を、赤ちゃんの写真に老いた顔を置き換えることができます。可能性は無限で、制限なく望む人になりきることができます。',
        item5Q: 'Beauty AI 画像は商用利用が可能ですか?',
        item5A:
          'はい、Beauty AI 機能で生成された画像を商用目的で使用できます。',
        item6Q: '無料でオンラインで画像を作成できますか?',
        item6A:
          'もちろんできます！Beauty AI では、画像を無料で作成できます。お客様のニーズに合わせて、無料プランとプレミアムプランをご用意しています。新規ユーザーの場合、登録時に 3 つのクレジットが付与され、画像作成機能だけでなく、フェイススワップやマジックブラシなどの他の機能にも完全にアクセスできるようになります。',
      },
    },
    realisticToFaceSwapPopup: {
      status1Text: 'キューイング中...<xx1> について',
      status2Text: '顔分析中...約<xx1>',
    },
    skeywordsHint:
      'ポリシーに違反する不適切なキーワードが検出されました: <xx1>',
    introducehomeindex: {
      item1title: 'AIアート画像をカスタマイズする',
      item1subtitle:
        'Beauty AI は、あらゆるスタイルの AI 作品を作成できる AI アート画像ジェネレーターです。インスピレーションを決め、プロンプトで説明し、AI 画像に変換します。アート作品を作成するときは、作品があなたのものであることを忘れないでください。',
      item1buttontext: '今すぐ無料トライアル',
      item2buttontext: '今すぐ無料トライアル',
    },
    showcasehomeindex: {
      title: 'AI生成画像',
      buttontext: '今すぐ無料トライアル',
      hovertitle: 'このプロンプトを試してください',
    },
    publishToast:
      '画像は当社のチームによって審査され、承認されるとギャラリーに表示されます。',
  },
};
