import { reactive, computed } from 'vue';
import { API } from './api.js';

export class Config {
    static get getInstance () {
        if (!this.instance) {
            this.instance = new Config();
        }
        return this.instance;
    }

    get affiliateUrl () {
        return computed(() => this.config.affiliateUrl | '');
    }

    get apiUrl () {
        return computed(() => this.config.apiUrl | '');
    }

    get freeBatchSize () {
        return computed(() => {
            let value = this.config.freeBatchSize | '';
            if (value) {
                return value.substring(1, value.length - 1).split(',').map((ele) => parseInt(ele));
            }
                return [4, 2, 1];
        });
    }

    get paidBatchSize() {
        return computed(() => {
            let value = this.config.paidBatchSize | '';
            if (value) {
                return value.substring(1, value.length - 1).split(',').map((ele) => parseInt(ele));
            }
            return [4, 2, 1];
        });
    }

    get paymentMethods () {
        return computed(() => {
            let value = this.config.paymentMethod | '';
            return value.split(',').map((ele) => ele.trim());
        });
    }

    get paypalPaymentActive () {
        return computed(() => this.paymentMethods.indexOf('Paypal'));
    }

    get stripePaymentActive () {
        return computed(() => this.paymentMethods.indexOf('Stripe'));
    }

    constructor () {
        let configStr = localStorage.getItem('config');
        if (configStr) {
            this.config = reactive(JSON.parse(configStr));
        }else {
            this.config = reactive({});
        }
    }

    async init () {
        await this.loadConfig();
    }
 
    async loadConfig () {
        try {
            let resp = await API.loadConfigInfo();
            if (resp.code != 200) {
                return false;
            }
            this.syncConfig(resp.data);
            return true;
        }catch (_) {
            return false;
        }
    }

    syncConfig (config) {
        this.config.value = config;
        let configStr = JSON.stringify(config);
        localStorage.setItem('config', configStr);
    }
}