<template>
    <div class="section-container">
        <h2 class="section-title">{{ $t('faceswapIntroduce.sectionTitle') }}</h2>
        <div class="card-container">
            <FaceSwapIntroduceCard v-for="item in cardDatas" :key="item.id" :class="'card-' + item.id" :src="item.imgSrc"
                :title="item.title" :subTitle="item.subTitle" :actionText="item.actionText" :action="item.action" :href="item.href"/>
        </div>
    </div>
</template>

<script setup>
import FaceSwapIntroduceCard from './FaceSwapIntroduceCard.vue';
import { getCurrentInstance, inject } from 'vue';


const { $utility, $jumpOtherPage, $otherPageUrl, i18n } = getCurrentInstance().appContext.config.globalProperties;
const tabCategory = inject('tabCategory');

const cardDatas = [
    {
 id: '1', imgSrc: $utility.assetImage(`faceswap_introduce_${i18n.global.locale}_item1`), title: i18n.global.t('faceswapIntroduce.item1Title'), subTitle: i18n.global.t('faceswapIntroduce.item1Subtitle'), actionText: i18n.global.t('faceswapIntroduce.item1ActionText'), action: () => {
        $jumpOtherPage('/photo-face-swap', true, true);
    }, href: $otherPageUrl('/photo-face-swap', true),
},
    {
 id: '2', imgSrc: $utility.assetImage(`faceswap_introduce_${i18n.global.locale}_item2`), title: i18n.global.t('faceswapIntroduce.item2Title'), subTitle: i18n.global.t('faceswapIntroduce.item2Subtitle'), actionText: i18n.global.t('faceswapIntroduce.item2ActionText'), action: () => {
        $jumpOtherPage('/multiple-picture-face-swap', true, true);
    }, href: $otherPageUrl('/multiple-picture-face-swap', true),
},
    {
 id: '3', imgSrc: $utility.assetImage(`faceswap_introduce_${i18n.global.locale}_item3`), title: i18n.global.t('faceswapIntroduce.item3Title'), subTitle: i18n.global.t('faceswapIntroduce.item3Subtitle'), actionText: i18n.global.t('faceswapIntroduce.item3ActionText'), action: () => {
        $jumpOtherPage('/video-face-swap', true, true);
    }, href: $otherPageUrl('/video-face-swap', true),
},
    {
 id: '4', imgSrc: $utility.assetImage(`faceswap_introduce_${i18n.global.locale}_item4`), title: i18n.global.t('faceswapIntroduce.item4Title'), subTitle: i18n.global.t('faceswapIntroduce.item4Subtitle'), actionText: i18n.global.t('faceswapIntroduce.item4ActionText'), action: () => {
        $jumpOtherPage('/multiple-video-face-swap', true, true);
    }, href: $otherPageUrl('/multiple-video-face-swap', true),
},
];
switch (tabCategory) {
    case 0: {
        cardDatas.splice(0, 1);
    }break;
    case 1: {
        cardDatas.splice(1, 1);
    }break;
    case 2: {
        cardDatas.splice(2, 1);
    }break;
    default: {
        cardDatas.splice(3, 1);
    }break;
}
</script>

<style scoped lang="scss"> 
@media (min-width: 1071px) {
    .section-container {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .section-title {
        font-size: 40px;
        font-weight: 700;
        font-family: 'PingFang SC';
        color: white;
        margin: 0;
        padding: 0;
    }

    .card-container {
        margin-left: auto;
        margin-right: auto;
        margin-top: 38px;
        max-width: 1360px;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        padding: 0% 40px;
        -webkit-align-items: center;
        align-items: center;
    }

    .card-1 {
        width: 400px;
    }

    .card-2 {
        width: 400px;
    }

    .card-3 {
        width: 416px;
    }

    .card-4 {
        width: 410px;
    }
}

@media (max-width: 1070px) {
    .section-container {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .section-title {
        font-size: 18px;
        font-weight: 700;
        font-family: 'PingFang SC';
        color: white;
        margin: 0;
        padding: 0;
    }

    .card-container {
        margin-left: auto;
        margin-right: auto;
    }

    .card-1 {
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
    }

    .card-2 {
        margin-left: auto;
        margin-right: auto;
        margin-top: 64px;
    }

    .card-3 {
        margin-left: auto;
        margin-right: auto;
        margin-top: 55px;
    }

    .card-4 {
        margin-left: auto;
        margin-right: auto;
        margin-top: 55px;
    }
}
</style>