<template>

    <div v-if="visible" class="toast-container">
        <div class="toast" :class="type">
            <img class="left" :src="assetImage(this.leftIconSrc)" />
            <div>{{ msg }}</div>
            <img class="right" @click="cancle" :src="assetImage('icon_cancle')" />
        </div>
    </div>
</template>

<script>
import { CommonUtility } from '@/script/utility';

export default {
    name: 'AppToast',
    data () {
        return {
            visible: false,
            msg: '',
            leftIconSrc: '',
            type: '',
            timer: null,
        };
    },
    methods: {
        show (msg, leftIconSrc, type, duration) {
            clearTimeout(this.timer);
            this.visible = true;
            this.type = type;
            this.msg = msg;
            this.leftIconSrc = leftIconSrc;

            this.timer = setTimeout(() => {
                this.visible = false;
                this.timer = null;
            }, duration);
        },
        assetImage (name) {
            return CommonUtility.assetImage(name);
        },
        cancle () {
            clearTimeout(this.timer);
            this.visible = false;
            this.timer = null;
        },
    },
};
</script>

<style scoped lang="scss"> 
.toast-container {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    position: fixed;
    z-index: 1100;
    top: 5%;
}

.toast {
    max-width: 90%;
    width: auto;
    padding: 10px 14px;
    background: rgba(0, 0, 0, 0.3);
    color: white;
    border-radius: 11px;
    font-size: 18px;
    font-weight: 400;
    font-family: 'PingFang SC';
    transition: opacity 0.3s;
    display: flex;
    align-items: center
}

.toast.info {
    background: rgba(38, 165, 14, 1);
}

.toast.warning {
    background: rgba(221, 59, 73, 1);
}

.left {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.right {
    width: 10px;
    height: 10px;
    margin-left: 20px;
}
</style>