<template>
  <div class="ar_box">
    <div class="ar_title">
      {{ $t('txtToImg.aspectRatio') }}
    </div>
    <div class="ar_content_box">
      <div class="ar_list">
        <div :class="['ar_item', {ar_item_check: txtToImgStore.currentRatioIndex == item['code']}]"  v-for="(item, index) in ratioList" :key="index" @click="ratioCheck(item)">
          <div class="ar_item_content">
            <div class="icon_box">
              <img :src="CommonUtility.assetImage(item['imgName'])" alt="" srcset="">
            </div>
            <div class="tip_box">
              {{item['tip']}}
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import {
  reactive,
  ref,
  computed,
  watch,
  onMounted,
  getCurrentInstance,
} from "vue";
// 导入页面数据
import {useTxtToImg} from "@/stores/useTxtToImg";
//  导入图片加载器
import {CommonUtility} from "@/script/utility";


// 加载页面数据
const txtToImgStore = useTxtToImg();

//  比率数组
const ratioList = reactive([{code: 0, tip: '2 : 3', imgName: 'aspectRatio2b3'}, {
  code: 1,
  tip: '1 : 1',
  imgName: 'aspectRatio1b1'
}, {code: 2, tip: '3 : 2', imgName: 'aspectRatio3b2'}])

/**
 * 比例选择
 * @param item
 */
const ratioCheck = (item) => {
  txtToImgStore.changeCurrentRatioIndex(item['code'])
}

</script>
<style scoped lang="scss">
.ar_box{
  width: 100%;
  .ar_title{
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    text-align: left;
    font-style: normal;
    padding:20px 0;
  }
  .ar_content_box{
    width: 100%;
    .ar_list{
      display: flex;
      align-items: center;


      .ar_item{
        width: 100px;
        height: 82px;
        margin-right: 17px;
        border-radius: 4px;
        border: 1px solid rgba(255,255,255,0.1);
        background-color: #0D0D0D;
        display: flex;
        justify-content: center;
        align-items: center;



        .ar_item_content{
          width: 92px;
          height: 74px;
          background: rgba(255,255,255,0.1);
          border-radius: 4px;

          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .icon_box{
            flex-grow: 1;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
              max-height: 36px;
              max-width: 36px;
            }
          }
          .tip_box{
            cursor: pointer;
            width: max-content;
            font-weight: 400;
            font-size: 14px;
            color: #FFFFFF;
            line-height: 16px;
            text-align: left;
            font-style: normal;
            margin-bottom: 5px;
          }
        }
      }

      .ar_item:last-child{
        margin-right: 0;
      }
      .ar_item_check {
        border: 1px solid  #FF7839;
      }
    }
  }
}
@media (any-hover: hover){
  .ar_item:hover{
    border: 1px solid #FF7839;

  }

}

@media screen and (max-width: 1070px){
  .ar_box {
    width: 100%;
    .ar_title {
      font-weight: 600;
      font-size: 20px;
      color: #DCDADE;
      line-height: 24px;
      text-align: left;
      font-style: normal;
      padding-top: 0;
    }

    .ar_content_box{
      width: 100%;
      .ar_list{
        display: flex;
        align-items: center;
        .ar_item{
          width: 79px;
          height: 64px;
          border-radius: 4px;
          margin-right: 10px;
          border: 1px solid rgba(255,255,255,0.1);
          background-color: #0D0D0D;
          display: flex;
          justify-content: center;
          align-items: center;



          .ar_item_content{
            width: 73px;
            height: 58px;
            background: #20222B;
            border-radius: 4px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .icon_box{
              flex-grow: 1;
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              img{
                max-height: 28px;
                max-width: 28px;
              }
            }
            .tip_box{
              cursor: pointer;
              width: max-content;
              font-weight: 400;
              font-size: 12px;
              color: #FFFFFF;
              line-height: 14px;
              text-align: left;
              font-style: normal;
              margin-bottom: 5px;
            }
          }
        }

        .ar_item:last-child{
          margin-right: 0;
        }
        .ar_item_check {
          border: 1px solid  #FF7839;
        }
      }
    }

  }

}
</style>
