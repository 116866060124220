<template>
    <div class="faq-item-container" @mouseover="hover = true" @mouseleave="hover = false" @click="click">
        <div class="faq-question-bar">
            <h3 :class="faqQuestionTxtClsBind">{{ data.question }}</h3>
            <img :class="faqStatusImgClsBind" :src="arrowSrc">
        </div>
        <transition name="router-slid">
            <div :class="faqAnswerContentClsBind">
                <p class="faq-answer-txt">{{ data.answer }}</p>
            </div>    
        </transition>
    </div>
</template>

<script>
export default {
    data () {
        return {
            faqAnswerContentClsBind : ['faq-answer-content', 'faq-display-none'],
            faqStatusImgClsBind : 'faq-status-img',
            hover: false,
            arrowSrc: this.$utility.assetImage('faq_detail_normal_arrow'),
            faqQuestionTxtClsBind : 'faq-question-txt',
        };
    },
    props: { data: Object },  
    watch: {
        'data.unfold' () {
            this.updateDisplayStatus();
        },
        hover () {
            this.updateDisplayStatus();
        },
    },
    methods: {
        click () {
            this.$emit('faqItemClick', this.data);
        },
        
        updateDisplayStatus () {
            let isMobile = window.mobileAndTabletCheck();
            if (this.data.unfold) {
                this.faqAnswerContentClsBind = ['faq-answer-content'];
                this.arrowSrc = this.$utility.assetImage('faq_detail_selected_arrow');
                this.faqStatusImgClsBind = 'faq-status-img-selected';
            }else {
                this.faqStatusImgClsBind = 'faq-status-img';
                this.faqAnswerContentClsBind = ['faq-answer-content', 'faq-display-none'];
                if (this.hover && !isMobile) {
                    this.arrowSrc = this.$utility.assetImage('faq_detail_hover_arrow');
                }else {
                    this.arrowSrc = this.$utility.assetImage('faq_detail_normal_arrow');
                }
            }
            if ((this.hover && !isMobile) || this.data.unfold) {
                this.faqQuestionTxtClsBind = 'faq-question-txt-highlight';
            }else {
                this.faqQuestionTxtClsBind = 'faq-question-txt';
            }
        },
    },
};
</script>

<style scoped lang="scss"> 
@media (min-width: 1071px) {
    .faq-item-container {
        width: 100%;
        min-width: 300px;
        background-color: #070A14;
        border-radius: 14px;
        cursor: pointer;
        text-align: start;
        color: white;
        font-family: 'PingFang SC';
        padding: 2px 0px;
        border: 2px #151821 solid;
    }
    .faq-question-bar {
        height: 48px;
        display: flex;
        padding-left: 14px;
        padding-right: 14px;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        -webkit-align-items: center;
        align-items: center;
    }
    .faq-answer-content {
        padding-left: 20px;
        padding-right: 47px;
        padding-bottom: 14px;
    }
    .faq-display-none {
        display: none;
    }
    .faq-question-txt {
        margin: 0;
        font-size: 17px;
        font-weight: 500; 
        font-family: 'PingFang SC';
    }  
    .faq-question-txt-highlight {
        margin: 0;
        font-size: 17px;
        font-weight: 500; 
        font-family: 'PingFang SC';
        color: #FF9441;
    }
    .faq-status-img {
        width: 7px;
        height: 12px;
    }
    .faq-status-img-selected {
        width: 12px;
        height: 7px;
    }
    .faq-answer-txt {
        margin: 0;
        font-size: 12px;
        font-weight: 400;
        font-family: 'PingFang SC';
    }
    .router-slid-enter-active, .router-slid-leave-active {
        transition: all .4s;
    }
    .router-slid-enter, .router-slid-leave-active {
        transform: translate3d(0, 3rem, 0);
        opacity: 0;
    }
}
@media (max-width: 1070px) {
    .faq-item-container {
        width: 100%;
        min-width: 300px;
        background-color: #070A14;
        border-radius: 14px;
        cursor: pointer;
        text-align: start;
        color: white;
        font-family: 'PingFang SC';
        padding: 2px 0px;
        border: 1px #151821 solid;
    }
    .faq-question-bar {
        height: 48px;
        display: flex;
        padding-left: 20px;
        padding-right: 16px;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        -webkit-align-items: center;
        align-items: center;
    }
    .faq-answer-content {
        padding-left: 20px;
        padding-right: 47px;
        padding-bottom: 14px;
    }
    .faq-display-none {
        display: none;
    }
    .faq-question-txt {
        margin: 0;
        font-size: 12px;
        font-weight: 500; 
        font-family: 'PingFang SC';
    }  
    .faq-question-txt-highlight {
        margin: 0;
        font-size: 12px;
        font-weight: 500; 
        font-family: 'PingFang SC';
        color: #FF9441;
    }
    .faq-status-img {
        width: 8px;
        height: 14px;
    }
    .faq-status-img-selected {
        width: 14px;
        height: 8px;
    }
    .faq-answer-txt {
        margin: 0;
        font-size: 12px;
        font-weight: 400;
        font-family: 'PingFang SC';
    }
    .router-slid-enter-active, .router-slid-leave-active {
        transition: all .4s;
    }
    .router-slid-enter, .router-slid-leave-active {
        transform: translate3d(0, 3rem, 0);
        opacity: 0;
    }
}
</style>