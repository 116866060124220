import basic from '../basic/es'
export default {
    ...basic,
    ...{
        ok : "DE ACUERDO",
        tutorialEntrance : "Cómo hacer una creación de calidad",
        examplePopup : {
            title : "¿Cómo hacer una creación de alta calidad?",
            photoTip : "Consejos: para obtener la mejor foto de intercambio de caras con IA, asegúrese de que la calidad de la foto que desea cambiar en línea",
            videoTip : "Consejos: para obtener el mejor video de intercambio de caras con IA, asegúrese de que la calidad del video que desea cambiar en línea",
            goodExample : "Buenos ejemplos",
            badExample : "Malos ejemplos",
            highQuality : "Alta calidad",
            lookingAtViewer : "Mirando al espectador",
            sideFaces : "·Caras laterales",
            tooFar : "·Muy lejos",
            blurry : "Borroso",
            lessCover : "Menos cobertura",
            lowResolution : "Baja resolucion",
        },
        youtubeSectionTitle : "¿Cómo utilizar el intercambio de caras?",
        tutorial : "Tutorial",
    },
}