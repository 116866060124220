

<template>
    <div class="home-introduce-section">
        <div class="home-introduce-section-wrapper">
            <IntroduceHomeItem 
                class="item-wrapper"
                v-for="item of items" 
                :key="item.id" 
                :imgSrc="item.src" 
                :title="item.title" 
                :subtitle="item.subtitle" 
                :actionText="item.actionText" 
                :action="item.action"
                :hReverse="item.hReverse"
            />
        </div>
    </div>
</template>

<script setup>
import IntroduceHomeItem from '@/components/home/IntroduceHomeItem.vue';

import { getCurrentInstance, defineEmits } from 'vue';

const emits = defineEmits(['action']);
const { $utility, i18n } = getCurrentInstance().appContext.config.globalProperties;

const items = [
    {
        id: 1,
        title: i18n.global.t('introducehomeindex.item1title'),
        subtitle: i18n.global.t('introducehomeindex.item1subtitle'),
        actionText: i18n.global.t('introducehomeindex.item1buttontext'),
        hReverse: true,
        action: () => {
            jump();
        },
        src: $utility.assetImage('home_introduce_img1'),
    },
];
function jump () {
    emits('action');
}
</script>

<style scoped lang="scss">
@media (min-width: 1000px) {
    .home-introduce-section {
        display: flex;
        background-color: #171717;
        padding: 0 120px;
        .space {
            min-width: 30px;
        }
        .home-introduce-section-wrapper {
            max-width: 1400px;
            margin: 100px auto 100px auto;
            .item-wrapper {
                margin-top: 150px;
                :deep(.txt-area) {
                    text-align: left;
                    align-items: start
                }
            }
            .item-wrapper:first-child {
                margin-top: 0px;
            }
        }
    }
}
@media (max-width: 999px) {
    .home-introduce-section {
        display: flex;
        background-color: #171717;
        padding: 0 12px;
        .space {
            width: 13px;
        }
        .home-introduce-section-wrapper {
            margin: 40px auto 40px auto;
            .item-wrapper {
                margin-top: 30px;
            }
            .item-wrapper:first-child {
                margin-top: 0px;
            }
        }
    }
}
</style>