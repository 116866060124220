<template>
    <button v-show="highlight" class="faceswap-category-tab-item" ref="btn1" @mouseenter="hover = true" @mouseleave="hover = false">
        {{  title  }}
    </button>
    <div ref="btn2" v-show="!highlight" class="faceswap-category-tab-item" @mouseenter="hover = true" @mouseleave="hover = false">
        <a :href="href" target="_blank">
            {{  title  }}
        </a>
    </div>
</template>

<script>
export default {
    data () {
        return { hover: false };
    },
    props: {
        title: String,
        highlight: Boolean,
        href: String,
    },
    watch: {
        hover () {
            this.updateLayout();
        },
    },
    mounted () {
        this.updateLayout();
    },
    methods: {
        updateLayout () {
            let btn;
            if (this.highlight) {
                btn = this.$refs.btn1;
            }else {
                btn = this.$refs.btn2;
            }
            if (this.highlight) {
                btn.style.background = 'linear-gradient(to right, #D33A31, #FF9441)';
            }else if (!this.hover) {
                btn.style.cursor = 'pointer';
                btn.style.background = 'rgba(255,255,255,0.1)';
            }else {
                btn.style.cursor = 'pointer';
                btn.style.background = 'rgba(255,255,255,0.2)';
            }
        },
        noneClick (e) {
            e.preventDefault();
        },
    },
};
</script>

<style scoped lang="scss">
/*去除a标签下划线*/
    a {
        text-decoration: none;
        color: white;
    }
    /*去除未被访问的a标签的下划线*/
    a:link {
        text-decoration: none;
    }
    /*去除已经被访问过的a标签的下划线*/
    a:visited {
        text-decoration: none;
    }
    /*去除鼠标悬停时的a标签的下划线*/
    a:hover {
        text-decoration: none;
    }
    /*去除正在点击的a标签的下划线（鼠标按下，尚未松开）*/
    a:active {
        text-decoration: none;
    }
    /*去除获得焦点的a标签的下划线（被鼠标点击过）*/
    a:focus {
        text-decoration: none;
    }
.faceswap-category-tab-item {
    height: 37px;
    padding: 0px 10px;
    border: 0px solid transparent;
    border-radius: 7px;
    outline: none;
    white-space:nowrap;
    text-align: center;
    color: white;
    font-size: 16px;
    font-weight: 700;
    font-family: 'PingFang SC';
    margin: 0;
    display: flex;
    justify-self: center;
    align-items: center;
    // p {
    //     margin-top: auto;
    //     margin-bottom: auto;
    // }
}
@media (max-width: 1070px) {
    .faceswap-category-tab-item {
        height: 30px;
        border-radius: 6px;
        text-align: center;
        color: white;
        font-size: 12px;
        font-weight: 400;
        font-family: 'PingFang SC';
        margin: 0;
    }
}
</style>