<template>
    <div class="faq-section">
        <div class="faq-section-title-header">
            <view class="faq-section-header-flex-wrapper-2 faq-display-none-narrow"></view>
            <view class="faq-section-header-flex-wrapper-8">
                <h2 class="faq-section-title">{{ $t('faq.title') }}</h2>
            </view>
            <view class="faq-section-header-flex-wrapper-2 faq-h-end faq-display-none-narrow">
                <a v-if="!isDetails" class="faq-section-details" :href="faqDetailsUrl" target="_blank">{{ $t('faq.details') }}</a>
            </view>
        </div>
        <div class="faq-display-none-large faq-section-details-top">
            <a v-if="!isDetails" class="faq-section-details" :href="faqDetailsUrl" target="_blank">{{ $t('faq.details') }}</a>
        </div>
        <div class="faq-items-container">
            <div v-for="item in datas" :key="item.id">
                <FaceSwapFAQItem :data="item" @faqItemClick="faqItemClick" />
                <div class="faq-item-v-space"></div>
            </div>
        </div>
    </div>
</template>

<script>
import FaceSwapFAQItem from '@/components/faceSwapIndex/FaceSwapFAQItem.vue';
export default {
    components: { FaceSwapFAQItem },
    data () {
        return {
            datas: [],
            currentUnfoldIndex: -1,
            faqDetailsUrl: '',
        };
    },
    methods: {
        faqItemClick (data) {
            if (this.datas.indexOf(data) == -1) {
                return;
            }
            let index = this.datas.indexOf(data);
            data.unfold = !data.unfold;
            if (this.currentUnfoldIndex != -1 && index != this.currentUnfoldIndex) {
                this.datas[this.currentUnfoldIndex].unfold = false;
            }
            if (data.unfold) {
                this.currentUnfoldIndex = index;
            } else {
                this.currentUnfoldIndex = -1;
            }
        },
    },
    props: { isDetails: Boolean },
    created () {
        let lang = this.$utility.geti18nComponentPath();
        if (lang) {
            this.faqDetailsUrl = `${window.location.protocol}//${window.location.host}/${lang}/faceswap-faq`;
        } else {
            this.faqDetailsUrl = `${window.location.protocol}//${window.location.host}/faceswap-faq`;
        }
    },
    beforeMount () {
        for (let i = 0; i < 6; i++) {
            let a = `faq.data${i + 1}.question`;
            this.datas.push({
                id: `${i}`,
                question: this.$t(a),
                answer: this.$t(`faq.data${i + 1}.answer`),
                unfold: false,
            });
        }
    },
};
</script>

<style scoped lang="scss"> 
@media (min-width: 1071px) {
    .faq-section {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        max-width: 920px;
    }

    .faq-section-title-header {
        width: 100%;
        display: -webkit-flex;
        display: flex;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        -webkit-align-items: center;
        align-items: end;
    }

    .faq-section-header-flex-wrapper-8 {
        flex: 8;
    }

    .faq-section-header-flex-wrapper-2 {
        flex: 2;
    }

    .faq-section-title {
        margin-left: auto;
        margin-right: auto;
        font-size: 40px;
        font-weight: 700;
        font-family: 'PingFang SC';
        color: white;
        margin: 0;
    }

    .faq-h-end {
        text-align: end;
        margin-bottom: 8px;
    }

    .faq-section-details {
        right: 0px;
        font-size: 16px;
        font-weight: 400;
        font-family: 'PingFang SC';
        color: white;

        &:hover {
            color: #FF9441;
        }
    }

    .faq-item-v-space {
        min-height: 16px;
    }

    .faq-items-container {
        margin-top: 40px;
    }

    .faq-display-none-large {
        display: none;
    }
}

@media (max-width: 1070px) {
    .faq-section {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        max-width: 990px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .faq-section-title-header {
        width: 100%;
        display: -webkit-flex;
        display: flex;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        -webkit-align-items: center;
        align-items: end;
    }

    .faq-section-header-flex-wrapper-8 {
        flex: 8;
    }

    .faq-section-header-flex-wrapper-2 {
        flex: 2;
    }

    .faq-section-title {
        margin-left: auto;
        margin-right: auto;
        font-size: 18px;
        font-weight: 700;
        font-family: 'PingFang SC';
        color: white;
        margin: 0;
    }

    .faq-h-end {
        text-align: end;
        margin-bottom: 8px;
    }

    .faq-section-details {
        right: 0px;
        font-size: 14px;
        font-weight: 400;
        font-family: 'PingFang SC';
        color: white;

        &:hover {
            color: #FF9441;
        }
    }

    .faq-section-details-top {
        margin-top: 10px;
    }

    .faq-item-v-space {
        min-height: 16px;
    }

    .faq-items-container {
        margin-top: 14px;
    }

    .faq-display-none-narrow {
        display: none;
    }
}
</style>