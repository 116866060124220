import * as user from './user'
import { md5 } from 'js-md5'
import { ref } from 'vue'

export class WSChannelEvent {

    constructor(statusCode = 0, data) {
        this.statusCode = ref(new StatusCode(statusCode))
        this.data = data
    }
}

export class StatusCode {
    constructor(code) {
        this.code = code
    }
}

export class WSChannel {

    static ConnectionErrorCode = -1001

    constructor(host, path) {
        let url = host.replace('https', 'wss')
        this.url = `${url}${path}`
        this.timerId = null
        this.lastSendVerifyMeesage = null
        window.addEventListener("offline", () => {
            this.dispose(3001)
        });
    }

    get active() {
        return this.wsObj != null
    }

    async open() {  
        this.wsObj = new WebSocket(this.url)
        return new Promise((resolve) => {
            this.wsObj.addEventListener('open', () => {
                resolve(true)
            })
            this.wsObj.addEventListener('error', (event) => {
                resolve(true)
            })
        })
    }

    sendVerifyMessage(taskId, tokenNew = true) {
        let timestamp = parseInt((Date.now() / 1000).toFixed(0))
        let token = user.User.getInstance.token.value
        let params
        if (taskId) {
            params = {
                'ctime': timestamp,
                'taskId': taskId,
            }
        }else {
            params = {
                'ctime': timestamp,
            }
        }
        if (tokenNew) {
            params['tokenNew'] = token
        }else {
            params['token'] = token
        }
        let queryString = Object.keys(params).sort().map(key => key + '=' + params[key]).join('&');
        queryString = `${queryString}FtA50esxi`
        let sign = md5(queryString)
        params['sign'] = sign
        let msg = JSON.stringify(params)
        this.waitForConnection(() => {
            try {
                this.wsObj.send(msg)
            }catch (_) {}
        }, 1000)
        this.lastSendVerifyMeesage = msg
        if (!this.timerId) {
            this.timerId = setInterval(() => {
                this.wsObj?.send(this.lastSendVerifyMeesage)
            }, 60000)
        }
        // setTimeout(() => {
        //     this.wsObj?.close(3002)
        // }, 3000)
    }

    waitForConnection(callback, interval) {
        if (this.wsObj.readyState === 1) {
            callback();
        } else {
            var that = this;
            setTimeout(function () {
                that.waitForConnection(callback, interval);
            }, interval);
        }
    };

    listener() {
        const event = new WSChannelEvent()
        this.wsObj.addEventListener('message', function(wsEvent) {
            try {
                let wsEventData = JSON.parse(wsEvent.data)
                if (wsEventData.code == 200) {
                    event.data = wsEventData
                    event.statusCode.value = new StatusCode(wsEventData.data.taskStatus)
                }else {
                    if (wsEventData.data) {
                        event.data = wsEventData.data.message
                    }else {
                        event.data = wsEventData.message
                    }
                    event.statusCode.value = new StatusCode(WSChannel.ConnectionErrorCode)
                }
            }catch (e) {}
        })
        this.wsObj.addEventListener('close', function(wsEvent) {
            if (wsEvent.code != 1000) {
                event.data = ''
                event.statusCode.value = new StatusCode(WSChannel.ConnectionErrorCode)
            }
        })
        return event
    }

    dispose(code) {
        if (code == 3001) {
            this.wsObj?.close(code, 'client close')
        }else {
            this.wsObj?.close()
        }
        this.wsObj = null
        if (this.timerId) {
            clearInterval(this.timerId)
            this.timerId = null
        }
        this.lastSendVerifyMeesage = null
    }
}