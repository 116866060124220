import { ref, computed } from 'vue';
import { ENV } from './proj_env.js';
import { API } from './api.js';
import { md5 } from 'js-md5';
import { googleSdkLoaded, googleLogout } from 'vue3-google-login';
export class User {
    static get getInstance () {
        if (!this.instance) {
            this.instance = new User();
        }
        return this.instance;
    }

    constructor () {
        let userInfoStr = localStorage.getItem('userInfo');
        if (userInfoStr) {
            const userInfo = JSON.parse(userInfoStr);
            if (userInfo.swords) {
                this.swordsList = window.atob(userInfo.swords).split(',');
            }
            this.info = ref(userInfo);
        } else {
            this.info = ref({});
        }
    }

    /// 是否登录
    get isLogin () {
        return computed(() => this.token.value.length > 0);
    }

    /// 用户token
    get token () {
        return computed(() => this.info.value?.token || '');
    }

    /// 用户邮箱
    get email () {
        return computed(() => this.info.value.email || '');
    }

    /// 是否订阅
    get isSubscribe () {
        return computed(() => this.info.value?.bSubscriptionMember || false);
    }

    /// 订阅过期时间戳
    get expiredCTime () {
        return computed(() => this.info.value.expiredCTime || 0);
    }

    /// 购买的消耗品+注册的消耗品剩余
    get promptConsumeNumber () {
        return computed(() => this.info.value?.promptConsumeNumber || 0);
    }

    /// 激励赠送的消耗品剩余
    get promptRewardConsumeNumber () {
        return computed(() => this.info.value?.promptRewardConsumeNumber || 0);
    }

    /// 订阅下消耗品剩余
    get promptSubscribeNumber () {
        return computed(() => this.info.value?.promptSubscribeNumber || 0);
    }

    /// 消耗品剩余-除订阅外
    get consumerLeftNumber () {
        return computed(() => this.promptConsumeNumber.value + this.promptRewardConsumeNumber.value);
    }

    /// 订阅+购买消耗品剩余
    get purchaseLeftNumber () {
        return computed(() => this.promptSubscribeNumber.value + this.promptConsumeNumber.value);
    }

    /// 所有可用的消耗品
    get allLeftNumber () {
        return computed(() => this.purchaseLeftNumber.value + this.promptRewardConsumeNumber.value);
    }

    /// 是否有过付费
    get hasPaid () {
        return computed(() => this.info.value?.hasPaid == '1');
    }

    /// 邀请链接
    get referralUrl () {
        return computed(() => this.info.value.referralUrl || '');
    }

    /// 邀请key
    get referralKey () {
        return computed(() => this.info.value.referralKey || '');
    }

    /// 是否有密码（google登录没有密码）
    get hasPass () {
        return computed(() => this.info.value.hasPass == '1');
    }

    /// 站长分成比例
    get affiliateCommission () {
        return computed(() => this.info.value.affiliateCommission || 0);
    }

    /// 站长邀请注册key
    get affiliateKey () {
        return computed(() => this.info.value.affiliateKey || '');
    }

    /// 站长邀请注册URL
    get affiliateUrl () {
        return computed(() => this.info.value.affiliateUrl || '');
    }

    /// 站长绑定收款账号
    get affiliatePayeeAccount () {
        return computed(() => this.info.value.affiliatePayeeAccount || '');
    }

    /// 站长提现费率
    get affiliatePayoutFees () {
        return computed(() => this.info.value.affiliatePayoutFees || 0);
    }

    /// 站长收益余额
    get affiliateRevenue () {
        return computed(() => this.info.value.affiliateRevenue);
    }

    /// 敏感词
    get swords () {
        return computed(() => this.swordsList);
    }

    /// 初始化
    async init () {
        if (this.token.value) {
            this.loadUserInfo();
        }else if (localStorage.getItem('token')) {
            this.info.value.token = localStorage.getItem('token');
            this.loadUserInfo();
        }
    }

    /// 加载用户数据
    async loadUserInfo () {
        try {
            let resp = await API.loadUserInfo();
            if (resp.code != 200) {
                return false;
            }
            this.syncUserInfo(resp.data);
            return true;
        } catch (_) {
            return false;
        }
    }

    /// 同步用户信息
    syncUserInfo (info) {
        if (info.swords) {
            this.swordsList = window.atob(info.swords).split(',');
        }
        this.info.value = info;
        let infoStr = JSON.stringify(info);
        localStorage.setItem('userInfo', infoStr);
    }

    /// 邮箱密码登录
    async loginByEmail ({
        email,
        password,
    }) {
        password = md5(`${password}asdzxucoihk1`);
        try {
            let resp = await API.userLogin({
                email,
                password,
            });
            if (resp.code == 200) {
                this.syncUserInfo(resp.data);
            }
            return resp;
        } catch (_) {
            return false;
        }
    }

    /// 用户注册
    async signupUser ({
        email,
        password,
    }) {
        password = md5(`${password}asdzxucoihk1`);
        try {
            let params = {
                email,
                password,
            };
            if (localStorage.getItem('affiliateChildKey')) {
                params.affiliateKey = localStorage.getItem('affiliateChildKey');
            }
            let resp = await API.userRegister(params);
            if (resp.code === 200) {
                this.syncUserInfo(resp.data);
            }
            return resp;
        } catch (_) {
            return false;
        }
    }

    /// 被其它用户邀请注册
    async referSignupUser ({
        email,
        password,
        verifyCode,
        referralKey,
    }) {
        password = md5(`${password}asdzxucoihk1`);
        try {
            let resp = await API.userRegisterVerify({
                email,
                password,
                verifyCode,
                referralKey,
            });
            if (resp.code === 200) {
                this.syncUserInfo(resp.data);
            }
            return resp;
        } catch (_) {
            return false;
        }
    }

    /// 发送注册邮箱验证码
    async sendSignupVerifyCode ({ email }) {
        try {
            let resp = await API.userSendRegisterCode({ email });
            // if (resp.code != 200) {
            //     return false;
            // }
            return resp;
        } catch (_) {
            return false;
        }
    }

    /// google登录
    async loginByGoogle () {
        try {
            let googlePromise = new Promise((resolve, reject) => {
                googleSdkLoaded(google => {
                    google.accounts.oauth2.initTokenClient({
                        client_id: ENV.currentConfig.googleSignInClientId,
                        scope: 'https://www.googleapis.com/auth/userinfo.profile\
                                https://www.googleapis.com/auth/userinfo.email',
                        callback: response => {
                            resolve(response);
                        },
                        error_callback: error => {
                            reject(error);
                        },
                    }).requestAccessToken();
                });
            });
            let resp = await googlePromise;
            if (!resp) return false;
            let googleUserData = await this.requestGoogleUserData(resp);
            let email = googleUserData.emailAddresses[0].value;
            resp = await API.userLogin({
                email,
                googleAccessToken: resp.access_token,
            });
            if (resp.code != 200) {
                return false;
            }
            this.syncUserInfo(resp.data);
            return true;
        } catch (_) {
            return false;
        }
    }

    /// 登出
    logout () {
        googleLogout();
        this.info.value = null;
        localStorage.removeItem('userInfo');
        localStorage.removeItem('token');
    }

    /// 重置密码
    async resetPassword ({
        email,
        newPassword,
        verifyCode,
    }) {
        try {
            newPassword = md5(`${newPassword}asdzxucoihk1`);
            const resp = await API.resetPassword({
                email,
                newPassword,
                code: verifyCode,
            });
            return resp;
        } catch (_) {
            return false;
        }
    }

    /// 发送重置密码邮箱验证码
    async sendResetPasswordVerifyCode ({ email }) {
        email = email || this.email.value;
        try {
            let resp = await API.sendForgotEmailCode({ email });
            if (resp.code != 200) {
                return false;
            }
            return true;
        } catch (_) {
            return false;
        }
    }

    /// 请求google登录用户的基础信息
    async requestGoogleUserData (accessTokenResponse) {
        const GOOGLE_MY_PROFILE = 'https://content-people.googleapis.com/v1/people/me?sources=READ_SOURCE_TYPE_PROFILE&personFields=photos%2Cnames%2CemailAddresses';
        let resp = await fetch(GOOGLE_MY_PROFILE, {
            method: 'GET',
            headers: { Authorization: `${accessTokenResponse.token_type} ${accessTokenResponse.access_token}` },
        });
        return await resp.json();
    }
}