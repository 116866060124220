<template>
  <div class="describe">
    <!--    标题-->
    <div class="h1">{{ $t("txtToImg.describeCreation") }}</div>
    <!--    输入框-->
    <div class="describe-input">
      <textarea
          class="input"
          v-model="txtToImgStore.prompt"
          maxlength="500"
          @input="promptInput"
          :placeholder="$t('txtToImg.promptPlace')"
      />
      <div class="describe-clear">
        <div class="value">{{ promptLength }} / 500</div>
        <div class="gedang">&nbsp;|&nbsp;</div>
        <div
            class="clear"
            @click="
            () => {
              txtToImgStore.clearPrompt();
            }
          "
        >
          {{ $t("txtToImg.clear") }}
        </div>
      </div>
    </div>
    <!--    历史记录-->
    <div class="history">
      <!--      历史记录图标-->
      <div :class="['select', { open: isOpen }]" @click="historySelct" v-if="useTxtToImg().submitHistory.length != 0">
        <img class="history-img" :src="history" alt=""/>
        <div class="text">{{ $t("txtToImg.history") }}</div>
        <img
            class="open"
            v-if="!isOpen"
            src="@/assets/images/png/caret-down.png"
            alt=""
        />
        <img
            class="open"
            v-if="isOpen"
            src="@/assets/images/png/caret-up.png"
            alt=""
        />
        <div v-if="isOpen" class="select-down">
          <div class="select-h1">
            <div class="select-text">{{ $t("txtToImg.history") }}</div>
            <img class="select-img" :src="txt_to_img_history_close" alt=""/>
          </div>
          <div class="select-list">
            <div
                v-for="(item, index) in txtToImgStore.submitHistory"
                :key="index"
                class="select-item"
                @click="
                () => {
                  $analytic.log('creation_suggest_history_data_tick');
                  txtToImgStore.useSubmitHistoryItem(item);
                }
              "
            >
              <!-- <div v-for="(item, index) in [{submitPrompt: ''}]" :key="index" class="select-item"> -->
              <div class="select-item-text">{{ item.submitPrompt }}</div>
              <img
                  class="select-list-img"
                  :src="txt_to_img_history_add"
                  alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <!--      历史记录显示框-->
      <div class="prompt-list_box">
        <ScrollableView>
          <template #default>
            <div class="prompt-list" v-if="txtToImgStore.suggestionPrompts && txtToImgStore.suggestionPrompts.length > 0">
              <div v-for="item in txtToImgStore.suggestionPrompts" :key="item.suggestionKey"  @click=" promptItemClick($event, item)"
                  :class="[ 'item', { act: item.selected,  }]" >
                {{ item.suggestionKeyword }}
              </div>
            </div>
            <div class="prompt-list" v-else>
              <div v-for="(item ,index ) in 9" :key="index"
                   :class="[ 'item itemdef', { act: item.selected,  }]" >
                <ShimmerCard></ShimmerCard>
              </div>
            </div>
          </template>
        </ScrollableView>
      </div>

    </div>
  </div>
</template>

<script setup>
import {CommonUtility} from "@/script/utility";
import {useTxtToImg} from "@/stores/useTxtToImg";
import {
  computed,
  getCurrentInstance,
  onUnmounted,
  reactive,
  toRefs,
  watch,
} from "vue";
import ScrollableView from "@/components/ScrollableView.vue";
import ShimmerCard from "@/components/common/ShimmerCard.vue";

const {$analytic} = getCurrentInstance().appContext.config.globalProperties
const history = CommonUtility.assetImage("history");
const txt_to_img_history_close = CommonUtility.assetImage(
    "txt_to_img_history_close"
);
const txt_to_img_history_add = CommonUtility.assetImage(
    "txt_to_img_history_add"
);
const txt_to_img_history_add_act = CommonUtility.assetImage(
    "txt_to_img_history_add_act"
);
const state = reactive({isOpen: false,});
const setIsOpen = () => {
  state.isOpen = false;
  window.removeEventListener("click", setIsOpen);
};
const historySelct = () => {
  state.isOpen = !state.isOpen;
  if (state.isOpen) {
    $analytic.log('creation_suggest_history_tick')
    setTimeout(() => {
      window.addEventListener("click", setIsOpen);
    });
  }
};

/**
 * prompt输入框，input事件
 */
const promptInput = () => {
  txtToImgStore.hasUserChangedPrompt = true;
  txtToImgStore.unselectSuggestionPrompt();
}

const txtToImgStore = useTxtToImg();
const promptLength = computed(() => {
  if (txtToImgStore.prompt.length > 500) {
    txtToImgStore.prompt = txtToImgStore.prompt.slice(0, 500)
  }
  return txtToImgStore.prompt.length
});
const promptItemClick = (event, item) => {
  $analytic.log('creation_suggest_tick')
  event.currentTarget.scrollIntoView({
    behavior: 'smooth',
    block: 'nearest',
    inline: 'center',
  });
  txtToImgStore.selecteSuggestionPrompt(item);
}

const localprompt = reactive(window.localStorage);

watch(() => localprompt.txttoimgparams, (newval) => {
  if (newval) {
    txtToImgStore.setPrompt(JSON.parse(newval).promptKeyword);
    window.localStorage.removeItem('txttoimgparams');
  }
}, {immediate: true});

const {isOpen} = toRefs(state);
</script>

<style scoped lang="scss">
.describe {
  color: #ffffff;

  .h1 {
    font-weight: 400;
    font-size: 24px;
    color: #ffffff;
    line-height: 30px;
  }

  .describe-input {
    font-family: 'PingFang SC';
    line-height: 17px;
    margin: 0 auto;
    margin-top: 20px;
    position: relative;
    width: calc(100% - 22px);
    height: 142px;
    background-color: #0a0913;
    border-radius: 8px;
    padding: 9px;
    border: 2px solid #ff7839;

    .input {
      font-family: 'PingFang SC';
      resize: none;
      background: transparent;
      border: none;
      height: 85px;
      text-align: left;
      width: 100%;
      font-weight: 500;
      font-size: 14px;
      color: #ffffff;
      line-height: 17px;

      &::placeholder {
        font-weight: 500;
        font-size: 14px;
        color: #919599;
        line-height: 27px;
        text-align: left;
      }

      &:focus-visible {
        outline: none;
      }
    }

    .describe-clear {
      display: flex;
      align-items: center;
      font-family: 'PingFang SC';
      font-weight: 500;
      font-size: 14px;
      color: #ffffff;
      line-height: 16px;
      text-align: right;
      font-style: normal;
      position: absolute;
      right: 18px;
      bottom: 14px;

      .clear {
        cursor: pointer;
      }
    }
  }

  .history {
    margin-top: 20px;
    display: flex;
    align-items: center;

    .select {
      height: 32px;
      background: rgba(255,148,65,0.1);
      border-radius: 4px;
      padding: 6px 10px;
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-right: 20px;
      border: 1px solid transparent;
      position: relative;
      min-width: max-content;



      &.open {
        border: 1px solid #ff7839;
      }

      .text {
        font-weight: 500;
        font-size: 14px;
        color: #ffffff;
        line-height: 16px;
        min-width: max-content;
      }

      .history-img {
        width: 19px;
        height: 20px;
        margin-right: 6px;
      }

      .open {
        width: 10px;
        height: 10px;
        margin-left: 6px;
      }

      .select-down {
        position: absolute;
        top: 48px;
        left: 0;
        width: 376px;
        height: 328px;
        background: #27282d;
        box-shadow: 0px 2px 17px 0px rgba(0, 0, 0, 0.5);
        border-radius: 4px;
        padding: 14px;
        z-index: 3;

        .select-h1 {
          position: relative;

          .select-text {
            font-weight: bold;
            font-size: 16px;
            color: rgba(255, 255, 255, 0.95);
            line-height: 20px;
            text-align: center;
            font-style: normal;
          }

          .select-img {
            position: absolute;
            right: 0;
            top: 0;
            width: 24px;
            height: 24px;
          }
        }

        .select-list {
          margin-top: 18px;
          overflow-y: auto;
          height: calc(100% - 40px);

          .select-item {
            border-radius: 4px;
            margin-bottom: 10px;
            padding: 6px 10px;
            font-family: 'PingFang SC';
            font-weight: 400;
            font-size: 12px;
            color: #ffffff;
            line-height: 16px;
            cursor: pointer;
            border: 1px solid rgba(255, 255, 255, 0.1);
            display: flex;
            align-items: center;



            &:last-child {
              margin-bottom: 0;
            }

            .select-item-text {
              flex: 1;
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              text-overflow: ellipsis;
              text-wrap: wrap;
              white-space: space;
            }

            .select-list-img {
              width: 14px;
              height: 14px;
              padding-left: 10px;
            }
          }
        }
      }
    }

    .prompt-list_box {
      flex-grow: 1;
      overflow: hidden;
    }

    .prompt-list {
      flex: 1;
      display: flex;
      overflow-x: auto;
      align-items: center;
      width: max-content;

      .item {
        padding: 6px 10px;
        display: flex;
        align-items: center;
        min-width: max-content;
        background: #20222b;
        height: 25px;
        border-radius: 4px;
        font-weight: 400;
        font-size: 12px;
        color: #FFFFFF;
        line-height: 14px;
        text-align: left;
        font-style: normal;
        margin-right: 14px;
        cursor: pointer;
        border: 1px solid transparent;


        &:last-child {
          margin-right: 0;
        }


      }
      .itemdef{
        width: 60px;
        padding: 0;
      }


      .item.act {
        border: 1px solid #ff7839;
      }
    }
  }
}
@media (any-hover: hover){
  .describe .history .prompt-list .item:hover {
    border: 1px solid #ff7839;
  }
  .describe .history .select:hover {
    border: 1px solid #e86639;
    background: rgba(255, 255, 255, 0.1);

    .select-list-img {
      content: url("@/assets/images/png/txt_to_img_history_add_act.png");
    }
  }
}

@media screen and (max-width: 1070px) {
  .describe {
    color: #ffffff;

    .describe-input {
      margin: 0 auto;
      margin-top: 18px;
      position: relative;
      width: calc(100% - 22px);
      height: 123px;
      border-radius: 5px;
      padding: 9px;
      border: 2px solid #ff7839;

      .input {
        resize: none;
        background: transparent;
        border: none;
        height: 85px;
        text-align: left;
        width: 100%;
        font-weight: 500;
        font-size: 13px;
        color: #ffffff;
        line-height: 17px;
      }

      .describe-clear[data-v-25f1113c] {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 14px;
        color: #ffffff;
        line-height: 16px;
        text-align: right;
        font-style: normal;
        position: absolute;
        right: 10px;
        bottom: 10px;
      }
    }

    .history {
      margin-top: 10px;
      display: flex;
      align-items: center;

      .select {
        height: 28px;
        background: rgba(255, 148, 65, 0.1);
        border-radius: 4px;
        padding: 4px 8px;
        /* width: 85px; */
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-right: 20px;
        border: 1px solid transparent;
        position: relative;

        .history-img {
          width: 19px;
          height: 20px;
          margin-right: 4px;
        }
        .text {
          font-weight: 500;
          font-size: 12px;
          color: #FFFFFF;
          line-height: 14px;
          text-align: left;
          font-style: normal;
        }
        .open{
          margin-left: 4px;
        }

        .select-down {
          position: absolute;
          top: 48px;
          left: 0;
          max-width: 299px;
          height: 268px;
          background: #27282d;
          box-shadow: 0px 2px 17px 0px rgba(0, 0, 0, 0.5);
          border-radius: 4px;
          padding: 14px;
          z-index: 23;
        }
      }
    }

    .h1 {
      max-width: max-content;
      font-weight: bold;
      font-size: 20px;
      color: #FFFFFF;
      line-height: 24px;
      text-align: left;
      font-style: normal;
    }

    .history {
      .prompt-list {
        .item {
          min-width: max-content;
          height: 26px;
          background: #20222B;
          border-radius: 4px;
          font-weight: 400;
          font-size: 12px;
          color: #FFFFFF;
          line-height: 14px;
          text-align: left;
          font-style: normal;
        }
      }
    }
  }
}
</style>
