import basic from '../basic/pt';
export default {
  ...basic,
  ...{
    meta: {
      title:
        'Gerador gratuito de imagens de garotas AI, texto on-line para imagens de garotas fofas e gostosas',
      description:
        'Produza imagens e arte geradas por IA com um aviso de texto utilizando as aplicações geradoras de fotografias de IA Beautyai.fun: ferramenta de IA de texto em imagem em tempo real',
      keywords:
        'Troca de rosto, Gerador de imagem AI, Troca de rosto de vídeo,',
    },
    recent: 'Recente',
    txtToImg: {
      history: 'História',
      clear: 'Claro',
      describeCreation: 'Descrever a Criação',
      promptPlace: 'Digite o prompt de texto e crie sua criação!',
      looksLike: 'Parece',
      batchSize: 'Tamanho do batch',
      hintTags: 'Tags de dicas',
      unselect: 'Desmarcar',
      create: 'Criar',
      aspectRatio: 'Proporção da tela',
      optional: '(Opcional)',
      pro: 'PRÓ',
    },
    use1Credit: 'Use 1 crédito.',
    useXCredits: 'Use <xx1> créditos.',
    guide: {
      title: 'Como fazer uma criação melhor',
      headTip:
        'Faça upload de fotos com melhor qualidade, fotos de baixa qualidade levarão a uma criação ruim.',
      goodTitle: 'Bons exemplos',
      badTitle: 'Maus exemplos',
    },
    examplePopup: {
      title: 'Como fazer uma criação melhor',
      photoTip:
        'Faça upload de fotos com melhor qualidade, fotos de baixa qualidade levarão a uma criação ruim.',
      goodExample: 'Bons exemplos',
      badExample: 'Maus exemplos',
      btnTip: 'Entendi',
    },
    AiGirlShowcase: {
      title: 'Imagens geradas por IA',
      hovertitle: 'Tente este prompt',
      buttontext: 'Btn btn btn',
    },
    textToImgToHistory: {
      tab1or2header: 'Mantivemos o registro de geração para você',
      footer: 'vá para o histórico para verificar',
    },
    txtToImgIndex: {
      introduce: {
        item1Title: 'Gerador de imagens AI inspirado na sua ideia',
        item1Subtitle:
          'Basta compartilhar sua ideia e observar como a tecnologia avançada de IA cria uma personagem feminina personalizada só para você.',
        item1ActionText: 'Comece a criar imagens',
        item2Title: 'Personalize a sua imagem de IA de anime',
        item2Subtitle:
          'Beauty AI é um gerador de imagens de arte de IA que lhe permite criar criações de animação em todos os estilos. Decida a sua inspiração, descreva-a rapidamente e transforme-a em imagens de anime. Lembre-se de que as suas criações lhe pertencem enquanto faz a sua alma gémea com a personagem de anime única!',
        item3Title: 'Gere almas gêmeas realmente gostosas com inspiração',
        item3Subtitle:
          'Como gerador de imagens AI fácil de usar, nosso objetivo é realizar seu sonho com grande esforço. Descreva a garota dos seus sonhos com palavras simples e nós geraremos sua arte em segundos!',
      },
      howToUse: {
        sectionTitle: 'Como criar imagens de IA no Beauty AI',
        item1Title: 'Escreva um prompt de texto',
        item1Subtitle:
          'Digite uma descrição do que você deseja ver no campo de prompt.',
        item2Title: 'Crie sua imagem',
        item2Subtitle:
          'Quando estiver satisfeito com os prompts, clique em Gerar. Os resultados aparecerão em alguns segundos. Se você gostou da imagem gerada pelo Beauty AI, clique no botão Download no canto superior direito para baixar esta imagem.',
        item3Title: 'Refine, revise e recrie',
        item3Subtitle:
          'Brinque com as configurações para explorar diferentes variações. No painel esquerdo, você pode ajustar tudo, desde a proporção até o tipo de conteúdo. Se desejar, você pode adicionar mais detalhes ao prompt de texto para gerar uma imagem completamente nova. Apenas certifique-se de salvar as imagens que desejar antes de criar uma nova.',
        actionText: 'Comece a criar imagens',
      },
      review: {
        sectionTitle: 'Eles nos amam, você também',
        card1Title: 'Depois de usar o Beauty AI',
        card1Subtitle:
          'Depois de utilizar a função de gerador de imagens Beauty AI, senti profundamente a sua poderosa função. Para além de serem fáceis de operar, as imagens geradas são de altíssima qualidade, o que melhorou em muito a eficiência do meu trabalho e estou muito satisfeito!',
        card1UserName: 'Michael Johnson',
        card1Date: '6 de junho de 2023',
        card2Title: 'O AI Image Generator é realmente uma ferramenta mágica!',
        card2Subtitle:
          'O gerador de imagens AI é realmente uma ferramenta mágica! Estou impressionado com o seu algoritmo inteligente. Pode gerar biquínis precisos e fotos de raparigas giras de acordo com as minhas necessidades sempre. É um bom ajudante indispensável no meu trabalho.',
        card2UserName: 'Sarah Thompson',
        card2Date: '6 de maio de 2024',
        card3Title: 'Desde que comecei a utilizar o gerador de imagens AI',
        card3Subtitle:
          'Desde que comecei a utilizar o gerador de imagens de IA, as minhas capacidades de design melhoraram significativamente. As suas poderosas capacidades de geração de imagens permitem que a minha criatividade seja apresentada com perfeição. É muito fácil de usar!',
        card3UserName: 'Emily Davis',
        card3Date: '4 de dezembro de 2023',
        card4Title: 'O design da interface do gerador de imagens AI',
        card4Subtitle:
          'O design da interface do gerador de imagens AI é muito fácil de utilizar e conveniente de utilizar. Além disso, as fotos que gera são de alta qualidade e rápidas, permitindo-me obter o dobro do resultado com metade do esforço de trabalho. Eu recomendo!',
        card4UserName: 'Mário Kuper',
        card4Date: '1º de janeiro de 2024',
        card5Title:
          'Esta ferramenta incrível detecta rostos de forma inteligente',
        card5Subtitle:
          'Esta ferramenta incrível detecta rostos em fotos de forma inteligente e os substitui perfeitamente por qualquer rosto que você escolher. É como ter seu próprio estúdio criativo ao seu alcance! Com o Beauty AI, trocar rostos em qualquer foto é fácil, permitindo-me dar asas à minha imaginação sem complicações.',
        card5UserName: 'Guilherme Smith',
        card5Date: '12 de fevereiro de 2023',
        card6Title: 'Um grande obrigado ao gerador de imagens AI',
        card6Subtitle:
          'Um grande obrigado ao gerador de imagens AI, o software que desenvolveram poupou-me muito tempo no trabalho. As imagens geradas não são apenas bonitas, mas também atendem plenamente às minhas necessidades. Elogio de cinco estrelas!',
        card6UserName: 'Cristóvão Anderson',
        card6Date: '19 de julho de 2023',
      },
      faq: {
        sectionTitle: 'perguntas frequentes',
        item1Q: 'O que é o gerador de IA?',
        item1A:
          'O gerador de imagens AI é um tipo de tecnologia generativa de IA que permite criar imagens com nada mais do que algumas palavras. O motor de IA recebe cada palavra numa descrição de texto como uma instrução e constrói a imagem com base na combinação de palavras e nas suas relações entre si.',
        item2Q: 'Como funciona o gerador de imagens AI?',
        item2A:
          'O gerador de imagens de IA funciona como outros tipos de inteligência artificial, utilizando modelos de aprendizagem automática e enormes conjuntos de dados para produzir os seus resultados. Por outras palavras, a tecnologia de IA aprende com milhares e milhares de imagens. Cria imagens totalmente novas aplicando o que aprendeu à linguagem do dia-a-dia que introduz no campo de prompt.',
        item3Q: 'O que torna um gerador de imagens de IA incrível?',
        item3A:
          'Ao escolher o seu gerador de imagens de IA, procure um que produza imagens de alta qualidade, permita o controlo criativo para fazer com que essas imagens satisfaçam as suas necessidades e possibilite a utilização comercial dessas imagens. O Beauty AI é uma ótima escolha porque faz todas estas coisas.',
        item4Q: 'Posso substituir meu rosto por qualquer imagem?',
        item4A:
          'Sim, de fato. Beauty AI permite que você substitua seu rosto por qualquer imagem selecionada gratuitamente. Por exemplo, você pode colocar um rosto masculino em um retrato feminino ou um rosto envelhecido em uma foto de bebê. As possibilidades são infinitas, permitindo que você se torne quem desejar, sem limitações.',
        item5Q: 'As imagens Beauty AI estão disponíveis para uso comercial?',
        item5A:
          'Sim, você pode usar imagens geradas pelo recurso Beauty AI para fins comerciais.',
        item6Q: 'Posso criar imagens online gratuitamente?',
        item6A:
          'Claro que você pode! Você pode criar imagens gratuitamente com Beauty AI. Oferecemos planos gratuitos e premium para atender às suas necessidades. Como novo usuário, você receberá três créditos no momento do registro, o que lhe dará acesso total ao nosso recurso de criação de imagens, bem como a outros recursos como Face Swap e Magic Brush.',
      },
    },
    realisticToFaceSwapPopup: {
      status1Text: 'Na fila...Sobre <xx1>s',
      status2Text: 'Análise facial...Sobre <xx1>s',
    },
    skeywordsHint:
      'Palavras-chave inadequadas detectadas em relação à nossa política: <xx1>',
    introducehomeindex: {
      item1title: 'Personalize suas imagens de arte AI',
      item1subtitle:
        'Beauty AI é um gerador de imagens de arte AI que permite criar criações de IA em todos os estilos. Decida sua inspiração, descreva-a rapidamente e transforme-a em imagens de IA. Lembre-se de que suas criações pertencem a você enquanto você faz sua criação artística.',
      item1buttontext: 'Teste grátis agora',
      item2buttontext: 'Teste grátis agora',
    },
    showcasehomeindex: {
      title: 'Imagens geradas por IA',
      buttontext: 'Teste grátis agora',
      hovertitle: 'Tente este prompt',
    },
    publishToast:
      'A imagem será revisada por nossa equipe e, se aprovada, será exibida na galeria.',
  },
};
