<template>
    <div class="txtToImgIndex-howtouse-section">
        <div class="space"></div>
        <div class="txtToImgIndex-howtouse-section-wrapper">
            <h2 class="title">{{ $t('txtToImgIndex.howToUse.sectionTitle') }}</h2>
            <div class="items">
                <HowToUseStep 
                    class="item"
                    v-for="item of items" 
                    :key="item.id"
                    :title="item.title"
                    :subtitle="item.subtitle"
                    :imgSrc="item.src"
                    :indexImgSrc="item.indexImgSrc">
                </HowToUseStep>
            </div>
            <a class="action" href="/free-ai-image-generator" @click.prevent="jump">
                <p>{{ $t('txtToImgIndex.howToUse.actionText') }}</p>
                <img class="arrow" :src="$utility.assetImage('button_detail')" alt="">
            </a>
        </div>
        <div class="space"></div>
    </div>
</template>

<script setup>
import { getCurrentInstance } from 'vue';

import HowToUseStep from '@/components/txtToImgIndex/HowToUseStep.vue';

const { $utility, i18n } = getCurrentInstance().appContext.config.globalProperties;

const items = [
    {
        id: 1,
        title: i18n.global.t('txtToImgIndex.howToUse.item1Title'),
        subtitle: i18n.global.t('txtToImgIndex.howToUse.item1Subtitle'),
        src: $utility.assetImage('txtToImg_howtouse1'),
        indexImgSrc: $utility.assetImage('step_1'),
    },
    {
        id: 2,
        title: i18n.global.t('txtToImgIndex.howToUse.item2Title'),
        subtitle: i18n.global.t('txtToImgIndex.howToUse.item2Subtitle'),
        src: $utility.assetImage('txtToImg_howtouse2'),
        indexImgSrc: $utility.assetImage('step_2'),
    },
    {
        id: 3,
        title: i18n.global.t('txtToImgIndex.howToUse.item3Title'),
        subtitle: i18n.global.t('txtToImgIndex.howToUse.item3Subtitle'),
        src: $utility.assetImage('txtToImg_howtouse3'),
        indexImgSrc: $utility.assetImage('step_3'),
    },
];
function jump () {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
}
</script>

<style scoped lang="scss">
@media (min-width: 1071px) {
    .txtToImgIndex-howtouse-section {
        display: flex;
        flex-direction: row;
        .space {
            min-width: 30px;
        }
        .txtToImgIndex-howtouse-section-wrapper {
            max-width: 1360px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 100px;
            justify-content: center;
            text-align: center;
            .title {
                font-size: 48px;
                font-weight: 700;
                color: white;
                font-family: 'PingFang SC';
                margin: 100px 0 0 0;
            }
            .items {
                display: flex;
                flex-direction: row;
                margin: 60px 0 0 0;
                .item {
                    margin-left: 85px;
                    &:first-child {
                        margin-left: 0px;
                    }
                }
            }
            .action {
                width: 388px;
                height: 63px;
                border-radius: 8px;
                margin-top: 84px;
                margin-left: auto;
                margin-right: auto;
                background: #FF7839;
                display: -webkit-flex;
                display: flex;
                -webkit-align-items: center;
                align-items: center;
                -webkit-justify-content: center;
                justify-content: center;
                cursor: pointer;
                border: 0;
                color: white;
                font-size: 20px;
                font-weight: 700;
                font-family: 'PingFang SC';
                text-align: center;
                text-decoration: none;
                &:hover {
                    background: #CF4D23;
                } 
                .arrow {
                    width: 19px;
                    height: 14px;
                    margin-left: 9px;
                }
            }
        }
    }
}
@media (max-width: 1070px) {
    .txtToImgIndex-howtouse-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        .space {
            min-width: 13px;
        }
        .txtToImgIndex-howtouse-section-wrapper {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 30px;
            justify-content: center;
            text-align: center;
            .title {
                font-size: 18px;
                font-weight: 700;
                color: white;
                font-family: 'PingFang SC';
                margin: 30px 0 0 0;
            }
            .items {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
                margin: 20px 0 0 0;
                .item {
                    margin-top: 30px;
                    &:first-child {
                        margin-top: 0px;
                    }
                }
            }
            .action {
                width: 241px;
                height: 48px;
                border-radius: 8px;
                margin-top: 30px;
                margin-left: auto;
                margin-right: auto;
                background: #FF7839;
                display: -webkit-flex;
                display: flex;
                -webkit-align-items: center;
                align-items: center;
                -webkit-justify-content: center;
                justify-content: center;
                cursor: pointer;
                border: 0;
                color: white;
                font-size: 16px;
                font-weight: 700;
                font-family: 'PingFang SC';
                text-align: center;
                text-decoration: none;
                &:hover {
                    background: #CF4D23;
                } 
                .arrow {
                    width: 13px;
                    height: 9px;
                    margin-left: 9px;
                }
            }
        }
    }
}
</style>