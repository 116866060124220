<template>
    <!-- 大屏幕 -->
    <div class="navigatorContainer">
        <div class="contentContainer">
            <div class="contentItemContainer " :class="{ narrowOptionItemContainer: narrow }">
                <div style="display: flex; flex-direction: row; cursor: pointer;" @click="toHome">
                    <img :src="assetImage('butterfly')" class="butterflyIcon" :class="{ narrowButterflyIcon: narrow }">
                    <div v-if="!narrow" class="smallHidden"
                        style="color: #F3F9FF; font-size: 30px; font-family: 'Silom'; transform: translateY(4px);"> {{
                            $t("siteName") }} </div>
                </div>
                <div :style="{ width: logoRightWidth + 'px' }"></div>
                <div class="optionItemContainer">
                    <!-- <div class="itemHome" :class="{ itemHome_n: narrow }">
                        <a :href="paintingUrl" :class="{ itemHomeSelected: this.highlightIndex == 0, }" @click="analytic('navigation_creation_tick')" target="_blank">{{
                            $t("creation") }}</a>
                        <div :class="{ indicator: this.highlightIndex == 0 }"></div>
                    </div>
                    <div :style="{ width: menuItemSpace + 'px' }"></div> -->
                    <div class="itemHome" :class="{ itemHome_n: narrow }">
                        <a :href="swapUrl" @click="analytic('navigation_faceswap_tick')" target="_blank"
                            :class="{ itemHomeSelected: this.highlightIndex == 1,pointer_none: currentSelected === 'face_swap'}">{{ $t("faceSwap") }}</a>
                        <div :class="{ indicator: this.highlightIndex == 1 }"></div>
                    </div>
                    <div :style="{ width: menuItemSpace + 'px' }"></div>
                    <div class="itemHome itemHome_product"
                        v-bind:class="{ itemHomeSelected: currentSelected == 'product', itemHome_n: narrow }"
                        @click.stop="productClick" @mouseover="productHover()" @mouseout="removeProductHover()">
                        <TopNavigatorProductMorePopup v-if="productHighlight" @mouseover="productHover()"
                            @mouseout="removeProductHover()" />
                        <p>
                            {{ $t("product") }}
                            <svg :class="{ displayNone: !productHighlight }" style="vertical-align: middle;" width="7px"
                                height="4px" viewBox="0 0 7 4" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g transform="translate(-271.000000, -71.000000)" fill="#FF7839">
                                        <g transform="translate(0.000000, 44.000000)">
                                            <g transform="translate(216.000000, 21.000000)">
                                                <path
                                                    d="M59.0268037,6.60206136 L60.9841659,8.83904677 C61.2387434,9.12999242 61.2092611,9.57222625 60.9183155,9.82680369 C60.7907114,9.93845723 60.6269184,10 60.4573622,10 L56.5426378,10 C56.1560384,10 55.8426378,9.68659932 55.8426378,9.3 C55.8426378,9.1304438 55.9041805,8.96665082 56.0158341,8.83904677 L57.9731963,6.60206136 C58.2277738,6.31111571 58.6700076,6.28163346 58.9609532,6.53621089 C58.9843408,6.55667499 59.0063396,6.57867382 59.0268037,6.60206136 Z"
                                                    id="三角形"
                                                    transform="translate(58.500000, 8.000000) scale(1, -1) rotate(-180.000000) translate(-58.500000, -8.000000) ">
                                                </path>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            <svg :class="{ displayNone: productHighlight }" style="vertical-align: middle;" width="7px"
                                height="4px" viewBox="0 0 7 4" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g transform="translate(-305.000000, -57.000000)" fill="#FFFFFF">
                                        <path
                                            d="M309.026804,57.6020614 L310.984166,59.8390468 C311.238743,60.1299924 311.209261,60.5722262 310.918315,60.8268037 C310.790711,60.9384572 310.626918,61 310.457362,61 L306.542638,61 C306.156038,61 305.842638,60.6865993 305.842638,60.3 C305.842638,60.1304438 305.904181,59.9666508 306.015834,59.8390468 L307.973196,57.6020614 C308.227774,57.3111157 308.670008,57.2816335 308.960953,57.5362109 C308.984341,57.556675 309.00634,57.5786738 309.026804,57.6020614 Z"
                                            id="三角形"
                                            transform="translate(308.500000, 59.000000) scale(1, -1) translate(-308.500000, -59.000000) ">
                                        </path>
                                    </g>
                                </g>
                            </svg>
                        </p>
                    </div>
                    <template v-if="!narrow && logined">
                        <div :style="{ width: menuItemSpace + 'px' }"></div>
                        <div class="itemHome">
                            <a :href="pricingUrl" @click="analytic('navigation_pricing_tick')" target="_blank">{{
                                $t("pricing") }}</a>
                        </div>
                        <div :style="{ width: menuItemSpace + 'px' }"></div>
                        <div class="itemHome">
                            <a :href="freeCreditsUrl" @click="analytic('navigation_referral_tick')" target="_blank" :class="{ itemHomeSelected: this.highlightIndex == 4}">{{
                                $t("freeCredits") }}</a>
                            <div :class="{ indicator: this.highlightIndex == 4 }"></div>
                        </div>
                        <div :style="{ width: menuItemSpace + 'px' }"></div>
                        <div class="itemHome">
                            <a :href="historyUrl" @click="analytic('navigation_history_tick')" target="_blank">{{
                                $t("history") }}</a>
                        </div>
                    </template>
                </div>

                <div style="flex:1"></div>

                <div class="largeSignOption">
                    <TopNavigatorNarrowToolPopup v-if="showNarrowToolPopup" :logined="logined"
                        :userHasPaid="userHasPaid" :allLeftNumber="allLeftNumber" @signinClick="signinClick"
                        @signOut="signOut" @pay="pay" @goToAccount="goToAccount" @loginClick="loginClick" />
                    <div v-if="narrow" style="cursor: pointer;" @click.stop="narrowToolClick">
                        <img style="width: 20px; height: 19px;" :src="assetImage('top_narrow_tool_entrance')" alt="" />
                    </div>

                    <template v-else>
                        <div v-if="userAccountStatus === 'logout'" class="flexrow">
                            <span style="width: 20px;"></span>
                            <div class="logininButton smallHidden" @click="loginClick">
                                <p> {{ $t("login") }} </p>
                            </div>
                            <span style="width: 9px;"></span>
                            <div class="signinButton smallHidden" @click="signinClick">
                                <p> {{ $t("signup") }} </p>
                            </div>
                        </div>

                        <div v-else class="flexrow">
                            <img v-if="isSubscribe" style="width: 25px; height: 25px;margin-right:20px"
                                :src="assetImage('crown')" />

                            <div v-if="userAccountStatus === 'creditsLess11'" class="credits-less-11">{{ $t("credits") }}:{{
                                purchaseLeftNumber }}</div>
                            <div class="flexrow" @mouseenter="userIconHover" @mouseleave="userIconMouseLeave">
                                <img style="width: 25px; height: 25px;margin-right: 7px" :src="assetImage('person')" />
                                <img :class="{ transformArrow: !showAccountPopup }" style="width: 7px; height:5px;"
                                    :src="assetImage('icon_up')" />
                            </div>

                            <!-- 用户信息弹框 -->
                            <div v-if="showAccountPopup" class="account-popup" @mouseenter="userIconHover"
                                @mouseleave="userIconMouseLeave">
                                <div class="sub" @click="pay">
                                    <img style="width: 22px; height: 22px;margin-right: 7px"
                                        :src="assetImage('crown_w')" />
                                    <div v-if="true" style="display: flex;flex-direction: column;">
                                        <div>
                                            {{ $t('getMoreCredits') }}
                                        </div>
                                        <div style="height: 2px;"></div>
                                        <div> {{ $t('left') }}
                                           ：{{ allLeftNumber }}
                                        </div>
                                    </div>
                                    <div v-else>
                                        {{ $t('upgradeToPremium') }}
                                    </div>

                                    <div style="flex:1"></div>
                                    <div class="go">
                                        {{ $t('go') }}
                                    </div>

                                </div>
                                <div class="user-title" @click="goToAccount">
                                    <img style="width: 22px; height: 22px;margin: 0 10px"
                                        :src="assetImage('person2')" />
                                   {{ $t('accountInformation') }}
                                </div>
                                <div class="user-title" @click="signOut">
                                    <img style="width: 22px; height: 22px;margin:0 10px" :src="assetImage('logout')" />
                                   {{ $t('signOut') }}
                                </div>

                            </div>

                        </div>
                    </template>

                </div>
            </div>

            <!-- <div class="sepratorLine"></div> -->
        </div>
    </div>
    <!-- 登录弹窗 -->
    <!-- <LoginDialog @signUp="toSignUp" @resetPassWord="toPassWord" v-if="isLoginShow" v-model:isShow="isLoginShow" />
    <SignUpDialog @back="backlogin" :isBack="upOnePage" v-if="isSignUp" v-model:isShow="isSignUp" />
    <ResetPassWordDialog @back="backlogin" :isBack="upOnePage" v-if="isResetPassWord"
        v-model:isShow="isResetPassWord" /> -->
</template>

<script>
import TopNavigatorNarrowToolPopup from '@/components/TopNavigatorNarrowToolPopup.vue';
import TopNavigatorProductMorePopup from '@/components/TopNavigatorProductMorePopup.vue';
import { User } from '@/script/user';
import { loginDialog, signUpDialog } from './Dialog';

const AccountStatus = {
    logout: 'logout',
    login: 'login',
    creditsLess11: 'creditsLess11', // 付费后少于11
    creditsMore10: 'creditsMore10',
};

let userIconHoverTimer;
export default {
    name: 'TopNavigatorBar',
    components: {
        TopNavigatorNarrowToolPopup,
        TopNavigatorProductMorePopup,
    },
    props: { highlightIndex: Number },
    data () {
        return {
            currentSelected: '',
            languageHiddeen: true,
            languageCanHiddeen: true,
            logined: false,
            currentLanguage: 'English',
            showNarrowToolPopup: false,
            productHighlight: false,
            productSelected: false,
            narrow: false,
            paintingUrl: this.$otherPageUrl('/free-ai-image-generator', true, true),
            swapUrl: this.$otherPageUrl('/photo-face-swap', true),
            pricingUrl: this.$otherPageUrl('/pricing', false),
            freeCreditsUrl: this.$otherPageUrl('/ai-generator-invite', true),
            disCountOfferUrl: this.$otherPageUrl('/pricing', false),
            historyUrl: this.$otherPageUrl('/history', false),
            removeProductMoreTimerId: null,
            isLoginShow: false,
            upOnePage: null,
            isSignUp: false,
            isResetPassWord: false,
            userAccountStatus: AccountStatus.logout,
            logoRightWidth: 14,
            menuItemSpace: 12,
            userHasPaid: false,
            allLeftNumber: 0,
            showAccountPopup: false,
            isSubscribe: false,
        };
    },
    created () {
        this.logined = User.getInstance.isLogin;
        this.userHasPaid = User.getInstance.hasPaid;
        this.allLeftNumber = User.getInstance.allLeftNumber;
        this.isSubscribe = User.getInstance.isSubscribe;
        this.setUserAccountStatus();
        if (this.$i18n.locale == 'en') {
            this.currentLanguage = 'English';
        } else if (this.$i18n.locale == 'fr') {
            this.currentLanguage = 'Français';
        } else if (this.$i18n.locale == 'es') {
            this.currentLanguage = 'Español';
        } else if (this.$i18n.locale == 'pt') {
            this.currentLanguage = 'Português';
        } else if (this.$i18n.locale == 'ja') {
            this.currentLanguage = '日本語';
        }
    },
    watch: {
        narrow () {
            if (this.narrow == false) {
                this.showNarrowToolPopup = false;
            }
        },
        productHighlight () {
            const ele = window.document.getElementsByClassName('itemHome_product')[0];
            if (this.productHighlight) {
                ele.style.color = '#FF7839';
            } else {
                ele.style.color = 'white';
            }
        },
        logined () {
            this.setUserAccountStatus();
        },
        userHasPaid () {
            this.setUserAccountStatus();
        },
        purchaseLeftNumber () {
            this.setUserAccountStatus();
        },
    },
    computed: {
        purchaseLeftNumber () {
            return User.getInstance.purchaseLeftNumber.value;
        },
    },
    mounted () {
        window.addEventListener('resize', this.resizeListener);
        document.addEventListener('click', this.closeLangPopup);
        document.addEventListener('scroll', this.closeLangPopup);
    },
    beforeUnmount () {
        window.removeEventListener('resize', this.resizeListener);
        document.removeEventListener('click', this.closeLangPopup);
        document.removeEventListener('scroll', this.closeLangPopup);
    },
    methods: {
        analytic (name) {
            this.$analytic.log(name);
        },
        toSignUp (type) {
            this.upOnePage = type;
            this.isLoginShow = false,
                this.isSignUp = true;
        },
        toPassWord (type) {
            this.isLoginShow = false,
                this.isResetPassWord = true;
            this.upOnePage = type;
        },
        backlogin () {
            this.upOnePage = null;
            this.isSignUp = false;
            this.isResetPassWord = false;
            this.isLoginShow = true;
        },
        assetImage (name) {
            return this.$utility.assetImage(name);
        },
        resizeListener () {
            this.updateSpace();
        },
        signinClick () {
            signUpDialog();
        },
        signOut () {
            User.getInstance.logout();
            window.location.reload();
        },
        goToAccount () {
            window.open(`${window.location.protocol}//${window.location.host}/userCenter`, '_blank');
        },
        pay () {
            window.open(`${window.location.protocol}//${window.location.host}/pricing`, '_blank');
        },
        userIconHover () {
            clearTimeout(userIconHoverTimer);
            this.showAccountPopup = true;
        },
        userIconMouseLeave () {
            userIconHoverTimer = setTimeout(() => {
                this.showAccountPopup = false;
            }, 300);
        },
        // 登录
        loginClick () {
            loginDialog();
        },
        narrowToolClick () {
            this.$analytic.log('set_column_express');
            this.showNarrowToolPopup = !this.showNarrowToolPopup;
        },
        productClick () {
            this.productHighlight = !this.productHighlight;
            this.showNarrowToolPopup = false;
        },
        productHover () {
            if (this.removeProductMoreTimerId) {
                clearTimeout(this.removeProductMoreTimerId);
                this.removeProductMoreTimerId = null;
            }
            this.productHighlight = true;
            this.showNarrowToolPopup = false;
        },
        removeProductHover () {
            if (!this.productHighlight) return;
            if (this.removeProductMoreTimerId) return;
            this.removeProductMoreTimerId = setTimeout(() => {
                this.productHighlight = false;
                clearTimeout(this.removeProductMoreTimerId);
                this.removeProductMoreTimerId = null;
            }, 300);
        },
        handleClose () {

        },
        toHome () {
            this.analytic('navigation_home_tick');
            window.open(`${window.location.protocol}//${window.location.host}`, '_blank');
        },
        clickLangHandle () {
            if (this.languageHiddeen) {
                this.languageHiddeen = false;
                this.languageCanHiddeen = true;
            } else {
                this.languageHiddeen = true;
                this.languageCanHiddeen = true;
            }
        },
        closeLangPopup () {
            this.showNarrowToolPopup = false;
            this.languageHiddeen = true;
            this.languageCanHiddeen = true;
            this.productHighlight = false;
        },
        changeCurLang (locale) {
            this.languageClick(locale);
        },
        languageClick (locale) {
            this.languageHiddeen = true;
            this.languageCanHiddeen = true;
            if (this.$i18n.locale != locale) {
                let lastPath = this.$utility.getUrlRelativeLastComponentPath();
                let langs = [
                    'en', 'fr', 'ja', 'zh', 'es', 'pt',
                ];
                let relativePath = this.$utility.getUrlRelativePath();
                if (langs.indexOf(lastPath) != -1) {
                    relativePath = relativePath.replace(lastPath, locale);
                    window.location.href = `${window.location.protocol}//${window.location.host}${relativePath}`;
                } else {
                    let comps = relativePath.split('/');
                    let last = comps.pop();
                    comps.push(locale);
                    comps.push(last);
                    relativePath = comps.join('/');
                    window.location.href = `${window.location.protocol}//${window.location.host}${relativePath}`;
                }
            }
        },
        itemHoverLanguageSelector () {
            let isMobile = window.mobileAndTabletCheck();
            if (isMobile) return;
            this.languageCanHiddeen = false;
        },
        itemHoverOutLanguageSelector () {
            let isMobile = window.mobileAndTabletCheck();
            if (isMobile) return;
            this.languageCanHiddeen = true;
            this.languageHiddeen = true;
        },
        itemHoverLanguage () {
            let isMobile = window.mobileAndTabletCheck();
            if (isMobile) return;
            this.languageCanHiddeen = true;
            this.languageHiddeen = false;
        },
        itemHoverOutLanguage () {
            let isMobile = window.mobileAndTabletCheck();
            if (isMobile) return;
            if (this.languageHiddeen == true) {
                return;
            }
            setTimeout(() => {
                if (this.languageCanHiddeen) {
                    this.languageHiddeen = true;
                }
            }, 1000);
        },
        setUserAccountStatus () {
            if (this.logined) {
                if (this.userHasPaid) {
                    if (this.purchaseLeftNumber > 10) {
                        this.userAccountStatus = AccountStatus.creditsMore10;
                    } else {
                        this.userAccountStatus = AccountStatus.creditsLess11;
                    }
                } else {
                    this.userAccountStatus = AccountStatus.login;
                }
            } else {
                this.userAccountStatus = AccountStatus.logout;
            }

            this.updateSpace();
        },

        updateSpace () {
            switch (this.userAccountStatus) {
                case AccountStatus.logout:
                    this.setMenuItemSpace(1376);
                    this.setLogoRightWidth(1224);
                    break;
                case AccountStatus.login:
                    this.setMenuItemSpace(1242);
                    this.setLogoRightWidth(1035);
                    break;

                case AccountStatus.creditsMore10:
                    this.setMenuItemSpace(1242 + 45);
                    this.setLogoRightWidth(1035 + 45);
                    break;
                case AccountStatus.creditsLess11:
                    this.setMenuItemSpace(1242 + 45 + 97);
                    this.setLogoRightWidth(1035 + 45 + 97);
                    break;
            }

            this.narrow = this.logoRightWidth <= 14 && this.menuItemSpace <= 12;
        },

        setMenuItemSpace (breakPint) {
            if (window.window.innerWidth > breakPint) {
                this.menuItemSpace = 40;
            } else {
                this.menuItemSpace = Math.max(39 + (window.window.innerWidth - breakPint) / 6, 12);
            }
        },
        setLogoRightWidth (breakPint) {
            if (window.window.innerWidth > breakPint) {
                this.logoRightWidth = 75;
            } else {
                this.logoRightWidth = Math.max(75 + window.window.innerWidth - breakPint, 14);
            }
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.displayNone {
    display: none;
}

p {
    margin: 0;
}

/*去除a标签下划线*/
a {
    text-decoration: none;
    color: white;
}

/*去除未被访问的a标签的下划线*/
a:link {
    text-decoration: none;
}

/*去除已经被访问过的a标签的下划线*/
a:visited {
    text-decoration: none;
}

/*去除鼠标悬停时的a标签的下划线*/
a:hover {
    text-decoration: none;
}

/*去除正在点击的a标签的下划线（鼠标按下，尚未松开）*/
a:active {
    text-decoration: none;
}

/*去除获得焦点的a标签的下划线（被鼠标点击过）*/
a:focus {
    text-decoration: none;
}
.navigatorContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 9;
    backdrop-filter: blur(50px);
}

.smallNavigatorContainer {
    display: none;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.contentContainer {
    max-width: 1400px;
    flex: 1;
}

.contentItemContainer {
    display: flex;
    flex-direction: row;
    color: #FFFFFF;
    justify-content: space-between;
    align-items: center;
}

.spaceContent {
    flex: 1;
}

.itemHome {
    font-weight: 700;
    font-family: 'PingFang SC';
    color: #FFFFFF;
    font-size: 16px;
    padding: 4px 0px;
    text-align: center;
    cursor: pointer;
    position: relative;
}

.itemHome_n {
    font-weight: 500;
    font-size: 14px;
}

.itemHomeSelected {
    color: #FF7839;
}

.pointer_none{
    pointer-events: none;
}
.indicator {
    position: absolute;
    width: 12px;
    height: 2px;
    background: #FF7839;
    border-radius: 2px;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
}

.loginContentContainer {
    height: 69px;
    width: 110px;
}

.sepratorLine {
    background-color: #FFFFFF1F;
    width: auto;
    height: 1px;
    margin-left: 16px;
    margin-right: 16px;
}

.languageOption {
    display: flex;
    flex-direction: row;
    width: auto;
    margin-right: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
    color: white;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
}


.languageContainer {
    position: fixed;
    top: 70px;
    background-color: #27282DFF;
    width: 138px;
    height: 200px;
    border-radius: 20px;
}

.languageContainerHidden {
    display: none;
}

.hidden {
    display: none;
}

.flexrow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.credits-less-11 {
    width: 77px;
    height: 19px;
    font-weight: 500;
    font-size: 16px;
    color: #FF9441;
    line-height: 19px;
    text-align: end;
    margin-right: 20px;
}

.languageItem {
    font-weight: 400;
    font-family: 'PingFang SC';
    height: 36px;
    text-align: center;
    align-content: center;
    font-size: 14px;

    &:hover {
        background-color: #FFFFFF1A;
        margin: 0 10px;
        border-radius: 10px;
    }
}

.transformArrow {
    transform: rotateZ(180deg);
}

.logininButton {
    display: flex;
    flex-direction: row;
    background-color: transparent;
    min-width: 82px;
    height: 30px;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: 'PingFang SC';
    font-weight: 400;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid #FF7839;
    color: #FF7839;
}

.signinButton {
    display: flex;
    flex-direction: row;
    background-color: #FF7839;
    color: white;
    min-width: 82px;
    height: 30px;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: 'PingFang SC';
    font-weight: 400;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
}

.largeSignOption {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
}

.smallSignOption {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.account-popup {
    width: 291px;
    height: 180px;
    background: #27282D;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    position: fixed;
    top: 70px;
    transform: translateX(-36%);
    display: flex;
    box-sizing: border-box;
    padding: 14px;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;

    .sub {
        width: 262px;
        height: 48px;
        background: linear-gradient(11deg, #D33A31 0%, #FF9441 100%);
        border-radius: 10px;
        backdrop-filter: blur(10px);
        display: flex;
        flex-direction: row;
        padding: 0 10px;
        justify-content: start;
        align-items: center;
        box-sizing: border-box;
        cursor: pointer;
    }

    .user-title {
        display: flex;
        flex-direction: row;
        width: 263px;
        height: 42px;
        align-items: center;
        cursor: pointer;

        &:hover {
            background: rgba(255, 255, 255, 0.1);
            border-radius: 10px;
        }
    }

    .go {
        width: 54px;
        height: 28px;
        border-radius: 20px;
        background: #FFFFFF;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 15px;
        color: rgba(211, 58, 49, 1);
        display: flex;
        justify-content: center;
        align-items: center;

    }
}


.optionItemContainer {
    display: flex;
    flex-direction: row;
    color: #FFFFFF;
    height: 69px;
    justify-content: center;
    align-items: center;
    margin-right: 40px;
}


.butterflyIcon {
    width: 54px;
    height: 46px;
    margin-right: 10px;
}


.narrowOptionItemContainer {
    display: flex;
    flex-direction: row;
    color: #FFFFFF;
    height: 56px;
    justify-content: center;
    align-items: center;
    margin-left: 0px;
}

.narrowButterflyIcon {
    width: 34px;
    height: 34px;
    margin-right: 10px;
    margin-left: 16px;
}


.narrowItemHome {
    font-size: 14px;
}
</style>
