<template>
    <div class="faceswap-category-tab">
        <div style="width: 100%;"></div>
        <CategoryTabItem :title="$t('photoFaceSwap')" :highlight="tabCategory == 0" :href="$otherPageUrl('/photo-face-swap', true)"/>
        <div class="faceswap-category-tab-item-space"></div>
        <CategoryTabItem :title="$t('photoMutipleFaceSwap')" :highlight="tabCategory == 1" :href="$otherPageUrl('/multiple-picture-face-swap', true)"/>
        <div class="faceswap-category-tab-item-space"></div>
        <CategoryTabItem :title="$t('videoFaceSwap')" :highlight="tabCategory == 2" :href="$otherPageUrl('/video-face-swap', true)"/>
        <div class="faceswap-category-tab-item-space"></div>
        <CategoryTabItem :title="$t('videoMutipleFaceSwap')" :highlight="tabCategory == 3" :href="$otherPageUrl('/multiple-video-face-swap', true)"/>
        <div style="width: 100%;"></div>
    </div>
</template>

<script setup>
import CategoryTabItem from '@/components/faceSwapIndex/edit/categoryTab/CategoryTabItem.vue';
import { inject, getCurrentInstance } from 'vue';

const tabCategory = inject('tabCategory');
</script>

<style scoped lang="scss">
.faceswap-category-tab {
    display: flex;
    align-items: center;
    justify-content: center;
    
}
::-webkit-scrollbar {
    height: 0;
    background: transparent;
    display: none;
}
.faceswap-category-tab-item-space {
    width: 30px;
    min-width: 30px;
}
@media (max-width: 1070px) {
    .faceswap-category-tab {
        justify-content: start;
        overflow-y: hidden;
        padding-left: 13px;
        padding-right: 13px;
    }
    .faceswap-category-tab-item-space {
        width: 10px;
        min-width: 10px;
    }
}
</style>