<template>
    <div class="dialog-mask">
      <div class="dialog-main">
        <div class="close-left">
          <img v-if="pageNextList.length" :src="back_icon" @click="backPage" alt="" />
          <div class="img" @click.self="close"></div>
        </div>
        <div class="tit-login">{{ $t("login") }}</div>
        <div class="tit-no-acc">
          <div>{{ $t("choose.noaccount") }}</div>
          <div @click="toSignUp" class="sign">&nbsp;{{ $t("signup") }}</div>
        </div>
        <div :class="['user-name', { err: emailErr }]">
          <input
              ref="userNameRef"
            type="text"
            class="input"
            maxlength="36"
            @blur="nameBlur"
            @focus="nameInputFocus"
            @input="validateInput"
            v-model="userName"
            :placeholder="$t('choose.usernamePlace')"
          />
          <div class="clear-img" @click="clearUserNameInput()"></div>
          <div v-if="emailErr" class="email-err">
            {{ $t("choose.emailTip") }}
          </div>
          <div
            class="tip-mask"
            v-if="userName && userName[userName.length - 1] == '@'"
          >
            <div
              @click="setUserNameEmail(item)"
              class="select"
              v-for="item in emailList"
              :key="item"
            >
              {{ item }}
            </div>
          </div>
        </div>
        <div :class="['user-name', 'pass-word', { err: passWordErr }]">
          <input
              ref="pwdRef"
            class="input"
            @blur="wordBlur"
            @focus="pwdInputFocus"
            :type="isShowPassWord ? 'text' : 'password'"
            v-model="passWord"
            maxlength="100"
            minlength="8"
            :placeholder="$t('choose.enterPassWord')"
            data="passworedtype"
          />
          <div class="clear-img" @click="clearPwdInpu()"></div>
          <img
            class="img"
            @click="setShowpassWord"
            v-if="isShowPassWord"
            :src="show_pass_word"
            alt=""
          />
          <img
            class="img"
            @click="setShowpassWord"
            v-if="!isShowPassWord"
            :src="hidd_pass_word"
            alt=""
          />
          <div v-if="passWordErr" class="email-err">
            {{ $t("choose.loginPassWordErr") }}
          </div>
        </div>
        <div
          ref="loginBtn"
          :class="['login-btn', { act: isCanLoging }]"
          @click="loginfun"
        >
          {{ $t("login") }}
        </div>
        <div class="fargot-pass-word" @click="toReset">
          {{ $t("choose.forgotpass") }}
        </div>
        <div class="google-login" @click="loginGoogle">
          <img class="google-img" :src="google" alt="" />&nbsp;{{
            $t("choose.googlesign")
          }}
        </div>
      </div>
    </div>
</template>

<script setup>
import {
  reactive,
  toRefs,
  getCurrentInstance,
  watch,
  watchEffect,
  onMounted,
  ref,
  defineProps, onUnmounted,
} from 'vue';
import { validateEmail } from '@/utils';
import { User } from '@/script/user';

import lottieLoadingAnimate from '@/assets/animationJson/white_circle_loading';
import lottie from 'lottie-web';
import { clearPageNextList, pageDialog, pageNextList, resetPassWordDialog, signUpDialog } from './Dialog';
import { CommonUtility } from '@/script/utility';

let { $showErrorToast, $showToast, $analytic, i18n } =
  getCurrentInstance().appContext.config.globalProperties;
const google = CommonUtility.assetImage('google');
const show_pass_word = CommonUtility.assetImage('show_pass_word');
const back_icon = CommonUtility.assetImage('back_icon');
const hidd_pass_word = CommonUtility.assetImage('hidd_pass_word');

// 用户名输入框ref
const userNameRef = ref(null);
// 密码输入框ref
const pwdRef = ref(null);
const props = defineProps({
  close: {
    type: Function,
    required: true,
  }
});
const emailList = [
  '@gmail.com',
  '@outlook.com',
  '@yahoo.com',
  '@hotmail.com',
  '@icloud.com',
  '@aol.com',
  '@protonmail.com',
];
const loginBtn = ref(null);
const state = reactive({
  userName: '',
  passWord: '',
  isShowPassWord: false,
  isCanLoging: false,
  emailErr: false,
  passWordErr: false,
});
const close = () => {
  clearPageNextList();
  props.close();
};
const toReset = () => {
  pageNextList.push('loginDialog');
  props.close();
  resetPassWordDialog();
};
const setShowpassWord = () => {
  state.isShowPassWord = !state.isShowPassWord;
};
const setUserNameEmail = (val) => {
  const str = state.userName.slice(0, state.userName.length - 1);
  state.userName = str + val;
  userNameRef.value.focus()
};
const nameBlur = () => {
  setTimeout(() => {
    state.emailErr = state.userName && !validateEmail(state.userName);
  }, 300);
};
const validateInput = () => {
      const newValue = state.userName.replace(/\s+/g, '');
      // state.userName = newValue
      state.userName = CommonUtility.replaceUtZh(newValue);
    };
const wordBlur = () => {
  state.passWordErr = state.passWord && state.passWord.length < 8;
};
watchEffect(() => {
  state.isCanLoging = !(
    state.userName &&
    validateEmail(state.userName) &&
    !state.emailErr &&
    state.passWord &&
    state.passWord.length >= 8 &&
    !state.passWordErr
  );
});
const loginfun = () => {
  setTimeout(async () => {
    if (!state.isCanLoging) {
      const text = loginBtn.value.innerText;
      loginBtn.value.innerText = '';
      const lottieAnim = lottie.loadAnimation({
        container: loginBtn.value,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: lottieLoadingAnimate,
      });
      $analytic.log('login_button_tick', { site: $analytic.page });
      const res = await User.getInstance.loginByEmail({
        email: state.userName,
        password: state.passWord,
      });
      lottieAnim.destroy();
      loginBtn.value.innerText = text;
      if (res.code === 200) {
        window.localStorage.removeItem('referKey');
        $analytic.log('login_finished', { site: $analytic.page });
        $analytic.log('register_login_finished', { site: $analytic.page });
        if ($analytic.page == "faceswap") {
          $analytic.log('register_login_faceswap_finished');
        }else if ($analytic.page == "referal") {
          $analytic.log('register_login_referral_finished');
        }else if ($analytic.page == "creation") {
          $analytic.log('register_login_creation_finished')
        }
      }
      if (res.code === 200) {
        $showToast(i18n.global.t('choose.loginsuccess'));
        close();
        return;
      }
      $showErrorToast(res.message || i18n.global.t('choose.logingerror'));
    }
  });
};
const loginGoogle = async () => {
  $analytic.log('login_button_tick', { site: $analytic.page });
  const res = await User.getInstance.loginByGoogle({
    email: state.userName,
    password: state.passWord,
  });
  res
    ? $showToast(i18n.global.t('choose.loginsuccess'))
    : $showErrorToast(i18n.global.t('choose.logingerror'));
  User.getInstance.isLogin.value && close();
};
const toSignUp = () => {
  pageNextList.push('loginDialog');
  props.close();
  signUpDialog();
};
const backPage = () => {
  const type = pageNextList.pop();
  pageDialog[type]?.();
  props.close();
};
const {
  userName,
  passWord,
  isCanLoging,
  isShowPassWord,
  emailErr,
  passWordErr,
} = toRefs(state);

const handleKeyPress = (event) => {
  if (event.key === 'Enter') {
    // 回车事件触发的操作
    console.log('回车键被按下');
    pwdRef.value.blur()
    userNameRef.value.blur()
    loginfun()
  }
};
onMounted(() => {
  $analytic.log('login_screen_express', { site: $analytic.page });
  // 添加键盘事件监听
  window.addEventListener('keypress', handleKeyPress);
});
// 清理函数，移除监听器
onUnmounted(() => {
  window.removeEventListener('keypress', handleKeyPress);
});


/**
 * 回车登陆
 */
const endedAction = () => {

}


/**
 * 邮箱框清空
 */
const clearUserNameInput = () => {
  state.userName = '';
  //  邮箱框清空时将邮箱错误状态初始化
  state.emailErr ? state.emailErr = false : '';
}



/**
 * 邮箱框获得焦点
 */
const nameInputFocus = () => {
  if (state.emailErr) {
    state.emailErr = false
  }
}

/**
 * 密码框获得焦点
 */
const pwdInputFocus = () => {
  if (state.passWordErr) {
    state.passWordErr = false
  }
}
const clearPwdInpu = () => {
  state.passWord = '';
  //  清空时将密码框错误状态初始化
  state.passWordErr ? state.passWordErr = false : '';
}
</script>

  <style scoped lang='scss'>
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-text-fill-color: #ededed !important; //这个地方的颜色是字体颜色，可以根据实际情况修改
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important; //设置input输入框的背景颜色为透明色
  background-color: transparent; //设置input输入框的背景颜色为透明色
  background-image: none;
  transition: background-color 50000s ease-in-out 0s;
}
input {
  background-color: transparent; //设置input输入框的背景颜色为透明色
}
.dialog-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
  font-family: "PingFang SC";
  .dialog-main {
    width: 320px;
    height: 390px;
    background: #27282d;
    border-radius: 20px;
    padding: 20px;
    .close-left {
      overflow: hidden;
      img{
        width: 32px;
        height: 32px;
      }
      .img {
        width: 32px;
        height: 32px;
        float: right;
        background: url('@/assets/images/png/close_icon.png');
        background-size: contain;
        &:hover {
          background: url('@/assets/images/png/close_icon_hover.png');
          background-size: contain;
        }
      }
    }
    .tit-login {
      font-weight: bold;
      font-size: 30px;
      color: #ffffff;
      line-height: 36px;
      text-align: center;
    }
    .tit-no-acc {
      display: flex;
      justify-content: center;
      margin-top: 10px;
      font-weight: 500;
      font-size: 14px;
      color: #f3f9ff;
      line-height: 17px;
      text-align: center;
      font-family: 'PingFang SC';
      .sign {
        color: #ff9441;
        cursor: pointer;
      }
    }
    .user-name {
      height: 44px;
      border-radius: 22px;
      margin-top: 30px;
      background: rgba(0, 0, 0, 0.2);
      position: relative;
      .input {
        height: 100%;
        width: calc(100% - 28px);
        color: #f3f9ff;
        border: none;
        outline: none;
        border-radius: 22px;
        padding: 0 14px;
        background: none;
        &::placeholder,
        &::-webkit-input-placeholder {
          font-weight: 500;
          font-size: 14px;
          color: #494c52;
        }
      }
      .clear-img {
        cursor: pointer;
        position: absolute;
        top: 8px;
        width: 28px;
        height: 28px;
        right: 14px;
        transform: scale(0.5);
        background: url('@/assets/images/png/clear_icon.png') no-repeat center center;
        &:hover {
          background: url('@/assets/images/png/clear_icon_act.png') no-repeat center center;
        }
      }
      &.err .input {
        color: #e05435;
      }
      .email-err {
        position: absolute;
        bottom: -14px;
        left: 15px;
        height: 13px;
        font-family: 'PingFang SC';
        font-weight: 500;
        font-size: 10px;
        color: #e05435;
        line-height: 13px;
      }
      .tip-mask {
        position: absolute;
        bottom: -112px;
        z-index: 1;
        left: 0;
        width: calc(100% - 20px);
        height: 90px;
        padding: 10px;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 18px;
        backdrop-filter: blur(4px);
        display: flex;
        flex-wrap: wrap;
        .select {
          cursor: pointer;
          padding: 0 10px;
          font-weight: 500;
          font-size: 10px;
          color: #ffffff;
          line-height: 24px;
          text-align: center;
          background: rgba(255, 255, 255, 0.1);
          border-radius: 19px;
          margin-bottom: 10px;
          margin-right: 10px;
          &:hover {
            background: linear-gradient(11deg, #d33a31 0%, #ff9441 100%);
          }
        }
      }
    }
    .pass-word {
      display: flex;
      align-content: center;
      margin-top: 25px;
      .input {
        width: calc(100% - 70px);
      }
      .clear-img {
        right: 46px;
      }
      .img {
        cursor: pointer;
        width: 28px;
        height: 28px;
        padding-top: 8px;
      }
      &.err .input {
        color: #e05435;
      }
    }
    .login-btn {
      margin-top: 35px;
      font-weight: bold;
      font-size: 16px;
      color: #ffffff;
      line-height: 30px;
      text-align: center;
      height: 30px;
      padding: 10px 0;
      background: linear-gradient(11deg, #d33a31 0%, #ff9441 100%);
      cursor: pointer;
      border-radius: 25px;
      &:hover {
        background:linear-gradient( 11deg, #A82D27 0%, #CC7633 100%);
      }
      &.act {
        color: #af9486;
        background: linear-gradient(90deg, #541613 0%, #673c1a 100%);
      }
    }
    .fargot-pass-word {
      font-family: 'PingFang SC';
      margin-top: 4px;
      font-weight: 500;
      font-size: 12px;
      color: #ff9441;
      line-height: 16px;
      text-align: center;
      cursor: pointer;
    }
    .google-login {
      margin-top: 30px;
      height: 22px;
      font-family: 'PingFang SC';
      font-weight: 400;
      font-size: 14px;
      color: #ff9441;
      line-height: 22px;
      text-align: center;
      cursor: pointer;
      .google-img {
        width: 16px;
        height: 16px;
        vertical-align: text-bottom;
      }
    }
  }
}
</style>
