
<template>
    <div :class="cellClass" ref="selfView" @mouseenter="deleteHover = true" @mouseleave="deleteHover = false">
        <div class="source" ref="sourceView">
            <VOCImageView class="source_img" ref="imageDOMTag1" :requestInfo="sourceImgRequest">
                <template #failed>
                        <div @click.stop="reloadImage(imageDOMTag1)" class="retry-item">
                            <img class="retry-img" :src="$utility.assetImage('retry_small')" />
                        </div>
                </template>
            </VOCImageView>
        </div>
        <img class="separate_arrow" :src="separateArrowSrc"/>
        <div class="target" ref="targetView" @mouseenter="targetFaceHover = true" @mouseleave="targetFaceHover = false">
            <VOCImageView class="target_img" ref="imageDOMTag2" v-if="mappingData.targetFace" :requestInfo="targetImgRequest">
                <template #failed>
                        <div @click.stop="reloadImage(imageDOMTag2)" class="retry-item">
                            <img class="retry-img" :src="$utility.assetImage('retry_small')" />
                        </div>
                </template>
            </VOCImageView>
            <div class="target_img" v-else></div>            
        </div>
        <img class="delete" v-if="showDelete" :src="faceDeleteIconSrc" @click.stop="removeTargetFace"/>
    </div>
</template>

<script setup>
import VOCImageView from '@/components/VOCImageView.vue';
import { VOCImageRequestInfo } from '@/script/voc_img_tool';
import { ref, watch, computed, defineProps, defineEmits, onMounted, getCurrentInstance } from 'vue';

const { $utility } = getCurrentInstance().appContext.config.globalProperties;

const props = defineProps({
    selected: Boolean,
    mappingData: {
        type: Object,
        default: null,
    },
});
const emit = defineEmits([ 'removeTargetFace' ]);
const selfView = ref(null);
const sourceView = ref(null);
const targetView = ref(null);
const imageDOMTag1 = ref(null);
const imageDOMTag2 = ref(null);
const cellClass = ref(['faceswap_edit_recognized_mappingCell']);
const sourceImgRequest = new VOCImageRequestInfo({ url: props.mappingData.sourceFace.url });
const targetImgRequest = ref(new VOCImageRequestInfo({ url: props.mappingData.targetFace?.url }));
const separateArrowSrc = ref($utility.assetImage('recognized_mapping_arrow'));
const faceDeleteIconSrc = ref($utility.assetImage('face_delete_icon'));
const targetFaceHover = ref(false);
const deleteHover = ref(false);
watch(props.mappingData, () => {
    targetImgRequest.value = new VOCImageRequestInfo({ url: props.mappingData.targetFace?.url });
});

const showDelete = computed(() => {
    return (props.mappingData && props.mappingData.targetFace) && ($utility.isMobile || targetFaceHover.value || deleteHover.value);
});


watch($utility.isNarrow, () => {
    updateStatus();
}); 


watch(() => props.selected, () => {
    updateStatus();
});

onMounted(() => {
    updateStatus();
});

function updateStatus () {
    if (props.selected) {
        separateArrowSrc.value = $utility.assetImage('recognized_mapping_arrow_highlight');
        selfView.value.style.backgroundColor = '#20222B';
        sourceView.value.style.border = 'solid 1px #E05435';
        if (props.mappingData.targetFace) {
            targetView.value.style.border = 'solid 1px #FF9441';
        }else {
            targetView.value.style.border = 'solid 1px rgba(255,255,255,0.1)';
        }
    }else {
        separateArrowSrc.value = $utility.assetImage('recognized_mapping_arrow');
        selfView.value.style.backgroundColor = 'transparent';
        sourceView.value.style.border = 'solid 1px #20222B';
        targetView.value.style.border = 'solid 1px #20222B';
    }
    if ($utility.isNarrow.value) {
        cellClass.value = ['faceswap_edit_recognized_mappingCell', 'narrow'];
    }else {
        cellClass.value = ['faceswap_edit_recognized_mappingCell'];
    }
}
function removeTargetFace () {
    emit('removeTargetFace');
}   
function reloadImage (tag) {
    tag.loadImage();
}

</script>

<style scoped lang="scss">
.faceswap_edit_recognized_mappingCell {
    width: 203px;
    height: 88px;
    border-radius: 16.5px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    position: relative;
}
.faceswap_edit_recognized_mappingCell.narrow {
    width: 166px;
    height: 72px;
    border-radius: 16.5px;
    display: flex;
    justify-content: center;
}

.faceswap_edit_recognized_mappingCell .source {
    height: 70px;
    margin: auto 0px;
    aspect-ratio: 1;
    display: flex;
    border-radius: 14px;
}
.faceswap_edit_recognized_mappingCell.narrow .source {
    height: 57px;
    margin: auto 0px;
    aspect-ratio: 1;
    display: flex;
    border-radius: 11px;
}

.faceswap_edit_recognized_mappingCell .source_img {
    margin: auto;
    width: 62px;
    height: 62px;
    border-radius: 14px;
    overflow: hidden;
}
.faceswap_edit_recognized_mappingCell.narrow .source_img {
    margin: auto;
    width: 50px;
    height: 50px;
    border-radius: 11px;
    overflow: hidden;
}

.faceswap_edit_recognized_mappingCell .separate_arrow {
    width: 28px;
    height: 12px;
    margin: auto 7px auto 10px;
}
.faceswap_edit_recognized_mappingCell.narrow .separate_arrow {
    width: 23px;
    height: 10px;
    margin: auto 6px auto 8px;
}

.faceswap_edit_recognized_mappingCell .target {
    height: 70px;
    margin: auto 0px;
    aspect-ratio: 1;
    display: flex;
    border-radius: 50%;
}
.faceswap_edit_recognized_mappingCell.narrow .target {
    height: 57px;
    margin: auto 0px;
    aspect-ratio: 1;
    display: flex;
    border-radius: 50%;
}

.faceswap_edit_recognized_mappingCell .target_img {
    margin: auto;
    width: 62px;
    height: 62px;
    border-radius: 50%;
    overflow: hidden;
    background-color: #060810;
}
.faceswap_edit_recognized_mappingCell.narrow .target_img {
    /* margin: auto; */
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    background-color: #060810;
}

.faceswap_edit_recognized_mappingCell .delete {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 9px;
    right: 9px;
}
.faceswap_edit_recognized_mappingCell.narrow .delete {
    width: 14px;
    height: 14px;
    position: absolute;
    top: 7px;
    right: 7px;
}

.faceswap_edit_recognized_mappingCell .retry-item {
    height: 100%;
    width: 100%;
    background-color: #20222B;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
    .retry-img {
        width: 50%;
        height: 50%;
        object-fit: contain;
    }
}

</style>