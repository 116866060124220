<template>
    <div class="txtToImgIndex-howtouse-item">
        <img class="img" :src="imgSrc" alt="">
        <span style="display: flex; justify-content: center; align-items: start;"><img class="index" :src="indexImgSrc"/><h3 class="title">{{ title }}</h3></span>
        <p class="subtitle">{{ subtitle }}</p>
    </div>
</template>

<script setup>
import { getCurrentInstance, defineProps } from 'vue'

const { title, subtitle, imgSrc, indexImgSrc } = defineProps([
    'title', 'subtitle', 'imgSrc', 'indexImgSrc'
])

</script>

<style scoped lang="scss">
@media (min-width: 1071px) {
    .txtToImgIndex-howtouse-item {
        max-width: 395px;
        .img {
            width: 80px;
            height: 80px;
        }
        span {
            margin: 30px 0 0 0;
        }
        .index {
                width: 35px;
                height: 35px;
                margin-right: 8px;
            }
        .title {
            font-family: 'PingFang SC';
            font-size: 24px;
            font-weight: 600;
            color: white;
            margin: 0;
        }
        .subtitle {
            font-family: 'PingFang SC';
            font-size: 17px;
            font-weight: 400;
            color: #828589;
            margin: 12px 0 0 0;
        }
    }
}
@media (max-width: 1070px) {
    .txtToImgIndex-howtouse-item {
        max-width: 540px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        span {
            margin: 14px 0 0 0;
        }
        .img {
            width: 40px;
            height: 40px;
        }
        .index {
            width: 16px;
            height: 16px;
            margin-right: 4px;
            margin-top: 2px;
        }
        .title {
            font-family: 'PingFang SC';
            font-size: 14px;
            font-weight: 600;
            color: white;
            margin: 0;
        }
        .subtitle {
            font-family: 'PingFang SC';
            font-size: 12px;
            font-weight: 400;
            color: #828589;
            margin: 10px 13px 0 13px;
            max-width: 300px;
            line-height: 14px;
        }
    }
}
</style>