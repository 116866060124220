<template>
    <div class="faceswap_edit_filePreview_container">
        <div class="wrapper" ref="wrapperDomTag">
            <VOCImageView 
                v-show="isPhoto" 
                ref="imageDOMTag"
                class="image" 
                :objectFit="'contain'"
                :requestInfo="imageRequest"
                @click.stop="fullPreview">
                <template #failed>
                        <div @click.stop="reloadImage" class="retry-item">
                            <img class="retry-img" :src="$utility.assetImage('retry_small')" />
                            <p>{{ $t('choose.retry') }}</p>
                        </div>
                </template>
            </VOCImageView>
            <HlsVideoView 
                v-show="!isPhoto"  
                class="video"
                ref="videoDOMTag" 
                :key="faceSwap.currentSelectedFile.file.taskId" 
                :videoSrc="faceSwap.currentSelectedFile.file.fileUrl"/>
        </div>
        <FileSwiper v-if="swiperVisible" v-model:visible="swiperVisible" :files="fullSourceData" :index="0" :showDelete="false"/>
    </div>
</template>

<script setup>
import { useFaceSwap } from '@/stores/useFaceSwap';
import { VOCImageRequestInfo } from '@/script/voc_img_tool';
import VOCImageView from '@/components/VOCImageView.vue';
import HlsVideoView from '@/components/HlsVideoView.vue';
import FileSwiper from '@/components/common/swiper/FileSwiper.vue';
import { ref, watch, computed, onMounted, onUpdated, onBeforeUnmount } from 'vue';
import { uploadFilesVM } from '@/vm/faceswap_vm';

const faceSwap = useFaceSwap();
const videoDOMTag = ref(null);
const wrapperDomTag = ref(null);
const imageDOMTag = ref(null);
const swiperVisible = ref(false);
const isPhoto = computed(() => {
    return faceSwap.currentSelectedFile.file.fileType == 'image';
});
const imageRequest = computed(() => {
    return new VOCImageRequestInfo({ url: faceSwap.currentSelectedFile.file.fileUrl });
});
const fullSourceData = computed(() => {
    return [faceSwap.currentSelectedFile];
});

watch(() => {
    return faceSwap.pausePreviewVideoFlag;
}, () => {
    if (!isPhoto.value) {
        videoDOMTag.value.pause();
    }
});

onMounted(() => {   
    updateLayout();
    window.addEventListener('resize', updateLayout);
});  

onUpdated(() => {
    updateLayout();
});

onBeforeUnmount(() => {
    window.removeEventListener('resize', updateLayout);
});

function updateLayout () {
    if (faceSwap.currentSelectedFile == null) return;
    const file = faceSwap.currentSelectedFile.file;
    let parentDOMNode = wrapperDomTag.value.parentNode;
    let parentRatio = parentDOMNode.offsetWidth / parentDOMNode.offsetHeight;
    let ratio = file.fileWidth / file.fileHeight;
    wrapperDomTag.value.style.aspectRatio = `${ratio}`;
    if (ratio / parentRatio > 1) {
        wrapperDomTag.value.style.width = 'calc(100% - 60px)';
        wrapperDomTag.value.style.height = null;
    }else {
        wrapperDomTag.value.style.width = null;
        wrapperDomTag.value.style.height = 'calc(100% - 20px)';
    }
}

function reloadImage (e) {
    imageDOMTag.value.loadImage();
}

function fullPreview (e) {
    swiperVisible.value = true;
}

</script>

<style scoped lang="scss">
.faceswap_edit_filePreview_container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    .video {
        width: 100%;
        height: 100%;
    }
    .image {
        width: 100%;
        height: 100%;
    }
}
.retry-item {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    color: #888A98;
    margin: 14px 0 0;
    cursor: pointer;
}
</style>